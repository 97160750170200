export const SimpleMetric = ({ name, value, description, type }) => {
  if (type === 'currency' && !isNaN(parseFloat(value))) {
    value = `$${(
      Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100
    ).toFixed(2)}`
  }
  if (!name || !value)
    throw new Error('Simple Metric must have name and value keys.')

  return (
    <svg
      width='180px'
      height='248px'
      viewBox='0 0 216 248'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <polygon
          id='path-1'
          points='120 0 223.923048 60 223.923048 180 120 240 16.0769515 180 16.0769515 60'
        ></polygon>
      </defs>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='PDP-Usage-Stats-1'
          transform='translate(-514.000000, -275.000000)'
        >
          <g id='Group' transform='translate(502.000000, 277.000000)'>
            <g>
              <use
                fill='black'
                fillOpacity='1'
                filter='url(#filter-2)'
                xlinkHref='#path-1'
              ></use>
              <use fill='#FBFBFB' fillRule='evenodd' xlinkHref='#path-1'></use>
            </g>
            <text
              transform='translate(100)'
              id='2200+'
              fontFamily='TradeGothic'
              fontSize={
                value && value.toString().length > 10
                  ? 36 - (value.toString().length - 10)
                  : 36
              }
              fontStyle='condensed'
              fontWeight='bold'
              fill='#5197D6'
            >
              <tspan
                style={{ wordWrap: 'break-word' }}
                textAnchor='middle'
                x='19.468'
                y='109'
              >
                {value && value.toString()}
              </tspan>
            </text>
            <foreignObject x='45' y='150' width='150' height='200'>
              <p data-tip={description}>{name}</p>
            </foreignObject>
            <path
              d='M88.5,130.5 L152,130.5'
              id='Line-6'
              stroke='#FC6200'
              strokeWidth='5'
              strokeLinecap='round'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
