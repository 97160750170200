exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".epic-font-base___3J2i5 {\n  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-base-md___Z3H0T {\n  font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif;\n}\n.epic-font-marketing___u_xD2 {\n  font-family: 'Futura', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-brand___21d91 {\n  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.field___1B6Ud {\n  display: inline-block;\n}\n.field___1B6Ud input {\n  display: inline;\n  text-align: center;\n}\n.field___1B6Ud.month___RGYUb input {\n  width: 50px;\n  padding: 0;\n}\n.field___1B6Ud.day___F9-a0 input {\n  width: 50px;\n  padding: 0;\n}\n.field___1B6Ud.year___1ahRR input {\n  width: 80px;\n  padding: 0;\n}\n.slash___12tTk {\n  font-size: 20px;\n  color: #b2b2b4;\n  margin: 0 5px;\n}\n.field___1B6Ud::-webkit-outer-spin-button,\n.field___1B6Ud::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n.field___1B6Ud[type=number] {\n  -moz-appearance: textfield;\n}\n", ""]);

// Exports
exports.locals = {
	"epic-font-base": "epic-font-base___3J2i5",
	"epic-font-base-md": "epic-font-base-md___Z3H0T",
	"epic-font-marketing": "epic-font-marketing___u_xD2",
	"epic-font-brand": "epic-font-brand___21d91",
	"field": "field___1B6Ud",
	"month": "month___RGYUb",
	"day": "day___F9-a0",
	"year": "year___1ahRR",
	"slash": "slash___12tTk"
};