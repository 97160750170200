import React, { useState, useEffect } from 'react'
import { generate as shortid } from 'shortid'
import { getDAGNames } from 'store/airflow'
import FormError from 'components/FormError/FormError.js'
import Button from 'components/Button/Button.js'
import validate from './Validation/ValidateHelpers.js'
import '../Settings.scss'
import './Notifications.scss'
import ContactNotification from './Services/Contact/ContactNotification.js'
import helper from './Config/NotificationsHelper.js'
import PagerDutyNotification from './Services/PagerDuty/PagerDutyNotification.js'
import ServiceNowNotification from './Services/ServiceNow/ServiceNowNotification.js'
import ExcludeDags from './Services/ExcludeDags/ExcludeDags.js'
import IncludeDags from './Services/IncludeDags/IncludeDags.js'

const Notifications = ({
  isCreate = false,
  notifications,
  onChange,
  validation,
  onSave,
  savePending,
  saveDisabled,
  disabled,
  error,
  region,
  cluster,
}) => {
  const [dags, setDags] = useState([])
  const [dagsRequested, setDagsRequested] = useState(false)

  const newNotification = {
    tmpId: shortid(),
    service: 'email',
    contact: '',
    event_type: 'airflow.webserver.failure',
    meta_data: '',
  }
  const workerNotificationExists =
    validate.workerNotificationExists(notifications)

  useEffect(() => {
    // only fetch dags if the cluster has at least one notification
    // with an event_type that contains 'dag'
    // only fetch dags once
    // do not fetch dags if the user is creating a new cluster
    if (
      !isCreate &&
      !dagsRequested &&
      notifications.some(n => n.event_type.includes('dag'))
    ) {
      setDagsRequested(true)
      getDagNames()
    }
  }, [notifications])

  const getDagNames = () => {
    return getDAGNames(region.value, cluster.cluster_name)
      .then(res => {
        setDags(res?.map(dag => ({ value: dag.dag_id, label: dag.dag_id })))
      })
      .catch(err => setDags([]))
  }

  return (
    <div className='settings-form-section' id='section-notifications'>
      <h3 className='settings-form-section-title'>NOTIFICATIONS</h3>

      <div className='settings-form-section-inner'>
        <div className='notification-description'>
          <p className='settings-form-section-description'>
            Register for notifications in the event of service disruptions or
            Airflow issues.
          </p>

          {error && <FormError error={error} />}
          {!workerNotificationExists && (
            <FormError
              error={[
                'Cluster must have at least one Cluster event Notification',
              ]}
            />
          )}
        </div>

        {notifications.map((notification, i) => (
          <div className='notification-config' key={i}>
            <div className='notification-config-container'>
              <div className='notification-config-column left-column'>
                <div className='notification-config-input'>
                  <ContactNotification
                    index={i}
                    notification={notification}
                    validation={validation}
                    updateCallback={(type, item = {}) => {
                      helper.updateFields(
                        type,
                        item,
                        i,
                        notifications,
                        onChange
                      )
                    }}
                    disabled={disabled}
                  />
                </div>
                <div className='notification-extra-input'>
                  {!isCreate &&
                    notification.event_type === 'dag.failure' &&
                    notification.service === 'service_now' && (
                      <IncludeDags
                        index={i}
                        notification={notification}
                        validation={validation}
                        updateCallback={(type, item = {}) => {
                          helper.updateFields(
                            type,
                            item,
                            i,
                            notifications,
                            onChange
                          )
                        }}
                        disabled={disabled}
                        dags={dags}
                      />
                    )}
                </div>
                <div className='notification-extra-input'>
                  {!isCreate && notification.event_type.includes('dag') && (
                    <ExcludeDags
                      index={i}
                      notification={notification}
                      validation={validation}
                      updateCallback={(type, item = {}) => {
                        helper.updateFields(
                          type,
                          item,
                          i,
                          notifications,
                          onChange
                        )
                      }}
                      disabled={disabled}
                      dags={dags}
                    />
                  )}
                  <PagerDutyNotification
                    notification={notification}
                    updateCallback={(type, item = {}) => {
                      helper.updateFields(
                        type,
                        item,
                        i,
                        notifications,
                        onChange
                      )
                    }}
                    disabled={disabled}
                  />

                  <ServiceNowNotification
                    notification={notification}
                    updateCallback={(type, item = {}) => {
                      helper.updateFields(
                        type,
                        item,
                        i,
                        notifications,
                        onChange
                      )
                    }}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className='notification-config-column'>
                <Button
                  id='notification-config-delete'
                  className='notification-config-delete'
                  onClick={() =>
                    helper.updateNotifications(
                      onChange,
                      notifications.filter((n, ni) => ni !== i)
                    )
                  }
                  disabled={notifications.length === 1}
                >
                  <i className='fa fa-trash-o'></i>
                </Button>
              </div>
            </div>
          </div>
        ))}

        <div className='button-row'>
          <Button
            onClick={() =>
              helper.updateNotifications(onChange, [
                ...notifications,
                newNotification,
              ])
            }
            className='list-add-button'
            id='list-add-button'
          >
            ADD NOTIFICATION
          </Button>

          {onSave && (
            <Button
              onClick={onSave}
              pending={savePending}
              locked={disabled}
              disabled={
                saveDisabled || validate.validate_notification(validation)
              }
            >
              SAVE CHANGES
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Notifications
