import React, { Component } from 'react'
import FormField from '../FormField/FormField.js'
import Tooltip from '../Tooltip/Tooltip.js'
import './KVTags.scss'

export default class KVTags extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  keyHandleKeyDown = (e, i) => {
    if (e && e.key === 'Backspace' && this.props.tags[i].key === '') {
      if (i > 0) {
        this[`ref-val-${i - 1}`].current.focus()
      }
      return this.deleteTag()
    }
  }

  valHandleKeyDown = (e, i) => {
    if (e && e.key === 'Backspace' && this.props.tags[i].value === '') {
      this[`ref-key-${i}`].current.focus()
    }
    if (e.key === 'Tab' && this.props.tags.length - 1 === i) {
      this.addTag()
    }
  }

  deleteTag = i => {
    let tags = JSON.parse(JSON.stringify(this.props.tags))
    tags.splice(i, 1)
    return this.props.onChange(tags)
  }

  addTag = () =>
    this.props.onChange([...this.props.tags, { key: '', value: '' }])

  handleChange = (index, target, value) => {
    // Deep Clone to avoid mutating parent prop
    let tags = JSON.parse(JSON.stringify(this.props.tags))
    tags[index][target] = this.sanitize(value)
    return this.props.onChange(tags)
  }

  sanitize = text =>
    text
      .toLowerCase()
      .replace(/[ _\\/|]/gi, '-')
      .replace(/[^0-9a-z-]/gi, '')

  // 8.3 ~= monospace font width @ 14px/14px
  calcWidth = (padding, placeholderLen, text) =>
    padding + (text.length || placeholderLen) * 8.3
  widthStyle = width => ({ flex: `0 0 ${width}px` })

  render() {
    const {
      className,
      label,
      detail,
      error,
      tags,
      disabled,
      tooltipText,
      required,
    } = this.props
    const minus = 20
    const keyPadding = 22
    const valPadding = 32
    const keyPlaceholderLen = 3 // 'key'
    const valPlacehodlerLen = 5 // 'value'

    return (
      <FormField
        className={className}
        label={label}
        detail={detail}
        required={required}
        error={error}
      >
        <div className="alt-kv-input">
          <div
            className={`alt-kv-tags${disabled ? ' alt-kv-tags-disabled' : ''}`}
          >
            {tags.map((tag, i) => (
              <div
                className="alt-kv-tag"
                key={i}
                style={this.widthStyle(
                  this.calcWidth(keyPadding, keyPlaceholderLen, tag.key) +
                    this.calcWidth(valPadding, valPlacehodlerLen, tag.value) +
                    minus
                )}
              >
                <div className="alt-kv-remove">
                  <i
                    className="fa fa-times-circle"
                    onClick={() => !disabled && this.deleteTag(i)}
                  ></i>
                </div>

                <div
                  className="alt-kv-tag-key"
                  style={this.widthStyle(
                    this.calcWidth(keyPadding, keyPlaceholderLen, tag.key)
                  )}
                >
                  <input
                    ref={(this[`ref-key-${i}`] = React.createRef())}
                    value={tag.key}
                    onKeyDown={e => !disabled && this.keyHandleKeyDown(e, i)}
                    onChange={e =>
                      !disabled && this.handleChange(i, 'key', e.target.value)
                    }
                    disabled={disabled}
                    placeholder="key"
                  />
                </div>

                <div
                  className="alt-kv-tag-value"
                  style={this.widthStyle(
                    this.calcWidth(valPadding, valPlacehodlerLen, tag.value)
                  )}
                >
                  <input
                    ref={(this[`ref-val-${i}`] = React.createRef())}
                    value={tag.value}
                    onKeyDown={e => !disabled && this.valHandleKeyDown(e, i)}
                    onChange={e =>
                      !disabled && this.handleChange(i, 'value', e.target.value)
                    }
                    disabled={disabled}
                    placeholder="value"
                  />
                </div>
              </div>
            ))}

            <div className="alt-kv-add">
              <i
                className="fa fa-plus-circle"
                onClick={() => !disabled && this.addTag()}
              ></i>
            </div>
          </div>
          <Tooltip tooltipText={tooltipText} />
        </div>
      </FormField>
    )
  }
}