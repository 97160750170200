import React, { Component } from 'react'
import './OfflineBanner.scss'

export default class ClusterHealth extends Component {
  constructor(...props) {
    super(...props)
    this.state = {
      online: navigator.onLine,
    }
  }

  componentDidMount() {
    window.addEventListener('online', this.goOnline)
    window.addEventListener('offline', this.goOffline)
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.goOnline)
    window.removeEventListener('offline', this.goOffline)
  }

  goOnline = () => this.setState({ online: true })
  goOffline = () => this.setState({ online: false })

  render() {
    const { online } = this.state

    return !online ? (
      <p className="offline-banner">
        <i className="fa fa-exclamation-circle"></i>
        <span className="offline-banner-bold">OFFLINE MODE</span>
        functionality may be limited
      </p>
    ) : null
  }
}
