import { getDag } from 'store/dags.js'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { Base64 } from 'js-base64'

async function getDagInfo(region, clusterId, fileName) {
  const data = await getDag(region, clusterId, fileName)

  return {
    name: fileName,
    content: Base64.decode(data.encoded_content),
  }
}

function getDateString() {
  const date = new Date()
  const year = date.getFullYear()
  const month = `${date.getMonth() + 1}`.padStart(2, '0')
  const day = `${date.getDate()}`.padStart(2, '0')
  return `${year}_${month}_${day}`
}

function generateFileName(clusterName) {
  return `DAGs_${clusterName}_${getDateString()}`
}

const downloadDags = async function downloadDags(
  region,
  clusterId,
  selected,
  clusterName
) {
  let response = false

  try {
    var zip = new JSZip()
    Promise.all(selected.map(x => getDagInfo(region, clusterId, x)))
      .then(responses => responses.map(res => zip.file(res.name, res.content)))
      .then(() => zip.generateAsync({ type: 'blob' }))
      .then((blob) => saveAs(blob, `${generateFileName(clusterName)}.zip`))

    response = true
  } catch (err) {
    console.log(err)
  }

  return response
}

const exportFunctions = {
  downloadDags,
}

export default exportFunctions
