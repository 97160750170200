import React from 'react'
import { aeGroups } from 'store/ae'
import { cloudredIdentityRoles } from 'store/cloudred.js'
import validate from '../Validation/ValidateHelpers.js'
import { generate as shortid } from 'shortid'

const loadAeGroups = async function loadAeGroups(setGroups, region, user) {
  let success = false

  try {
    await aeGroups(region.value, user.email)
      .then(data => {
        setGroups(
          data
            // select expects label & value even if they're the same.
            .map(group => ({ label: group, value: group }))
            .sort((a, b) => (a.label > b.label ? 1 : -1))
        )
        success = true
      })
      .catch(() => {
        setGroups([])
      })
  } catch (err) {
    console.log(err)
  }

  return success
}

const loadCloudredIdentityRoles = async function loadCloudredIdentityRoles(
  setState,
  user
) {
  let success = false

  try {
    await cloudredIdentityRoles(user.username)
      .then(data => {
        setState(prev => ({
          ...prev,
          adRoles: data
            .map(role => ({
              label: role.full_name,
              value: role.full_name,
            }))
            .sort((a, b) => (a.label > b.label ? 1 : -1)),
          adRolesLoading: false,
        }))
        success = true
      })
      .catch(() => {
        setState(prev => ({
          ...prev,
          adRolesLoading: false,
        }))
      })
  } catch (err) {
    console.log()
  }

  return success
}

const loadExtraRolesLanding = function loadExtraRolesLanding(
  aegisGroups,
  setState,
  state
) {
  let success = false

  try {
    if (state.search && state.search.length >= 3) {
      setState(prev => ({
        ...prev,
        extraRolesLoading: true,
      }))

      aegisGroups(state.search)
        .then(roles =>
          setState(prev => ({
            ...prev,
            extraRoles: roles
              .map(role => ({
                label: role.displayName,
                value: role.displayName,
              }))
              .filter(
                role =>
                  !state.adRoles.some(adRole => adRole.value === role.value)
              )
              .sort((a, b) => (a.label > b.label ? 1 : -1)),
            extraRolesLoading: false,
          }))
        )
        .catch(() =>
          setState(prev => ({
            ...prev,
            extraRoles: [],
            extraRolesLoading: false,
          }))
        )
    }
    success = true
  } catch (err) {
    console.log(err)
  }

  return success
}

const loadExtraEmailsLanding = function loadExtraEmailsLanding(
  aegisUsers,
  setState,
  state
) {
  let success = false

  try {
    if (state.search && state.search.length >= 3) {
      setState(prev => ({
        ...prev,
        extraRolesLoading: true,
      }))

      aegisUsers(state.search)
        .then(roles =>
          setState(prev => ({
            ...prev,
            extraRoles: [...roles, { id: state.search }]
              .map(role => ({
                label: role.id,
                value: role.id,
              }))
              .sort((a, b) => (a.label > b.label ? 1 : -1)),
            extraRolesLoading: false,
          }))
        )
        .catch(() =>
          setState(prev => ({
            ...prev,
            extraRoles: [],
            extraRolesLoading: false,
          }))
        )
    }
    success = true
  } catch (err) {
    console.log(err)
  }

  return success
}

const manageMembers = function manageMembers(onChange, value) {
  let success = false

  try {
    onChange(value, validate.validateUserAccess(value))
    success = true
  } catch (err) {
    console.log(err)
  }

  return success
}

const updateMember = function updateMember(
  type,
  item,
  i,
  members,
  onChange,
  ae_compute_integration = false
) {
  switch (type) {
    case 'type':
      exportFunctions.manageMembers(
        onChange,
        members.map((m, mi) =>
          mi !== i
            ? m
            : {
              ...m,
              type: item.value,
              sub: '',
              ad_group: '',
              role:
                ae_compute_integration && item.value === 'ad_group'
                  ? 'user'
                  : m.role,
            }
        )
      )
      break

    case 'sub':
      exportFunctions.manageMembers(
        onChange,
        members.map((m, mi) => (mi !== i ? m : { ...m, sub: item.value }))
      )
      break

    case 'ad_group':
      exportFunctions.manageMembers(
        onChange,
        members.map((m, mi) => (mi !== i ? m : { ...m, ad_group: item.value }))
      )
      break

    case 'role':
      exportFunctions.manageMembers(
        onChange,
        members.map((m, mi) =>
          mi !== i ? m : { ...m, role: item.value, ad_group: '' }
        )
      )
      break

    default:
      return 'error'
  }
}

const removeMember = function removeMember(i, members, onChange) {
  let success = false

  try {
    if (
      exportFunctions.manageMembers(
        onChange,
        members.filter((m, mi) => mi !== i)
      )
    )
      success = true
  } catch (err) {
    console.log(err)
  }

  return success
}

const addMember = function addMember(members, onChange) {
  let success = false

  try {
    if (
      exportFunctions.manageMembers(onChange, [
        ...members,
        {
          type: 'ad_group',
          role: 'user',
          ad_group: '',
          sub: '',
          tmpId: shortid(),
        },
      ])
    )
      success = true
  } catch (err) {
    console.log(err)
  }

  return success
}

const isDeleteDisabled = function isDeleteDisabled(i, members) {
  let isDisabled = false

  if (members.length === 1) {
    // if length of ad groups is only one, disable delete
    isDisabled = true
  } else if (
    members[i].role === 'admin' &&
    members.filter(member => member.role === 'admin').length <= 1
  ) {
    // if num of members with the role admin is less than or equal to 1, then disable delete
    isDisabled = true
  }

  return isDisabled
}

const isSaveDisabled = function isSaveDisabled(
  saveDisabled,
  validation,
  noAdminRole
) {
  return saveDisabled || validation.filter(Boolean).length > 0 || noAdminRole
}

const returnSelectOptions = function returnSelectOptions(
  ae_compute_integration,
  member,
  groups,
  state
) {
  return ae_compute_integration && member.role === 'admin' && member.type !== 'sub'
    ? groups
    : [...state.adRoles, ...state.extraRoles]
}

const onSelectInputChange = function onSelectInputChange(setState, val) {
  try {
    setState(prev => ({
      ...prev,
      search: val,
    }))
  } catch (err) {
    console.log(err)
    return false
  }
}

const formatOptionLabel = function formatOptionLabel(state, value, label) {
  try {
    return (
      <div className="ad-group-option">
        {label}
        {!state.adRoles.some(role => role.value === value) && (
          <i className="fa fa-user-times" />
        )}
      </div>
    )
  } catch (err) {
    return false
  }
}

const exportFunctions = {
  loadAeGroups,
  loadCloudredIdentityRoles,
  loadExtraRolesLanding,
  loadExtraEmailsLanding,
  manageMembers,
  updateMember,
  removeMember,
  addMember,
  isDeleteDisabled,
  isSaveDisabled,
  returnSelectOptions,
  onSelectInputChange,
  formatOptionLabel,
}

export default exportFunctions
