import React from 'react'
import ReactSelect from 'react-select'
import Spinner from 'components/AltSpinner/AltSpinner.js'
import FormField from '../FormField/FormField.js'
import './SelectInput.scss'
import Tooltip from 'components/Tooltip/Tooltip.js'

export default ({
  className,
  label,
  loading,
  required,
  detail,
  error,
  options,
  value,
  disabled,
  onChange,
  onInputChange,
  onFocus,
  onBlur,
  formatOptionLabel,
  tooltipText,
  defaultValue,
  isOptionDisabled,
}) => (
  <FormField
    className={className}
    label={label}
    required={required}
    detail={detail}
    error={error}
  >
    <div className='alt-text-input'>
      <ReactSelect
        className='alt-select'
        classNamePrefix='select'
        defaultValue={defaultValue}
        options={options}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        onInputChange={onInputChange}
        value={value}
        formatOptionLabel={formatOptionLabel}
        isDisabled={disabled}
        isOptionDisabled={isOptionDisabled}
        components={{
          ...(loading && {
            DropdownIndicator: () => <Spinner className='alt-select-spinner' />,
          }),
        }}
      />
      <Tooltip tooltipText={tooltipText} />
    </div>
  </FormField>
)
