import v1_10_10_p36_reqsFile from '../../../../map-custom-ops/1.10.10-python36/requirements.txt'
import v2_2_5_p38_reqsFile from '../../../../map-custom-ops/2.2.5-python38/requirements.txt'

const transformResponse = function transformResponse(version, fileText) {
  let response = {}
  response[version] = []

  let processedFileText = fileText.split('\n')
  processedFileText = processedFileText.filter(item => item.includes('=='))

  processedFileText.forEach(item => {
    let newAttribute = item.split('==')
    response[version].push({
      package: newAttribute[0],
      version: newAttribute[1],
    })
  })

  return response
}

const getImagePackageData = function getImagePackageData() {
  let requirements = {}

  ;[
    { url: v1_10_10_p36_reqsFile, version: '1.10.10-python36' },
    { url: v2_2_5_p38_reqsFile, version: '2.2.5-python38' },
  ].forEach(async obj => {
    try {
      await fetch(obj.url)
        .then(r => r.text())
        .then(text => {
          Object.assign(requirements, transformResponse(obj.version, text))
        })
    } catch (err) {
      console.log('ERROR: ', err)
    }
  })

  return requirements
}

const exportFunctions = {
  getImagePackageData,
  transformResponse,
}

export default exportFunctions
