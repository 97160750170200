exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".epic-font-base___QQs7m,\n.section___1nmJ7 .header___JIa6f h3 {\n  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-base-md___2Cwtj {\n  font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif;\n}\n.epic-font-marketing___3QbhS {\n  font-family: 'Futura', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-brand___1R60b,\n.section___1nmJ7 .header___JIa6f h1 {\n  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.section___1nmJ7 {\n  position: relative;\n  background-color: #f7f7f7;\n  border-radius: 1em;\n  padding: 30px 9%;\n  margin-bottom: 30px;\n}\n.section___1nmJ7 .header___JIa6f {\n  margin-bottom: 0;\n  padding: 10px;\n}\n.section___1nmJ7 .header___JIa6f h1 {\n  text-align: left;\n  text-transform: uppercase;\n  font-size: 24px;\n  margin: 0;\n}\n.section___1nmJ7 .header___JIa6f h1 .done___WdZDH {\n  color: #48bd1f;\n}\n.section___1nmJ7 .header___JIa6f h3 {\n  margin: 0;\n  color: #c7c7c7;\n  text-align: left;\n  font-size: 16px;\n}\n.sectionContent___2IRIr {\n  padding: 10px;\n  margin-top: 50px;\n}\n.fullContent___3RcRJ {\n  margin-top: 0;\n}\n.collapseIcon___2lIit {\n  line-height: 1.5;\n  color: #6d6d6d;\n  position: absolute;\n  top: 15px;\n  right: 50px;\n  cursor: pointer;\n  padding: 10px;\n}\n.collapseIcon___2lIit.plus___3tRsa {\n  font-size: 40px;\n}\n.collapseIcon___2lIit.minus___dKLSa {\n  top: 5px;\n  font-size: 60px;\n}\n", ""]);

// Exports
exports.locals = {
	"epic-font-base": "epic-font-base___QQs7m",
	"section": "section___1nmJ7",
	"header": "header___JIa6f",
	"epic-font-base-md": "epic-font-base-md___2Cwtj",
	"epic-font-marketing": "epic-font-marketing___3QbhS",
	"epic-font-brand": "epic-font-brand___1R60b",
	"done": "done___WdZDH",
	"sectionContent": "sectionContent___2IRIr",
	"fullContent": "fullContent___3RcRJ",
	"collapseIcon": "collapseIcon___2lIit",
	"plus": "plus___3tRsa",
	"minus": "minus___dKLSa"
};