import validate from 'validate.js'

const validateIAMRoleARN = value =>
  value.length > 0 &&
  validate.single(
    value,
    {
      format: {
        pattern: /^arn:aws(?:-cn|):iam::[0-9]+.*$/,
        message: 'AWS ARN must match format arn:aws(?:-cn|):iam::[0-9]+.*',
      },
    },
    { fullMessages: false }
  )

const validateImage = value =>
  value &&
  value.length > 0 &&
  validate.single(
    value,
    {
      format: {
        pattern: /^.*(amazonaws\.com|artifactory).*$/,
        message: 'Image must be hosted by amazonaws.com or Nike-artifactory',
      },
    },
    { fullMessages: false }
  )

export default (key, value) =>
  ({
    iam_role_arn: validateIAMRoleARN,
    image: validateImage,
  }[key](value))
