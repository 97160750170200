import React from 'react'
import './FormField.scss'

export default ({ className, label, required, detail, error, children }) => (
  <div className={`alt-form-field${className ? ' ' + className : ''}`}>
    <div className="alt-field-label">
      <label className={required ? 'alt-field-label-required' : ''}>
        {label}
      </label>
    </div>
    {detail && <p className="alt-field-detail">{detail}</p>}
    {children}
    {error && <p className="alt-field-error">{error}</p>}
  </div>
)
