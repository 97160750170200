import { http, digResponse, getRequestError } from 'util/index.js'

const encode = encodeURIComponent

export const getEvents = (api, clusterId, queryString) =>
  http
    .get(`${api}/clusters/${encode(clusterId)}/events?${queryString}`)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'getEvents')
    })
