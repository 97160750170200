import styles from './Feedback.styl'
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ScreenShot from './deps/Screenshot.js'
import { Modal, Overlay, PageSpinner } from '../../main.js'

class Feedback extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      toastMessage: '',
      feedbackMessage: '',
      screenShotCanvas: null,
      feedbackWasEmpty: false,
      screenShotActive: false,
      screenShotWasEmpty: false,
      processingScreenshot: false,
      shouldIncludeScreenshot: props.defaultToIncludeScreenshot
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.keyDown)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.keyDown)
  }

  keyDown = e => {
    if (e.key === 'Escape') {
      this.closeDrawer()
    }
  }

  toast = cb => {
    setTimeout(() => {
      cb()
    }, 2000)
  }

  activateScreenshot = () => {
    if (typeof this.props.onScreenshotActivate === 'function') {
      this.props.onScreenshotActivate()
    }
    this.setState({ screenShotActive: true })
  }

  closeDrawer = () => {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose()
    }
    this.setState({ active: false })
  }

  openDrawer = () => {
    if (typeof this.props.onOpen === 'function') {
      this.props.onOpen()
    }
    this.setState({ active: true })
  }

  resetState = () => {
    this.setState({
      active: false,
      toastMessage: '',
      feedbackMessage: '',
      screenShotCanvas: null,
      overlayId: 'screenshot',
      feedbackWasEmpty: false,
      screenShotActive: false,
      screenShotWasEmpty: false,
      processingScreenshot: false,
      shouldIncludeScreenshot: false
    })
  }

  onSnapShot = canvas => {
    this.setState({
      processingScreenshot: false,
      toastMessage: 'Screenshot Captured!'
    })
    this.toast(() => this.removeCanvasState(canvas))
  }

  onProcessing = doc => {
    this.setState({ processingScreenshot: true })
    if (typeof this.props.onProcessing === 'function') {
      this.props.onProcessing(doc)
    }
  }

  // resets back to 'feedback' state, saves canvas url to state
  removeCanvasState = canvas => {
    this.setState({
      toastMessage: '',
      screenShotActive: false,
      screenShotCanvas: canvas.toDataURL('image/jpg') // save canvas DataURL to State from screen shot component
    })
  }

  // renders image in feedback drawer
  renderScreenShot = (screenShotCanvas, shouldIncludeScreenshot) => {
    if (shouldIncludeScreenshot && !screenShotCanvas) {
      return this.props.previewImage ? (
        <img crossOrigin="anonymous" src={this.props.previewImage} />
      ) : (
        <span className={styles.imagePlaceholder}>Take Screenshot</span>
      )
    }
    return screenShotCanvas && shouldIncludeScreenshot ? (
      <img crossOrigin="anonymous" src={screenShotCanvas} />
    ) : null
  }

  hasEmptyFields = shouldIncludeScreenshot => {
    let hasEmptyValues = false
    if (!this.state.feedbackMessage) {
      this.setState({ feedbackWasEmpty: true, feedbackMessage: '' })
      hasEmptyValues = true // allow through to check for other errors
    }
    if (shouldIncludeScreenshot && !this.state.screenShotCanvas) {
      this.setState({ screenShotWasEmpty: true })
      hasEmptyValues = true
    }
    return hasEmptyValues
  }

  handleSendFeedback = async shouldIncludeScreenshot => {
    // Quick validation
    if (this.hasEmptyFields(shouldIncludeScreenshot)) return

    // show loader
    this.setState({
      processingScreenshot: true,
      active: false
    })

    this.trySendFeedback()
  }

  trySendFeedback = async () => {
    let message

    try {
      let res = await this.props.onSend(
        this.state.feedbackMessage,
        this.state.screenShotCanvas
      )
      message = this.props.successMessage
      if (this.props.onSendSuccess) {
        this.props.onSendSuccess(res)
      }
    } catch (err) {
      message = this.props.failureMessage
      if (this.props.onSendFailure) {
        this.props.onSendFailure(err)
      }
    } finally {
      this.onCompleted(message)
    }
  }

  onCompleted = message => {
    this.toast(this.resetState)
    this.setState({
      processingScreenshot: false,
      toastMessage: message
    })
  }

  render() {
    const {
      active,
      toastMessage,
      feedbackMessage,
      screenShotActive,
      screenShotCanvas,
      feedbackWasEmpty,
      screenShotWasEmpty,
      processingScreenshot
    } = this.state

    const {
      className,
      attributes,
      overlayClassname,
      html2canvasConfig,
      defaultToIncludeScreenshot
    } = this.props

    const shouldIncludeScreenshot =
      this.state.shouldIncludeScreenshot || defaultToIncludeScreenshot
    const takeOrRetake = screenShotCanvas ? 'Retake' : 'Take'

    // Screenshot mode
    if (screenShotActive) {
      return (
        <Fragment>
          <ScreenShot
            onProcessing={this.onProcessing}
            onSnapShot={this.onSnapShot}
            html2canvasConfig={html2canvasConfig}
          />
          <Modal
            closeButton={false}
            fadeIn={false}
            className={styles.confirmModal}
            overlayClassname={styles.confirmOverlay}
            show={!!toastMessage}
            title={toastMessage}
          />
        </Fragment>
      )
    }

    return (
      <div className={classnames(styles.wrapper, className)}>
        <PageSpinner show={processingScreenshot} className={styles.topZ} />
        <Modal
          closeButton={false}
          fadeIn={false}
          className={styles.confirmModal}
          overlayClassname={styles.confirmOverlay}
          show={!!toastMessage}
          title={toastMessage}
        />
        <div
          className={`${styles.slideout} slideout ${
            active ? styles.active : ''
          }`}
        >
          <Overlay
            className={overlayClassname}
            show={active}
            onClick={this.closeDrawer}
          />
          <div
            className={`epic-font-brand ${styles.feedbackTab}`}
            onClick={this.openDrawer}
          >
            FEEDBACK
          </div>
          <div
            className={`${styles.slideoutInner} epic-font-base-md`}
            {...attributes}
          >
            <i
              className="epic-icon epic-icon-close-x"
              onClick={this.closeDrawer}
            />
            <p
              className={`${styles.feedbackError} ${
                feedbackWasEmpty && !feedbackMessage ? '' : styles.hide
              }`}
            >
              Please Enter Feedback Message.
            </p>
            <textarea
              onChange={e =>
                this.setState({
                  feedbackMessage: e.target.value,
                  feedbackWasEmpty: false
                })
              }
              value={feedbackMessage}
              maxLength="350"
              placeholder="Describe your issue or share your ideas"
            />
            <div className={styles.screenshotInput}>
              <input
                checked={shouldIncludeScreenshot}
                onChange={() =>
                  this.setState({
                    shouldIncludeScreenshot: !shouldIncludeScreenshot
                  })
                }
                id="screenshot-checkbox"
                type="checkbox"
              />
              <label
                className={styles.checkboxLabel}
                htmlFor="screenshot-checkbox"
              >
                Include Screenshot
              </label>
            </div>
            <p
              className={`${styles.feedbackImageError} ${
                screenShotWasEmpty &&
                !screenShotCanvas &&
                shouldIncludeScreenshot
                  ? ''
                  : styles.hide
              }`}
            >
              Please Supply Feedback Image.
            </p>
            <div className={styles.screenshotContainer}>
              {this.renderScreenShot(screenShotCanvas, shouldIncludeScreenshot)}
            </div>
            <div className={styles.submit}>
              {shouldIncludeScreenshot && !processingScreenshot ? (
                <a onClick={this.activateScreenshot}>
                  {takeOrRetake} Screenshot
                </a>
              ) : null}
              {processingScreenshot ? null : (
                <a
                  onClick={() =>
                    this.handleSendFeedback(shouldIncludeScreenshot)
                  }
                >
                  Send Feedback
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Feedback.propTypes = {
  /** Optional function that fires when feedback drawer is opened */
  onOpen: PropTypes.func,
  /** Optional function that fires when feedback drawer is close */
  onClose: PropTypes.func,
  /** function that fires when send feedback is clicked */
  onSend: PropTypes.func.isRequired,
  /** Optional function that fires when html2canvas is processing the dom to capture the screenshot "canvas"  */
  onProcessing: PropTypes.func,
  /** Optional configuration object passed to html2canvas, the library used to capture screenshots. View html2canvas docs above for more details. */
  html2canvasConfig: PropTypes.object,
  /** Optional function that fires when process is completed (after notification) */
  onScreenshotActivate: PropTypes.func,
  /** Function that fires when the onSend is successful. */
  onSendSuccess: PropTypes.func,
  /** Function that fires when the onSend produces an error. */
  onSendFailure: PropTypes.func,
  /** HTML attributes that will be applied to the Feedback Drawer */
  attributes: PropTypes.object,
  /** specifies whether the screenshot should default to including screenshot */
  defaultToIncludeScreenshot: PropTypes.bool,
  /** Image shown as demonstrative placeholder for upload image */
  previewImage: PropTypes.any,
  /** className for the overlay that appears as feedback slideout appears */
  overlayClassname: PropTypes.string,
  /** className for container div of feedback component */
  className: PropTypes.string,
  /** Message displayed to user when feedback send action is UNsuccessful */
  failureMessage: PropTypes.string,
  /** Message displayed to user when feedback send action is successful */
  successMessage: PropTypes.string
}

Feedback.defaultProps = {
  defaultToIncludeScreenshot: false,
  className: '',
  overlayClassname: '',
  failureMessage: 'Upload Failed',
  successMessage: 'Thanks for your feedback!',
  html2canvasConfig: {
    scale: 1,
    useCORS: true,
    logging: false,
    imageTimeout: 5000,
    ignoreElements: el => el.tagName === 'IFRAME'
  }
}

export default Feedback
