import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import config from 'envs/config'
import Button from 'components/Button/Button.js'
import Health from './Health/Health.js'
import ActionDialogue from './RestartCluster/RestartCluster.js'
import HelpDialogue from './HelpDialogue/HelpDialogue.js'
import './Sidebar.scss'
import queryString from 'qs'
import { Tooltip } from 'react-tooltip'

export default ({
  cluster,
  updateCluster,
  permissions,
  region,
  user,
  view,
}) => {
  const [closeSlackSupport, setCloseSlackSupport] = useState(false)
  const [actionOpen, setActionOpen] = useState(false)
  const [refreshHealth, setRefreshHealth] = useState(false)
  const [action, setAction] = useState({})
  const [isImageActive, setImageActive] = useState(
    view === '/image' ||
      JSON.parse(sessionStorage.getItem('map_sidebar_expanders'))?.image
  )
  const airflowLink = config.airflowAdmin(region.value, cluster.cluster_name)

  let pageShown = 'nav'
  if (!closeSlackSupport) {
    const parsed = queryString.parse(window.location.search)
    pageShown = parsed.page === 'slack' ? 'slack' : 'nav'
  }
  const [getHelpOpen, setGetHelpOpen] = useState(
    pageShown === 'slack' ? true : false
  )

  const handleRestartClose = () => {
    setAction({})
    setActionOpen(false)
  }

  const [copiedToClipboard, setCopiedToClipboard] = useState(false)

  const copyToClipboard = text => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedToClipboard(true)
        setTimeout(() => {
          setCopiedToClipboard(false)
        }, 2000)
      })
      .catch(err => console.error('Could not copy to clipboard: ', err))
  }

  useEffect(() => {
    if (action?.type) setActionOpen(true)
  }, [action])

  useEffect(() => {
    sessionStorage.setItem(
      'map_sidebar_expanders',
      JSON.stringify({ image: isImageActive })
    ) // add additional expanders here
  }, [isImageActive])

  return (
    <div className='dash-sidebar'>
      {actionOpen && (
        <ActionDialogue
          region={region}
          cluster={cluster}
          action={action}
          updateCluster={updateCluster}
          updateHealth={() => setRefreshHealth(true)}
          onClose={handleRestartClose}
        />
      )}

      {cluster.life_cycle !== 'updating' && (
        <>
          <Button
            className='btn-action'
            locked={!permissions['CLUSTER.UPDATE']}
            isDropdown
            dropDownTitle='Actions'
          >
            <Button.DropdownContent>
              <Tooltip id='cluster-content-tooltip' place='right' />
              <Button.DropdownItem>
                <div
                  className='cluster-restart'
                  data-tooltip-id='cluster-content-tooltip'
                  data-tooltip-content='Restart Cluster'
                  onClick={() =>
                    setAction({ type: 'Restart', resource: 'Cluster' })
                  }
                >
                  Restart
                </div>
              </Button.DropdownItem>
              {cluster.life_cycle !== 'stopped' && (
                <Button.DropdownItem>
                  <div
                    className='cluster-stop'
                    data-tooltip-id='cluster-content-tooltip'
                    data-tooltip-content='Stop Cluster Resources'
                    onClick={() =>
                      setAction({ type: 'Stop', resource: 'Cluster' })
                    }
                  >
                    Stop
                  </div>
                </Button.DropdownItem>
              )}
            </Button.DropdownContent>
          </Button>
        </>
      )}

      <div className='sidebar-title'>
        <p className='back'>CLUSTER</p>
        <div className='cluster-name-container'>
          <p className='cluster-name'>{cluster.cluster_name}</p>
          <Button
            className='copy-to-clipboard-button'
            borderless={false}
            dataTooltipId='copy-to-clipboard-in-sidebar'
            dataTooltipContent={
              copiedToClipboard ? 'Copied!' : 'Copy To Clipboard'
            }
            onClick={() => copyToClipboard(cluster.cluster_name)}
          >
            <Tooltip id='copy-to-clipboard-in-sidebar' place='bottom' />
            <i className='fa fa-copy' />
          </Button>
        </div>
      </div>

      <Health
        cluster={cluster}
        region={region}
        isChina={region.value?.includes('cn-')}
        restartScheduler={() =>
          setAction({ type: 'Restart', resource: 'Scheduler' })
        }
        restartAllowed={
          !['updating', 'stopped'].includes(cluster.life_cycle) &&
          !region.value?.includes('cn-')
        }
        restartLocked={!permissions['CLUSTER.UPDATE']}
        refreshHealth={refreshHealth}
        resetRefreshHealth={() => setRefreshHealth(false)}
      />

      <Link
        to={`/${region.value}/clusters/${cluster.cluster_name}`}
        className={`sidebar-link${view === '' ? ' active' : ''}`}
      >
        <i className='fa fa-tachometer'></i>DASHBOARD
      </Link>
      <Link
        to={`/${region.value}/clusters/${cluster.cluster_name}/dags`}
        className={`sidebar-link${view === '/dags' ? ' active' : ''}`}
      >
        <i className='fa fa-code-fork'></i>DAGs
      </Link>
      <Link
        to={`/${region.value}/clusters/${cluster.cluster_name}/operators`}
        className={`sidebar-link${view === '/operators' ? ' active' : ''}`}
      >
        <i className='fa fa-cube'></i>OPERATORS
      </Link>
      <Link
        to={`/${region.value}/clusters/${cluster.cluster_name}/files`}
        className={`sidebar-link${view === '/files' ? ' active' : ''}`}
      >
        <i className='fa fa-files-o'></i>FILES
      </Link>
      <Link
        to={`/${region.value}/clusters/${cluster.cluster_name}/costs`}
        className={`sidebar-link${view === '/costs' ? ' active' : ''}`}
      >
        <i className='fa fa-dollar'></i>COSTS
      </Link>
      <Link
        to={`/${region.value}/clusters/${cluster.cluster_name}/metrics`}
        className={`sidebar-link${view === '/metrics' ? ' active' : ''}`}
      >
        <i className='fa fa-pie-chart'></i>METRICS
      </Link>
      <Link
        to={`/${region.value}/clusters/${cluster.cluster_name}/logs`}
        className={`sidebar-link${view === '/logs' ? ' active' : ''}`}
      >
        <i className='fa fa-book'></i>LOGS
      </Link>
      <Link
        to={`/${region.value}/clusters/${cluster.cluster_name}/events`}
        className={`sidebar-link${view === '/events' ? ' active' : ''}`}
      >
        <i className='fa fa-user-secret'></i>EVENTS
      </Link>

      <Link
        to={`/${region.value}/clusters/${cluster.cluster_name}/image`}
        className={`sidebar-link${view === '/image' ? ' active' : ''}`}
        onClick={() => setImageActive(!isImageActive)}
      >
        <i className='fa fa-truck'></i>IMAGE
        {isImageActive ? (
          <i className='fa fa-minus sidebar-link-expander' />
        ) : (
          <i className='fa fa-plus sidebar-link-expander' />
        )}
      </Link>

      {isImageActive && (
        <>
          <Link
            to={`/${region.value}/clusters/${cluster.cluster_name}/packages`}
            className='sidebar-link sidebar-sublink'
          >
            <i className='fa fa-archive' />
            PACKAGES
          </Link>
          <Link
            to={`/${region.value}/clusters/${cluster.cluster_name}/buildscript`}
            className='sidebar-link sidebar-sublink'
          >
            <i className='fa fa-sticky-note' />
            BUILD SCRIPT
          </Link>
        </>
      )}
      <Link
        to={`/${region.value}/clusters/${cluster.cluster_name}/builds`}
        className={`sidebar-link${view === '/builds' ? ' active' : ''}`}
      >
        <i className='fa fa-space-shuttle'></i>BUILDS
      </Link>

      <Link
        to={`/${region.value}/clusters/${cluster.cluster_name}/config`}
        className={`sidebar-link${view === '/config' ? ' active' : ''}`}
      >
        <i className='fa fa-cogs'></i>CONFIG
      </Link>

      <Link
        to={`/${region.value}/clusters/${cluster.cluster_name}/settings`}
        className={`sidebar-link${view === '/settings' ? ' active' : ''}`}
      >
        <i className='fa fa-sliders'></i>SETTINGS
      </Link>

      <a
        className='sidebar-link sidebar-link-external'
        href={airflowLink}
        target='_blank'
        rel='noopener noreferrer'
      >
        AIRFLOW UI
      </a>

      {getHelpOpen && (
        <HelpDialogue
          onClose={() => {
            setGetHelpOpen(false)
            setCloseSlackSupport(true)
          }}
          cluster={cluster}
          email={user.email}
          region={region}
          pageShown={pageShown}
        />
      )}
      <Button className='btn-help' onClick={() => setGetHelpOpen(true)} primary>
        NEED HELP?
      </Button>
    </div>
  )
}
