import React, { Component } from 'react'
import { getEvents } from 'store/events.js'
import TextInput from 'components/TextInput/TextInput.js'
import DateRange from 'components/DateRange/DateRange.js'
import SelectInput from 'components/SelectInput/SelectInput.js'
import Button from 'components/Button/Button.js'
import Table from 'components/Table/Table.js'
import './Events.scss'
import Tooltip from 'components/Tooltip/Tooltip.js'

const eventCols = [
  {
    name: 'TIMESTAMP',
    key: 'created',
    maxWidth: 180,
  },
  {
    name: 'RESOURCE',
    key: 'resource',
    highlight: true,
  },
  {
    name: 'ACTION',
    key: 'action',
    highlight: true,
  },
  {
    name: 'ID',
    key: 'resource_id',
    highlight: true,
  },
  {
    name: 'USER',
    key: 'user',
    highlight: true,
  },
]

const tsOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
}

const filterResourceOpts = [
  { label: 'ALL RESOURCES', value: '' },
  { label: 'CLUSTER', value: 'CLUSTER' },
  { label: 'CONFIGS', value: 'CONFIGS' },
  { label: 'BUILDS', value: 'BUILDS' },
  { label: 'BUILDSPEC', value: 'BUILDSPEC' },
  { label: 'DAGS', value: 'DAGS' },
  { label: 'FILES', value: 'FILES' },
  { label: 'OPERATORS', value: 'OPERATORS' },
  { label: 'LIFECYCLE', value: 'LIFECYCLE' },
]

const filterActionOpts = [
  { label: 'ALL ACTIONS', value: '' },
  { label: 'CREATE', value: 'CREATE' },
  { label: 'UPDATE', value: 'UPDATE' },
  { label: 'DELETE', value: 'DELETE' },
]

export default class Files extends Component {
  constructor(props) {
    super(props)
    this.state = {
      events: [],
      eventsLoading: true,
      eventsError: false,
      filterFrom: null,
      filterTo: null,
      filterAction: '',
      filterResource: '',
      filterResourceId: '',
      filterUser: '',

      // pagination
      page: 0,
      pages: 1,
      pageSize: 50,

      // sorting
      sortBy: 'created',
      sortDesc: true,
    }
  }

  componentDidMount() {
    this.fetchEvents()
  }

  fetchEvents = () => {
    const { cluster, region } = this.props
    const {
      filterFrom,
      filterTo,
      filterAction,
      filterResource,
      filterResourceId,
      filterUser,
      page,
      pageSize,
    } = this.state

    return getEvents(
      region.api,
      cluster.cluster_id,
      new URLSearchParams({
        filter_from:
          (filterFrom && filterFrom.format('YYYY-MM-DD HH:mm:ss ZZ')) || '',
        filter_to:
          (filterTo && filterTo.format('YYYY-MM-DD HH:mm:ss ZZ')) || '',
        filter_action: filterAction,
        filter_resource: filterResource,
        filter_resource_id: filterResourceId,
        filter_user: filterUser,
        page: page + 1,
        rows: pageSize,
      }).toString()
    )
      .then(res =>
        this.setState({
          events: res.events.map(event => ({
            ...event,
            // local ts. 'sv' locale uses ISO 8601 format while still keeping browsers local tz.
            created: new Date(event.created_date).toLocaleString(
              'sv',
              tsOptions
            ),
            resource_id: event.resource_id.replace(/^\//, ''),
          })),
          eventsLoading: false,
          eventsError: false,
          pages: Math.ceil(res.count / res.size),
        })
      )
      .catch(err => {
        console.log(err)
        this.setState({
          eventsLoading: false,
          eventsError: err?.map(item => item.message),
        })
      })
  }

  reloadEvents = () =>
    this.setState(
      {
        events: [],
        eventsLoading: true,
        eventsError: false,
      },
      this.fetchEvents
    )

  render() {
    let {
      events,
      eventsLoading,
      eventsError,
      filterFrom,
      filterTo,
      filterAction,
      filterResource,
      filterResourceId,
      filterUser,
      // pagination
      page,
      pages,
      pageSize,
      sortBy,
      sortDesc,
    } = this.state

    // Sort events
    let sortedEvents = events.sort((a, b) =>
      sortDesc
        ? a[sortBy] < b[sortBy]
          ? 1
          : -1
        : a[sortBy] > b[sortBy]
        ? 1
        : -1
    )
    return (
      <div className='dash-main events'>
        <div className='events-header'>
          <h2 className='events-header-title tooltip-ad'>
            CLUSTER EVENTS
            <a
              className='events-header-subtitle'
              href='https://docs.platforms.nike.com/managed-airflow-platform/event-auditing'
              target='_blank'
              rel='noopener noreferrer'
            >
              DOCUMENTATION →
            </a>
          </h2>
          <Tooltip tooltipText=' MAP Clusters have an audit log for C(r)UD actions (CREATE, DELETE, and UPDATE)'></Tooltip>
          <SelectInput
            className='events-filter events-filter-resource'
            options={filterResourceOpts}
            value={filterResourceOpts.find(opt => opt.value === filterResource)}
            onChange={opt => this.setState({ filterResource: opt.value })}
            disabled={eventsLoading}
          />
          <SelectInput
            className='events-filter events-filter-action'
            options={filterActionOpts}
            value={filterActionOpts.find(opt => opt.value === filterAction)}
            onChange={opt => this.setState({ filterAction: opt.value })}
            disabled={eventsLoading}
          />
          <TextInput
            className='events-filter events-filter-resource-id'
            placeholder='ALL IDs'
            value={filterResourceId}
            onChange={e => this.setState({ filterResourceId: e.target.value })}
            disabled={eventsLoading}
          />
          <TextInput
            className='events-filter events-filter-user'
            placeholder='ALL USERs'
            value={filterUser}
            onChange={e => this.setState({ filterUser: e.target.value })}
            disabled={eventsLoading}
          />
          <DateRange
            className='events-filter events-filter-time'
            startDate={filterFrom}
            endDate={filterTo}
            onDatesChange={({ startDate, endDate }) =>
              this.setState({ filterFrom: startDate, filterTo: endDate })
            }
            disabled={eventsLoading}
          />
          <Button
            className='alt-search-button'
            onClick={this.reloadEvents}
            disabled={eventsLoading}
          >
            <i className='fa fa-search'></i>
          </Button>
        </div>

        <div className='dash-main-list'>
          <Table
            rows={sortedEvents}
            cols={eventCols}
            filter=''
            loading={eventsLoading}
            error={eventsError}
            sorted={[
              {
                id: sortBy,
                desc: sortDesc,
              },
            ]}
            onSortedChange={sorted =>
              this.setState({
                sortBy: sorted[0].id,
                sortDesc: sorted[0].desc,
              })
            }
            paginate
            page={page}
            pages={pages}
            onPageChange={page =>
              this.setState(
                {
                  page,
                },
                this.fetchEvents
              )
            }
            pageSize={pageSize}
            pageSizeOptions={[20, 50, 100, 200, 500]}
            onPageSizeChange={pageSize =>
              this.setState(
                {
                  page: 0,
                  pageSize,
                },
                this.fetchEvents
              )
            }
            showPageJump={false}
          />
        </div>
      </div>
    )
  }
}
