import { Component } from 'react'
import './ErrorBoundary.scss'

export default class altCluster extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  componentDidCatch() {
    return this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) return this.props.errorComponent
    return this.props.children
  }
}
