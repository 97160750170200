import React, { Component } from 'react'
import { ErrorBoundary } from 'components/index.js'
import MetricsError from './MetricsError.js'
import ChartStacked from './Charts/ChartStacked.js'
import ChartLegend from './Charts/ChartLegend.js'
import Spinner from 'components/AltSpinner/AltSpinner.js'
import './Metrics.scss'

const colors = [
  {
    area: '#a2f6e0',
    line: '#10ac84',
  },
  {
    area: '#ff9292',
    line: '#ff4c11',
  },
  {
    area: '#fff3d6',
    line: '#fdc032',
  },
  {
    area: '#f7527e',
    line: '#b71540',
  },
  {
    area: '#c9e9fe',
    line: '#49baff',
  },
  {
    area: '#ff9ff3',
    line: '#f368e0',
  },
]
const formatBytes = bytes =>
  `${Math.round((bytes / 1000 / 1000 / 1000 + Number.EPSILON) * 100) / 100}gb`
const formatCpu = nanocores =>
  `${Math.round((nanocores / 1000 / 1000 + Number.EPSILON) * 100) / 100}mi`
const ChartLoader = ({ loading, children }) =>
  loading ? <Spinner /> : children

const limitsFunc = filter => {
  if (filter && filter.startsWith('airflow-webserver')) {
    return {
      cpu: 'capacity_webserver_cpu',
      memory: 'capacity_webserver_memory',
    }
  }
  if (filter && filter.startsWith('airflow-scheduler')) {
    return {
      cpu: 'capacity_scheduler_cpu',
      memory: 'capacity_scheduler_memory',
    }
  }
  return {
    cpu: 'capacity_namespace_cpu',
    memory: 'capacity_namespace_memory',
  }
}

const processPodMetrics = data => {
  // Calculating this stuff once instead of in each chart
  let metrics = {
    minTs: Number.MAX_VALUE,
    maxTs: 0,
    values: [],
    keys: new Set(),
  }

  let tsMap = {}
  data.forEach(m => {
    // Round to 10s to line up a metric for each period
    let ts = new Date(m['@timestamp']).getTime()
    let roundedTs = Math.round(ts / 10 / 1000) * 10 * 1000

    tsMap[roundedTs] = tsMap[roundedTs] || { ts: roundedTs }
    tsMap[roundedTs][m.kubernetes.pod.name] = {
      cpuPercent:
        (m.kubernetes.pod.cpu.usage.limit &&
          m.kubernetes.pod.cpu.usage.limit.pct) ||
        0,
      cpuUsage: m.kubernetes.pod.cpu.usage.nanocores,
      memPercent:
        (m.kubernetes.pod.memory.usage.limit &&
          m.kubernetes.pod.memory.usage.limit.pct) ||
        0,
      memUsage: m.kubernetes.pod.memory.usage.bytes,
      networkRx: m.kubernetes.pod.network.rx.bytes,
      networkTx: m.kubernetes.pod.network.tx.bytes,
      ts: roundedTs,
    }

    metrics.keys.add(m.kubernetes.pod.name)
    metrics.minTs = roundedTs < metrics.minTs ? roundedTs : metrics.minTs
    metrics.maxTs = roundedTs > metrics.maxTs ? roundedTs : metrics.maxTs
  })

  console.log(metrics)

  return {
    ...metrics,
    keys: Array.from(metrics.keys).sort(),
    values: Object.values(tsMap).sort((a, b) => a.ts - b.ts),
  }
}

export default class PodMetrics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      processedMetrics: false,
      filter: false,
    }
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      processedMetrics:
        nextProps.metrics && processPodMetrics(nextProps.metrics),
    }
  }

  colorFunc = key =>
    this.state.processedMetrics.keys.indexOf(key) === -1
      ? { area: 'lightgray', line: 'gray' }
      : colors[this.state.processedMetrics.keys.indexOf(key) % colors.length]

  render() {
    let { processedMetrics, filter } = this.state
    let { metricsLoading, cluster } = this.props
    let limits = limitsFunc(filter)
    let filteredKeys = filter ? [filter] : processedMetrics.keys

    return (
      <div className="metrics-main alt-scroll">
        <div className="metric-chart-row cpu-usage-row">
          <ErrorBoundary
            errorComponent={<MetricsError error="Error Rendering Metric." />}
          >
            <ChartLoader loading={metricsLoading}>
              <div className="metric-chart">
                <ChartStacked
                  minX={processedMetrics.minTs}
                  maxX={processedMetrics.maxTs}
                  limitY={cluster[limits.cpu] * 1000 * 1000} // millicpu -> nanocpu
                  formatY={formatCpu} // nanocpu -> rounded millicpu w/ label
                  xKey="ts"
                  yKey="cpuUsage"
                  data={processedMetrics.values}
                  keys={filteredKeys}
                  colorFunc={this.colorFunc}
                  highWaterMark
                  {...this.props}
                />
              </div>

              <ChartLegend
                name="CPU USAGE"
                items={processedMetrics.keys}
                selected={filter}
                onSelect={filter => this.setState({ filter })}
                colorFunc={this.colorFunc}
              />
            </ChartLoader>
          </ErrorBoundary>
        </div>

        <div className="metric-chart-row memory-usage-row">
          <ErrorBoundary
            errorComponent={<MetricsError error="Error Rendering Metric." />}
          >
            <ChartLoader loading={metricsLoading}>
              <div className="metric-chart">
                <ChartStacked
                  minX={processedMetrics.minTs}
                  maxX={processedMetrics.maxTs}
                  limitY={cluster[limits.memory] * 1000 * 1000} // mb -> b
                  formatY={formatBytes} // b -> rounded gb
                  xKey="ts"
                  yKey="memUsage"
                  data={processedMetrics.values}
                  keys={filteredKeys}
                  colorFunc={this.colorFunc}
                  highWaterMark
                  {...this.props}
                />
              </div>

              <ChartLegend
                name="MEMORY USAGE"
                items={processedMetrics.keys}
                selected={filter}
                onSelect={filter => this.setState({ filter })}
                colorFunc={this.colorFunc}
              />
            </ChartLoader>
          </ErrorBoundary>
        </div>

        <div className="metric-chart-row network-rx-row">
          <ErrorBoundary
            errorComponent={<MetricsError error="Error Rendering Metric." />}
          >
            <ChartLoader loading={metricsLoading}>
              <div className="metric-chart">
                <ChartStacked
                  minX={processedMetrics.minTs}
                  maxX={processedMetrics.maxTs}
                  formatY={formatBytes} // b -> rounded gb
                  xKey="ts"
                  yKey="networkRx"
                  data={processedMetrics.values}
                  keys={filteredKeys}
                  colorFunc={this.colorFunc}
                  {...this.props}
                />
              </div>

              <ChartLegend
                name="NETWORK RX"
                items={processedMetrics.keys}
                selected={filter}
                onSelect={filter => this.setState({ filter })}
                colorFunc={this.colorFunc}
              />
            </ChartLoader>
          </ErrorBoundary>
        </div>

        <div className="metric-chart-row network-tx-row">
          <ErrorBoundary
            errorComponent={<MetricsError error="Error Rendering Metric." />}
          >
            <ChartLoader loading={metricsLoading}>
              <div className="metric-chart">
                <ChartStacked
                  minX={processedMetrics.minTs}
                  maxX={processedMetrics.maxTs}
                  formatY={formatBytes} // b -> rounded gb
                  xKey="ts"
                  yKey="networkTx"
                  data={processedMetrics.values}
                  keys={filteredKeys}
                  colorFunc={this.colorFunc}
                  {...this.props}
                />
              </div>

              <ChartLegend
                name="NETWORK TX"
                items={processedMetrics.keys}
                selected={filter}
                onSelect={filter => this.setState({ filter })}
                colorFunc={this.colorFunc}
              />
            </ChartLoader>
          </ErrorBoundary>
        </div>
      </div>
    )
  }
}
