import { http, digResponse, getRequestError } from 'util/index.js'

const encode = encodeURIComponent

export const getMetrics = (api, clusterId, resource, fromTime, toTime) =>
  http
    .get(
      `${api}/clusters/${encode(clusterId)}/metrics/${encode(
        resource
      )}?range_from=${fromTime}&range_to=${toTime}`
    )
    .then(response => digResponse(response, 'data', 'metrics'))
    .catch(response => {
      throw getRequestError(response, 'getMetrics')
    })
