import React from 'react'
import './AltSpinner.scss'

export default ({ className }) => {
  let classes = ['alt-spinner']
  if (className) {
    classes.push(className)
  }
  return <div className={classes.join(' ')} />
}
