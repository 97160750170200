exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".epic-font-base___Zzzb5 {\n  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-base-md___2iMQO {\n  font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif;\n}\n.epic-font-marketing___3JPJr {\n  font-family: 'Futura', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-brand___2DEAK {\n  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.textarea___1H1hz {\n  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n  width: 100%;\n  height: 100%;\n  padding: 12px 16px;\n  border-radius: 1.4em;\n  resize: none;\n  line-height: 23px;\n  border-color: #c7c7c7;\n  outline: none;\n  font-size: 14px;\n  min-height: 60px;\n  min-width: 180px;\n}\n.textarea___1H1hz::placeholder {\n  color: #c7c7c7;\n  line-height: 23px;\n}\n.textarea___1H1hz.noBorder___1k8iM {\n  border: none;\n}\n.textarea___1H1hz.hasErrors___qUxSz {\n  border: 1px solid #fe0000;\n  background-color: #fceeed;\n}\n.textarea___1H1hz.hasErrors___qUxSz::placeholder {\n  color: #fe0000;\n}\n.required___38g5P {\n  color: #fe0000;\n}\n", ""]);

// Exports
exports.locals = {
	"epic-font-base": "epic-font-base___Zzzb5",
	"epic-font-base-md": "epic-font-base-md___2iMQO",
	"epic-font-marketing": "epic-font-marketing___3JPJr",
	"epic-font-brand": "epic-font-brand___2DEAK",
	"textarea": "textarea___1H1hz",
	"noBorder": "noBorder___1k8iM",
	"hasErrors": "hasErrors___qUxSz",
	"required": "required___38g5P"
};