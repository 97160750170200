import base from './base.env.js'

function getAirflowAdmin(region, cluster) {
	let suffix = region === 'cn-northwest-1' ? '' : '.preprod'
	return `https://gateway.${region}${suffix}.map.nike.com/${encodeURI(cluster)}/`
}

function getAirflowProxyURL(region, cluster) {
	let suffix = region === 'cn-northwest-1' ? '' : '.preprod'
	return `https://proxy.${region}${suffix}.map.nike.com/${encodeURI(cluster)}`
}
const nonprod =
{
	...base,
	identifier: 'nonprod',
	entitlementsUrl: 'https://api.entitlements.nikecloud.com',
	ecr: '514712878770.dkr.ecr.us-west-2.amazonaws.com',

	// OKTA
	oAuth: {
		client_id: "nike.niketech.map-ui",
		redirect_uri: window.location.origin,
		response_type: 'code',
		state: 'OR',
		scope: 'openid profile email offline_access',
		code_challenge_method: 'S256',
		authorization: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1',
	},

	// AIRFLOW URLS
	airflowAdmin: (region, cluster) => `${getAirflowAdmin(region, cluster)}`,
	airflowProxy: (region, cluster) => `${getAirflowProxyURL(region, cluster)}`,

	// EP CONSOLE
	consoleOrigin: 'https://console-nonprod.platforms.nike.com',
	consoleSubroute: '/map/',

	// MONITORING
	apmEndpoint: 'https://berlioux.us-west-2.eap-mon-prod.nikecloud.com/map-us-west-2/apm',
	apmLabel: 'map-ui-nonprod',

	// MULTI REGION
	regions: [
		{ value: "localhost", api: 'http://localhost:8000', label: "Localhost:8000" },
		{ value: "us-west-2", api: 'https://apinonprod.us-west-2.preprod.map.nike.com/v1', label: "US West (Oregon)" },
	]
}


export default nonprod