import React from 'react'

export default ({ error }) => {
  const listErrors = error.map((item, index) => {
    return (
      <p key={index}>
        <i className="fa fa-exclamation-triangle"></i> {item}
      </p>
    )
  })

  return <div className="metric-error">{listErrors}</div>
}
