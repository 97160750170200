import React, { Component } from 'react'
import ReactDropzone from 'react-dropzone'
import Button from '../Button/Button.js'
import './DropZone.scss'

export default class Dash extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dragging: false,
      dragElError: false,
      dragEnterX: 0,
      dragEnterY: 0,
    }
  }

  setDropzoneRef = node => {
    this.dropzone = node
    this.props.setRef(node)
  }

  // traverse a object to get a deep value or null
  deep = (p, o) => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o)

  dragEnter = e => {
    let error = false

    if (this.props.locked) {
      error = 'Uploads Locked'
    }
    if (
      !error &&
      !(e.dataTransfer.types && e.dataTransfer.types.includes('Files'))
    ) {
      error = 'Only Direct File Uploads Allowed'
    }

    return this.setState({
      dragging: true,
      dragEnterX:
        e.clientX -
        (this.deep(['node', 'offsetParent', 'offsetLeft'], this.dropzone) || 0),
      dragEnterY:
        e.clientY -
        (this.deep(['node', 'offsetParent', 'offsetTop'], this.dropzone) || 0),
      dragElError: error,
    })
  }

  dragLeave = () =>
    this.setState({
      dragging: false,
      dragElError: false,
    })

  onDrop = e =>
    this.setState(
      {
        dragging: false,
        dragElError:
          this.state.dragElError ||
          (this.props.locked && 'Uploads Locked') ||
          (e.length === 0 && `Not an accepted ${this.props.accept} file`),
      },
      () => !this.state.dragElError && this.loadContents(e)
    )

  loadContents = dropEvent =>
    Promise.all(
      dropEvent.map(file => {
        return new Promise((resolve, reject) => {
          let reader = new FileReader()
          reader.readAsText(file)
          reader.onerror = error => reject(error)
          reader.onload = loadEvent =>
            resolve({
              name: file.name,
              content: loadEvent.target.result,
            })
        })
      })
    )
      .then(this.props.onDrop)
      .catch(() =>
        this.setState({ dragElError: 'Encountered Error Parsing File(s)' })
      )

  rippleDotStyle = () => ({
    left: `${this.state.dragEnterX}px`,
    top: `${this.state.dragEnterY}px`,
  })

  render() {
    let { className, accept, children } = this.props
    let { dragging, dragElError } = this.state
    let classes = ['alt-dropzone']
    if (className) {
      classes.push(className)
    }

    return (
      <ReactDropzone
        ref={this.setDropzoneRef}
        className={classes.join(' ')}
        accept={accept}
        onDrop={this.onDrop}
        onDragEnter={this.dragEnter}
        onDragLeave={this.dragLeave}
        multiple={false}
        disableClick
      >
        {children}
        {(dragging || dragElError) && (
          <div className="alt-drop-preview">
            {dragElError ? (
              <div className="alt-drop-preview-inner">
                <p className="alt-drop-preview-icon-error">
                  <i className="fa fa-times"></i>
                </p>
                <p className="alt-drop-preview-text">{dragElError}</p>
              </div>
            ) : (
              <div className="alt-drop-preview-inner">
                <p className="alt-drop-preview-icon">
                  <i className="fa fa-cloud-upload"></i>
                </p>
                <p className="alt-drop-preview-text">Drop File To Upload</p>
              </div>
            )}
            {dragging && (
              <div
                className="alt-drop-hover-ripple"
                style={this.rippleDotStyle()}
              ></div>
            )}
            {!dragging && (
              <Button onClick={() => this.setState({ dragElError: false })}>
                BACK TO LIST
              </Button>
            )}
          </div>
        )}
      </ReactDropzone>
    )
  }
}
