import config from 'envs/config'

const generateECROptions = region => {
  const isChina = region?.toLowerCase()?.includes('cn-')
  const awsAccount = isChina ? config.awsAccountIdChina : config.awsAccountId
  const chinaUrl = isChina ? '.cn' : ''
  return [
    {
      value: `${awsAccount}.dkr.ecr.${region}.amazonaws.com${chinaUrl}/map/map-custom-ops/2.2.5-python38:${config.mapCustomOpsImageVersion}`,
      label: `${awsAccount}.dkr.ecr.${region}.amazonaws.com${chinaUrl}/map/map-custom-ops/2.2.5-python38:${config.mapCustomOpsImageVersion}`,
      version: '2.2.5-python38',
    },
  ]
}

const packageCols = [
  {
    name: 'PACKAGE',
    key: 'package',
  },
  {
    name: 'VERSION',
    key: 'version',
  },
]

export { generateECROptions, packageCols }
