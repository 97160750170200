import React from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'

import styles from './styles.styl'
import md5 from '../../utils/md5.js'
import Badge from '../Badge/Badge.js'
import defaultAvatar from '../../assets/images/avatar-default.png'

const query = v => {
  let q = qs.stringify(v)
  return q ? `?${q}` : ''
}

function Gravatar({ email = '', missing = 'mm', size = 100 }) {
  return (
    <img
      className="gravatar"
      src={`https://www.gravatar.com/avatar/${md5(
        email.trim().toLowerCase()
      )}${query({
        d: missing,
        s: size
      })}`}
    />
  )
}

const Avatar = ({ src, children, small, email, badgeCount, isActive }) => {
  let imageSrc = children ? '' : src !== '' ? src : defaultAvatar
  let isSmall = small ? styles.small : styles.default
  let active = isActive ? styles.active : ''

  return (
    <div className={`${styles.avatar} ${isSmall} ${active}`.trim()}>
      {email !== '' ? (
        <Gravatar email={email} />
      ) : imageSrc !== '' ? (
        <img alt="avatar image" src={imageSrc} />
      ) : (
        <div className={styles.nested}>{children}</div>
      )}
      <Badge count={badgeCount} className={styles.badge} />
    </div>
  )
}

Avatar.propTypes = {
  /** image path for avatar */
  src: PropTypes.string,
  /** children rendered inside of avatar */
  children: PropTypes.any,
  /** size for avatar */
  small: PropTypes.bool,
  /** if you want to use user's gravatar image (https://en.gravatar.com/)  */
  email: PropTypes.string,
  /** Show a badge number value at the top right of the avatar */
  badgeCount: PropTypes.number
}

Avatar.defaultProps = {
  src: '',
  email: '',
  small: false,
  badgeCount: 0
}

export default Avatar
