import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { getBuild, getBuildLogs } from 'store/builds.js'
import ApplyImage from './ApplyImage/ApplyImage.js'
import DeleteBuild from './DeleteBuild/DeleteBuild.js'
import Spinner from 'components/Spinner/Spinner.js'
import ErrorView from 'components/Error/Error.js'
import Stages from 'components/Stages/Stages.js'
import Button from 'components/Button/Button.js'
import config from 'envs/config'
import moment from 'moment'
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch.js'
import './Build.scss'

export default class Build extends Component {
  constructor(props) {
    super(props)
    this.state = {
      build: false,
      buildLogs: [],
      buildLoading: true,
      buildError: false,
      openStage: false,
      applyOpen: false,
      deleteOpen: false,
      imageList: false,
      imageToBuild: false,
    }
  }

  componentDidMount() {
    this.fetchBuild().then(() => {
      this.fetchBuildInterval = setInterval(
        () =>
          this.state.build &&
          this.state.build.meta &&
          this.state.build.meta.current_phase !== 'COMPLETED' &&
          this.fetchBuild(),
        5000
      )
    })
    this.fetchBuildLogs().then(() => {
      this.fetchBuildLogsInterval = setInterval(
        () =>
          this.state.build &&
          this.state.build.meta &&
          [
            'PRE_BUILD',
            'BUILD',
            'POST_BUILD',
            'UPLOAD_ARTIFACTS',
            'FINALIZING',
          ].includes(this.state.build.meta.current_phase) &&
          this.fetchBuildLogs(),
        3000
      )
    })
  }

  componentWillUnmount() {
    clearInterval(this.fetchBuildInterval)
    clearInterval(this.fetchBuildLogsInterval)
  }

  fetchBuild = () =>
    getBuild(
      this.props.region.api,
      this.props.cluster.cluster_id,
      this.props.match.params.buildId
    )
      .then(build => {
        const imageList = [
          {
            label: 'ECR Image',
            value: build.ecr_image,
          },
          {
            label: 'Artifactory Image',
            value: build.image,
          },
        ]

        this.setState({
          build,
          buildLoading: false,
          buildError: false,
          imageList: imageList,
          imageToBuild: build.ecr_image ? imageList[0] : imageList[1],
          openStage:
            // Open build logs if build pending or failed
            !build.image &&
            build.meta &&
            build.meta.phases &&
            build.meta.phases.findIndex(p => p.phase_type === 'BUILD'),
        })
      })
      .catch(err =>
        this.setState({
          buildLoading: false,
          buildError: err.map(item => item.message),
        })
      )

  fetchBuildLogs = () =>
    getBuildLogs(
      this.props.region.api,
      this.props.cluster.cluster_id,
      this.props.match.params.buildId
    )
      .then(logs =>
        this.setState({
          buildLogs: this.processBuildLogs(logs),
        })
      )
      .catch(console.log)

  processBuildLogs = logs => {
    let proccessed = []
    let buildPhaseFound = false

    for (let i = 0; i < logs.length; i++) {
      let log = logs[i]

      if (buildPhaseFound) {
        if (log.message.includes('Phase complete: BUILD')) {
          break
        }
        if (log.message.trim().length > 0) {
          proccessed.push({
            // Splice ts prefix embedded in container log messages.
            message: log.message.replace(
              /^\[Container\] \d{1,4}\/\d{1,2}\/\d{1,2} \d{1,2}:\d{1,2}:\d{1,2} /,
              ''
            ),
            ts: moment(log.timestamp).format('YYYY-MM-DD HH:mm:ss'),
          })
        }
      } else {
        if (log.message.includes('Entering phase BUILD')) {
          buildPhaseFound = true
        }
      }
    }
    return proccessed
  }

  processPhases = (meta, logs, openStage) =>
    (meta.phases || []).map((phase, i) => {
      let last = meta.phases.length === i + 1

      return {
        title: phase.phase_type,
        succeeded: phase.phase_status === 'SUCCEEDED' || (last && meta.end_time),
        failed: phase.phase_status === 'FAILED',
        active: !phase.phase_status && phase.phase_type !== 'COMPLETED',
        start_time: phase.start_time && new Date(phase.start_time).getTime(),
        end_time:
          (phase.end_time && new Date(phase.end_time).getTime()) ||
          (last && meta.end_time && new Date(meta.end_time).getTime()),
        steps: phase.phase_type === 'BUILD' ? logs : [],
        open: openStage === i,
      }
    })

  render() {
    let { region, cluster, permissions, updateCluster } = this.props
    let {
      build,
      buildLogs,
      buildLoading,
      buildError,
      openStage,
      applyOpen,
      deleteOpen,
      imageList,
      imageToBuild,
    } = this.state
    let buildsLink = `${config.siteRoot}${region.value}/clusters/${cluster.cluster_name}/builds`

    if (buildLoading) {
      return <Spinner large center />
    }

    if (buildError) {
      return <ErrorView title="OOPS" error={buildError} />
    }

    return (
      <div className="dash-main build">
        <div className="build-header">
          <h3 className="list-title">
            <Link to={buildsLink}>BUILDS</Link> / {build.build_id}
            <p className="list-subtitle">
              Status - {build.status} | Airflow Version -{' '}
              {build.airflow_version} | Tag - {build.tag}
            </p>
          </h3>

          <Button
            borderless
            onClick={() => this.setState({ deleteOpen: true })}
            locked={!permissions['CLUSTER.UPDATE']}
          >
            DELETE BUILD
          </Button>

          <Button
            primary
            borderless
            onClick={() => this.setState({ applyOpen: true })}
            disabled={!build.image && !build.ecr_image}
            locked={!permissions['CLUSTER.UPDATE']}
          >
            APPLY TO CLUSTER
          </Button>
        </div>

        <div className="build-info">
          <div className="build-info-inner alt-scroll">
            <Stages
              stages={this.processPhases(build.meta, buildLogs, openStage)}
              onStageToggle={s =>
                this.setState({ openStage: s === openStage ? false : s })
              }
            />
            {(build.image || build.ecr_image) && (
              <div className="build-image">
                <h3 className="build-image-title">
                  Image Build Completed Successfully
                </h3>

                <div className="build-image-copy">
                  <p>
                    {imageToBuild.value === build.ecr_image
                      ? build.ecr_image
                      : build.image}
                  </p>
                </div>
                <div>
                  <ToggleSwitch
                    value={imageToBuild}
                    optionsList={imageList}
                    onChange={option => {
                      this.setState({
                        imageToBuild: option,
                      })
                    }}
                    tooltipText="This build was saved as an image in two different locations. Pick which source you'd like to use before clicking 'Apply'."
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {applyOpen && (
          <ApplyImage
            region={region}
            cluster={cluster}
            imageToBuild={imageToBuild}
            onClose={() =>
              this.setState({ applyOpen: false }, () => updateCluster())
            }
          />
        )}

        {deleteOpen && (
          <DeleteBuild
            region={region}
            cluster={cluster}
            build={build}
            onClose={() => this.setState({ deleteOpen: false })}
          />
        )}
      </div>
    )
  }
}
