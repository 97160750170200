import React, { Component } from 'react'
import Dialogue from 'components/Dialogue/Dialogue.js'
import { decommissionCluster } from 'store/clusters.js'
import config from 'envs/config'
import './DecommissionDialogue.scss'

export default class Decommission extends Component {
  constructor(props) {
    super(props)
    this.state = {
      input: '',
      started: false,
      completed: false,
      error: false,
    }
  }

  decommissionCluster = () =>
    this.setState(
      {
        started: true,
      },
      () => {
        decommissionCluster(
          this.props.region.api,
          this.props.cluster.cluster_id
        )
          .then(() =>
            window.location.assign(
              `${config.siteRoot}${this.props.region.value}/decommissioned`
            )
          )
          .catch(err =>
            this.setState({
              completed: true,
              error: err.map(item => item.message),
            })
          )
      }
    )

  render() {
    let { cluster, onClose } = this.props
    let { input, started, completed, error } = this.state
    let confirmProps = {
      subtitle: 'This cluster will soon become unavailable.',
    }
    let title = 'Cluster Decommission Scheduled.'

    // dont show the confirm action/text after completion
    if (!completed) {
      title = `Decommission Cluster?`
      confirmProps = {
        subtitle: (
          <span>
            Are you sure you want to delete this cluster? All metadata and all
            historical task runs will become unavailable!
          </span>
        ),
        onConfirm: this.decommissionCluster,
        confirmText: 'DECOMMISSION CLUSTER',
        confirmDisabled: started || cluster.cluster_name !== input,
      }
    }

    if (completed && error) {
      const listErrors = error.map((item, index) => {
        return <li key={index}>{item}</li>
      })

      title = 'Error Decommissioning Cluster!'
      confirmProps = {
        subtitle: (
          <>
            <p>
              The following errors were encountered when decommissioning this
              cluster:
            </p>
            <ul>{listErrors}</ul>
          </>
        ),
      }
    }

    return (
      <Dialogue
        className={'alt-decommission-cluster'}
        title={title}
        onClose={
          error
            ? () => {
              window.location.reload()
            }
            : onClose
        }
        closeText={started ? 'CLOSE' : 'CANCEL'}
        {...confirmProps}
      >
        {!started && (
          <div
            className={
              'input-confirm' +
              (cluster.cluster_name.startsWith(input) ? '' : ' error')
            }
          >
            <p className="input-label">Confirm Cluster Name</p>

            {cluster.cluster_name.startsWith(input) && (
              <p className="input-placeholder">{cluster.cluster_name}</p>
            )}

            <input
              autoFocus
              value={input}
              onChange={e => this.setState({ input: e.target.value })}
            />
          </div>
        )}
      </Dialogue>
    )
  }
}
