import React from 'react'

import styles from '../styles.styl'

const Th = ({ isSorted, isSortedDesc, className, ...props }) => {
  const mergedClassname = `${styles.th} ${
    isSorted ? (isSortedDesc ? styles.sortDesc : styles.sortAsc) : ''
  } ${className || ''}`

  return <th {...props} className={mergedClassname} />
}

export default Th
