const typeOpts = [
  {
    label: 'AD Group',
    value: 'ad_group',
  },
  {
    label: 'Sub',
    value: 'sub',
  },
]

const roleOpts = [
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Read Only',
    value: 'user',
  },
]

export { typeOpts, roleOpts }
