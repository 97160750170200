import React, { Component } from 'react'
import Dialogue from 'components/Dialogue/Dialogue.js'
import StatusList from 'components/StatusList/StatusList.js'
import { deleteDag } from 'store/dags.js'

export default class DagsDelete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      started: false,
      completed: false,
      success: {},
      error: {},
    }
  }

  deleteDags = () =>
    this.setState(
      {
        started: true,
      },
      () => {
        Promise.all(
          this.props.dags.map(dag =>
            deleteDag(this.props.region.api, this.props.cluster.cluster_id, dag)
              .then(() =>
                this.setState({
                  success: Object.assign({ [dag]: true }, this.state.success),
                })
              )
              .catch(err =>
                this.setState({
                  error: Object.assign(
                    { [dag]: err.map(item => item.message) },
                    this.state.error
                  ),
                })
              )
          )
        ).then(this.setState({ completed: true }))
      }
    )

  dagStatus = dag => {
    if (this.state.error[dag]) return 'error'
    if (this.state.success[dag]) return 'success'
    if (this.state.started) return 'pending'
    return 'default'
  }

  render() {
    let { dags, onClose } = this.props
    let { started, completed, error } = this.state
    let confirmProps = {}
    let errorCount = Object.keys(error).length
    let title = `Deleted ${dags.length} DAGs successfully.`

    // dont show the confirm action/text after completion
    if (!completed) {
      title = `Delete ${dags.length} DAGs?`
      confirmProps = {
        subtitle: (
          <span>
            Are you sure you want to delete the{' '}
            {dags.length === 1
              ? `${dags[0]} DAG `
              : ` following ${dags.length} DAGs `}
            from your cluster? This action may break things!
          </span>
        ),
        onConfirm: this.deleteDags,
        confirmText: `DELETE ${dags.length} DAGS`,
        confirmDisabled: started,
      }
    }

    if (completed && errorCount > 0) {
      title = `Error Deleting ${errorCount}/${dags.length} DAGs!`
    }

    return (
      <Dialogue
        title={title}
        onClose={onClose}
        closeText={started ? 'CLOSE' : 'CANCEL'}
        {...confirmProps}
      >
        <StatusList
          list={dags.map(dag => ({
            title: dag,
            status: this.dagStatus(dag),
            error: error[dag],
          }))}
        />
      </Dialogue>
    )
  }
}
