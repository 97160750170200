exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".epic-font-base___3DJI7 {\n  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-base-md___3aqZq {\n  font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif;\n}\n.epic-font-marketing___3A8Bg {\n  font-family: 'Futura', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-brand___1Hy41 {\n  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.screenShot___JgBIo {\n  position: fixed;\n  margin: 0;\n  top: 0;\n  left: 0;\n  height: 100vh;\n  width: 100vw;\n  cursor: crosshair;\n  z-index: 2000;\n}\n.screenShot___JgBIo.showLoader___1Bcoi {\n  cursor: wait;\n}\n.screenShot___JgBIo.finished___3_-Sc {\n  cursor: none;\n}\n.screenShot___JgBIo.showBorder___33D0M .screenShotSelectionRect___2_iML {\n  border: solid 1px #009bd5;\n}\n.screenShot___JgBIo .screenShotSelectionRect___2_iML {\n  position: relative;\n  background-color: rgba(0,155,213,0.1);\n  border: none;\n  z-index: 20001;\n}\n", ""]);

// Exports
exports.locals = {
	"epic-font-base": "epic-font-base___3DJI7",
	"epic-font-base-md": "epic-font-base-md___3aqZq",
	"epic-font-marketing": "epic-font-marketing___3A8Bg",
	"epic-font-brand": "epic-font-brand___1Hy41",
	"screenShot": "screenShot___JgBIo",
	"showLoader": "showLoader___1Bcoi",
	"finished": "finished___3_-Sc",
	"showBorder": "showBorder___33D0M",
	"screenShotSelectionRect": "screenShotSelectionRect___2_iML"
};