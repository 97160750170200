import { http, digResponse, getRequestError } from 'util/index.js'

const encode = encodeURIComponent

export const getPermissions = (api, clusterId) =>
  http
    .get(`${api}/clusters/${encode(clusterId)}/permissions`)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'getPermissions')
    })

export const requestSupport = (api, clusterId) =>
  http
    .put(`${api}/clusters/${encode(clusterId)}/supportaccess`, {})
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'requestSupport')
    })

export const revokeSupport = (api, clusterId) =>
  http
    .delete(`${api}/clusters/${encode(clusterId)}/supportaccess`, {})
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'revokeSupport')
    })
