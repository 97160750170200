import React, { useState, useEffect } from 'react'
import './Stages.scss'

const stageClass = stage => {
  if (stage.active) return 'stage stage-gray'
  if (stage.succeeded) return 'stage stage-green'
  if (stage.failed) return 'stage stage-red'
  return 'stage'
}

const stageTime = (start, end) => {
  let dist = (end - start) / 1000.0
  if (dist < 1.0) return '<1s'
  if (dist < 60.0) return `${Math.floor(dist)}s`
  return `${Math.floor(dist / 60)}m ${Math.floor(dist % 60)}s`
}

export default ({ stages, onStageToggle }) => {
  const [time, setTime] = useState(Date.now())

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className="alt-stages">
      {stages.map((stage, i) => (
        <div className={stageClass(stage)} key={i}>
          <div className="stage-inner">
            <div className="stage-spinner">
              {stage.succeeded && <i className="fa fa-check"></i>}
              {stage.failed && <i className="fa fa-times"></i>}
              {stage.active && <div className="stage-spinning"></div>}
            </div>
            <h2 className="stage-title">
              {stage.title}
              {stage.steps && stage.steps.length > 0 && (
                <i
                  className={`fa fa-chevron-down${stage.open ? '' : ' closed'}`}
                  onClick={() => onStageToggle && onStageToggle(i)}
                />
              )}
            </h2>
            {stage.start_time && (
              <p className="stage-time">
                {stageTime(stage.start_time, stage.end_time || time)}
              </p>
            )}
          </div>

          {stage.open && stage.steps && stage.steps.length > 0 && (
            <div className="stage-steps">
              {stage.steps.map((step, si) => (
                <div className="stage-step" key={si}>
                  <p className="step-timestamp">{step.ts}</p>
                  <p className="step-message">{step.message}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
