import { http, digResponse, getRequestError } from 'util/index.js'

const encode = encodeURIComponent

export const getConfig = (api, clusterId) =>
  http
    .get(`${api}/clusters/${encode(clusterId)}/config`)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'getConfig')
    })

export const updateConfig = (api, clusterId, body) =>
  http
    .put(`${api}/clusters/${encode(clusterId)}/config`, body)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'updateConfig')
    })

export const getConfigVersions = api =>
  http
    .get(`${api}/configs`)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'getConfigVersions')
    })
