exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".epic-font-base___mLUBN,\n.card___2HXK9,\n.description___o7ylP {\n  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-base-md___20Yr6,\n.subtitle___2g39M {\n  font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif;\n}\n.epic-font-marketing___D-ofa {\n  font-family: 'Futura', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-brand___20jUg,\n.title___IuuGR {\n  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.card___2HXK9 {\n  color: #6d6d6d;\n  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);\n  padding: 15px;\n  border-radius: 15px;\n  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);\n  background-color: #f7f7f7;\n  text-align: left;\n}\n.card___2HXK9.hover___2t1jH {\n  transition: transform 0.2s;\n  will-change: transform;\n  cursor: pointer;\n}\n.card___2HXK9.hover___2t1jH:hover {\n  transform: scale(1.057);\n}\n.icon___3nKWj {\n  width: 36px;\n  height: 36px;\n  display: block;\n  text-align: center;\n  line-height: 36px;\n}\n.icon___3nKWj img {\n  width: 36px;\n  height: 36px;\n  display: block;\n  margin: auto;\n}\n.icon___3nKWj i {\n  display: block;\n  padding-top: 7px;\n}\n.title___IuuGR {\n  font-size: 24px;\n  font-weight: 24px;\n  weight: 24px;\n  line-height: 1em;\n  letter-spacing: -0.5px;\n  color: #14171a;\n  text-transform: uppercase;\n  margin: 0;\n  margin-top: 8px;\n  padding: 0;\n  text-align: left;\n}\n.subtitle___2g39M {\n  font-size: 12px;\n  font-weight: bold;\n  color: #5197d6;\n  text-transform: uppercase;\n  padding: 0;\n  margin: 0;\n  text-align: left;\n  margin-top: 8px;\n  padding: 0;\n}\n.description___o7ylP {\n  max-height: 125px;\n  overflow: hidden;\n  font-size: 14px;\n  line-height: 1.33em;\n  text-align: left;\n  margin: 0;\n  color: #6d6d6d;\n  padding-top: 8px;\n}\n", ""]);

// Exports
exports.locals = {
	"epic-font-base": "epic-font-base___mLUBN",
	"card": "card___2HXK9",
	"description": "description___o7ylP",
	"epic-font-base-md": "epic-font-base-md___20Yr6",
	"subtitle": "subtitle___2g39M",
	"epic-font-marketing": "epic-font-marketing___D-ofa",
	"epic-font-brand": "epic-font-brand___20jUg",
	"title": "title___IuuGR",
	"hover": "hover___2t1jH",
	"icon": "icon___3nKWj"
};