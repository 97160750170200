import { http, getRequestError, digResponse } from 'util/index.js'

export const cloudredTag = tag =>
  http
    .get(
      `https://cloudred.nike.com/api/ssot/tagsets/${encodeURIComponent(tag)}`
    )
    .then(response => console.log(response))
    .catch(response => {
      throw getRequestError(response, 'cloudredTag')
    })

export const cloudredApplications = () =>
  http
    .get(`https://cloudred.nike.com/api/ssot/systems/`)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'cloudredApplications')
    })

export const cloudredIdentityRoles = user =>
  http
    .get(
      `https://cloudred.nike.com/api/identity/user/${encodeURIComponent(
        user
      )}/roles`
    )
    .then(response => digResponse(response, 'data', 'other'))
    .catch(response => {
      throw getRequestError(response, 'cloudredIdentityRoles')
    })
