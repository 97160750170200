const serviceOptions = [
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Slack Webhook',
    value: 'slack_webhook',
  },
  {
    label: 'Pagerduty',
    value: 'pagerduty',
  },
  {
    label: 'Service Now',
    value: 'service_now',
  },
]

const eventTypeOptions = [
  {
    label: 'cluster.restart.failure',
    value: 'cluster.restart.failure',
  },
  {
    label: 'airflow.webserver.failure',
    value: 'airflow.webserver.failure',
  },
  {
    label: 'airflow.scheduler.failure',
    value: 'airflow.scheduler.failure',
  },
  {
    label: 'airflow.task.failure',
    value: 'airflow.task.failure',
  },
  {
    label: 'airflow.task.success',
    value: 'airflow.task.success',
  },
  {
    label: 'airflow.import.errors',
    value: 'airflow.import.errors',
  },
  {
    label: 'operator.failure',
    value: 'operator.failure',
  },
  {
    label: 'operator.success',
    value: 'operator.success',
  },
  {
    label: 'dag.success',
    value: 'dag.success',
  },
  {
    label: 'dag.failure',
    value: 'dag.failure',
  },
]

export { eventTypeOptions, serviceOptions }
