import React, { Component } from 'react'
import Button from 'components/Button/Button.js'
import './ReleaseNotesButton.scss'

export default class ReleaseNotesButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lastOpened: Number(localStorage.getItem('map_release_notes_opened') || 0),
    }
  }

  onClick = () => {
    if (!this.props.isOpen) {
      localStorage.setItem('map_release_notes_opened', Date.now())
      this.setState({ lastOpened: Date.now() })
    }
    this.props.onClick()
  }

  render() {
    const { latestTs } = this.props
    const { lastOpened } = this.state
    const newNews = latestTs > lastOpened

    return (
      <Button
        onClick={this.onClick}
        className={`release-notes-button${newNews ? ' release-notes-button-active' : ''
          }`}
      >
        What&apos;s New <i className={`fa fa-gift${newNews ? ' bounce' : ''}`} />
      </Button>
    )
  }
}
