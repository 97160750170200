import React, { Component } from 'react'
import { getCluster } from 'store/clusters.js'
import config from 'envs/config'
import './Provisioning.scss'

export default class Provisioning extends Component {
  constructor(props) {
    super(props)
    this.state = {
      updatedCluster: false,
    }
  }

  componentDidMount() {
    this.refreshInterval = setInterval(this.refreshCluster, 5000)
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval)
  }

  refreshCluster = () =>
    getCluster(this.props.region.api, this.props.cluster.cluster_id)
      .then(cluster => {
        if (!['requested', 'provisioning'].includes(cluster.life_cycle)) {
          // we're done here
          setTimeout(() => window.location.reload(), 2000)
        }
        this.setState({ updatedCluster: cluster })
      })
      .catch(err => console.log(err))

  render() {
    const cluster = this.state.updatedCluster || this.props.cluster
    let queuedClass = 'step active'
    let provisioningClass = 'step'
    let readyClass = 'step'

    if (cluster.life_cycle === 'provisioning') {
      queuedClass = 'step green'
      provisioningClass = 'step active'
    }

    if (cluster.life_cycle === 'provisioned') {
      queuedClass = 'step green'
      provisioningClass = 'step green'
      readyClass = 'step active'
    }

    return (
      <div className="dash-main provisioning">
        <h1 className="provisioning-title">
          Hang Tight while we prepare your cluster
        </h1>
        <p className="provisioning-subtitle">
          New clusters are generally ready for use within 10 minutes, but can
          sometimes take up to 30 minutes. Our <a href={config.slack}>Slack</a>.
        </p>

        <div className="steps">
          <div className="step green">
            <div className="step-inner">
              <div className="step-spinner">
                <i className="fa fa-check"></i>
              </div>
              <h2 className="step-title">Requested</h2>
            </div>
          </div>

          <div className={queuedClass}>
            <div className="step-inner">
              <div className="step-spinner">
                <i className="fa fa-check"></i>
                <div className="step-spinning"></div>
              </div>
              <h2 className="step-title">Queued</h2>
            </div>
          </div>

          <div className={provisioningClass}>
            <div className="step-inner">
              <div className="step-spinner">
                <i className="fa fa-check"></i>
                <div className="step-spinning"></div>
              </div>
              <h2 className="step-title">Provisioning</h2>

              <div className="sub-steps">
                <div className="sub-step">
                  <h3 className="sub-step-title">
                    Generating cluster-specific secrets
                  </h3>
                </div>

                <div className="sub-step">
                  <h3 className="sub-step-title">
                    Creating new Postgres Database
                  </h3>
                </div>

                <div className="sub-step">
                  <h3 className="sub-step-title">
                    Creating new Cluster IAM Role
                  </h3>
                </div>

                <div className="sub-step">
                  <h3 className="sub-step-title">
                    Creating new Kubernetes Namespace
                  </h3>
                </div>

                <div className="sub-step">
                  <h3 className="sub-step-title">Deploying Apache Airflow</h3>
                </div>
                <div className="sub-step">
                  <h3 className="sub-step-title">
                    Verifying Apache Airflow is online and operating normally
                  </h3>
                </div>
                <div className="sub-step">
                  <h3 className="sub-step-title">
                    Registering your new cluster with the regional gateway
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className={readyClass}>
            <div className="step-inner">
              <div className="step-spinner">
                <div className="step-spinning"></div>
              </div>
              <h2 className="step-title">Ready</h2>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
