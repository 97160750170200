import { http, digResponse, getRequestError } from 'util/index.js'

const encode = encodeURIComponent

export const getBuilds = (api, clusterId) =>
  http
    .get(`${api}/clusters/${encode(clusterId)}/builds`)
    .then(response => digResponse(response, 'data', 'builds'))
    .catch(response => {
      throw getRequestError(response, 'getBuilds')
    })

export const getBuild = (api, clusterId, buildId) =>
  http
    .get(`${api}/clusters/${encode(clusterId)}/builds/${encode(buildId)}`)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'getBuild')
    })

export const getBuildLogs = (api, clusterId, buildId) =>
  http
    .get(`${api}/clusters/${encode(clusterId)}/builds/${encode(buildId)}/logs`)
    .then(response => digResponse(response, 'data', 'logs'))
    .catch(response => {
      throw getRequestError(response, 'getBuildLogs')
    })

export const startBuild = (api, clusterId) =>
  http
    .post(`${api}/clusters/${encode(clusterId)}/builds`, {})
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'startBuild')
    })

export const deleteBuild = (api, clusterId, buildId) =>
  http
    .delete(`${api}/clusters/${encode(clusterId)}/builds/${encode(buildId)}`)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'deleteBuild')
    })

export const getBuildspec = (api, clusterId) =>
  http
    .get(`${api}/clusters/${encode(clusterId)}/buildspec`)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'getBuildspec')
    })

export const updateBuildspec = (api, clusterId, body) =>
  http
    .post(`${api}/clusters/${encode(clusterId)}/buildspec`, body)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'updateBuildspec')
    })
