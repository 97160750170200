import React from 'react'
import './Toggle.scss'

export default ({ on, disabled, className, onClick }) => {
  let classes = ['alt-toggle']
  if (on) {
    classes.push('alt-toggle-on')
  }
  if (disabled) {
    classes.push('alt-toggle-disabled')
  }
  if (className) {
    classes.push(className)
  }

  return (
    <div className={classes.join(' ')} onClick={() => !disabled && onClick()}>
      <div className="alt-toggle-inner"></div>
    </div>
  )
}
