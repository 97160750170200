import qs from 'qs'
import config from 'envs/config'
import { http, setGlobalHeader, removeGlobalHeader } from 'util/index.js'

function getRandomString(length) {
  const a = new Uint8Array(Math.ceil(length / 2))
  crypto.getRandomValues(a)

  const str = Array.from(a, dec => ('0' + dec.toString(16)).substr(-2)).join('')
  return str.slice(0, length)
}

function makeVerifier() {
  const minLength = 43
  const maxLength = 128

  let verifier = ''

  if (verifier.length < minLength) {
    verifier = verifier + getRandomString(minLength - verifier.length)
  }

  return encodeURIComponent(verifier).slice(0, maxLength)
}

function base64URLEncode(input) {
  return btoa(input)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '')
}

async function makeChallenge(str) {
  const buffer = new TextEncoder().encode(str)
  const arrayBuffer = await crypto.subtle.digest('SHA-256', buffer)
  const hash = String.fromCharCode.apply(null, new Uint8Array(arrayBuffer))
  return base64URLEncode(hash)
}

export async function handleAuth(history, refresh) {
  if (refresh) return await getOAuthTokenWithRefresh(refresh)
  await parseRedirectCode(history)

  // User has stored token
  let token = JSON.parse(localStorage.getItem('map_okta_token'))
  let unpackedToken = unpackToken(token)

  if (!unpackedToken || unpackedToken.expiresAt * 1000 < Date.now()) {
    localStorage.removeItem('map_okta_token')
    navigator.onLine ? await login() : new Error('Broswer offline')
  } else {
    setGlobalHeader('Authorization', 'Bearer ' + unpackedToken.accessToken)
    return unpackedToken
  }
}

async function parseRedirectCode(history) {
  const queryString = new URLSearchParams(window.location.search)
  const code = queryString.get('code')
  const codeVerifier = localStorage.getItem('map_code_verifier')

  if (!code || !code.length) return null
  let token = await getOAuthToken(code, codeVerifier)
  localStorage.setItem('map_okta_token', JSON.stringify(token))

  let prevRoute = localStorage.getItem('map_pre_redirect_location')
  if (prevRoute) {
    history.replace(prevRoute)
    cleanup()
    return
  }
}

async function getOAuthToken(code, codeVerifier) {
  return new Promise((resolve, reject) => {
    const params = new URLSearchParams()

    params.set('code', code)
    params.set('code_verifier', codeVerifier)
    params.set('grant_type', 'authorization_code')
    params.set('redirect_uri', config.oAuth.redirect_uri)
    params.set('client_id', config.oAuth.client_id)

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    }
    http
      .post(`${config.oAuth.authorization}/token`, params, { headers })
      .then(response => {
        setGlobalHeader('Authorization', 'Bearer ' + response.data.access_token)
        resolve(response.data)
      })
      .catch(error => {
        console.error('Error while requesting `token`')
        cleanup()
        reject(error)
      })
  })
}

async function getOAuthTokenWithRefresh(refreshToken) {
  return new Promise((resolve, reject) => {
    removeGlobalHeader('Authorization')
    const params = new URLSearchParams()

    params.set('grant_type', 'refresh_token')
    params.set('client_id', config.oAuth.client_id)
    params.set('scope', config.oAuth.scope)
    params.set('refresh_token', refreshToken)

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    }
    http
      .post(`${config.oAuth.authorization}/token`, params, { headers })
      .then(response => {
        setGlobalHeader('Authorization', 'Bearer ' + response.data.access_token)
        localStorage.setItem('map_okta_token', JSON.stringify(response.data))
        resolve(unpackToken(response.data))
      })
      .catch(error => {
        console.error('Error while requesting `token`')
        cleanup()
        reject(error)
      })
  })
}

export async function login(isRefresh) {
  try {
    const codeVerifier = makeVerifier()
    localStorage.setItem('map_code_verifier', codeVerifier)
    localStorage.setItem(
      'map_pre_redirect_location',
      window.location.pathname + window.location.search
    )
    let url = await createRedirectURL(codeVerifier, isRefresh)
    window.location.href = url
    return
  } catch (error) {
    console.error(`Failed while attempting Okta redirect: ${error}`)
    cleanup()
    return error
  }
}

export function logout() {
  localStorage.removeItem('map_okta_token')
  localStorage.removeItem('map_api_token')
}

function createRedirectURL(codeVerifier, isRefresh) {
  return new Promise((resolve, reject) => {
    try {
      makeChallenge(codeVerifier).then(codeChallenge =>
        resolve(
          `${config.oAuth.authorization}/authorize?${qs.stringify({
            ...config.oAuth,
            code_challenge: codeChallenge,
            nonce: '1',
            prompt: isRefresh ? 'none' : '',
          })}`
        )
      )
    } catch (error) {
      console.error(`Failed while creating redirect url: ${error}`)
      reject()
    }
  })
}

function unpackToken(token) {
  if (!token || !token.access_token) return null

  let accessValues = token.access_token.split('.')
  let openIdValues = token.id_token.split('.')
  let accessClaims = JSON.parse(window.atob(accessValues[1]))
  let openIdClaims = JSON.parse(window.atob(openIdValues[1]))

  return {
    accessToken: token.access_token,
    refreshToken: token.refresh_token,
    openIdToken: token.id_token,
    expiresAt: accessClaims.exp,
    username: accessClaims.sub,
    email: openIdClaims.email,
    groups: accessClaims.groups || [],
    name: openIdClaims.name,
  }
}

function cleanup() {
  localStorage.removeItem('map_code_verifier')
  localStorage.removeItem('map_pre_redirect_location')
}
