import React from 'react'
import {
  serviceOptions,
  eventTypeOptions,
} from '../../Config/NotificationsConfig.js'
import SelectInput from 'components/SelectInput/SelectInput.js'
import TextInput from 'components/TextInput/TextInput.js'
import '../../Notifications.scss'
import './ContactNotification.scss'

export default ({
  index,
  notification,
  validation,
  updateCallback,
  disabled,
}) => {
  return (
    <div id="notification-contact">
      <div className="notification-config-row" key={index}>
        <div className="notification-config-service">
          <SelectInput
            id="serviceInput"
            label="SERVICE"
            options={serviceOptions}
            onChange={opt => updateCallback('service', opt)}
            value={serviceOptions.find(
              opt => opt.value === notification.service
            )}
            disabled={disabled}
          />
        </div>

        <div className="notification-config-contact">
          <TextInput
            id="contactInput"
            required
            label="CONTACT"
            onChange={e => updateCallback('contact', e)}
            value={notification.contact}
            error={
              validation &&
              validation[index] &&
              validation[index].contact &&
              validation[index].contact[0]
            }
            disabled={disabled}
          />
        </div>
      </div>

      <div className="notification-config-row">
        <div className="notification-config-event-type">
          <SelectInput
            id="eventTypeInput"
            label="EVENT TYPE"
            options={eventTypeOptions}
            onChange={opt => updateCallback('event_type', opt)}
            value={eventTypeOptions.find(
              opt => opt.value === notification.event_type
            )}
            disabled={disabled}
          />
        </div>

        <div className="notification-config-meta_data">
          <TextInput
            id="metaDataInput"
            label="METADATA (Blank for all)"
            onChange={e => updateCallback('meta_data', e)}
            disabled={
              disabled ||
              !(
                notification.event_type.includes('dag') ||
                notification.event_type.includes('operator')
              )
            }
            value={
              notification.event_type.includes('dag') ||
                notification.event_type.includes('operator')
                ? notification.meta_data
                  ? notification.meta_data
                  : ''
                : ''
            }
            error={
              validation &&
              validation[index] &&
              validation[index].meta_data &&
              validation[index].meta_data[0]
            }
          />
        </div>
      </div>
    </div>
  )
}
