/* eslint-disable no-case-declarations, react/no-unescaped-entities */

import React, { Component } from 'react'
import Dialogue from 'components/Dialogue/Dialogue.js'
import Button from 'components/Button/Button.js'
import SelectInput from 'components/SelectInput/SelectInput.js'
import TextInput from 'components/TextInput/TextInput.js'
import ToggleInput from 'components/Toggle/ToggleInput.js'
import TextAreaInput from 'components/TextAreaInput/TextAreaInput.js'
import validate from './HelpDialogue.validate.js'
import { postToSlack } from 'store/slack.js'
import './HelpDialogue.scss'

export default class HelpDialogue extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageShown: props.pageShown === 'slack' ? 'slack-form' : 'nav',
      categories: [
        {
          label: 'Cluster(s) Unavailable',
          value: 'unavailable',
        },
        {
          label: 'DAG(s) Not Running/Delayed',
          value: 'dag-delayed',
        },
        {
          label: 'DAG Failure(s)',
          value: 'dag-failures',
        },
        {
          label: 'Failure to Build',
          value: 'build-failure',
        },
        {
          label: 'Peer Ring-Balancer Issues',
          value: 'peer-ring-balancer',
        },
        {
          label: 'KONG Error',
          value: 'kong-error',
        },
        {
          label: 'Other',
          value: 'other',
        },
        {
          label: 'Notification Problem',
          value: 'notification',
        },
      ],
      category: {
        label: '--',
        value: 'choose',
      },
      affectedClusters: props.cluster.cluster_name,
      affectedDags: '',
      supportAccessGranted: false,
      email: props.email,
      subject: '',
      description: '',
      showCopyAlert: false,
      ifSubjectTouched: false,
      ifDescriptionTouched: false,
      validation: {},
    }
  }

  generateSlackPayload = function (currentState, currentProps) {
    let slackPayload = {}

    slackPayload = {
      channel: '#test-slack-webhook-map',
      username: 'Map Support Request',
      attachments: [
        {
          color: '#007a87',
          blocks: [
            {
              type: 'context',
              elements: [
                {
                  type: 'mrkdwn',
                  text: `*${currentState.email}* has submitted a support request via the UI Support guide`,
                },
              ],
            },
            {
              type: 'header',
              text: {
                type: 'plain_text',
                text: `${currentState.category.label} - ${currentState.subject}`,
                emoji: true,
              },
            },
            {
              type: 'context',
              elements: [
                {
                  type: 'plain_text',
                  text: currentState.affectedClusters,
                  emoji: true,
                },
              ],
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: currentState.description,
              },
            },
            {
              type: 'actions',
              elements: [
                {
                  type: 'button',
                  text: {
                    type: 'plain_text',
                    text: 'Open Cluster',
                  },
                  url: `${currentProps.region.api.includes('nonprod')
                    ? 'https://preprod.map.nike.com'
                    : 'https://map.nike.com'
                    }/${currentProps.region.value}/clusters/${currentProps.cluster.cluster_name
                    }`,
                },
              ],
            },
            {
              type: 'divider',
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: `*Region: * \`${currentProps.region.value}\``,
              },
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: `*Cluster Name: * \`${currentProps.cluster.cluster_name}\``,
              },
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: `*Cluster ID: * \`${currentProps.cluster.cluster_id}\``,
              },
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: `*Image: * \`${currentProps.cluster.image}\``,
              },
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: `*Airflow Version: * \`${currentProps.cluster.airflow_version}\``,
              },
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: `*AE Compute Integration: * \`${currentProps.cluster.ae_compute_integration}\``,
              },
            },
          ],
        },
      ],
    }

    if (currentState.affectedDags && currentState.affectedDags.length > 2) {
      slackPayload.attachments[0].blocks.push({
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*Affected DAGs: * \`${currentState.affectedDags}\``,
        },
      })
    }

    if (currentState.supportAccessGranted) {
      slackPayload.attachments[0].blocks.push({
        type: 'context',
        elements: [
          {
            type: 'mrkdwn',
            text: '*Support Access* has been granted.',
          },
        ],
      })
    }

    console.log(slackPayload)
    return slackPayload
  }

  generateSlackMessage = function (currentState, currentProps) {
    const message = [
      `${currentState.email} has submitted a support request via the UI Support guide`,
      `${currentState.category.label} - ${currentState.subject}\n${currentState.affectedClusters}`,
      currentState.description,
      '-------------------------',
    ]

    const details = [
      `Region:\t\t\t${currentProps.region.value}`,
      `Cluster Name:\t\t${currentProps.cluster.cluster_name}`,
      `Cluster ID:\t\t\t${currentProps.cluster.cluster_id}`,
      `Image:\t\t\t${currentProps.cluster.image}`,
      `Airflow Version:\t\t${currentProps.cluster.airflow_version}`,
      `AE Compute Integration:\t${currentProps.cluster.ae_compute_integration}`,
    ]

    if (currentState.affectedDags) {
      details.push(`Affected DAGs:\t\t${currentState.affectedDags}`)
    }

    if (currentState.supportAccessGranted) {
      details.push('\nSupport Access has been granted.')
    }

    return message.join('\n\n') + '\n\n' + details.join('\n')
  }

  generateMailToLink = function (currentState, currentProps) {
    const email = 'Lst-EP.Managed.Airflow.Platform@nike.com'
    const subject = encodeURI(
      `${currentState.category.label} - ${currentState.subject}`
    )

    const message = [
      'Hello MAP Team. I am encountering an issue within the MAP product. Can you please assist me?',
      `Affected Clusters:\t\t${currentState.affectedClusters}`,
      `${currentState.category.label} - ${currentState.subject}`,
      currentState.description,
      '-------------------------',
    ]

    const details = [
      `Region: ${currentProps.region.value}`,
      `Cluster Name: ${currentProps.cluster.cluster_name}`,
      `Cluster ID: ${currentProps.cluster.cluster_id}`,
      `Image: ${currentProps.cluster.image}`,
      `Airflow Version: ${currentProps.cluster.airflow_version}`,
      `AE Compute Integration: ${currentProps.cluster.ae_compute_integration}`,
    ]

    if (currentState.affectedDags && currentState.affectedDags.length > 2) {
      details.push(`Affected DAGs: ${currentState.affectedDags}`)
    }
    if (currentState.supportAccessGranted) {
      details.push('\nSupport Access has been granted.')
    }

    const encoded = encodeURI(
      message.join('\n\n') + '\n\n' + details.join('\n')
    )

    let response = `mailto:${email}?subject=${subject}&body=${encoded}`

    if (encoded.length > 2080) {
      response = `mailto:${email}?subject=${subject}`
    }

    return response
  }

  copyToClipboard = function (
    e,
    copiedAlertCallback,
    generateSlackMessage,
    currentState,
    currentProps
  ) {
    let success = false

    try {
      navigator.clipboard.writeText(
        generateSlackMessage(currentState, currentProps)
      )
      e.target.focus()
      copiedAlertCallback()
      success = true
    } catch (err) {
      console.log('Error on Copy: ', err)
    }
    return success
  }

  publishToSlack = function (generateSlackPayload, currentState, currentProps) {
    let success = false

    try {
      postToSlack(
        currentProps.region.api,
        generateSlackPayload(currentState, currentProps)
      )
      success = true
    } catch (err) {
      console.log('Error Posting to Slack: ', err)
    }

    return success
  }

  validateErrorExists = function (errors) {
    let errorCount = 0
    for (const [key, value] of Object.entries(errors)) {
      if (key && value && value.length > 0) errorCount++
    }
    return errorCount > 0 ? true : false
  }

  render() {
    let { onClose } = this.props
    let title = 'NEED HELP?'
    let subtitle = ''

    switch (this.state.pageShown) {
      case 'nav':
        title = 'NEED HELP?'
        subtitle = (
          <div id="nav">
            <p>Choose the method in which you would like to get assistance:</p>
            <div className="subtitle-options">
              <div
                id="general"
                className="help-options"
                onClick={() => {
                  window.open(
                    'https://docs.platforms.nike.com/managed-airflow-platform',
                    'docs',
                    'windowFeatures'
                  )
                  onClose()
                }}
              >
                <div className="help-info">
                  <h3>General Guidance</h3>
                  <p>
                    Assistance with getting started with the Managed Airflow
                    Platform.
                  </p>
                </div>
                <div className="help-arrow">
                  <i className="fa fa-long-arrow-right"></i>
                </div>
              </div>
              <div
                id="slack"
                className="help-options"
                onClick={() => {
                  this.setState({
                    pageShown: 'slack-form',
                  })
                }}
              >
                <div className="help-info">
                  <h3>Technical Support</h3>
                  <p>
                    Requests for technical assistance (non-production
                    impacting).
                  </p>
                </div>
                <div className="help-arrow">
                  <i className="fa fa-long-arrow-right"></i>
                </div>
              </div>
              <div
                id="production"
                className="help-options"
                onClick={() => {
                  window.open(
                    'https://docs.platforms.nike.com/managed-airflow-platform/support/get-support#_contacting_the_team',
                    'docs',
                    'windowFeatures'
                  )
                  onClose()
                }}
              >
                <div className="help-info">
                  <h3>Production Incident</h3>
                  <p>
                    Service-related technical issues requiring immediate
                    assistance.
                  </p>
                </div>
                <div className="help-arrow">
                  <i className="fa fa-long-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        )
        break
      case 'slack-form':
        title = 'TECHNICAL SUPPORT'
        subtitle = (
          <div className="slack-form">
            <SelectInput
              label="Category"
              detail="Select the issue you're currently experiencing."
              options={this.state.categories}
              value={this.state.category}
              onChange={opt => this.setState({ category: opt })}
              required
            />
            <TextInput
              label="Affected Cluster Names"
              detail="Please enter the name of the affected clusters."
              value={this.state.affectedClusters}
              onChange={e => {
                this.setState({
                  affectedClusters: e.target.value,
                  validation: {
                    ...this.state.validation,
                    affectedClusters: validate(
                      'affectedClusters',
                      e.target.value
                    ),
                  },
                })
              }}
              error={
                this.state.validation.affectedClusters &&
                this.state.validation.affectedClusters[0]
              }
              required
            />
            {this.state.category.value !== 'dag-delayed' &&
              this.state.category.value !== 'dag-failures' ? (
              ''
            ) : (
              <div>
                <TextInput
                  label="DAGs in Question"
                  detail="Please enter the names of the DAGs experiencing difficulty "
                  value={this.state.affectedDags}
                  onChange={e => {
                    this.setState({
                      affectedDags: e.target.value,
                      validation: {
                        ...this.state.validation,
                        dag: validate('dag', e.target.value),
                      },
                    })
                  }}
                  onBlur={() => {
                    this.setState({
                      validation: {
                        ...this.state.validation,
                        dag: validate('dag', this.state.affectedDags),
                      },
                    })
                  }}
                  error={
                    this.state.validation.dag && this.state.validation.dag[0]
                  }
                />
              </div>
            )}

            <ToggleInput
              label="Has Support Access Been Granted?"
              detail="Have you already granted Support Access? If you haven't, we'll need you to do so after contacting us."
              value={this.state.supportAccessGranted}
              onClick={() =>
                this.setState({
                  supportAccessGranted: !this.state.supportAccessGranted,
                })
              }
              required
            />
            <TextInput
              label="Nike Email"
              detail="Please enter your nike email. This is so we can find you in the Slack Workspace."
              value={this.state.email}
              onChange={e => {
                this.setState({
                  email: e.target.value,
                  validation: {
                    ...this.state.validation,
                    email: validate('email', e.target.value),
                  },
                })
              }}
              onBlur={() => {
                this.setState({
                  validation: {
                    ...this.state.validation,
                    email: validate('email', this.state.email),
                  },
                })
              }}
              error={
                this.state.validation.email && this.state.validation.email[0]
              }
              required
            />
            <TextInput
              label="Subject"
              detail="Breifly describe the subject you're having an issue with."
              value={this.state.subject}
              onChange={e => {
                this.setState({
                  subject: e.target.value,
                  validation: {
                    ...this.state.validation,
                    subject: validate('subject', e.target.value),
                  },
                })
              }}
              onBlur={() => {
                this.setState({
                  validation: {
                    ...this.state.validation,
                    subject: validate('subject', this.state.subject),
                  },
                })
              }}
              onFocus={() => {
                this.setState({
                  ifSubjectTouched: true,
                })
              }}
              error={
                this.state.validation.subject &&
                this.state.validation.subject[0]
              }
              required
            />
            <TextAreaInput
              label="Description"
              detail="Explain the issue you're experiencing. Screenshots are unnecessary. Include error log details if you have them."
              value={this.state.description}
              onChange={e => {
                this.setState({
                  description: e.target.value,
                  validation: {
                    ...this.state.validation,
                    description: validate('description', e.target.value),
                  },
                })
              }}
              onBlur={() => {
                this.setState({
                  validation: {
                    ...this.state.validation,
                    description: validate(
                      'description',
                      this.state.description
                    ),
                  },
                })
              }}
              onFocus={() => {
                this.setState({
                  ifDescriptionTouched: true,
                })
              }}
              error={
                this.state.validation.description &&
                this.state.validation.description[0]
              }
              required
            />
            <Button
              className="back-button alt-button-primary"
              onClick={() => {
                this.setState({
                  pageShown: 'nav',
                })
              }}
            >
              Back
            </Button>
            <Button
              primary
              onClick={() => {
                if (this.state.category.value === 'dag-failures') {
                  this.setState({
                    pageShown: 'ae-operator-failure',
                  })
                } else {
                  if (
                    this.publishToSlack(
                      this.generateSlackPayload,
                      this.state,
                      this.props
                    )
                  ) {
                    this.setState({
                      pageShown: 'slack-success',
                    })
                  } else {
                    this.setState({
                      pageShown: 'slack-error',
                    })
                  }
                }
              }}
              disabled={
                !(
                  this.state.ifSubjectTouched &&
                  this.state.ifDescriptionTouched &&
                  !this.validateErrorExists(this.state.validation) &&
                  this.state.category !== 'choose'
                )
              }
            >
              Next
            </Button>
          </div>
        )
        break
      case 'slack-error':
        title = 'TECHNICAL SUPPORT'
        subtitle = (
          <div>
            <p className="final-slack">
              It appears we had issues attempting to post your message to slack.
              <br />
              Please copy and paste the following text into a message on our
              Slack Channel, #ep-map-support, or{' '}
              <a href={this.generateMailToLink(this.state, this.props)}>
                send an email to the MAP Engineering Team
              </a>
              . We will get back to you as soon as possible.
            </p>
            {!this.state.supportAccessGranted && (
              <p className="support-access-reminder">
                Grant{' '}
                <a href="https://docs.platforms.nike.com/managed-airflow-platform/faq#_how_to_provide_support_access_in_map">
                  Support Access
                </a>{' '}
                after pasting this into <b>#ep-map-support</b>.
              </p>
            )}

            <TextAreaInput
              label="Generated Help Message"
              detail="This message was generated to give MAP Engineers all they need to assist you with your issue."
              value={this.generateSlackMessage(this.state, this.props)}
              disabled
            />
            <div className="button-container">
              <Button
                onClick={e => {
                  this.copyToClipboard(
                    e,
                    () => {
                      this.setState({
                        showCopyAlert: true,
                      })

                      setTimeout(() => {
                        this.setState({
                          showCopyAlert: false,
                        })
                      }, 2000)
                    },
                    this.generateSlackMessage,
                    this.state,
                    this.props
                  )
                }}
              >
                Copy
              </Button>
              <span className={this.state.showCopyAlert ? 'show' : ''}>
                Copied!
              </span>
              <Button
                primary
                onClick={() => {
                  window.open(
                    'https://nikedigital.slack.com/archives/CR37NGSH1',
                    'docs',
                    'windowFeatures'
                  )
                }}
              >
                Open Slack
              </Button>
            </div>
          </div>
        )
        break
      case 'ae-operator-failure':
        let codeExample = `[2021-06-30 13:10:15,537] {compute_pod_operator.py:119} INFO - ***** Task is running on AE Compute Unified Runtime Environment ******\n[2021-06-30 13:10:15,617] {compute_pod_operator.py:131} INFO - Runtime name is default ComputeImage is compute_image3.6 and region is us-east-1\n[2021-06-30 13:10:16,300] {logging_mixin.py:112} INFO - Connecting to py3.6 image 659972660363.dkr.ecr.us-east-1.amazonaws.com/ae-compute/operators-python36:2.1.3\n[2021-06-30 13:10:16,349] {compute_pod_operator.py:147} INFO - Connecting to py3.6 image 659972660363.dkr.ecr.us-east-1.amazonaws.com/ae-compute/operators-python36:2.1.3`

        title = 'AE OPERATOR FAILURE CHECK'
        subtitle = (
          <div className="ae-operator-check">
            <p>
              The MAP Team has enabled the usage of AE Compute Operators,
              however we do not maintain those repositories. Before proceeding
              to the form, please follow these steps to check whether or not
              your DAG is failing due to an AE Compute Error.
            </p>
            <br />
            <h4>Steps to Check Logs for AE Compute Errors</h4>
            <p>1. Open the Airflow UI and navigate to the Failed DAG.</p>
            <p>2. Click 'Graph View' on the Airflow DAG's Page. </p>
            <p>3. Click on the failed task in the graph.</p>
            <p>4. Click 'View Logs' in the dialogue window.</p>
            <p>5. Look at the logs and answer the following question.</p>
            <h3 className="question">
              Are your failure logs beneath other logs that say "Task is running
              on AE Compute Unified Runtime Environment"?
            </h3>
            <TextAreaInput
              disabled
              label="Example Error Logs"
              value={codeExample}
            />
            <br />
            <p>
              <strong>
                If YES, your issue is AE Compute Operator failure. Please reach
                out to #ep-ae-support.
              </strong>{' '}
              If NO, please continue with your support request.
            </p>
            <br />
            <div className="button-container">
              <Button
                onClick={() =>
                  window.open(
                    'https://nikedigital.slack.com/archives/C3G4JFT1D'
                  )
                }
              >
                AE Support Slack
              </Button>
              <Button
                primary
                onClick={() => {
                  if (
                    this.publishToSlack(
                      this.generateSlackPayload,
                      this.state,
                      this.props
                    )
                  ) {
                    this.setState({
                      pageShown: 'slack-success',
                    })
                  } else {
                    this.setState({
                      pageShown: 'slack-error',
                    })
                  }
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        )
        break
      case 'slack-success':
        setTimeout(
          () => window.open('https://nikedigital.slack.com/archives/CR37NGSH1'),
          5000
        )
        title = 'YOUR REQUEST HAS BEEN SUBMITTED!'
        subtitle = (
          <div className="slack-success">
            {!this.state.supportAccessGranted && (
              <p className="support-access-reminder">
                Grant{' '}
                <a href="https://docs.platforms.nike.com/managed-airflow-platform/faq#_how_to_provide_support_access_in_map">
                  Support Access
                </a>{' '}
                after pasting this into <b>#ep-map-support</b>.
              </p>
            )}
            <p>
              Please open the{' '}
              <strong>
                <a
                  href="#"
                  onClick={() =>
                    window.open(
                      'https://nikedigital.slack.com/archives/CR37NGSH1',
                      'docs',
                      'windowFeatures'
                    )
                  }
                >
                  #ep-map-support
                </a>
              </strong>{' '}
              slack channel and follow your support request there. A member of
              the MAP Engineering team will be with you as soon as possible.
            </p>
            <br />
            <p>
              If you are not redirected automatically in five seconds, please
              allow pop-ups on your browser and manually navigate to{' '}
              <a
                href="#"
                onClick={() =>
                  window.open(
                    'https://nikedigital.slack.com/archives/CR37NGSH1'
                  )
                }
              >
                #ep-map-support
              </a>
              .
            </p>
            <br />
            <h3 className="thanks">
              Thank you for using the UI Support Guide! We look forward to
              assisting you as soon as possible.
            </h3>
            <br />
          </div>
        )
        break
      default:
        title = ''
        subtitle = ''
        break
    }

    return (
      <Dialogue
        className="restart-cluster-dialogue"
        title={title}
        subtitle={subtitle}
        onClose={onClose}
        closeText="CLOSE"
      ></Dialogue>
    )
  }
}
