import React, { useState, useCallback, useEffect } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import './Toasts.scss'

export const ToastsContext = React.createContext(null)
const toastTimeout = 8000
let id = 0

const Toast = ({ toast, removeToast }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(toast.id)
    }, toastTimeout)

    return () => {
      clearTimeout(timer)
    }
  })

  return (
    <div
      className={`toast toast-${toast.type}`}
      onClick={() => removeToast(toast.id)}
    >
      {toast.type === 'ok' && <i className="fa fa-check-circle"></i>}
      {toast.type === 'error' && <i className="fa fa-exclamation-circle"></i>}
      {(toast.type === 'info' || !toast.type) && (
        <i className="fa fa-info-circle"></i>
      )}{' '}
      {toast.message} <i className="fa fa-times"></i>
      <div className="toast-timer"></div>
    </div>
  )
}

const ToastsContainer = ({ toasts, removeToast }) => (
  <div className="toasts-container">
    <TransitionGroup>
      {toasts.map((toast, i) => (
        <CSSTransition
          key={i}
          classNames={'toasts'}
          timeout={600}
        >
          <Toast
            key={`toast-${toast.id}`}
            toast={toast}
            removeToast={removeToast}
          />
        </CSSTransition>
      ))}
    </TransitionGroup>
  </div>
)

export default ({ children }) => {
  const [toasts, setToasts] = useState([])

  const addToast = useCallback(
    (message, type) => {
      setToasts(toasts => [
        ...toasts,
        { id: id++, message: message, type: type },
      ])
    },
    [setToasts]
  )

  const removeToast = useCallback(
    id => {
      setToasts(toasts => toasts.filter(t => t.id !== id))
    },
    [setToasts]
  )

  return (
    <ToastsContext.Provider value={{ addToast, removeToast }}>
      <ToastsContainer
        toasts={toasts}
        removeToast={removeToast}
        addToast={addToast}
      />
      {children}
    </ToastsContext.Provider>
  )
}
