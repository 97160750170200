import React, { Component } from "react"
import { Base64 } from "js-base64"
import { getBuildspec, updateBuildspec } from "store/builds.js"
import { ToastsContext } from "components/Toasts/Toasts.js"
import FileEdit from "components/FileEdit/FileEdit.js"
import Button from "components/Button/Button.js"
import CreateBuild from "../Builds/CreateBuild"
export default class BuildScriptEdit extends Component {
  static contextType = ToastsContext
  constructor(props) {
    super(props)
    this.state = {
      buildSpec: false,
      buildSpecLoading: true,
      buildSpecError: false,
      buildScript: false,
      uploadPending: false,
      uploadError: false,
      createOpen: false,
    }
  }

  componentDidMount() {
    this.fetchBuildSpec()
  }

  fetchBuildSpec = () =>
    getBuildspec(this.props.region.api, this.props.cluster.cluster_id)
      .then((buildSpec) =>
        this.setState({
          buildSpec,
          buildSpecLoading: false,
          buildSpecError: false,
          buildScript: Base64.decode(buildSpec.build_script),
        })
      )
      .catch((err) =>
        this.setState({
          buildSpecLoading: false,
          buildSpecError: err.map((item) => item.message),
        })
      )

  onSubmit = () =>
    this.setState(
      {
        uploadPending: true,
        uploadError: false,
      },
      () =>
        updateBuildspec(this.props.region.api, this.props.cluster.cluster_id, {
          ...this.state.buildSpec,
          build_script: Base64.encode(this.state.buildScript),
        })
          .then((buildSpec) =>
            this.setState(
              {
                buildSpec,
                buildScript: Base64.decode(buildSpec.build_script),
                uploadPending: false,
                uploadError: false,
              },
              () =>
                this.context.addToast(`Successfully Updated Build Script`, "ok")
            )
          )
          .catch((err) =>
            this.setState({
              uploadPending: false,
              uploadError: err.map((item) => item.message),
            })
          )
    )

  render() {
    let { permissions, cluster, region } = this.props
    let {
      buildSpecLoading,
      buildSpecError,
      buildScript,
      uploadPending,
      uploadError,
      createOpen,
    } = this.state

    let errors = []

    if (!permissions["CLUSTER.UPDATE"]) {
      errors.push(
        "Read Only Mode; `CLUSTER.UPDATE` permission required to modify."
      )
    }

    if (uploadError && uploadError.length > 0) {
      const displayError = "Upload Error: "
      uploadError.forEach((item) => {
        errors.push(displayError.concat(item))
      })
    }

    if (buildSpecError && buildSpecError.length > 0) {
      const displayError = "BuildSpec Error: "
      buildSpecError.forEach((item) => {
        errors.push(displayError.concat(item))
      })
    }

    const secondaryButton = (
      <Button
        primary
        borderless
        onClick={() => this.setState({ createOpen: true })}
        locked={!permissions["CLUSTER.UPDATE"]}
      >
        TRIGGER BUILD
      </Button>
    )

    return (
      <FileEdit
        errors={errors}
        headerTitle='EDIT BUILD SCRIPT'
        baseFolder='buildspec'
        fileName='build_script.sh'
        modifiedContent={buildScript}
        onContentChange={(value) => this.setState({ buildScript: value })}
        getPending={buildSpecLoading}
        onSubmit={this.onSubmit}
        submitText='SAVE'
        submitPending={uploadPending}
        submitDisabled={buildSpecError}
        readOnly={!permissions["CLUSTER.UPDATE"]}
        secondaryButton={secondaryButton}
        aside={
          createOpen && (
            <CreateBuild
              region={region}
              cluster={cluster}
              onClose={() => this.setState({ createOpen: false })}
            />
          )
        }
      />
    )
  }
}
