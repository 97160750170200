import styles from './Overlay.styl'
import React from 'react'
import PropTypes from 'prop-types'

const Overlay = ({ show, onClick, className }) => (
  <div
    className={`${styles.overlay} ${className || ''} ${
      show ? styles.show : ''
    }`}
    onClick={onClick}
  />
)

Overlay.propTypes = {
  /** Dictates whether or not the overlay is visible */
  show: PropTypes.bool.isRequired,
  /** Optional function that fires when overlay is clicked */
  onClick: PropTypes.func,
  /** Optional additional custom className */
  className: PropTypes.string
}

export default Overlay
