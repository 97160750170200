import React, { Component } from 'react'
import { getOperators } from 'store/operators.js'
import Search from 'components/Search/Search.js'
import Button from 'components/Button/Button.js'
import Table from 'components/Table/Table.js'
import DropZone from 'components/DropZone/DropZone.js'
import OperatorUpload from './OperatorUpload.js'
import OperatorsDelete from './OperatorsDelete.js'
import moment from 'moment'
import './Operators.scss'
import { debounce } from 'lodash'
import Tooltip from 'components/Tooltip/Tooltip.js'
import { Link } from 'react-router-dom'

const defaultState = {
  operators: [],
  operatorsLoading: true,
  operatorsError: true,
  filter: '',
  selected: [],
}

export default class Operators extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...defaultState,

      // sub-views
      upload: false,
      deleteOpen: false,
      dropzone: false,

      // pagination
      page: 0,
      pages: 1,
      pageSize: 20,
      sortBy: 'file_name',
      sortDesc: false,
    }
    this.operatorCols = [
      {
        name: 'FILE NAME',
        key: 'file_name',
        Cell: row => (
          <Link
            className="op-edit-link"
            draggable="false"
            key={row.value}
            to={`/${encodeURI(this.props.region.value)}/clusters/${encodeURI(
              this.props.cluster.cluster_name)}/operators/${encodeURIComponent(row.value)}`
            }
          >
            {row.value}
          </Link>
        ),
      },
      {
        name: 'UPDATED',
        key: 'updated',
        maxWidth: 240,
      },
    ]

    // Prevents fetchOperators from getting called more than once in 500 ms
    this.delayedHandleChange = debounce(
      this.delayedHandleChange.bind(this),
      500
    )
  }

  componentDidMount() {
    this.fetchOperators()
  }

  fetchOperators = (search_critera = '') =>
    getOperators(
      this.props.region.api,
      this.props.cluster.cluster_id,
      this.state.pageSize,
      this.state.page,
      search_critera
    )
      .then(body =>
        this.setState({
          operators:
            body.data.length > 0
              ? body.data.map(operator => ({
                ...operator,
                updated: moment(operator.updated_date).format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
              }))
              : [],
          operatorsLoading: false,
          operatorsError: false,
          pages:
            body.meta && body.meta.page
              ? Math.ceil(body.meta.page.max_size / this.state.pageSize)
              : 1,
        })
      )
      .catch(err =>
        this.setState({
          operatorsLoading: false,
          operatorsError: err.map(item => item.message),
        })
      )

  toggleSelected = key =>
    this.setState({
      selected: this.state.selected.includes(key)
        ? this.state.selected.filter(k => k !== key)
        : [key, ...this.state.selected],
    })

  toggleAll = () => {
    const opKeys = this.state.operators.map(op => op.file_name)

    this.setState({
      selected: this.state.selected.length === opKeys.length ? [] : opKeys,
    })
  }

  delayedHandleChange(data) {
    this.fetchOperators(data)
  }

  render() {
    let { cluster, region, permissions } = this.props
    let {
      operators,
      operatorsLoading,
      operatorsError,
      filter,
      selected,
      upload,
      deleteOpen,
      dropzone,
      page,
      pages,
      pageSize,
      sortBy,
      sortDesc,
    } = this.state

    // Sorts operators based on sort option
    let filtered = operators.sort((a, b) =>
      sortDesc
        ? a[sortBy] < b[sortBy]
          ? 1
          : -1
        : a[sortBy] > b[sortBy]
          ? 1
          : -1
    )

    if (upload)
      return (
        <OperatorUpload
          region={region}
          cluster={cluster}
          files={upload}
          onClose={() =>
            this.setState({ upload: false }, () => this.fetchOperators())
          }
        />
      )

    return (
      <div className="dash-main operators">
        <div className="list-header">
          <div className="list-header-bar">
            <h3 className="list-title">CLUSTER OPERATORS</h3>

            <Tooltip tooltipText="MAP Platform enables customers to Add any operators they want to their Airflow cluster. Simply upload them to the MAP UI and those operators will be available in your Airflow cluster When you restart the cluster."></Tooltip>
          </div>
          <div className="list-header-bar">
            <Search
              value={filter}
              onChange={e => {
                this.setState({ filter: e.target.value })
                this.delayedHandleChange(e.target.value)
              }}
            />

            <Button
              onClick={() => dropzone && dropzone.open()}
              locked={!permissions['OPERATORS.CREATE']}
            >
              UPLOAD OPERATOR
            </Button>

            <Button
              disabled={selected.length === 0}
              locked={!permissions['OPERATORS.DELETE']}
              onClick={() => this.setState({ deleteOpen: true })}
            >
              DELETE {selected.length > 0 ? selected.length : ''} OPERATORS
            </Button>
          </div>
        </div>

        <DropZone
          className="dash-main-list"
          accept=".py"
          onDrop={files => this.setState({ upload: files })}
          setRef={ref => this.setState({ dropzone: ref })}
          locked={!permissions['OPERATORS.CREATE']}
        >
          <Table
            rows={filtered}
            cols={this.operatorCols}
            loading={operatorsLoading}
            error={operatorsError}
            filter={filter}
            selectable
            onSelect={op => this.toggleSelected(op.file_name)}
            selectedMethod={op => selected.includes(op.file_name)}
            onSelectAll={this.toggleAll}
            selectedCount={selected.length}
            multiSelect
            paginate
            page={page}
            pages={pages}
            onPageChange={page =>
              this.setState(
                {
                  ...defaultState,
                  page,
                },
                this.fetchOperators
              )
            }
            pageSize={pageSize}
            pageSizeOptions={[20, 50, 100, 200, 500]}
            onPageSizeChange={pageSize =>
              this.setState(
                {
                  ...defaultState,
                  page: 0,
                  pageSize,
                },
                this.fetchOperators
              )
            }
            sorted={[
              {
                id: sortBy,
                desc: sortDesc,
              },
            ]}
            onSortedChange={sorted =>
              this.setState({
                sortBy: sorted[0].id,
                sortDesc: sorted[0].desc,
              })
            }
          />
        </DropZone>

        {deleteOpen && (
          <OperatorsDelete
            region={region}
            cluster={cluster}
            operators={selected}
            onClose={() =>
              this.setState({ deleteOpen: false, selected: [] }, () =>
                this.fetchOperators()
              )
            }
          />
        )}
      </div>
    )
  }
}
