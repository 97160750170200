import React, { Component } from 'react'
import Dialogue from 'components/Dialogue/Dialogue.js'
import StatusList from 'components/StatusList/StatusList.js'
import { deleteOperator } from 'store/operators.js'

export default class OperatorDelete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      started: false,
      completed: false,
      success: {},
      error: {},
    }
  }

  deleteOperators = () =>
    this.setState(
      {
        started: true,
      },
      () => {
        Promise.all(
          this.props.operators.map(op =>
            deleteOperator(
              this.props.region.api,
              this.props.cluster.cluster_id,
              op
            )
              .then(() =>
                this.setState({
                  success: Object.assign({ [op]: true }, this.state.success),
                })
              )
              .catch(err =>
                this.setState({
                  error: Object.assign(
                    { [op]: err.map(item => item.message) },
                    this.state.error
                  ),
                })
              )
          )
        ).then(this.setState({ completed: true }))
      }
    )

  opStatus = operator => {
    if (this.state.error[operator]) return 'error'
    if (this.state.success[operator]) return 'success'
    if (this.state.started) return 'pending'
    return 'default'
  }

  render() {
    let { operators, onClose } = this.props
    let { started, completed, error } = this.state
    let confirmProps = {}
    let errorCount = Object.keys(error).length
    let title = `Deleted ${operators.length} Operators successfully.`

    // dont show the confirm action/text after completion
    if (!completed) {
      title = `Delete ${operators.length} Operators?`
      confirmProps = {
        subtitle: (
          <span>
            Are you sure you want to delete the following {operators.length}{' '}
            operators from your cluster? Any DAGs importing these operators will
            likely break!
          </span>
        ),
        onConfirm: this.deleteOperators,
        confirmText: `DELETE ${operators.length} OPERATORS`,
        confirmDisabled: started,
      }
    }

    if (completed && errorCount > 0) {
      title = `Error Deleting ${errorCount}/${operators.length} Operators!`
    }

    return (
      <Dialogue
        title={title}
        onClose={onClose}
        closeText={started ? 'CLOSE' : 'CANCEL'}
        {...confirmProps}
      >
        <StatusList
          list={operators.map(op => ({
            title: op,
            status: this.opStatus(op),
            error: error[op],
          }))}
        />
      </Dialogue>
    )
  }
}
