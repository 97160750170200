import React, { useState, forwardRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './styles.styl'

// Static Value to ensure uniqueness
let ID = 0

const Checkbox = forwardRef(function Checkbox(
  { checked, label, onValueChange, disabled, className, hasError, ...rest },
  ref
) {
  // useState initial-value function is only called on first-render
  const [_id] = useState(() => `ep-checkbox-${++ID}`)
  const id = rest.id || _id

  // styles
  let labelClass = classnames(styles.label, className)
  let checkboxContainerClass = styles.checkboxContainer
  let checkboxClass = styles.checkbox
  if (hasError) {
    checkboxContainerClass = classnames(checkboxContainerClass, styles.hasError)
  }
  if (disabled) {
    checkboxContainerClass = classnames(checkboxContainerClass, styles.disabled)
  }

  return (
    <div className={checkboxContainerClass}>
      <input
        ref={ref}
        type="checkbox"
        className={checkboxClass}
        id={id}
        checked={checked}
        value={!!checked}
        disabled={disabled}
        onChange={e => {
          onValueChange && onValueChange(e.target.checked)
          return rest.onChange && rest.onChange(e)
        }}
        {...rest}
      />
      <label className={labelClass} htmlFor={id}>
        {label}
      </label>
    </div>
  )
})

Checkbox.propTypes = {
  /** Sets label for element */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object
  ]),
  /** Handles Checkbox change event, invokes with new value */
  onValueChange: PropTypes.func,
  /** Handles Checkbox change event, invokes with React SyntheticEvent */
  onChange: PropTypes.func,
  /** Sets className on container of Checkbox element */
  className: PropTypes.string,
  /** Sets whether input is in error state */
  hasError: PropTypes.bool
}

Checkbox.defaultProps = {
  label: '',
  className: ''
}

export default Checkbox
