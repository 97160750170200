import { http, digResponse, getRequestError } from 'util/index.js'

const encode = encodeURIComponent

export const getLogs = (api, clusterId, resource, fromTime, toTime) => {
  if (resource.includes('webserver') || resource.includes('scheduler')) {
    return http
      .get(
        `${api}/clusters/${encode(clusterId)}/logs/${encode(
          resource
        )}?range_from=${fromTime}&range_to=${toTime}`
      )
      .then(response => digResponse(response, 'data', 'logs'))
      .catch(response => {
        throw getRequestError(response, 'getLogs')
      })
  } else {
    return http
      .get(
        `${api}/clusters/${encode(clusterId)}/logs/base/${encode(
          resource
        )}?range_from=${fromTime}&range_to=${toTime}`
      )
      .then(response => digResponse(response, 'data', 'logs'))
      .catch(response => {
        throw getRequestError(response, 'getLogs')
      })
  }
}
