import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.styl'

const Tooltip = ({ className, message, position, size, inverse, children }) => {
  const wrapperClassNames = `${styles.tooltip} ${className}`
  const tooltipClassNames = `${styles.tooltiptext} ${styles[position]} ${
    styles[size]
  } ${inverse ? styles.inverse : ''}`

  return (
    <div className={wrapperClassNames}>
      {children}
      <span className={tooltipClassNames}>{message}</span>
    </div>
  )
}

Tooltip.propTypes = {
  /** Custom className to override styles */
  className: PropTypes.string,

  /** Custom Content that when hovered will show the tooltip  */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),

  /** Message to be shown in the tooltip */
  message: PropTypes.string,

  /** Tooltip placement  */
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),

  /** Tooltip size  */
  size: PropTypes.oneOf(['small', 'default', 'large']),

  /** Inverse colors (white background blue text) */
  inverse: PropTypes.bool
}

Tooltip.defaultProps = {
  className: '',
  message: '...',
  position: 'top',
  size: 'default',
  inverse: false
}

export default Tooltip
