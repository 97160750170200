import React from 'react'
import './Error.scss'

export default ({ title, error, children }) => (
  <div className="dash-main dash-error">
    <h3 className="dash-error-title">{title}</h3>
    <p className="dash-error-text">{error}</p>

    {children && <div className="dash-error-children">{children}</div>}
  </div>
)
