import React, { Component } from 'react'
import { Base64 } from 'js-base64'
import { getOperator, uploadOperator } from 'store/operators.js'
import { ToastsContext } from 'components/Toasts/Toasts.js'
import FileEdit from 'components/FileEdit/FileEdit.js'

export default class OperatorEdit extends Component {
  static contextType = ToastsContext
  constructor(props) {
    super(props)
    this.state = {
      operator: false,
      operatorLoading: true,
      operatorError: false,
      operatorContent: false,
      uploadPending: false,
      uploadError: false,
    }
  }

  componentDidMount() {
    this.fetchOperator()
  }

  onClose = () => this.props.history.replace(`/${encodeURI(this.props.region.value)}/clusters/${encodeURI(
    this.props.cluster.cluster_name)}/operators`)

  fetchOperator = () =>
    getOperator(
      this.props.region.api,
      this.props.cluster.cluster_id,
      decodeURIComponent(this.props.match.params.operatorId)
    )
      .then(data =>
        this.setState({
          operator: data,
          operatorLoading: false,
          operatorError: false,
          operatorContent: Base64.decode(data.encoded_content),
        })
      )
      .catch(err =>
        this.setState({
          operatorLoading: false,
          operatorError: err.map(item => item.message),
        })
      )

  onSubmit = e =>
    this.setState(
      {
        uploadPending: true,
        uploadError: false,
      },
      () => {
        let body = {
          file_name: this.state.operator.file_name,
          encoded_content: Base64.encode(this.state.operatorContent),
        }

        return uploadOperator(
          this.props.region.api,
          this.props.cluster.cluster_id,
          this.state.operator.file_name,
          body
        )
          .then(() => {
            this.context.addToast(
              `Successfully Updated ${this.state.operator.file_name}`,
              'ok'
            )
            return this.onClose()
          })
          .catch(error =>
            this.setState({
              uploadPending: false,
              uploadError: error.map(item => item.message),
            })
          )
      }
    )

  render() {
    let { permissions } = this.props
    let onClose = this.onClose
    let file_name = decodeURIComponent(this.props.match.params.operatorId)
    let {
      operatorContent,
      operatorLoading,
      operatorError,
      uploadPending,
      uploadError,
    } = this.state

    let errors = []

    if (!permissions['OPERATORS.UPDATE']) {
      errors.push(
        'Read Only Mode; `OPERATORS.UPDATE` permission required to modify.'
      )
    }
    if (uploadError) {
      const displayError = 'Upload Error: '
      uploadError.map(item => errors.push(displayError.concat(item)))
    }
    if (operatorError) {
      const displayError = 'Operator Error: '
      operatorError.map(item => errors.push(displayError.concat(item)))
    }

    return (
      <FileEdit
        errors={errors}
        headerTitle="EDIT OPERATOR"
        baseFolder="plugins"
        fileName={file_name}
        modifiedContent={operatorContent}
        onContentChange={value => this.setState({ operatorContent: value })}
        getPending={operatorLoading}
        onSubmit={this.onSubmit}
        submitText="SAVE"
        submitPending={uploadPending}
        submitDisabled={operatorError}
        onCancel={onClose}
        readOnly={!permissions['OPERATORS.UPDATE']}
      />
    )
  }
}
