import config from './ValidateConfig.js'
import validate from 'validate.js'

const validate_notification = function validate_notification(validation) {
  let validate_contact = validation.map(({ contact }) => contact)
  let validate_meta_data = validation.map(({ meta_data }) => meta_data)

  return (
    validate_contact.filter(Boolean).length > 0 ||
    validate_meta_data.filter(Boolean).length > 0
  )
}

const validateEntry = function validateEntry(notifications) {
  return notifications
    .map(notification => ({
      meta_data:
        notification.meta_data &&
        validate.single(notification.meta_data, config.metedataConstraints, {
          fullMessages: false,
        }),
      contact: validate.single(
        notification.contact,
        config.contactConstraints[notification.service],
        { fullMessages: false }
      ),
    }))
    .map(n => n)
}

const workerNotificationExists = function workerNotificationExists(
  notifications
) {
  let success = false

  notifications.forEach(notif => {
    if (
      notif.service === 'email' &&
      config.workerNotifications.includes(notif.event_type)
    ) {
      success = true
    }
  })

  return success
}

const exportFunctions = {
  validate_notification,
  validateEntry,
  workerNotificationExists,
}

export default exportFunctions
