export default formData => {
  let errors = {};

  const validateLabels = value => {
    const envLabelExists = value.some(
      label => label.key === 'env' &&!!label.value
    );
    if (!envLabelExists) {
      return 'At least one label with key `env` is required';
    }
    if (value.filter(label =>!label.key ||!label.value).length > 0) {
      return 'All labels must have both a key and a value';
    }
    return null; 
  };

  if (formData.labels) {
    const labelError = validateLabels(formData.labels);
    if (labelError) { 
      errors.labels = labelError;
    }
  }

  if (!formData.tagguid || formData.tagguid.length === 0) {
    errors.tagguid = "TAGGUID can't be blank";
  }

  return errors;
};