import React from 'react'
import ErrorView from 'components/Error/Error.js'

export default () => (
  <ErrorView
    title="Cluster Decommissioned"
    error="This cluster has been decommissioned and is no longer viewable."
  >
    <a href="clusters">Manage Clusters →</a>
  </ErrorView>
)
