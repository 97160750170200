import React, { Component } from 'react'
import { updateBuildspec } from 'store/builds.js'
import Dialogue from 'components/Dialogue/Dialogue.js'
import StatusList from 'components/StatusList/StatusList.js'

export default class DeletePackages extends Component {
  constructor(props) {
    super(props)
    this.state = {
      started: false,
      completed: false,
      error: false,
    }
  }

  removePackages = () =>
    this.setState(
      {
        started: true,
      },
      () => {
        updateBuildspec(this.props.region.api, this.props.cluster.cluster_id, {
          ...this.props.buildspec,
          packages: this.props.buildspec.packages.filter(
            p =>
              !this.props.packages.some(
                d => d.name === p.name && d.version === p.version
              )
          ),
        })
          .then(() => this.setState({ completed: true, error: false }))
          .catch(err =>
            this.setState({
              completed: true,
              error: err.map(item => item.message),
            })
          )
      }
    )

  statusText = () => {
    if (this.state.error) return 'error'
    if (this.state.completed) return 'success'
    if (this.state.started) return 'pending'
    return 'default'
  }

  render() {
    let { packages, onClose } = this.props
    let { started, completed, error } = this.state
    let statusText = this.statusText()
    let confirmProps = {
      subtitle: 'Successfully removed packages.',
    }
    let title = 'Packages Removed.'

    // dont show the confirm action/text after completion
    if (!completed) {
      title = `Remove ${packages.length} Packages?`
      confirmProps = {
        subtitle: (
          <span>
            Are you sure you want to remove the following {packages.length}{' '}
            packages from your buildspec?
          </span>
        ),
        onConfirm: this.removePackages,
        confirmText: `REMOVE ${packages.length} PACKAGES`,
        confirmDisabled: started,
      }
    }

    if (completed && error) {
      const listErrors = error.map((item, index) => {
        return <li key={index}>{item}</li>
      })

      title = 'Error Removing Packages!'
      confirmProps = {
        subtitle: (
          <>
            <p>
              The following errors were encountered when decommissioning this
              cluster:
            </p>
            <ul>{listErrors}</ul>
          </>
        ),
      }
    }

    return (
      <Dialogue
        title={title}
        onClose={onClose}
        closeText={started ? 'CLOSE' : 'CANCEL'}
        {...confirmProps}
      >
        <StatusList
          list={packages.map(p => ({
            title: p.name,
            status: statusText,
            error: error,
          }))}
        />
      </Dialogue>
    )
  }
}
