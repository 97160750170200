import React, { Component } from 'react'
import ChartSingle from './Charts/ChartSingle.js'
import Table from 'components/Table/Table.js'
import './Metrics.scss'

const operatorCols = [
  {
    name: 'OPERATOR ID',
    key: 'operator_id',
    maxWidth: 180,
  },
  {
    name: 'SUCCESSES',
    key: 'operatorSuccesses',
  },
  {
    name: 'FAILURES',
    key: 'operatorFailures',
  },
]

const handleMatch = (obj, group, key, ts, val) => {
  // obj pass by ref like all js objects
  obj[group] = obj[group] || {}
  obj[group][key] = obj[group][key] || []
  obj[group][key].push({ ts, val })
}

const processPodMetrics = data => {
  let metrics = {}

  data.forEach(m => {
    let match = false
    let ts = new Date(m['@timestamp']).getTime()

    switch (true) {
      case !!(match = m.name.match(/^airflow\.operator_successes_(.+)/)):
        handleMatch(metrics, match[1], 'operatorSuccesses', ts, m.val)
        break
      case !!(match = m.name.match(/^airflow\.operator_failures_(.+)/)):
        handleMatch(metrics, match[1], 'operatorFailures', ts, m.val)
        break
      default:
        break
    }
  })

  Object.keys(metrics).forEach(groupItemKey => {
    Object.keys(metrics[groupItemKey]).forEach(metricKey => {
      metrics[groupItemKey][metricKey] = metrics[groupItemKey][metricKey].sort(
        (a, b) => a.ts - b.ts
      )
    })
  })

  // LEAVING THIS IN FOR DEBUG
  console.log(metrics)
  return metrics
}

export default class Operatoretrics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      processedMetrics: false,
    }
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      processedMetrics:
        nextProps.metrics && processPodMetrics(nextProps.metrics),
    }
  }

  render() {
    let { processedMetrics } = this.state
    let { metricsLoading } = this.props

    let operators = Object.keys(
      (processedMetrics && processedMetrics) || []
    ).map(opKey => ({
      operator_id: opKey,
      operatorSuccesses: (
        <div className="table-chart-wrapper">
          <ChartSingle
            xKey="ts"
            yKey="val"
            data={processedMetrics[opKey]['operatorSuccesses']}
            margin={{ top: 10, bottom: 0, left: 0, right: 0 }}
            {...this.props}
          />
        </div>
      ),
      operatorFailures: (
        <div className="table-chart-wrapper">
          <ChartSingle
            xKey="ts"
            yKey="val"
            data={processedMetrics[opKey]['operatorFailures']}
            margin={{ top: 10, bottom: 0, left: 0, right: 0 }}
            {...this.props}
          />
        </div>
      ),
    }))

    return (
      <div className="metrics-main metrics-table">
        <Table rows={operators} cols={operatorCols} loading={metricsLoading} />
      </div>
    )
  }
}
