import React, { Component } from 'react'
import { generate as shortid } from 'shortid'
import { getConfig } from 'store/config.js'
import Spinner from 'components/AltSpinner/AltSpinner.js'
import Button from 'components/Button/Button.js'
import ErrorView from 'components/Error/Error.js'
import UpgradeBanner from './components/UpgradeBanner/UpgradeBanner.js'
import ConfigSection from './components/ConfigSection/ConfigSection.js'
import ConfigUpdate from './components/ConfigUpdate/ConfigUpdate.js'
import './Config.scss'

export default class Config extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cfg: false,
      cfgVersion: false,
      cfgLoading: true,
      cfgError: false,
      modified: false,
      filter: false,
      updateOpen: false,
    }
  }

  componentDidMount() {
    this.fetchCfg()
  }

  fetchCfg = () =>
    getConfig(this.props.region.api, this.props.cluster.cluster_id)
      .then(data => {
        let config = {}
        console.log(data)

        Object.keys(data).forEach(sectionKey => {
          if (Array.isArray(data[sectionKey])) {
            config[shortid()] = {
              key: sectionKey,
              fields: data[sectionKey].reduce(
                (obj, field) => ({ ...obj, [shortid()]: field }),
                {}
              ),
            }
          }
        })

        return this.setState({
          cfg: config,
          cfgLoading: false,
          cfgError: false,
          modified: config,
        })
      })
      .catch(err =>
        this.setState({
          cfgLoading: false,
          cfgError: err.map(item => item.message),
        })
      )

  addSection = () => {
    let key = shortid()
    let obj = {
      new: true,
      key: '',
      fields: {
        // Initialize section with empty field
        [shortid()]: {
          key: '',
          value: '',
          editable: true,
          type: 'custom',
        },
      },
    }

    // Set key in both here to avoid lots of deep lookup checks later
    this.setState({
      cfg: { [key]: { key: '', fields: {} }, ...this.state.cfg },
      modified: { [key]: obj, ...this.state.modified },
    })
  }

  invalid = () => {
    let { cfg, modified } = this.state

    // no changes
    if (JSON.stringify(cfg) === JSON.stringify(modified)) {
      return true
    }
    let sectionKey, fieldKey
    for (sectionKey in modified) {
      let sectionFields = modified[sectionKey].fields

      // User must populate section name if it has fields
      if (
        Object.keys(sectionFields).length > 0 &&
        modified[sectionKey].key === ''
      ) {
        return true
      }

      for (fieldKey in sectionFields) {
        let field = sectionFields[fieldKey]

        // no empty inputs on editable fields
        if (field.editable && (!field.key || !field.value)) {
          return true
        }

        // no duplicate keys in section
        if (
          Object.keys(sectionFields).filter(
            fk => sectionFields[fk].key === field.key
          ).length > 1
        ) {
          return true
        }
      }
    }
    return false
  }

  scrollTop = () => this.innerRef.scrollTo(0, 0)
  scrollBot = () => this.innerRef.scrollTo(0, this.innerRef.scrollHeight)

  render() {
    let { cluster, region, permissions, updateCluster } = this.props
    let { cfg, cfgLoading, cfgError, modified, filter, updateOpen } = this.state
    let content = false
    let configUpdate = permissions['CONFIG.UPDATE']

    if (!permissions['CONFIG.READ']) {
      return (
        <div className="dash-main config">
          <ErrorView
            title="UNAUTHORIZED"
            error={
              'You do not have `CONFIG.READ` permissions on this cluster.'
            }
          />
        </div>
      )
    }

    if (cfgLoading) {
      content = <Spinner />
    }

    if (cfgError && !content) {
      const listErrors = cfgError.map((item, index) => {
        return (
          <p key={index}>
            <i className="fa fa-exclamation-triangle"></i> {item}
          </p>
        )
      })

      content = <div className="config-error">{listErrors}</div>
    }

    if (!content) {
      content = Object.keys(modified).map(section => (
        <ConfigSection
          key={section}
          filter={filter}
          name={modified[section].key}
          original={cfg[section]}
          modified={modified[section]}
          onChange={value =>
            this.setState({
              modified: { ...modified, [section]: value },
            })
          }
        />
      ))
    }

    return (
      <div className="dash-main config">
        <div
          className="config-inner alt-scroll"
          ref={el => (this.innerRef = el)}
        >
          <h1 className="config-title">CLUSTER CONFIG</h1>
          <p className="config-description">
            The cluster configuration defines all of the cluster-wide settings
            that can be used by Dags, Plugins, and Operators. Un-editable fields
            are blocked to ensure overall cluster stability and to allow the
            automation within the Managed Airflow Platform to operate correctly.
            Please ensure that all key and values are entered before updating.
            Changes performed here will require a cluster restart to take
            effect.
          </p>
          {!configUpdate && <ErrorView
            error={
              'You do not have `CONFIG.UPDATE` permissions on this cluster. Config updates are disabled.'
            }
          />}
          {!cfgLoading && !cfgError && (
            <div className="config-action-bar">
              <p className="config-filter-label">Filter</p>
              <div className="config-filter">
                <p
                  className={`config-filter-option${filter === false ? ' active' : ''
                    }`}
                  onClick={() => this.setState({ filter: false })}
                >
                  All
                </p>
                <p
                  className={`config-filter-option${filter === 'editable' ? ' active' : ''
                    }`}
                  onClick={() => this.setState({ filter: 'editable' })}
                >
                  Editable
                </p>
                <p
                  className={`config-filter-option${filter === 'custom' ? ' active' : ''
                    }`}
                  onClick={() => this.setState({ filter: 'custom' })}
                >
                  Custom
                </p>
              </div>

              <Button borderless disabled={!cfg || !configUpdate} onClick={this.addSection}>
                ADD SECTION
              </Button>

              <Button
                primary
                borderless
                disabled={!cfg || this.invalid() || !configUpdate}
                onClick={() => this.setState({ updateOpen: true })}
              >
                UPDATE CONFIG
              </Button>
            </div>
          )}
          {content}
        </div>

        <div className="config-up" onClick={this.scrollTop}>
          <i className="fa fa-angle-double-up"></i>
        </div>
        <div className="config-down" onClick={this.scrollBot}>
          <i className="fa fa-angle-double-down"></i>
        </div>

        <UpgradeBanner
          region={region}
          cluster={cluster}
          updateCluster={updateCluster}
          version={cluster.config_version_id}
        />

        {updateOpen && (
          <ConfigUpdate
            region={region}
            cluster={cluster}
            original={cfg}
            modified={modified}
            onClose={() =>
              this.setState(
                {
                  cfg: false,
                  cfgLoading: true,
                  cfgError: false,
                  modified: false,
                  updateOpen: false,
                },
                () => {
                  this.fetchCfg()
                  updateCluster()
                }
              )
            }
          />
        )}
      </div>
    )
  }
}
