import React from 'react'
import './StatusList.scss'

const statusIcons = {
  pending: 'fa fa-circle-o-notch',
  notice: 'fa fa-exclamation-triangle',
  success: 'fa fa-check-circle',
  error: 'fa fa-exclamation-circle',
  default: 'fa fa-circle',
}

export default ({ list }) => (
  <div className="alt-status-list">
    {list.map((l, i) => (
      <div className="alt-list-item" key={i}>
        <p
          className={`alt-list-item-name${
            l.strike ? ' alt-list-item-strike' : ''
          }`}
        >
          <i className={statusIcons[l.status] || 'fa fa-circle'}></i>
          {l.title}
        </p>
        {l.error && (
          <ul className="alt-list-item-error">
            {l.error.map((err, index) => (
              <li key={index}>{err}</li>
            ))}
          </ul>
        )}
      </div>
    ))}
  </div>
)
