import React, { Component } from 'react'
import { releaseNotes } from 'store/release_notes.js'
import Spinner from 'components/AltSpinner/AltSpinner.js'
import moment from 'moment'
import marked from 'marked'
import './ReleaseNotes.scss'

// Override links renderer to open in a new tab
const renderer = new marked.Renderer()
renderer.link = (href, title, text) =>
  `<a
    href="${href}"
    target="_blank"
    rel="noopener noreferrer"
    title="${title}"
  >
    ${text}
  </a>`

export default class ReleaseNotes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      notes: [],
      notesLoading: true,
      notesError: false,
    }
  }

  componentDidMount() {
    releaseNotes()
      .then(notes =>
        notes
          .map(note => ({
            ...note,
            date: moment(note.dateReleased).format('MMMM Do YYYY'),
            ts: moment(note.dateReleased).unix() * 1000,
          }))
          .sort((a, b) => (a.ts < b.ts ? 1 : -1))
      )
      .then(notes =>
        this.setState(
          {
            notes,
            notesLoading: false,
            notesError: false,
          },
          () => this.props.onLoad(Math.max(...notes.map(d => d.ts)))
        )
      )
      .catch(err =>
        this.setState({
          notes: [],
          notesLoading: false,
          notesError: err.map(item => item.message),
        })
      )
  }

  render() {
    const { open, onClose } = this.props
    const { notes, notesLoading, notesError } = this.state
    if (!open) return null
    let body = false

    if (notesLoading) {
      body = <Spinner />
    }

    if (notesError) {
      const listErrors = notesError.map((item, index) => {
        return (
          <p className="release-notes-error" key={index}>
            <i className="fa fa-exclamation-triangle"></i> {item}
          </p>
        )
      })

      body = <div>{listErrors}</div>
    }

    if (!body) {
      body = notes.map((note, i) => (
        <div className="release-notes-article" key={i}>
          <p className="release-notes-article-date">{note.date}</p>
          <p className="release-notes-article-title">{note.title}</p>
          <p
            className="release-notes-article-body"
            dangerouslySetInnerHTML={{
              __html: marked(note.markdown, { renderer: renderer }),
            }}
          />
        </div>
      ))
    }

    return (
      <div className="release-notes">
        <div className="release-notes-header">
          <h3 className="release-notes-title">RELEASE NOTES</h3>
          <i className="fa fa-times" onClick={onClose}></i>
        </div>

        <div className="release-notes-articles alt-scroll">{body}</div>
      </div>
    )
  }
}
