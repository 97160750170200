import React, { Component } from 'react'
import { Base64 } from 'js-base64'
import { debounce } from 'util/index.js'
import { getDag, uploadDag } from 'store/dags.js'
import { ToastsContext } from 'components/Toasts/Toasts.js'
import FileEdit from 'components/FileEdit/FileEdit.js'
import DagValidate from '../DagValidate/DagValidate.js'

export default class DagUpload extends Component {
  static contextType = ToastsContext
  constructor(props) {
    super(props)
    this.state = {
      name: props.files[0].name,
      content: props.files[0].content,
      uploadPending: false,
      uploadError: false,
      dag: {},
      dagLoading: true,
      validateOpen: false,
    }
    this.refetchDag = debounce(() => this.fetchDag(this.state.name), 500)
  }

  componentDidMount() {
    this.fetchDag(this.props.files[0].name)
  }

  fetchDag = dagId =>
    getDag(this.props.region.api, this.props.cluster.cluster_id, dagId)
      .then(data => this.setState({ dag: data, dagLoading: false }))
      .catch(() => this.setState({ dagLoading: false }))

  handleNameChange = e =>
    this.setState(
      {
        name: e.target.value,
        dag: {},
        dagLoading: true,
      },
      this.refetchDag()
    )

  onSubmit = () =>
    this.setState(
      {
        uploadPending: true,
        uploadError: false,
        validateOpen: false,
      },
      () => {
        if (!this.validName()) {
          return
        }

        let folder = '/'
        let sections = this.state.name.split('/').filter(Boolean)

        if (sections.length > 1) {
          folder = sections.slice(0, -1).join('/')
        }

        let body = {
          file_name: sections[sections.length - 1],
          encoded_content: Base64.encode(this.state.content),
        }

        return uploadDag(
          this.props.region.api,
          this.props.cluster.cluster_id,
          folder,
          body
        )
          .then(() => {
            this.context.addToast(
              `Successfully Uploaded ${this.state.name}`,
              'ok'
            )
            return this.props.onClose()
          })
          .catch(error =>
            this.setState({
              uploadPending: false,
              uploadError: error.map(item => item.message),
            })
          )
      }
    )

  validName = () => /^[a-zA-Z0-9-_./]{1,}$/.test(this.state.name)

  render() {
    let { cluster, region, onClose } = this.props
    let {
      name,
      content,
      uploadPending,
      uploadError,
      dag,
      dagLoading,
      validateOpen,
    } = this.state
    let validName = this.validName()
    let existingDagContent = false

    if (dag.encoded_content) {
      existingDagContent = Base64.decode(dag.encoded_content)
    }

    let errors = []

    if (uploadError) {
      uploadError.forEach(item => {
        errors.push(item)
      })
    }

    return (
      <FileEdit
        errors={errors}
        headerTitle="UPLOAD DAG"
        baseFolder="dags"
        fileName={name}
        fileNameError={
          !validName && 'Alphanumeric, -, ., and _ characters only.'
        }
        fileNameOnChange={this.handleNameChange}
        originalContent={existingDagContent}
        modifiedContent={content}
        onContentChange={value => this.setState({ content: value })}
        getPending={dagLoading}
        onSubmit={() => this.setState({ validateOpen: true })}
        submitText="VALIDATE & UPLOAD"
        submitPending={uploadPending}
        submitDisabled={!validName}
        onCancel={onClose}
        aside={
          validateOpen && (
            <DagValidate
              region={region}
              cluster={cluster}
              file_name={name}
              encoded_content={Base64.encode(content)}
              onSubmit={this.onSubmit}
              submitText="UPLOAD"
              onClose={() => this.setState({ validateOpen: false })}
            />
          )
        }
      />
    )
  }
}
