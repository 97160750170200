import React, { Component } from 'react'
import { getFiles } from 'store/files.js'
import Search from 'components/Search/Search.js'
import Button from 'components/Button/Button.js'
import DropZone from 'components/DropZone/DropZone.js'
import Table from 'components/Table/Table.js'
import FilesDelete from './FilesDelete/FilesDelete.js'
import FileUpload from './FileUpload/FileUpload.js'
import moment from 'moment'
import './Files.scss'
import { debounce } from 'lodash'
import downloadHelper from './FilesDownload/FilesDownload.js'
import { Link } from 'react-router-dom'

const defaultState = {
  files: [],
  filesLoading: true,
  filesError: false,
  filter: '',
  selected: [],
}

export default class Files extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...defaultState,

      // sub-views
      upload: false,
      deleteOpen: false,
      dropzone: false,

      // pagination
      page: 0,
      pages: 1,
      pageSize: 20,
      sortBy: 'file_name',
      sortDesc: false,
    }
    this.fileCols = [
      {
        name: 'FILE NAME',
        key: 'file_name',
        Cell: row => (
          <Link
            className="file-edit-link"
            draggable="false"
            key={row.value}
            to={`/${encodeURI(this.props.region.value)}/clusters/${encodeURI(
              this.props.cluster.cluster_name)}/files/${encodeURIComponent(row.value)}`
            }
          >
            {row.value}
          </Link>
        ),
      },
      {
        name: 'UPDATED',
        key: 'updated',
        maxWidth: 240,
      },
    ]

    // Prevents fetchFiles from getting called more than once in 500 ms
    this.delayedHandleChange = debounce(
      this.delayedHandleChange.bind(this),
      500
    )
  }

  componentDidMount() {
    this.fetchFiles()
  }

  fetchFiles = (search_critera = '') =>
    getFiles(
      this.props.region.api,
      this.props.cluster.cluster_id,
      this.state.pageSize,
      this.state.page,
      search_critera
    )
      .then(body =>
        this.setState({
          files:
            body.data.length > 0
              ? body.data.map(file => ({
                ...file,
                updated: moment(file.updated_date).format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
              }))
              : [],
          filesLoading: false,
          filesError: false,
          pages:
            body.meta && body.meta.page
              ? Math.ceil(body.meta.page.max_size / this.state.pageSize)
              : 1,
        })
      )
      .catch(err =>
        this.setState({
          filesLoading: false,
          filesError: err.map(item => item.message),
        })
      )

  toggleSelected = key =>
    this.setState({
      selected: this.state.selected.includes(key)
        ? this.state.selected.filter(k => k !== key)
        : [key, ...this.state.selected],
    })

  toggleAll = () => {
    const fileKeys = this.state.files.map(file => file.file_name)

    this.setState({
      selected: this.state.selected.length === fileKeys.length ? [] : fileKeys,
    })
  }

  delayedHandleChange(data) {
    this.fetchFiles(data)
  }

  render() {
    let { cluster, region, permissions } = this.props
    let {
      files,
      filesLoading,
      filesError,
      filter,
      selected,
      upload,
      deleteOpen,
      dropzone,
      page,
      pages,
      pageSize,
      sortBy,
      sortDesc,
    } = this.state

    // Sorts files based on sort option
    let filtered = files.sort((a, b) =>
      sortDesc
        ? a[sortBy] < b[sortBy]
          ? 1
          : -1
        : a[sortBy] > b[sortBy]
          ? 1
          : -1
    )

    if (upload)
      return (
        <FileUpload
          region={region}
          cluster={cluster}
          files={upload}
          onClose={() =>
            this.setState({ upload: false }, () => this.fetchFiles())
          }
        />
      )

    return (
      <div className="dash-main files">
        <div className="list-header">
          <h3 className="list-title">CLUSTER FILES</h3>

          <div className="list-header-bar">
            <Search
              value={filter}
              onChange={e => {
                this.setState({ filter: e.target.value })
                this.delayedHandleChange(e.target.value)
              }}
            />
            <Button
              primary
              borderless
              onClick={() => dropzone && dropzone.open()}
              locked={!permissions['FILES.CREATE']}
            >
              UPLOAD FILE
            </Button>
            <Button
              onClick={() =>
                downloadHelper.downloadFiles(
                  region.api,
                  cluster.cluster_id,
                  selected,
                  cluster.cluster_name
                )
              }
              disabled={selected.length === 0}
            >
              DOWNLOAD {selected.length > 0 ? selected.length : ''} FILE
              {selected.length > 1 ? 'S' : ''}
            </Button>
            <Button
              onClick={() => this.setState({ deleteOpen: true })}
              disabled={selected.length === 0}
              locked={!permissions['FILES.DELETE']}
            >
              DELETE {selected.length > 0 ? selected.length : ''} FILE
              {selected.length > 1 ? 'S' : ''}
            </Button>
          </div>
        </div>

        <DropZone
          className="dash-main-list"
          accept=""
          onDrop={files => this.setState({ upload: files })}
          setRef={ref => this.setState({ dropzone: ref })}
          locked={!permissions['FILES.CREATE']}
        >
          <Table
            rows={filtered}
            cols={this.fileCols}
            loading={filesLoading}
            error={filesError}
            filter={filter}
            selectable
            onSelect={file => this.toggleSelected(file.file_name)}
            selectedMethod={file => selected.includes(file.file_name)}
            onSelectAll={this.toggleAll}
            selectedCount={selected.length}
            multiSelect
            paginate
            page={page}
            pages={pages}
            onPageChange={page =>
              this.setState(
                {
                  ...defaultState,
                  page,
                },
                this.fetchFiles
              )
            }
            pageSize={pageSize}
            pageSizeOptions={[20, 50, 100, 200, 500]}
            onPageSizeChange={pageSize =>
              this.setState(
                {
                  ...defaultState,
                  page: 0,
                  pageSize,
                },
                this.fetchFiles
              )
            }
            sorted={[
              {
                id: sortBy,
                desc: sortDesc,
              },
            ]}
            onSortedChange={sorted =>
              this.setState({
                sortBy: sorted[0].id,
                sortDesc: sorted[0].desc,
              })
            }
          />
        </DropZone>

        {deleteOpen && (
          <FilesDelete
            region={region}
            cluster={cluster}
            files={selected}
            onClose={() =>
              this.setState({ deleteOpen: false, selected: [] }, () =>
                this.fetchFiles()
              )
            }
          />
        )}
      </div>
    )
  }
}
