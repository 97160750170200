import React, { Component } from 'react'
import Dialogue from 'components/Dialogue/Dialogue.js'
import StatusList from 'components/StatusList/StatusList.js'
import { updateConfig } from 'store/config.js'

export default class ConfigUpdate extends Component {
  constructor(props) {
    super(props)
    let flatChanges = []

    // additive changes
    Object.keys(props.modified).forEach(section => {
      let originalFields = props.original[section].fields
      let modifiedFields = props.modified[section].fields

      Object.keys(modifiedFields).forEach(key => {
        if (
          !originalFields ||
          !originalFields[key] ||
          originalFields[key].value !== modifiedFields[key].value ||
          originalFields[key].key !== modifiedFields[key].key
        ) {
          flatChanges.push({
            additive: true,
            section: props.modified[section].key,
            key: modifiedFields[key].key,
            value: modifiedFields[key].value,
          })
        }
      })
    })

    // subtractive changes
    Object.keys(props.original).forEach(section => {
      let originalFields = props.original[section].fields
      let modifiedFields = props.modified[section].fields

      Object.keys(originalFields).forEach(key => {
        if (
          !modifiedFields ||
          !modifiedFields[key] ||
          modifiedFields[key].key !== originalFields[key].key
        ) {
          flatChanges.push({
            additive: false,
            section: props.original[section].key,
            key: originalFields[key].key,
            value: originalFields[key].value,
          })
        }
      })
    })

    // deduplicate by section/key
    flatChanges = flatChanges.filter(
      (v, i) =>
        flatChanges.findIndex(
          el => el.section === v.section && el.key === v.key
        ) === i
    )

    this.state = {
      flatChanges: flatChanges,
      started: false,
      completed: false,
      success: false,
      error: false,
    }
  }

  buildRequest = () => {
    let body = {}

    Object.keys(this.props.modified).forEach(tmpid => {
      let section = this.props.modified[tmpid]
      let fields = Object.keys(section.fields)
        .map(fieldId => section.fields[fieldId])
        .filter(field => field.editable)

      if (Object.keys(fields).length > 0) {
        body[section.key] = fields
      }
    })
    return body
  }

  updateConfigs = () =>
    this.setState(
      {
        started: true,
      },
      () => {
        updateConfig(
          this.props.region.api,
          this.props.cluster.cluster_id,
          this.buildRequest()
        )
          .then(() =>
            this.setState({
              completed: true,
              success: true,
            })
          )
          .catch(err =>
            this.setState({
              completed: true,
              error: err.map(item => item.message),
            })
          )
      }
    )

  configStatus = () => {
    if (this.state.error) return 'error'
    if (this.state.success) return 'success'
    if (this.state.started) return 'pending'
    return 'default'
  }

  render() {
    let { onClose } = this.props
    let { flatChanges, started, completed, error } = this.state
    let configStatus = this.configStatus()
    let confirmProps = {}
    let title = 'Updated Config successfully.'

    // dont show the confirm action/text after completion
    if (!completed) {
      title = `Update Config Fields?`
      confirmProps = {
        subtitle: (
          <span>
            Are you sure you want to update these {flatChanges.length} Config
            Fields?
            <br /> This will result in a cluster restart!
          </span>
        ),
        onConfirm: this.updateConfigs,
        confirmText: 'UPDATE CONFIG',
        confirmDisabled: started,
      }
    }

    if (completed && error) {
      title = `Error Updating Config!`
    }

    return (
      <Dialogue
        title={title}
        onClose={onClose}
        closeText={started ? 'CLOSE' : 'CANCEL'}
        {...confirmProps}
      >
        <StatusList
          list={flatChanges.map(field => ({
            strike: !field.additive,
            title: `${field.section} / ${field.key}`,
            status: configStatus,
            error: error,
          }))}
        />
      </Dialogue>
    )
  }
}
