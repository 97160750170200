import React from 'react'
import Toggle from '../Toggle/Toggle.js'
import FormField from '../FormField/FormField.js'
import Tooltip from '../Tooltip/Tooltip.js'

export default ({
  className,
  label,
  required,
  detail,
  error,
  value,
  disabled,
  onClick,
  tooltipText,
}) => (
  <FormField
    className={className}
    label={label}
    required={required}
    detail={detail}
    error={error}
  >
    <div className="alt-toggle-input">
      <Toggle
        on={value}
        disabled={disabled}
        onClick={() => !disabled && onClick()}
      />
      <Tooltip tooltipText={tooltipText} />
    </div>
  </FormField>
)
