export const clusterCostColumns = {
  scheduler: {
    className: 'scheduler-row',
    fields: [
      {
        name: 'SCHEDULER',
        key: 'scheduler_cost',
        description: 'Handles scheduling of DAGs and tasks.',
      },
    ],
  },
  webserver: {
    className: 'webserver-row',
    fields: [
      {
        name: 'WEBSERVER',
        key: 'webserver_cost',
        description: 'Hosts the Airflow UI and Airflow REST API.',
      },
    ],
  },
  tasks: {
    className: 'tasks-row',
    fields: [
      {
        name: 'AIRFLOW TASKS',
        key: 'tasks_cost',
        description:
          'Individual tasks within a DAG. Data will be available if Observability is enabled.',
      },
    ],
  },
  db: {
    className: 'db-row',
    fields: [
      {
        name: 'DATABASE',
        key: 'db_cost',
        description:
          'Airflow metadata database hosted in AWS RDS. Does not include sandbox cluster databases.',
      },
    ],
  },
}
