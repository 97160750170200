exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".epic-font-base___2tp3H,\n.formSection___3UGqU .header___1All8 h3 {\n  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-base-md___1pdGS {\n  font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif;\n}\n.epic-font-marketing___3kn9P {\n  font-family: 'Futura', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-brand___D_qUk,\n.formSection___3UGqU .header___1All8 h1 {\n  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.formSection___3UGqU {\n  background-color: #f7f7f7;\n  border-radius: 1em;\n  padding: 30px 15%;\n  margin: 30px 10%;\n}\n.formSection___3UGqU .header___1All8 {\n  margin-bottom: 50px;\n}\n.formSection___3UGqU .header___1All8 h1 {\n  text-align: left;\n  text-transform: uppercase;\n  font-size: 24px;\n  margin: 0;\n}\n.formSection___3UGqU .header___1All8 h1 .done___2iNmK {\n  color: #48bd1f;\n}\n.formSection___3UGqU .header___1All8 h3 {\n  margin: 0;\n  color: #c7c7c7;\n  text-align: left;\n  font-size: 16px;\n}\n", ""]);

// Exports
exports.locals = {
	"epic-font-base": "epic-font-base___2tp3H",
	"formSection": "formSection___3UGqU",
	"header": "header___1All8",
	"epic-font-base-md": "epic-font-base-md___1pdGS",
	"epic-font-marketing": "epic-font-marketing___3kn9P",
	"epic-font-brand": "epic-font-brand___D_qUk",
	"done": "done___2iNmK"
};