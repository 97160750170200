import React, { Component } from 'react'
import { Switch } from 'react-router-dom'
import { ApmRoute } from '@elastic/apm-rum-react'
import { getCluster } from 'store/clusters.js'
import { ErrorBoundary } from 'components/index.js'
import Sidebar from './Sidebar/Sidebar.js'
import RebootBanner from './RebootBanner/RebootBanner.js'
import DeprecationBanner from './DeprecationBanner/DeprecationBanner.js'
import Spinner from 'components/Spinner/Spinner.js'
import UpdatingView from './views/Updating/Updating.js'
import ProvisioningView from './views/Provisioning/Provisioning.js'
import DashboardView from './views/Dashboard/Dashboard.js'
import MetricsView from './views/Metrics/Metrics.js'
import DagsView from './views/Dags/Dags.js'
import DagEditView from './views/Dags/DagEdit/DagEdit.js'
import OperatorsView from './views/Operators/Operators.js'
import OperatorEditView from './views/Operators/OperatorEdit.js'
import FilesView from './views/Files/Files.js'
import FileEditView from './views/Files/FileEdit/FileEdit.js'
import SettingsView from './views/Settings/Settings.js'
import ConfigView from './views/Config/Config.js'
import LogsView from './views/Logs/Logs.js'
import CostsView from './views/Costs/Costs.js'
import ImageView from './views/Image/Image.js'
import EventsView from './views/Events/Events.js'
import PackagesView from './views/Packages/Packages.js'
import BuildScriptView from './views/BuildScript/BuildScript.js'
import BuildsView from './views/Builds/Builds.js'
import BuildView from './views/Build/Build.js'
import ErrorView from 'components/Error/Error.js'
import config from 'envs/config'
import './Index.scss'

const unknownPageError = (
  <ErrorView title='OOPS' error='An Unexpected Error Occured' />
)
const Wrapper = (Component, props) => routeProps =>
  (
    <div className='page-view-wrapper'>
      <ErrorBoundary errorComponent={unknownPageError}>
        <UpdatingView {...props}>
          <RebootBanner cluster={props.cluster} />
          <DeprecationBanner cluster={props.cluster} />
          <Component {...props} {...routeProps} />
        </UpdatingView>
      </ErrorBoundary>
    </div>
  )

export default class altCluster extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cluster: {},
      clusterLoading: true,
      clusterError: false,
    }
  }

  componentDidMount() {
    this.fetchCluster()
  }

  fetchCluster = () =>
    getCluster(this.props.region.api, this.props.match.params.clusterName)
      .then(cluster =>
        this.setState({
          cluster: cluster,
          clusterLoading: false,
          clusterError: false,
          permissions: cluster.actions_allowed.reduce(
            (obj, action) => ({
              ...obj,
              [action]: true,
            }),
            {}
          ),
        })
      )
      .catch(err =>
        this.setState({
          clusterLoading: false,
          clusterError: err.map(item => item.message),
        })
      )

  updateCluster = cluster =>
    cluster ? this.setState({ cluster }) : this.fetchCluster()

  render() {
    let { cluster, clusterLoading, clusterError, permissions } = this.state
    let { match, region, user } = this.props
    let viewProps = {
      match,
      cluster,
      updateCluster: this.updateCluster,
      permissions,
      region,
      user,
    }

    if (clusterLoading) {
      return <Spinner large center />
    }

    if (clusterError) {
      let errorMessage =
        'The cluster failed to load for the following reasons: '
      errorMessage = errorMessage.concat(clusterError.join(','))
      return <ErrorView title='OOPS' error={errorMessage} />
    }

    if (cluster.life_cycle === 'decommissioning') {
      window.location.assign(`${config.siteRoot}${region.value}/decommissioned`)
      return <Spinner large center />
    }

    if (['requested', 'provisioning'].includes(cluster.life_cycle)) {
      return <ProvisioningView region={region} cluster={cluster} />
    }

    return (
      <div className='alt-page-wrapper'>
        <Sidebar
          cluster={cluster}
          updateCluster={this.updateCluster}
          permissions={permissions}
          region={region}
          user={user}
          view={window.location.pathname.replace(match.url, '')}
        />
        <Switch>
          <ApmRoute
            path={`${match.path}/`}
            exact
            component={Wrapper(DashboardView, viewProps)}
          />
          <ApmRoute
            path={`${match.path}/costs`}
            exact
            component={Wrapper(CostsView, viewProps)}
          />
          <ApmRoute
            path={`${match.path}/metrics`}
            exact
            component={Wrapper(MetricsView, viewProps)}
          />
          <ApmRoute
            path={`${match.path}/image`}
            exact
            component={Wrapper(ImageView, viewProps)}
          />
          <ApmRoute
            path={`${match.path}/packages`}
            exact
            component={Wrapper(PackagesView, viewProps)}
          />
          <ApmRoute
            path={`${match.path}/buildscript`}
            exact
            component={Wrapper(BuildScriptView, viewProps)}
          />
          <ApmRoute
            path={`${match.path}/builds`}
            exact
            component={Wrapper(BuildsView, viewProps)}
          />
          <ApmRoute
            path={`${match.path}/builds/:buildId`}
            exact
            component={Wrapper(BuildView, viewProps)}
          />
          <ApmRoute
            path={`${match.path}/config`}
            exact
            component={Wrapper(ConfigView, viewProps)}
          />
          <ApmRoute
            path={`${match.path}/settings`}
            exact
            component={Wrapper(SettingsView, viewProps)}
          />
          <ApmRoute
            path={`${match.path}/operators`}
            exact
            component={Wrapper(OperatorsView, viewProps)}
          />
          <ApmRoute
            path={`${match.path}/operators/:operatorId`}
            exact
            component={Wrapper(OperatorEditView, viewProps)}
          />
          <ApmRoute
            path={`${match.path}/files`}
            exact
            component={Wrapper(FilesView, viewProps)}
          />
          <ApmRoute
            path={`${match.path}/files/:fileId`}
            exact
            component={Wrapper(FileEditView, viewProps)}
          />
          <ApmRoute
            path={`${match.path}/dags`}
            exact
            component={Wrapper(DagsView, viewProps)}
          />
          <ApmRoute
            path={`${match.path}/dags/:dagId`}
            exact
            component={Wrapper(DagEditView, viewProps)}
          />
          <ApmRoute
            path={`${match.path}/events`}
            exact
            component={Wrapper(EventsView, viewProps)}
          />
          <ApmRoute
            path={`${match.path}/logs`}
            exact
            component={Wrapper(LogsView, viewProps)}
          />
          <ApmRoute
            component={() => <ErrorView title='404' error='Page Not Found' />}
          />
        </Switch>
      </div>
    )
  }
}
