import styles from './Button.styl'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Button = ({
  className,
  children,
  small,
  large,
  theme,
  inverse,
  text,
  style,
  type = 'button',
  ...others
}) => {
  let buttonClass = classnames(styles.button, className)

  if (small) {
    buttonClass = classnames(styles.small, buttonClass)
  }

  if (large) {
    buttonClass = classnames(styles.large, buttonClass)
  }

  if (inverse) {
    buttonClass = classnames(styles.inverse, buttonClass)
  }

  if (text) {
    buttonClass = classnames(styles.text, buttonClass)
  }

  let themeStyles = {}
  if (theme.primary && theme.secondary) {
    if (inverse) {
      themeStyles = {
        backgroundColor: theme.secondary,
        color: theme.primary,
        border: `1px solid ${theme.primary}`
      }
    } else if (text) {
      themeStyles = {
        color: theme.primary
      }
    } else {
      themeStyles = {
        backgroundColor: theme.primary,
        color: theme.secondary,
        border: `1px solid ${theme.primary}`
      }
    }
  }

  let buttonStyles = {
    ...style,
    ...themeStyles
  }

  return (
    <button
      className={buttonClass}
      type={type}
      {...others}
      style={buttonStyles}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  /** Sets className on button element */
  className: PropTypes.string,
  /** Content inside button */
  children: PropTypes.any,
  /** Bool setting size of button to small */
  small: PropTypes.bool,
  /** Bool setting size of button to large */
  large: PropTypes.bool,
  /** Sets primary and Secondary colors for button */
  theme: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string
  }),
  /** Bool specifying that button color scheme is reversed. If not used with theme it reverses with default colors (blue and white). */
  inverse: PropTypes.bool,
  /** Bool specifying button without bubble (until on hover). */
  text: PropTypes.bool
}

Button.defaultProps = {
  className: '',
  theme: {},
  small: false,
  large: false,
  inverse: false
}

export default Button
