exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".epic-font-base___vkMbY {\n  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-base-md___1oxiG {\n  font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif;\n}\n.epic-font-marketing___18-5m {\n  font-family: 'Futura', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-brand___3lvpM {\n  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.overlay___1UcS6 {\n  display: none;\n  position: fixed;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100vw;\n  background-color: rgba(0,0,0,0.65);\n}\n.overlay___1UcS6.show___1JaoV {\n  display: initial;\n  z-index: 6;\n}\n", ""]);

// Exports
exports.locals = {
	"epic-font-base": "epic-font-base___vkMbY",
	"epic-font-base-md": "epic-font-base-md___1oxiG",
	"epic-font-marketing": "epic-font-marketing___18-5m",
	"epic-font-brand": "epic-font-brand___3lvpM",
	"overlay": "overlay___1UcS6",
	"show": "show___1JaoV"
};