import React, { useState, useEffect } from 'react'
import { aegisGroups, aegisUsers } from 'store/aegis.js'
import SelectInput from 'components/SelectInput/SelectInput.js'
import FormError from 'components/FormError/FormError.js'
import Button from 'components/Button/Button.js'
import '../Settings.scss'
import './UserAccess.scss'
import { typeOpts, roleOpts } from './Config/UserAccessConfig.js'
import helper from './Config/UserAccessHelper.js'
import Tooltip from '../../Tooltip/Tooltip.js'

export default ({
  region,
  ae_compute_integration,
  members,
  onChange,
  validation,
  user,
  onSave,
  savePending,
  saveDisabled,
  disabled,
  error,
  setUserAccessLoading = () => {},
}) => {
  const [groups, setGroups] = useState([])
  const noAdminRole = !members.find(m => m.role === 'admin')
  const [state, setState] = useState({
    adRoles: [],
    search: '',
    extraRoles: [],
  })

  const [emailstate, setEmailState] = useState({
    adRoles: [],
    search: '',
    extraRoles: [],
    emails: [],
  })

  useEffect(() => {
    setUserAccessLoading(true)
    Promise.all([
      helper.loadAeGroups(setGroups, region, user),
      helper.loadCloudredIdentityRoles(setState, user),
    ])
      .catch(error => console.error(error))
      .finally(() => {
        setUserAccessLoading(false)
      })
  }, [])

  useEffect(() => {
    helper.loadExtraRolesLanding(aegisGroups, setState, state)
  }, [state.search])

  useEffect(() => {
    helper.loadExtraEmailsLanding(aegisUsers, setEmailState, emailstate)
  }, [emailstate.search])

  return (
    <div className='settings-form-section section-members'>
      <h3 className='settings-form-section-title'>USER ACCESS</h3>
      <div className='user-access-description'>
        <p className='settings-form-section-description'>
          Tell us what users and groups should have access to your cluster. If
          users are a member of multiple groups, the highest privilege will
          apply to that role.{' '}
          <a
            href='https://niketech.service-now.com/nikenow?id=nike_kb_article&sys_id=5708f49b1b0c00d0797ba8e82d4bcb09'
            rel='noopener noreferrer'
            target='_blank'
          >
            Learn how to create an AD Group.
          </a>
        </p>
        {error && <FormError error={error} />}
        {noAdminRole && (
          <FormError
            error={['Clusters must have at least one Admin AD group']}
          />
        )}
      </div>

      <div className='settings-form-section-inner'>
        {members.map((member, i) => {
          return (
            <div className='split-group' key={member.tmpId}>
              <SelectInput
                label='TYPE'
                options={typeOpts}
                onChange={item =>
                  helper.updateMember(
                    'type',
                    item,
                    i,
                    members,
                    onChange,
                    ae_compute_integration
                  )
                }
                value={typeOpts.find(opt => {
                  return opt.value === member.type
                })}
                disabled={disabled || (i === 0 && ae_compute_integration)}
              />

              {member.type === 'sub' ? (
                <SelectInput
                  required
                  label='SUB'
                  options={helper.returnSelectOptions(
                    ae_compute_integration,
                    member,
                    groups,
                    emailstate
                  )}
                  onChange={e =>
                    helper.updateMember('sub', e, i, members, onChange)
                  }
                  onInputChange={val =>
                    helper.onSelectInputChange(setEmailState, val)
                  }
                  className='ad-group-field'
                  formatOptionLabel={({ value, label }) =>
                    helper.formatOptionLabel(emailstate, value, label)
                  }
                  value={
                    member.sub && {
                      label: member.sub,
                      value: member.sub,
                    }
                  }
                  //value={member.sub}
                  error={validation && validation[i]}
                  disabled={disabled}
                />
              ) : (
                <SelectInput
                  required
                  label='AD GROUP'
                  options={helper.returnSelectOptions(
                    ae_compute_integration,
                    member,
                    groups,
                    state
                  )}
                  onChange={group =>
                    helper.updateMember('ad_group', group, i, members, onChange)
                  }
                  onInputChange={val =>
                    helper.onSelectInputChange(setState, val)
                  }
                  className='ad-group-field'
                  formatOptionLabel={({ value, label }) =>
                    helper.formatOptionLabel(state, value, label)
                  }
                  value={
                    member.ad_group && {
                      label: member.ad_group,
                      value: member.ad_group,
                    }
                  }
                  error={validation && validation[i]}
                  disabled={disabled}
                />
              )}

              <SelectInput
                label='ROLE'
                options={roleOpts}
                onChange={role =>
                  helper.updateMember('role', role, i, members, onChange)
                }
                value={roleOpts.find(opt => opt.value === member.role)}
                disabled={disabled || (i === 0 && ae_compute_integration)}
              />

              <Button
                className='alt-members-delete-btn'
                onClick={() => {
                  helper.removeMember(i, members, onChange)
                }}
                disabled={helper.isDeleteDisabled(i, members)}
              >
                <i className='fa fa-trash-o'></i>
              </Button>

              <Tooltip
                id='user-access-tooltip'
                tooltipText={
                  member.type === 'sub'
                    ? 'Please make sure you Enter a verified Okta/Email Id.'
                    : "If your AD Group doesn't show, make sure you're in the right region."
                }
              />
            </div>
          )
        })}

        <div className='button-row'>
          <Button
            onClick={() => helper.addMember(members, onChange)}
            className='list-add-button'
            locked={disabled}
          >
            ADD GROUP
          </Button>

          {onSave && (
            <Button
              onClick={onSave}
              pending={savePending}
              locked={disabled}
              disabled={helper.isSaveDisabled(
                saveDisabled,
                validation,
                noAdminRole
              )}
            >
              SAVE CHANGES
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
