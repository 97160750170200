import React, { Component } from 'react'
import * as d3 from 'd3'
import ReactFauxDOM from 'react-faux-dom'
import './ChartStacked.scss'

export default class ChartStacked extends Component {
  constructor(props) {
    super(props)
    this.state = {
      chartDimensions: false,
    }
  }

  setRef = el => {
    this.chartRef = el
    this.setState({
      chartDimensions: {
        x: (el && el.scrollWidth) || 100,
        y: (el && el.scrollHeight) || 100,
      },
    })
  }

  createChart = () => {
    var { chartDimensions } = this.state
    var {
      data,
      keys,
      minX,
      maxX,
      limitY,
      formatY,
      xKey,
      yKey,
      colorFunc,
      highWaterMark,
    } = this.props

    var margin = { top: 30, right: 20, bottom: 30, left: 20 }
    var width = chartDimensions.x
    var height = chartDimensions.y
    var innerWidth = width - margin.left - margin.right
    var innerHeight = height - margin.top - margin.bottom

    // d3 stack
    // https://github.com/d3/d3-shape/blob/v1.3.7/README.md#stacks
    var series = d3
      .stack()
      .keys(keys)
      .value((d, key) => (d[key] && d[key][yKey]) || 0)
      .order(d3.stackOrderDescending)(data)

    // If limit isnt provided make sure all data in series fits.
    limitY = limitY || d3.max(series.map(x => d3.max(x, d => d[1])))

    // x; timerange bounded to data
    var xScale = d3
      .scaleLinear()
      .domain([minX, maxX])
      .range([0, innerWidth])

    // y; always starts at 0, max can predefined or the highest value
    var yScale = d3
      .scaleLinear()
      .domain([0, limitY])
      .range([innerHeight, 0])

    var area = d3
      .area()
      .x(d => xScale(d.data[xKey]))
      .y0(d => yScale(d[0]))
      .y1(d => yScale(d[1]))
      .curve(d3.curveStepAfter)

    var line = d3
      .line()
      .x(d => xScale(d.data[xKey]))
      .y(d => yScale(d[1]))
      .curve(d3.curveStepAfter)

    var tickCount = Math.floor(innerWidth / 60)
    var tickDist = (maxX - minX) / (tickCount - 1)
    var timeAxis = d3
      .axisBottom()
      .scale(xScale)
      .tickValues([...Array(tickCount).keys()].map(x => tickDist * x + minX))
      .tickFormat(ts => {
        let t = new Date(ts)
        return `${t.getHours()}:${String(t.getMinutes()).padStart(2, '0')}`
      })

    // 90% line added when high water mark enabled
    var yTickVals = highWaterMark
      ? [0, limitY / 2, limitY * 0.9, limitY]
      : [0, limitY / 2, limitY]

    var yAxis = d3
      .axisRight()
      .scale(yScale)
      .tickValues(yTickVals)
      .tickSize(innerWidth)
      .tickFormat(val => (formatY ? formatY(val) : val))

    // RENDERING
    var el = ReactFauxDOM.createElement('div')
    el.setAttribute('class', 'chart-wrapper')

    var svg = d3
      .select(el)
      .append('svg')
      .attr('class', 'chart')
      .attr('width', width)
      .attr('height', height)
      .attr('transform', `translate(0,0)`)

    svg
      .selectAll('path.area')
      .data(series)
      .enter()
      .append('path')
      .attr('class', 'area')
      .attr('d', area)
      .attr('fill', v => colorFunc(v.key).area)
      .attr('transform', `translate(${margin.left},${margin.top})`)

    svg
      .selectAll('path.line')
      .data(series)
      .enter()
      .append('path')
      .attr('d', line)
      .attr('stroke', v => colorFunc(v.key).line)
      .attr('class', 'line')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    svg
      .append('g')
      .attr('class', 'axis x-axis')
      .attr(
        'transform',
        `translate(${margin.left},${innerHeight + margin.top})`
      )
      .call(timeAxis)

    svg
      .append('g')
      .attr('class', `axis y-axis${highWaterMark ? ' high-water-mark' : ''}`)
      .attr('transform', `translate(${margin.left},${margin.top})`)
      .call(yAxis)
      .selectAll('.tick text')
      .attr('x', 4)
      .attr('dy', -4)

    return el.toReact()
  }

  render() {
    let { chartDimensions } = this.state

    return (
      <div className="chart-stacked" ref={this.setRef}>
        {chartDimensions && this.createChart()}
      </div>
    )
  }
}
