import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import styles from './styles.styl'
import Icon from '../../Icon/Icon.js'
import Spinner from '../../Spinner/Spinner.js'

const ActionInput = forwardRef(function ActionInput(
  {
    className,
    type,
    onAction,
    large,
    leftButton,
    ariaLabel,
    icon,
    isPending,
    ...other
  },
  ref
) {
  const componentClassName = `${styles.component} ${
    large ? styles.large : ''
  } ${className}`
  const buttonClassName = `${styles.button} ${
    leftButton ? styles.buttonLeft : ''
  }`
  const iconClassName = `${large ? styles.largeIcon : ''}`
  const inputClassName = `${styles.input} ${leftButton ? styles.inputLeft : ''}`

  return (
    <div className={componentClassName}>
      <input
        {...other}
        className={inputClassName}
        aria-label={`${ariaLabel} field`}
        type="text"
        ref={ref}
      />
      <button
        aria-label={`${ariaLabel} action`}
        type={type}
        className={buttonClassName}
        onClick={onAction}
      >
        {isPending ? (
          <Spinner color="white" />
        ) : (
          <Icon type={icon} className={iconClassName} />
        )}
      </button>
    </div>
  )
})

ActionInput.propTypes = {
  /** Define custom css class to override styles */
  className: PropTypes.string,

  /** Type of button */
  type: PropTypes.string,

  /** Handle change of the input value */
  onChange: PropTypes.func,

  /** Handle when the search button is clicked */
  onAction: PropTypes.func,

  /** Predefined large search option */
  large: PropTypes.bool,

  /** Show the button on the left */
  leftButton: PropTypes.bool,

  /** Aria label */
  ariaLabel: PropTypes.string.isRequired,

  /** Epic icon for the button */
  icon: PropTypes.string,

  /** Spin the icon in the button when pending */
  isPending: PropTypes.bool
}

ActionInput.defaultProps = {
  className: '',
  leftButton: false,
  icon: 'search',
  isPending: false
}

export default ActionInput
