import React, { Component } from 'react'
import { getBaseContainers } from 'store/packages.js'
import TextInput from 'components/TextInput/TextInput.js'
import SelectInput from 'components/SelectInput/SelectInput.js'
import CreatableSelect from 'components/CreatableSelect/CreatableSelect.js'
import '../Settings.scss'
import validate from './Advanced.validate.js'
import { generateECROptions, packageCols } from './config/config.js'
import Table from 'components/Table/Table.js'
import helper from './config/getPackagesHelper.js'
import FormError from 'components/FormError/FormError.js'
import Button from 'components/Button/Button.js'

export default class Advanced extends Component {
  constructor(props) {
    super(props)
    this.state = {
      versionOptions: [],
      filteredEcrOptions: [],
      lockVersionDropdown: false,
      versionAttributes: {},
      //this dummy value is added so that the unit test cases passs
      iam_role_arn:
        'arn:aws:iam::104636225068:role/tenant-a24e3952-107d-11ea-8d71-362b9e155667',
    }
  }
  static defaultProps = {
    setAdvancedLoading: () => { },
  }

  toggleVersionDropdownLock = async newImage => {
    const exists = this.state.filteredEcrOptions?.filter(x => x.value === newImage)
    this.setState({
      lockVersionDropdown: exists.length > 0 ? true : false,
    })

    if (exists[0] && exists[0].version) {
      await this.props.onChange('airflow_version', exists[0].version, false)
    }
  }

  getBaseContainers = () => {
    this.props.setAdvancedLoading(true)
    getBaseContainers(this.props.region.api).then(data => {
      this.setState({
        filteredEcrOptions: generateECROptions(this.props.region.value),
        versionOptions: this.filterVersionOptions(data),
        iam_role_arn: this.props.iam_role_arn,
      })
      this.props.setAdvancedLoading(false)
    })
  }

  filterVersionOptions = data => {
    let response = []

    data.forEach(item => {
      if (!this.props.ae_compute_integration || !item.name.includes('1.10.4')) {
        response.push({
          label: item.name,
          value: item.name,
        })
      }
    })

    return response
  }

  handleIamArnChange = arn => {
    const newState = {
      ...this.state,
      iam_role_arn: arn,
    }
    this.setState({ ...newState })
    this.props.onChange('iam_role_arn', arn, validate('iam_role_arn', arn))
  }

  setVersionAttributes = () => {
    this.setState({
      versionAttributes: helper.getImagePackageData(),
    })
  }

  componentDidMount() {
    this.getBaseContainers()
    this.setVersionAttributes()
  }

  render() {
    return (
      <div className="settings-form-section">
        <h3 className="settings-form-section-title">ADVANCED</h3>

        <div className="settings-form-section-inner">
          {this.props.error && <FormError error={this.props.error} />}

          <TextInput
            label="AWS ARN"
            detail="If you set up a cross-account role for your cluster to assume, provide your role ARN so we can configure the cluster accordingly."
            value={this.state.iam_role_arn}
            onChange={e => this.handleIamArnChange(e.target.value)}
            error={this.props.validation.iam_role_arn}
            disabled={this.props.locked || this.props.disabled}
          />

          <CreatableSelect
            label="AIRFLOW DOCKER IMAGE"
            detail="Not recommended. Provide a custom-built container image that we will run instead of our default. This can be used for custom packages or other container level customization."
            options={this.state.filteredEcrOptions}
            onChange={async (opt, meta) => {
              let newValue

              if (opt === null && meta.action === 'clear') {
                newValue = ''
              } else {
                newValue = opt.value
              }

              await this.props.onChange(
                'image',
                newValue,
                validate('image', newValue)
              )
              await this.toggleVersionDropdownLock(newValue)
            }}
            value={{ label: this.props.image, value: this.props.image }}
            error={this.props.validation.image}
            disabled={this.props.disabled || this.props.locked}
            displayCopyButton
          />

          {this.state.lockVersionDropdown && (
            <div className="package-window">
              <p>This image includes the following packages. </p>
              <Table
                cols={packageCols}
                rows={this.state.versionAttributes[this.props.airflow_version]}
                minRowsOverride={true}
              />
            </div>
          )}

          <SelectInput
            id="airflowInput"
            required
            label="AIRFLOW VERSION"
            className="alt-select-input"
            detail="Select the Airflow version number to be used for this cluster. Clusters can also be upgraded later."
            defaultValue={{
              label: this.props.airflow_version,
              value: this.props.airflow_version,
            }}
            value={{
              label: this.props.airflow_version,
              value: this.props.airflow_version,
            }}
            options={this.state.versionOptions}
            onChange={opt => {
              this.props.onChange('airflow_version', opt.value, false)
            }}
            disabled={this.state.lockVersionDropdown || this.props.disabled}
          />

          {this.props.onSave && (
            <div className="button-row">
              <Button
                onClick={this.props.onSave}
                pending={this.props.savePending}
                locked={this.props.locked}
                disabled={this.props.saveDisabled}
              >
                SAVE CHANGES
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  }
}
