import React from 'react'
import Button from 'components/Button/Button.js'
import Tooltip from 'components/Tooltip/Tooltip.js'
import './ToggleSwitch.scss'

export default ({ value, optionsList, onChange, tooltipText }) => {
  try {
    return (
      <div className="alt-toggle-switch">
        <Button
          className={`toggle-button option-one ${value.value === optionsList[0].value ? 'checked' : ''
            }`}
          onClick={() => onChange(optionsList[0])}
          disabled={!optionsList[0].value}
        >
          {optionsList[0].label}
        </Button>
        <Button
          className={`toggle-button option-two ${value.value === optionsList[1].value ? 'checked' : ''
            }`}
          onClick={() => onChange(optionsList[1])}
          disabled={!optionsList[1].value}
        >
          {optionsList[1].label}
        </Button>
        <Tooltip tooltipText={tooltipText} />
      </div>
    )
  } catch (err) {
    console.log('Error rendering ToggleSwitch')
    return (
      <div className="toggle-switch-error">
        Error Rendering ToggleSwitch Component
      </div>
    )
  }
}
