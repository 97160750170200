import React, { Component } from 'react'
import Dialogue from 'components/Dialogue/Dialogue.js'
import { revokeSupport } from 'store/permissions.js'

export default class Decommission extends Component {
  constructor(props) {
    super(props)
    this.state = {
      started: false,
      completed: false,
      error: false,
    }
  }

  revokeSupport = () =>
    this.setState(
      {
        started: true,
      },
      () =>
        revokeSupport(this.props.region.api, this.props.cluster.cluster_id)
          .then(() => this.setState({ completed: true }))
          .catch(error =>
            this.setState({
              completed: true,
              error: error.map(item => item.message),
            })
          )
    )

  render() {
    let { onClose } = this.props
    let { started, completed, error } = this.state
    let confirmProps = {
      subtitle:
        'MAP Platform Engineers no longer have admin access to cluster resources.',
    }
    let title = 'Support Access Revoked.'

    // dont show the confirm action/text after completion
    if (!completed) {
      title = `Revoke Support Access?`
      confirmProps = {
        subtitle: (
          <span>
            Are you sure you want revoke MAP Platform Engineer admin access to
            cluster resources?
          </span>
        ),
        onConfirm: this.revokeSupport,
        confirmText: 'REVOKE SUPPORT ACCESS',
        confirmDisabled: started,
      }
    }

    if (completed && error) {
      const listErrors = error.map((item, index) => {
        return <li key={index}>{item}</li>
      })

      title = 'Error Revoking Support Access!'
      confirmProps = {
        subtitle: (
          <>
            <p>
              The following errors were encountered when revoking support
              access:
            </p>
            <ul>{listErrors}</ul>
          </>
        ),
      }
    }

    return (
      <Dialogue
        className="alt-request-support"
        title={title}
        onClose={onClose}
        closeText={started ? 'CLOSE' : 'CANCEL'}
        {...confirmProps}
      />
    )
  }
}
