import React, { useState, useEffect } from 'react'
import './IncludeDags.scss'
import CreatableSelect from 'components/CreatableSelect/CreatableSelect.js'
import SelectInput from 'components/SelectInput/SelectInput'
import Button from 'components/Button/Button.js'

export const IncludeDags = ({
  notification,
  validation,
  updateCallback,
  disabled,
  dags,
}) => {
  // included_dags: [ { dag_ids: ['dag1', 'dag2'], severity: 'S2' } ]
  const [includeDags, setIncludeDags] = useState(
    notification?.included_dags || [{ dag_ids: [], severity: '' }]
  )
  const severityOpts = [
    { value: 'S2', label: 'S2' },
    { value: 'S3', label: 'S3' },
    { value: 'S4', label: 'S4' },
    { value: 'S5', label: 'S5' },
  ]

  useEffect(() => {
    updateCallback('included_dags', includeDags)
  }, [includeDags])

  const handleIncludeDagsAddRow = () => {
    setIncludeDags([...includeDags, { dag_ids: [], severity: '' }])
  }

  const handleIncludeDagsDeleteRow = index => {
    const updatedIncludeDags = [...includeDags]
    updatedIncludeDags.splice(index, 1)
    setIncludeDags(updatedIncludeDags)
  }

  const handleIncludeDagsUpdate = (key, value, index) => {
    const updatedIncludeDags = [...includeDags]
    updatedIncludeDags[index][key] = value
    setIncludeDags(updatedIncludeDags)
  }

  return (
    <div id='notification-include-dags'>
      {includeDags.length === 0 && (
        <div className='alt-form-field placeholder-title'>
          <div className='alt-field-label'>INCLUDE DAGS</div>
        </div>
      )}
      {includeDags.map((row, rowi) => (
        <div className='notification-config-include-dags' key={rowi}>
          <CreatableSelect
            isMulti
            id='includeDagsInput'
            className='notification-input-dags'
            label='INCLUDE DAGS'
            options={dags}
            isOptionDisabled={option =>
              includeDags?.some(row => row.dag_ids.includes(option.value)) ||
              notification?.excluded_dags?.includes(option.value)
            }
            value={row?.dag_ids?.map(dag => ({
              value: dag,
              label: dag,
            }))}
            onChange={opt =>
              handleIncludeDagsUpdate(
                'dag_ids',
                opt?.map(o => o.value) || [],
                rowi
              )
            }
            disabled={disabled}
            validation={validation}
          />
          <SelectInput
            className='notification-input-severity'
            label='SEVERITY'
            options={severityOpts}
            isOptionDisabled={option =>
              includeDags?.some(row => row.severity.includes(option.value))
            }
            onChange={opt =>
              handleIncludeDagsUpdate('severity', opt?.value || '', rowi)
            }
            value={
              severityOpts.find(opt => opt.value === row?.severity) || null
            }
            disabled={disabled}
          />
          <Button
            className='alt-members-delete-btn'
            onClick={() => handleIncludeDagsDeleteRow(rowi)}
            disabled={disabled}
          >
            <i className='fa fa-trash-o'></i>
          </Button>
        </div>
      ))}
      <Button
        className='notification-include-dags-add-row-btn'
        onClick={() => handleIncludeDagsAddRow()}
        locked={disabled}
      >
        ADD ROW
      </Button>
    </div>
  )
}

export default IncludeDags
