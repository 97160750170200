import { http, getRequestError, digResponse } from 'util/index.js'

export const aegisGroups = search =>
  http
    .get(
      `https://api.aegis.nikecloud.com/v1/prod/groups?startsWith=${encodeURIComponent(
        search
      )}&count=50`
    )
    .then(response => digResponse(response, 'data', 'items'))
    .catch(response => {
      throw getRequestError(response, 'aegisGroups')
    })

export const aegisUsers = search =>
  http
    .get(
      `https://api.aegis.nikecloud.com/v1/prod/users?startsWith=${encodeURIComponent(
        search
      )}&count=50`
    )
    .then(response => digResponse(response, 'data', 'items'))
    .catch(response => {
      throw getRequestError(response, 'aegisUsers')
    })
