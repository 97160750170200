import React from 'react'
import './FormError.scss'

export default ({ className, error }) => {
  const listErrors = error.map((item, index) => {
    return (
      <p
        className={`alt-form-error${className ? ' ' + className : ''}`}
        key={index}
      >
        {item}
      </p>
    )
  })

  return <div>{listErrors}</div>
}
