import React, { Component } from 'react'
import Dialogue from 'components/Dialogue/Dialogue.js'
import {
  restartCluster,
  restartScheduler,
  stopCluster,
} from 'store/clusters.js'
import './RestartCluster.scss'

export default class ConfigUpdate extends Component {
  constructor(props) {
    super(props)

    this.state = {
      started: false,
      completed: false,
      success: false,
      error: false,
    }
  }

  handleSchedulerRestart = () => {
    // Do not wait for request to return in case it hangs
    restartScheduler(this.props.region.api, this.props.cluster.cluster_id)
      .then(cluster => {
        this.props.updateCluster()
      })
      .catch(err =>
        this.setState({
          completed: true,
          error: err,
        })
      )
    this.setState(
      {
        completed: true,
        success: true,
      },
      () => this.props.updateHealth()
    )
  }

  doAction = action =>
    this.setState(
      {
        started: true,
      },
      () => {
        switch (action?.type) {
          case 'Restart':
            switch (action?.resource) {
              case 'Cluster':
                return restartCluster(
                  this.props.region.api,
                  this.props.cluster.cluster_id
                )
                  .then(cluster => {
                    this.setState({
                      completed: true,
                      success: true,
                    })
                    this.props.updateCluster(cluster)
                  })
                  .catch(err =>
                    this.setState({
                      completed: true,
                      error: err.map(item => item.message),
                    })
                  )
              case 'Scheduler':
                return this.handleSchedulerRestart()
              default:
                return
            }
          case 'Stop':
            return stopCluster(
              this.props.region.api,
              this.props.cluster.cluster_id
            )
              .then(cluster => {
                this.setState({
                  completed: true,
                  success: true,
                })
                this.props.updateCluster(cluster)
              })
              .catch(err =>
                this.setState({
                  completed: true,
                  error: err.map(item => item.message),
                })
              )
          default:
            return
        }
      }
    )

  getPresentTense = action => {
    switch (action) {
      case 'Restart':
        return 'Restarting'
      case 'Stop':
        return 'Stopping'
      default:
        return ''
    }
  }

  getSubtitle = action => {
    switch (action?.type) {
      case 'Restart':
        switch (action?.resource) {
          case 'Cluster':
            return 'Restarting all cluster resources typically takes 5-10 minutes during which time further changes will be disabled.'
          case 'Scheduler':
            return 'Restarting the Scheduler typically takes 30 seconds - 1 minute. DAGs will still run during this process. You can only restart the Scheduler once every 5 minutes.'
          default:
            return ''
        }
      case 'Stop':
        return 'Stopping all cluster resources typically takes 30 seconds. Scheduler, webserver, DAGs, and the database will be stopped. Resources can be reactivated by restarting the cluster.'
      default:
        return ''
    }
  }

  render() {
    let { onClose, action } = this.props
    let { started, completed, error } = this.state
    let confirmProps = {}
    let title = `${action?.resource} ${this.getPresentTense(action?.type)}.`
    let subtitle = `Your ${action?.resource} is now ${this.getPresentTense(
      action?.type
    ).toLowerCase()}.`

    // dont show the confirm action/text after completion
    if (!completed) {
      title = `${action?.type} ${action?.resource}?`
      subtitle = this.getSubtitle(action)
      confirmProps = {
        onConfirm: () => this.doAction(action),
        confirmText: started
          ? `${this.getPresentTense(action?.type).toUpperCase()}...`
          : `${action?.type?.toUpperCase()} ${action?.resource.toUpperCase()}`,
        confirmDisabled: started,
      }
    }

    if (completed && error) {
      const listErrors =
        typeof error === 'object' ? (
          error.map((item, index) => {
            return <li key={index}>{item}</li>
          })
        ) : (
          <li>{error}</li>
        )

      title = `Error ${this.getPresentTense(action?.type)} ${action?.resource}!`
      subtitle = (
        <>
          <p>The following errors were encountered while restarting:</p>
          <ul>{listErrors}</ul>
        </>
      )
    }

    return (
      <Dialogue
        className='restart-cluster-dialogue'
        title={title}
        subtitle={subtitle}
        onClose={onClose}
        closeText={started ? 'CLOSE' : 'CANCEL'}
        {...confirmProps}
      ></Dialogue>
    )
  }
}
