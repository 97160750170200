import React from 'react'
import TextInput from 'components/TextInput/TextInput.js'
import helper from '../../Config/NotificationsHelper.js'
import './ServiceNowNotification.scss'

export default ({ notification, updateCallback, disabled }) => {
  helper.manageServiceNowAttribute(notification)
  if (notification.service && notification.service !== 'service_now') {
    return null
  }

  return (
    <div id="notification-service-now">
      <div className="notification-config-row">
        <div className="notification-config-sn-assignment-group">
          <TextInput
            id="serviceNowAssignmentGroupInput"
            required
            label="ASSIGNMENT GROUP"
            tooltipText="Must be a valid assignment group that you can find in a servicenow incident. Otherwise no incident will be created."
            value={notification.service_now.assignment_group}
            disabled={disabled}
            onChange={opt =>
              updateCallback('service_now_assignment_group', opt)
            }
          />
        </div>

        <div className="notification-config-sn-category">
          <TextInput
            id="serviceNowCategoryInput"
            required
            label="CATEGORY"
            tooltipText="Must be a valid category for your ServiceNow Service."
            value={notification.service_now.category}
            disabled={disabled}
            onChange={opt => updateCallback('service_now_category', opt)}
          />
        </div>

        <div className="notification-config-sn-sub-category">
          <TextInput
            id="serviceNowSubcategoryInput"
            required
            label="SUB-CATEGORY"
            tooltipText="Must be a valid subcategory for the given category, otherwise it will show up blank in the incident."
            disabled={disabled}
            value={notification.service_now.sub_category}
            onChange={opt => updateCallback('service_now_sub_category', opt)}
          />
        </div>
      </div>
    </div>
  )
}
