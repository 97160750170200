import React, { Component } from 'react'
import * as d3 from 'd3'
import ReactFauxDOM from 'react-faux-dom'
import './ChartSingle.scss'

export default class ChartSingle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      chartDimensions: false,
    }
  }

  setRef = el => {
    this.chartRef = el
    this.setState({
      chartDimensions: {
        x: (el && el.scrollWidth) || 100,
        y: (el && el.scrollHeight) || 100,
      },
    })
  }

  createChart = () => {
    var { chartDimensions } = this.state
    var { data, xKey, yKey, formatY, minX, maxX, margin, title } = this.props
    data = data || []

    margin = margin || { top: 30, right: 20, bottom: 30, left: 20 }
    margin.top = title ? 40 : margin.top
    var width = chartDimensions.x
    var height = chartDimensions.y
    var innerWidth = width - margin.left - margin.right
    var innerHeight = height - margin.top - margin.bottom
    var xRange = d3.extent(data, d => d[xKey])
    xRange[0] = minX || xRange[0]
    xRange[1] = maxX || xRange[1]
    var yMax = d3.max(data, d => d[yKey]) || 1

    // x; timerange bounded to data
    var xScale = d3
      .scaleLinear()
      .domain(xRange)
      .range([0, innerWidth])

    // y; always starts at 0, max can predefined or the highest value
    var yScale = d3
      .scaleLinear()
      .domain([0, yMax])
      .range([innerHeight, 0])

    var area = d3
      .area()
      .x(d => xScale(d[xKey]))
      .y0(innerHeight)
      .y1(d => yScale(d[yKey]))
      .curve(d3.curveStepAfter)

    var line = d3
      .line()
      .x(d => xScale(d[xKey]))
      .y(d => yScale(d[yKey]))
      .curve(d3.curveStepAfter)

    var tickCount = Math.floor(innerWidth / 60)
    var tickDist = (xRange[1] - xRange[0]) / (tickCount - 1)
    var timeAxis = d3
      .axisBottom()
      .scale(xScale)
      .tickValues(
        [...Array(tickCount).keys()].map(x => tickDist * x + xRange[0])
      )
      .tickFormat(ts => {
        let t = new Date(ts)
        return `${t.getHours()}:${String(t.getMinutes()).padStart(2, '0')}`
      })

    var yAxis = d3
      .axisRight()
      .scale(yScale)
      .tickValues([0, yMax / 2, yMax])
      .tickSize(innerWidth)
      .tickFormat(val => (formatY ? formatY(val) : val))

    // RENDERING
    var el = ReactFauxDOM.createElement('div')
    el.setAttribute('class', 'chart-wrapper')

    var svg = d3
      .select(el)
      .append('svg')
      .attr('class', 'chart')
      .attr('width', width)
      .attr('height', height)
      .attr('transform', `translate(0,0)`)

    svg
      .append('path')
      .datum(data)
      .attr('class', 'line')
      .attr('transform', `translate(${margin.left},${margin.top})`)
      .attr('d', line)

    svg
      .append('path')
      .datum(data)
      .attr('class', 'area')
      .attr('transform', `translate(${margin.left},${margin.top})`)
      .attr('d', area)

    svg
      .append('g')
      .attr('class', 'axis x-axis')
      .attr(
        'transform',
        `translate(${margin.left},${innerHeight + margin.top})`
      )
      .call(timeAxis)

    svg
      .append('g')
      .attr('class', 'axis y-axis')
      .attr('transform', `translate(${margin.left},${margin.top})`)
      .call(yAxis)
      .selectAll('.tick text')
      .attr('x', 4)
      .attr('dy', -4)

    return el.toReact()
  }

  render() {
    let { chartDimensions } = this.state
    let { title } = this.props

    return (
      <div className="chart-single" ref={this.setRef}>
        {chartDimensions && this.createChart()}
        {title && <h3 className="chart-title">{title}</h3>}
      </div>
    )
  }
}
