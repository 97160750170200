import { getFile } from 'store/files.js'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { Base64 } from 'js-base64'

async function getFileInfo(region, clusterId, fileName) {
  const data = await getFile(region, clusterId, fileName)

  return {
    name: fileName,
    content: Base64.decode(data.encoded_content),
  }
}

function getDateString() {
  const date = new Date()
  const year = date.getFullYear()
  const month = `${date.getMonth() + 1}`.padStart(2, '0')
  const day = `${date.getDate()}`.padStart(2, '0')
  return `${year}_${month}_${day}`
}

function generateFileName(clusterName) {
  return `FILEs_${clusterName}_${getDateString()}`
}

const downloadFiles = async function downloadFiles(
  region,
  clusterId,
  selected,
  clusterName
) {
  let response = false

  try {
    var zip = new JSZip()

    for (const item of selected) {
      const response = await getFileInfo(region, clusterId, item)
      zip.file(response.name, response.content)
    }

    saveAs(
      await zip.generateAsync({ type: 'blob' }),
      `${generateFileName(clusterName)}.zip`
    )
    response = true
  } catch (err) {
    console.log(err)
  }

  return response
}

const exportFunctions = {
  downloadFiles,
}

export default exportFunctions
