import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ type, className, fontSize }) => (
  <i
    className={`epic-icon epic-icon-${type} ${className}`}
    style={{ fontSize }}
  />
)

Icon.propTypes = {
  /** className passed to Icon */
  className: PropTypes.string,
  /** icon type  */
  type: PropTypes.oneOf([
    'add',
    'arrow-backward',
    'arrow-down',
    'arrow-forward',
    'arrow-up',
    'bookmark',
    'calendar',
    'checkmark',
    'chevron-back',
    'chevron-circle-back',
    'chevron-circle-forward',
    'chevron-down',
    'chevron-forward',
    'chevron-up',
    'close-x',
    'collaborate',
    'confluence',
    'contact',
    'delete',
    'edit',
    'filter-x',
    'filter',
    'github',
    'information',
    'jira',
    'map-pin',
    'nike-logo',
    'preview-eye',
    'preview',
    'profile',
    'quotes',
    'search',
    'share',
    'slack-with-name',
    'slack',
    'steps',
    'up-and-running',
    'user-profile',
    'walkthrough',
    'warning'
  ]),
  /** Allow to custom webfont size */
  fontSize: PropTypes.string
}

Icon.defaultProps = {
  className: '',
  fontSize: '14px'
}

export default Icon
