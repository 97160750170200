import React, { Component } from 'react'
import { getLogs } from 'store/logs.js'
import { getDAGNames } from 'store/airflow'
import { generate as shortid } from 'shortid'
import Button from 'components/Button/Button.js'
import DateSingle from 'components/DateSingle/DateSingle.js'
import SelectInput from 'components/SelectInput/SelectInput.js'
import CreatableSelect from 'components/CreatableSelect/CreatableSelect'
import config from 'envs/config'
import moment from 'moment'
import './Logs.scss'
import Tooltip from 'components/Tooltip/Tooltip.js'

const periodOpts = [
  { label: '-15M', value: 15 },
  { label: '-30M', value: 30 },
  { label: '-45M', value: 45 },
  { label: '-1H', value: 60 },
  { label: '-2H', value: 120 },
]

const serviceOpts = [
  { label: 'WEBSERVER', value: 'webserver' },
  { label: 'SCHEDULER', value: 'scheduler' },
  { label: 'CONTAINER', value: 'container' },
]

const containerInstrctions = [
  {
    label: 'containerMSG',
    value: ' Please select or enter a DAG name in the search field',
  },
]
export default class Logs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mounted: false,
      service: 'webserver',
      period: 15,
      date: null,
      minDate: moment().subtract(2, 'd'),
      maxDate: moment(),
      logs: [],
      logsLoading: true,
      logsError: false,
      showResults: false,
      filter: '',
      dags: [],
    }
  }

  componentDidMount() {
    this.fetchLogs()
    this.innerRef && this.setState({ mounted: this.innerRef.scrollHeight })
  }

  processLog = log => {
    if (log.length === 0) {
      return null
    }

    let ts = ''
    let match = false
    log = log.replace(/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3} - - /, '') // removes prepended IP

    switch (true) {
      // TS format [23/Apr/2020:21:49:50 +0000]
      case !!(match = log.match(
        /^\[\d{1,2}\/\w{1,3}\/\d{1,4}:\d{1,2}:\d{1,2}:\d{1,2} \+\d{1,4}\]/
      )):
        ts = moment(match[0], 'DD/MMM/YYYY:HH:mm:ss ZZ').format(
          'YYYY-MM-DD HH:mm:ss'
        )
        log = log.substring(match[0].length)
        break
      // TS format [2020-04-23 21:49:59 +0000]
      case !!(match = log.match(
        /\[\d{1,4}-\d{1,2}-\d{1,2} \d{1,2}:\d{1,2}:\d{1,2} \+\d{1,4}\]/
      )):
        ts = moment(match[0], 'YYYY-MM-DD HH:mm:ss ZZ').format(
          'YYYY-MM-DD HH:mm:ss'
        )
        log = log.substring(match[0].length)
        break
      // TS format [2020-04-23 21:50:00,251]
      // or [\u001b[34m2023-07-05 18:00:19,231\u001b[0m]
      case !!(match = log.match(
        /\d{1,4}-\d{1,2}-\d{1,2} \d{1,2}:\d{1,2}:\d{1,2},\d{1,6}/
      )):
        ts = moment(match[0], 'YYYY-MM-DD HH:mm:ss,SSS').format(
          'YYYY-MM-DD HH:mm:ss'
        )
        log = log.substring(match[0].length + 19) //19 chars to get rid of extra junk
        break
      // TS format [2020-04-23T21:50:00,251]
      // or [\u001b[34m2024-05-03T15:55:01.341+0000\u001b[0m]
      // [\u001b[34m2024-05-03T16:22:52.692+0000\u001b[0m] {\u001b[34mkubernetes_executor_utils.py:\u001b[0m157} INFO\u001b[0m
      case !!(match = log.match(
        /\d{1,4}-\d{1,2}-\d{1,2}T\d{1,2}:\d{1,2}:\d{1,2}.\d{1,3}\+\d{1,4}/
      )):
        ts = moment(match[0], 'YYYY-MM-DDTHH:mm:ss.SSSZZ').format(
          'YYYY-MM-DD HH:mm:ss'
        )
        log = log.substring(match[0].length + 18) //18 chars to get rid of extra junk
        break
      default:
        break
    }

    return (
      <div className='log' key={shortid()}>
        <p className='log-timestamp'>{ts}</p>
        <p className='log-message'>{log}</p>
      </div>
    )
  }

  fetchLogs = () => {
    const { cluster, region } = this.props
    const { service, period } = this.state
    const date = this.state.date || moment()
    const toDate = date.toISOString()
    const fromDate = date
      .clone() // avoid mutability issues
      .subtract(period, 'm')
      .toISOString()

    return getLogs(region.api, cluster.cluster_id, service, fromDate, toDate)
      .then(logs =>
        this.setState({
          logs: logs.map(this.processLog),
          logsLoading: false,
          logsError: false,
        })
      )
      .catch(err =>
        this.setState({
          logs: [],
          logsLoading: false,
          logsError: err.map(item => item.message),
        })
      )
  }

  getDagNames = () => {
    const { cluster, region } = this.props
    return getDAGNames(region.value, cluster.cluster_name)
      .then(dags => {
        this.setState({
          dags: dags.map(dag => ({ value: dag.dag_id, label: dag.dag_id })),
        })
      })
      .catch(err =>
        this.setState({
          dags: [],
        })
      )
  }

  reloadLogs = () => {
    if (this.state.showResults && this.state.service === 'container') {
      this.setState({
        logs: [],
        logsLoading: false,
        logsError: containerInstrctions.map(item => item.value),
      })
      this.getDagNames()
    } else {
      this.setState(
        {
          logs: [],
          logsLoading: true,
          logsError: false,
        },
        this.fetchLogs
      )
    }
  }

  loadingSkeleton = () =>
    [...Array(Math.floor(this.state.mounted / 27))].map((e, i) => (
      <div className='log log-skeleton' key={i}>
        <p className='log-timestamp'></p>
        <p className='log-message'></p>
      </div>
    ))

  render() {
    let {
      mounted,
      service,
      period,
      date,
      minDate,
      maxDate,
      logs,
      logsLoading,
      logsError,
      filter,
      dags,
    } = this.state
    let { cluster, region } = this.props
    const nspLogsLink = config.nspLogs(region.value, cluster.cluster_id)
    const backDisabled = date && date.diff(minDate, 'm') <= period
    const forwardDisabled = !date || date.diff(maxDate, 'm') >= period

    let displayError = null
    if (logsError) {
      let errorList = logsError.map((item, index) => {
        return (
          <p key={index}>
            <i className='fa fa-exclamation-triangle'></i>
            {item}
          </p>
        )
      })

      displayError = <div className='logs-error'>{errorList}</div>
    }

    return (
      <div className='dash-main logs'>
        <div className='logs-header'>
          <h3 className='list-title tooltip-ad'>
            CLUSTER LOGS
            {region.value !== 'cn-northwest-1' && (
              <a
                className='list-subtitle'
                href={nspLogsLink}
                target='_blank'
                rel='noopener noreferrer'
              >
                NSP LOGS SOURCE →
              </a>
            )}
          </h3>
          <Tooltip tooltipText='Shows container logs in realtime for webserver, scheduler and DAGs'></Tooltip>
          <SelectInput
            className='logs-service'
            options={serviceOpts}
            value={serviceOpts.find(opt => opt.value === service)}
            onChange={opt =>
              this.setState(
                {
                  service: opt.value,
                  showResults: opt.value === 'container' ? true : false,
                },
                this.reloadLogs
              )
            }
            disabled={logsLoading}
          />
          {this.state.showResults && (
            <CreatableSelect
              className={'logs-dag-search'}
              placeholder='Select or enter DAG name...'
              disabled={logsLoading}
              value={
                filter
                  ? {
                      label: filter,
                      value: filter,
                    }
                  : null
              }
              options={dags}
              onChange={opt => {
                let dagSelected = opt ? opt.value : ''
                this.setState(
                  { filter: dagSelected, service: dagSelected },
                  dagSelected ? this.fetchLogs : null
                )
              }}
            />
          )}
          <DateSingle
            className='logs-range'
            date={date}
            minDate={minDate}
            maxDate={maxDate}
            onDateChange={date => this.setState({ date })}
            disabled={logsLoading}
          />
          <SelectInput
            className='logs-period'
            options={periodOpts}
            value={periodOpts.find(opt => opt.value === period)}
            onChange={opt => this.setState({ period: opt.value })}
            disabled={logsLoading}
          />
          <Button
            className='alt-back-button'
            onClick={() =>
              this.setState(
                {
                  date: (date || moment()).clone().subtract(period, 'm'),
                },
                this.reloadLogs
              )
            }
            disabled={logsLoading || backDisabled}
          >
            <i className='fa fa-arrow-left' />
          </Button>
          <Button
            className='alt-forward-button'
            onClick={() =>
              this.setState(
                {
                  date: (date || moment()).clone().add(period, 'm'),
                },
                this.reloadLogs
              )
            }
            disabled={logsLoading || forwardDisabled}
          >
            <i className='fa fa-arrow-right' />
          </Button>
          <Button
            className='alt-refresh-button'
            onClick={this.reloadLogs}
            disabled={logsLoading}
          >
            <i className={`fa fa-${logsLoading ? 'refresh' : 'search'}`} />
          </Button>
        </div>

        <div className='logs-main alt-scroll' ref={el => (this.innerRef = el)}>
          {displayError}
          {logsLoading && mounted && this.loadingSkeleton()}
          {logs}
          {logs.length > 0 && logs.length < 10000 && (
            <p className='logs-cap'>Reached Oldest Returned Log...</p>
          )}
          {logs.length > 0 && logs.length >= 10000 && (
            <p className='logs-cap'>Reached 10,000 Log Request Limit...</p>
          )}
        </div>
      </div>
    )
  }
}
