exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".epic-font-base___nrsZB {\n  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-base-md___QA5-L {\n  font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif;\n}\n.epic-font-marketing___2ImnK {\n  font-family: 'Futura', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-brand___oaB68 {\n  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.toggle___1rY5O {\n  display: inline-block;\n  background-color: #fff;\n  white-space: nowrap;\n  border: 1px solid #5197d6;\n  border-radius: 20px;\n  box-sizing: border-box;\n  padding: 0;\n  margin: 0;\n  line-height: 1.2;\n}\n.toggle___1rY5O .toggleItem___2zyVT {\n  display: inline-block;\n  cursor: pointer;\n  transition: 0.4s;\n  font-size: 14px;\n  height: 100%;\n  margin: 0;\n  line-height: 1.5;\n}\n.toggle___1rY5O .toggleItem___2zyVT input[type=radio] {\n  position: absolute;\n  left: -9999px;\n}\n.toggle___1rY5O .toggleItem___2zyVT label {\n  display: inline-block;\n  vertical-align: top;\n  text-decoration: none;\n  cursor: pointer;\n  overflow: hidden;\n  -webkit-transition: all 0.2s ease;\n  -o-transition: all 0.2s ease;\n  transition: all 0.2s ease;\n  color: #5197d6;\n  padding: 6px 14px;\n  border-radius: 20px;\n  border: 1px solid transparent;\n  user-select: none;\n  margin: 0;\n}\n.toggle___1rY5O .toggleItem___2zyVT input[type=radio]:not(checked) + label {\n  background: transparent;\n  margin: 0;\n}\n.toggle___1rY5O .toggleItem___2zyVT input[type=radio]:checked + label {\n  background: #5197d6;\n  margin: 0;\n  color: #fff;\n  border: 1px solid #5197d6;\n}\n", ""]);

// Exports
exports.locals = {
	"epic-font-base": "epic-font-base___nrsZB",
	"epic-font-base-md": "epic-font-base-md___QA5-L",
	"epic-font-marketing": "epic-font-marketing___2ImnK",
	"epic-font-brand": "epic-font-brand___oaB68",
	"toggle": "toggle___1rY5O",
	"toggleItem": "toggleItem___2zyVT"
};