import React, { Component } from 'react'
import { getMetrics } from 'store/metrics.js'
import { ErrorBoundary } from 'components/index.js'
import MetricsError from './MetricsError.js'
import PodMetrics from './PodMetrics.js'
import AirflowMetrics from './AirflowMetrics.js'
import DagMetrics from './DagMetrics.js'
import OperatorMetrics from './OperatorMetrics.js'
import SelectInput from 'components/SelectInput/SelectInput.js'
import Button from 'components/Button/Button.js'
import DateSingle from 'components/DateSingle/DateSingle.js'
import config from 'envs/config'
import moment from 'moment'
import './Metrics.scss'
import Tooltip from 'components/Tooltip/Tooltip.js'

const periodOpts = [
  { label: '-15M', value: 15 },
  { label: '-30M', value: 30 },
  { label: '-45M', value: 45 },
  { label: '-1H', value: 60 },
  { label: '-2H', value: 120 },
]

const serviceEndpointMap = {
  resource_util: 'pod',
  airflow_general: 'airflow',
  airflow_dags: 'airflow',
  airflow_operators: 'airflow',
}

const serviceComponentMap = {
  resource_util: PodMetrics,
  airflow_general: AirflowMetrics,
  airflow_dags: DagMetrics,
  airflow_operators: OperatorMetrics,
}

export default class Metrics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      service: 'airflow_general',
      period: 30,
      date: null,
      minDate: moment().subtract(7, 'd'),
      maxDate: moment(),
      metrics: false,
      metricsLoading: true,
      metricsError: false,

      // From last fetch to bound charts
      tsFrom: false,
      tsTo: false,
    }
  }

  componentDidMount() {
    this.fetchMetrics()
  }

  fetchMetrics = () => {
    const { cluster, region } = this.props
    const { service, period } = this.state
    const date = this.state.date || moment()
    const toDate = date.clone()
    const fromDate = date
      .clone() // avoid mutability issues
      .subtract(period, 'm')

    return getMetrics(
      region.api,
      cluster.cluster_id,
      serviceEndpointMap[service],
      fromDate.toISOString(),
      toDate.toISOString()
    )
      .then(metrics =>
        this.setState({
          metrics: metrics,
          metricsLoading: false,
          metricsError: false,
          tsFrom: fromDate.valueOf(),
          tsTo: toDate.valueOf(),
        })
      )
      .catch(err =>
        this.setState({
          metrics: false,
          metricsLoading: false,
          metricsError: err.map(item => item.message),
        })
      )
  }

  reloadMetrics = () =>
    this.setState(
      {
        metrics: false,
        metricsLoading: true,
        metricsError: false,
      },
      this.fetchMetrics
    )

  render() {
    let {
      service,
      period,
      date,
      minDate,
      maxDate,
      metrics,
      metricsLoading,
      metricsError,
      tsFrom,
      tsTo,
    } = this.state
    let { cluster, region } = this.props
    const nspMetricsLink = config.nspMetrics(region.value, cluster.cluster_id)
    const MetricsRenderer = serviceComponentMap[service]
    const backDisabled = date && date.diff(minDate, 'm') <= period
    const forwardDisabled = !date || date.diff(maxDate, 'm') >= period
    const serviceOpts = [
      { label: 'RESOURCE UTILIZATION', value: 'resource_util' },
      { label: 'AIRFLOW GENERAL', value: 'airflow_general' },
      { label: 'AIRFLOW DAGS', value: 'airflow_dags' },
      { label: 'AIRFLOW OPERATORS', value: 'airflow_operators' },
    ]

    return (
      <div className="dash-main metrics">
        <div className="metrics-header">
          <h2 className="metrics-header-title tooltip-ad">
            CLUSTER METRICS
            {region.value !== 'cn-northwest-1' && (
              <a
                className="metrics-header-subtitle"
                href={nspMetricsLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                NSP METRICS SOURCE →
              </a>
            )}
          </h2>
          <Tooltip tooltipText="To see the CPU Stats on the cluster"></Tooltip>
          <SelectInput
            className="metrics-service"
            options={serviceOpts}
            value={serviceOpts.find(opt => opt.value === service)}
            onChange={opt =>
              this.setState(
                {
                  service: opt.value,
                  metrics: false,
                  metricsLoading: true,
                  metricsError: false,
                },
                this.fetchMetrics
              )
            }
            disabled={metricsLoading}
          />
          <DateSingle
            className="metrics-range"
            date={date}
            minDate={minDate}
            maxDate={maxDate}
            onDateChange={date => this.setState({ date })}
            disabled={metricsLoading}
          />
          <SelectInput
            className="metrics-period"
            options={periodOpts}
            value={periodOpts.find(opt => opt.value === period)}
            onChange={opt => this.setState({ period: opt.value })}
            disabled={metricsLoading}
          />
          <Button
            className="alt-back-button"
            onClick={() =>
              this.setState(
                {
                  date: (date || moment()).clone().subtract(period, 'm'),
                },
                this.reloadMetrics
              )
            }
            disabled={metricsLoading || backDisabled}
          >
            <i className="fa fa-arrow-left" />
          </Button>

          <Button
            className="alt-forward-button"
            onClick={() =>
              this.setState(
                {
                  date: (date || moment()).clone().add(period, 'm'),
                },
                this.reloadMetrics
              )
            }
            disabled={metricsLoading || forwardDisabled}
          >
            <i className="fa fa-arrow-right" />
          </Button>

          <Button
            className="alt-refresh-button"
            onClick={this.reloadMetrics}
            disabled={metricsLoading}
          >
            <i className={`fa fa-${metricsLoading ? 'refresh' : 'search'}`} />
          </Button>
        </div>

        <ErrorBoundary
          errorComponent={<MetricsError error="Error Rendering Metrics." />}
        >
          {metricsError ? (
            <MetricsError error={metricsError} />
          ) : (
            <MetricsRenderer
              metrics={metrics}
              metricsLoading={metricsLoading}
              tsFrom={tsFrom}
              tsTo={tsTo}
              {...this.props}
            />
          )}
        </ErrorBoundary>
      </div>
    )
  }
}
