import React, { Component } from 'react'
import { cloudredApplications } from 'store/cloudred.js'
import { updateCluster, enableObservability } from 'store/clusters.js'
import FormError from 'components/FormError/FormError.js'
import TextInput from 'components/TextInput/TextInput.js'
import KVTags from 'components/KVTags/KVTags.js'
import SelectInput from 'components/SelectInput/SelectInput.js'
import Button from 'components/Button/Button.js'
import validate from './validate.js'
import ToggleInput from 'components/Toggle/ToggleInput.js'

export default class BasicInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      applications: [],
      cluster: props.cluster, // reference to unmodified values
      tagguid: props.cluster.tagguid,
      labels: props.cluster.labels,
      nsp_sources: props.cluster.nsp_sources,
      observability: props.cluster.observability,
      validation: {},
      submitPending: false,
      submitError: false,
    }
  }

  componentDidMount() {
    cloudredApplications().then(data =>
      this.setState({
        applications: data
          .map(app => ({
            label: app.name,
            value: app.key,
          }))
          .sort((a, b) => (a.label > b.label ? 1 : -1)),
      })
    )
    this.setState({
      validation: validate({...this.state, labels: this.state.labels }),
    });
  }

  onChange = (key, value) => {
    this.setState({
      [key]: value,
      validation: validate({...this.state, [key]: value }),
    });
  }

  onSubmit = () =>
    this.setState(
      {
        submitPending: true,
        submitError: false,
      },
      () => {
        // If user turns observability on, send post request
        if (!this.state.cluster.observability && this.state.observability)
          enableObservability(this.props.region.api, this.props.cluster.cluster_id)
            .then(() => {
              this.setState({
                submitPending: false,
                submitError: false,
              })
              this.props.updateCluster()
            })
            .catch(error =>
              this.setState({
                submitError: typeof error === 'object' ? error.map(item => item.message) : error,
                submitPending: false,
              })
            )

        // If user makes other changes, send update cluster put request
        if (JSON.stringify(this.state.labels) !== JSON.stringify(this.state.cluster.labels) ||
          this.state.tagguid !== this.state.cluster.tagguid ||
          this.state.nsp_sources !== this.state.cluster.nsp_sources)
          updateCluster(this.props.region.api, this.props.cluster.cluster_id, {
            labels: this.state.labels,
            tagguid: this.state.tagguid,
            nsp_sources: this.state.nsp_sources,
          })
            .then(data =>
              this.setState(
                {
                  cluster: data,
                  labels: data.labels,
                  tagguid: data.tagguid,
                  nsp_sources: data.nsp_sources,
                  submitPending: false,
                  submitError: false,
                },
                () => this.props.updateCluster(data)
              )
            )
            .catch(error =>
              this.setState({
                submitError: error.map(item => item.message),
                submitPending: false,
              })
            )
      }
    )

  render() {
    let { region, permissions } = this.props
    let {
      applications,
      cluster,
      nsp_sources,
      tagguid,
      labels,
      observability,
      validation,
      submitPending,
      submitError,
    } = this.state
    let editDisabled = !permissions['CLUSTER.UPDATE']

    return (
      <div className="settings-form-section section-base">
        <h3 className="settings-form-section-title">BASIC INFO</h3>

        <div className="settings-form-section-inner">
          {submitError && <FormError error={submitError} />}

          <TextInput
            label="CLUSTER NAME"
            value={cluster.cluster_name}
            disabled
          />

          <SelectInput
            label="CLOUDRED TAGGUID"
            options={applications}
            onChange={opt => this.onChange('tagguid', opt.value)}
            value={applications.find(app => app.value === tagguid)}
            className="tagguid-field"
            formatOptionLabel={({ value, label }) => (
              <div className="tagguid-option">
                <p className="tagguid-option-label">{label}</p>
                <p className="tagguid-option-value">{value}</p>
              </div>
            )}
            error={validation.tagguid}
            disabled={editDisabled}
          />

          <SelectInput
            label="REGION"
            options={[region]}
            value={region}
            disabled
          />

          <ToggleInput
            label="SANDBOX"
            detail="Optional field to have a playground MAP cluster. Provisioning time will decrease, and will cut unnecessary costs. It is not recommended for QA/Production Environments."
            value={cluster.sandbox}
            disabled
          />

          {region.value === 'cn-northwest-1' ||
            region.value === 'cn-north-1' ? (
            ''
          ) : (
            <ToggleInput
              label="NSP Sources"
              detail="Enable/Disable NSP Sources for Metrics/Logs."
              className="toggle-field"
              value={nsp_sources}
              disabled
              tooltipText="NSP Integration"
              onClick={() => this.onChange('nsp_sources', !nsp_sources)}
            />
          )}

          <ToggleInput
            label="OBSERVABILITY"
            detail="Send cluster observability metrics to Snowflake. Once enabled, this feature cannot be disabled."
            className="toggle-field"
            value={observability}
            disabled //cluster.observability || editDisabled
            onClick={() => this.onChange('observability', !observability)}
          />

          <KVTags
            label="LABELS"
            detail='Apply labels to your clusters to organize and identify them. The `env` label is required to distinguish cluster environments (prod, qa, dev, etc.).'
            tags={labels}
            onChange={tags => this.onChange('labels', tags)}
            error={validation.labels}
            disabled={editDisabled}
          />
          <div className="button-row">
            <Button
              onClick={this.onSubmit}
              pending={submitPending}
              locked={editDisabled}
              disabled={
                Object.keys(validation).length > 0 ||
                (JSON.stringify(labels) === JSON.stringify(cluster.labels) &&
                  tagguid === cluster.tagguid &&
                  nsp_sources === cluster.nsp_sources &&
                  observability === cluster.observability)
              }
            >
              SAVE CHANGES
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
