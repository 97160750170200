import React, { useState, useEffect } from 'react'
import { generate as shortid } from 'shortid'
import { aeGroups } from 'store/ae.js'
import SelectInput from 'components/SelectInput/SelectInput.js'
import ToggleInput from 'components/Toggle/ToggleInput.js'
import FormError from 'components/FormError/FormError.js'
import Button from 'components/Button/Button.js'
import '../Settings.scss'

const environmentOptions = [
  {
    label: 'Production',
    value: 'prod',
  },
  {
    label: 'QA',
    value: 'qa',
  },
  {
    label: 'Development',
    value: 'dev',
  },
]

export default ({
  cluster,
  onChange,
  existingCluster,
  error,
  locked,
  onSave,
  savePending,
  saveDisabled,
  region,
  user,
  disabled,
  setIntegrationsLoading = () => {},
}) => {
  const [groups, setGroups] = useState([])
  const {
    ae_compute_integration,
    airflow_version,
    configs,
    members,
    ae_data_access_ad_group,
  } = cluster

  const ae_environment_config =
    configs && configs.ae_compute !== undefined
      ? configs['ae_compute'].find(config => config.key === 'env')
      : false

  const ae_environment = ae_environment_config && ae_environment_config.value

  useEffect(() => {
    setIntegrationsLoading(true)
    aeGroups(region.value, user.email)
      .then(data => {
        setGroups(
          data
            // select expects label & value even if they're the same.
            .map(group => ({ label: group, value: group }))
            .sort((a, b) => (a.label > b.label ? 1 : -1))
        )
      })
      .catch(() => setGroups([]))
      .finally(() => setIntegrationsLoading(false))
  }, [])

  return (
    <div className='settings-form-section'>
      <h3 className='settings-form-section-title'>INTEGRATIONS</h3>

      <div className='settings-form-section-inner'>
        {error && <FormError error={error} />}

        <ToggleInput
          label='AE DATA ACCESS AD GROUP'
          detail='Permanently configure this cluster to communicate with AE Compute.'
          value={ae_compute_integration}
          onClick={() => {
            if (!ae_compute_integration) {
              onChange({
                ae_compute_integration: !ae_compute_integration,
                airflow_version: airflow_version.includes('1.10.4')
                  ? '1.10.10-python36'
                  : airflow_version,
                members: [
                  {
                    type: 'ad_group',
                    role: 'admin',
                    ad_group: '',
                    sub: '',
                    tmpId: shortid(),
                  },
                ],
              })
            } else {
              // remove key without mutation
              let { ae_compute, ...withoutAE } = configs // eslint-disable-line no-unused-vars
              onChange({
                ae_compute_integration: !ae_compute_integration,
                airflow_version: airflow_version.includes('1.10.4')
                  ? '1.10.10-python36'
                  : airflow_version,
                configs: withoutAE,
                members: [
                  {
                    type: 'ad_group',
                    role: 'admin',
                    ad_group: '',
                    sub: '',
                    tmpId: shortid(),
                  },
                ],
              })
            }
          }}
          disabled={disabled || existingCluster || locked}
        />

        {ae_compute_integration && (
          <SelectInput
            required
            label='AE COMPUTE ENVIRONMENT'
            detail='The AE Compute Environment this cluster should use.'
            options={environmentOptions}
            value={environmentOptions.find(opt => opt.value === ae_environment)}
            onChange={opt =>
              onChange({
                configs: {
                  ...configs,
                  ae_compute: [
                    {
                      key: 'env',
                      value: opt.value,
                    },
                  ],
                },
              })
            }
            disabled={disabled || existingCluster || locked}
          />
        )}

        {ae_compute_integration && (
          <SelectInput
            required
            label='AE ADMIN AD GROUP'
            detail={
              <span>
                The <i>AE AD group</i> to authenticate admin users with. Only
                approved groups you belong to will appear.{' '}
                <a
                  href='https://confluence.nike.com/display/NGP/Advanced+Compute+Group+Onboarding'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Follow this guide to create an appropriate AD group.
                </a>
              </span>
            }
            options={groups}
            value={
              ae_data_access_ad_group && {
                label: ae_data_access_ad_group,
                value: ae_data_access_ad_group,
              }
            }
            onChange={opt =>
              onChange({
                ae_data_access_ad_group: opt.value,
                members: [
                  {
                    ...members[0],
                    ad_group: opt.value,
                  },
                  ...members
                    .slice(1)
                    .filter(m => m.type === 'sub' || m.role === 'user'),
                ],
              })
            }
            disabled={disabled || existingCluster || locked}
          />
        )}

        {onSave && ae_compute_integration && (
          <div className='button-row'>
            <Button
              onClick={onSave}
              pending={savePending}
              locked={locked}
              disabled={saveDisabled}
            >
              SAVE CHANGES
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
