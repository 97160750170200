import React from 'react'
import './Spinner.scss'

const Spinner = ({ large, medium, center }) => (
  <div
    className={`spinner${large ? ' spinner-lg' : ''}${
      medium ? ' spinner-md' : ''
    }${center ? ' spinner-center' : ''}`}
  />
)
export default Spinner
