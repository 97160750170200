import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { startBuild } from 'store/builds.js'
import Dialogue from 'components/Dialogue/Dialogue.js'
import config from 'envs/config'

export default class CreateBuild extends Component {
  constructor(props) {
    super(props)
    this.state = {
      started: false,
      completed: false,
      error: false,
    }
  }

  startBuild = () =>
    this.setState(
      {
        started: true,
      },
      () => {
        startBuild(this.props.region.api, this.props.cluster.cluster_id)
          .then(build =>
            this.setState(
              {
                completed: true,
              },
              () =>
                window.location.assign(
                  `${config.siteRoot}${this.props.region.value}/clusters/${this.props.cluster.cluster_name}/builds/${build.build_id}`
                )
            )
          )
          .catch(err =>
            this.setState({
              completed: true,
              error: err.map(item => item.message),
            })
          )
      }
    )

  render() {
    let { cluster, region, onClose } = this.props
    let { started, completed, error } = this.state
    let buildspecLink = `${config.siteRoot}${region.value}/clusters/${cluster.cluster_name}/image`
    let confirmProps = {
      subtitle: 'You will soon be redirected to the build.',
    }
    let title = 'Image Build Triggered.'

    if (started && !completed) {
      confirmProps = {
        ...confirmProps,
        confirmPending: true,
        onConfirm: true
      }
    }

    // dont show the confirm action/text after completion
    if (!started && !completed) {
      title = 'Trigger Image Build?'
      confirmProps = {
        subtitle: (
          <span>
            Are you sure you want to trigger a new image build? This will start
            a process that will build a custom image extension for your cluster
            using your pre-defined <Link to={buildspecLink}>buildspec</Link>.
          </span>
        ),
        onConfirm: this.startBuild,
        confirmText: 'TRIGGER BUILD',
        confirmDisabled: started,
      }
    }

    if (completed && error) {
      const listErrors = error.map((item, index) => {
        return <li key={index}>{item}</li>
      })

      title = 'Error Triggering Build!'
      confirmProps = {
        subtitle: (
          <>
            <p>
              The following errors were encountered when triggering this build:
            </p>
            <ul>{listErrors}</ul>
          </>
        ),
      }
    }

    return (
      <Dialogue
        title={title}
        onClose={onClose}
        closeText={started ? 'CLOSE' : 'CANCEL'}
        {...confirmProps}
      />
    )
  }
}
