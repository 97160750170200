const contactConstraints = {
  email: {
    presence: {
      allowEmpty: false,
      message: "Contact can't be blank",
    },
    email: {
      message: 'Email must be a correctly formatted',
    },
    format: {
      pattern: /^.*(@nike.com|@converse.com|@nikeb2c.pagerduty.com)/,
      message: 'Must be nike.com, converse.com, or nikeb2c.pagerduty.com',
    },
    length: {
      maximum: 256,
      message: 'Email is too long (max 256)',
    },
  },
  slack_webhook: {
    presence: {
      allowEmpty: false,
      message: "Contact can't be blank",
    },
    format: {
      pattern: /^https:\/\/hooks.slack.com\/.*/,
      message: 'Must be a valid slack webhook url',
    },
    length: {
      maximum: 256,
      message: 'Slack Webhook is too long (max 256)',
    },
  },
  pagerduty: {
    presence: {
      allowEmpty: false,
      message: "Contact can't be blank",
    },
    format: {
      pattern: /^[a-zA-Z0-9]*$/,
      message: 'Pagerduty must contain only a-zA-Z0-9',
    },
    length: {
      maximum: 256,
      message: 'Pagerduty is too long (max 256)',
    },
  },
  service_now: {
    presence: {
      allowEmpty: false,
      message: "Contact can't be blank",
    },
    format: {
      pattern: /^https:\/\/.*/,
      message: 'Service Now must be a valid https url',
    },
    length: {
      maximum: 50,
      message: 'Service Now is too long (max 50)',
    },
  },
}

const metedataConstraints = {
  presence: {
    allowEmpty: false,
    message: "Meta data can't be blank",
  },
  format: {
    pattern: /^[a-zA-Z0-9\-_.]*$/,
    message: 'Metadata must contain only a-zA-Z0-9\\-_.',
  },
  length: {
    maximum: 50,
    message: 'Metadata Now is too long (max 50)',
  },
}

const workerNotifications = ['cluster.restart.failure']

export default { contactConstraints, metedataConstraints, workerNotifications }
