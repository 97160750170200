import React from 'react'
import icon from 'assets/images/tooltip.svg'
import './Tooltip.scss'

export default ({ tooltipText }) => {
  if (typeof tooltipText === 'undefined') {
    return null
  }

  return (
    <div className="tooltip">
      <img src={icon} className="icon" alt="tooltip icon" />
      <span className="tooltiptext">{tooltipText}</span>
    </div>
  )
}
