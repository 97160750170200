import React, { Component } from 'react'
import { ApmRoute } from '@elastic/apm-rum-react'
import { Router, Switch, Link, matchPath } from 'react-router-dom'

import 'assets/styles/theme.scss'
import '@nike/epic-react-ui/dist/styles/main.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import logo from 'assets/images/logo.svg'
import config from 'envs/config'
import { apm, history } from 'util/index.js'
import { handleAuth, login, logout } from './Auth.js'

import {
  Logout,
  Spinner,
  OfflineBanner,
  RegionSelect,
  ReleaseNotes,
  ReleaseNotesButton,
  Toasts,
} from 'components/index.js'
import IntegratedPlatform from '@nike/console-integrated-platform'
import { NavBar } from '@nike/epic-react-ui'

// Routes
import Clusters from 'apps/Clusters/Clusters.js'
import Cluster from 'apps/Cluster/Index.js'
import Create from 'apps/Create/Create.js'
import Decommissioned from 'apps/Errors/Decommissioned.js'
import NotFound from 'apps/Errors/NotFound.js'

export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      authComplete: false,
      authToken: false,
      releaseNotesTs: 0,
      releaseNotesOpen: false,
    }
  }

  auth = (isRefresh = false) =>
    handleAuth(history, isRefresh ? this.state.authToken.refreshToken : false)
      .then(token =>
        this.setState({ authComplete: true, authToken: token }, () =>
          apm.setUserContext({ email: token.email })
        )
      )
      .catch(() => this.setState({ authComplete: true, authToken: false }))

  componentDidMount() {
    this.watchAuthExpiration = setInterval(() => {
      if (
        this.state.authToken &&
        this.state.authToken.expiresAt * 1000 < Date.now() + 15000
      ) {
        logout()
        this.auth(true)
      }
    }, 15000)

    this.auth()
    localStorage.setItem('consoleAbort', 'true') //This disables the ep console banner from appearing at the top of the page in development envs. It is automatically disabled in production.
  }

  componentWillUnmount() {
    clearInterval(this.watchAuthExpiration)
  }

  routeRenderer = (Component, props) => routerProps => {
    const regionParam = routerProps.match.params.region
    if (regionParam && !config.regions.find(r => r.value === regionParam)) {
      // Region param provided but is invalid
      return <NotFound />
    }
    return <Component {...props} {...routerProps} />
  }

  getRegion = () => {
    const match = matchPath(window.location.pathname, { path: '/:region' })
    const matchParam = match && match.params && match.params.region
    const matchRegion = config.regions.find(r => r.value === matchParam)

    if (matchRegion) {
      localStorage.setItem('lastRegion', matchRegion.value)
      return matchRegion
    }

    const lastRegion = localStorage.getItem('lastRegion')
    return (
      config.regions.find(r => r.value === lastRegion) ||
      config.regions.find(r => r.value === config.defaultRegion)
    )
  }

  render() {
    const { authComplete, authToken, releaseNotesTs, releaseNotesOpen } =
      this.state
    const { openIdToken, accessToken } = authToken || {}
    const region = this.getRegion()
    const routeProps = {
      region,
      history,
      user: authToken,
    }

    // Navigation routes passed to ep console navbar
    const navRoutes = [
      {
        text: 'Manage',
        path: `/${encodeURI(region.value)}/clusters`,
        exact: true,
      },
      {
        text: 'Create',
        path: `/${encodeURI(region.value)}/create`,
        exact: true,
      },
      {
        text: 'Docs',
        path: 'https://docs.platforms.nike.com/map/latest/index.html',
        external: true,
      },
      {
        text: 'Training',
        path: 'https://nikeu.nike.com/course/introduction-map',
        external: true,
      },
    ]

    if (!authComplete) {
      return (
        <div id='content-host'>
          <OfflineBanner />
          <Spinner large center />
        </div>
      )
    }

    return (
      <Router history={history}>
        <IntegratedPlatform
          idToken={openIdToken}
          accessToken={accessToken}
          name='Managed Airflow'
          slackURL={config.slack}
          history={history}
          onLogin={login}
          isLoggedIn={!!authToken}
          hasAuthCheckFinished={authComplete}
          useConsoleLoginScreen
        >
          <div id='content-host'>
            <div className='navbar-wrapper'>
              <NavBar
                className='navbar'
                name='Managed Airflow'
                routes={navRoutes}
                rootPath='/'
                slackURL={config.slack}
                logo={logo}
                RouterLink={({ href, ...props }) => (
                  <Link to={href} {...props} />
                )}
                aside={
                  <div className='nav-aside'>
                    <ReleaseNotesButton
                      key='release-notes'
                      isOpen={releaseNotesOpen}
                      latestTs={releaseNotesTs}
                      onClick={() => {
                        this.setState({ releaseNotesOpen: !releaseNotesOpen })
                        const editContainer =
                          document.querySelector('.edit-container')
                        const openClassName =
                          'edit-container-release-notes-open'

                        if (editContainer) {
                          releaseNotesOpen
                            ? editContainer.classList.remove(openClassName)
                            : editContainer.classList.add(openClassName)
                        }
                      }}
                    />
                    <RegionSelect key='alt-region-select' region={region} />
                  </div>
                }
              />
            </div>
            <OfflineBanner />
            <Toasts>
              <div className='alt-full-view-wrapper'>
                <div className='alt-full-cols'>
                  <div className='alt-view-wrapper'>
                    <Switch>
                      <ApmRoute
                        path='/'
                        exact
                        component={this.routeRenderer(Clusters, routeProps)}
                      />
                      <ApmRoute
                        path='/create'
                        exact
                        component={this.routeRenderer(Create, routeProps)}
                      />
                      <ApmRoute
                        path='/:region/create'
                        exact
                        component={this.routeRenderer(Create, routeProps)}
                      />
                      <ApmRoute
                        path='/:region/clusters'
                        exact
                        component={this.routeRenderer(Clusters, routeProps)}
                      />
                      <ApmRoute
                        path='/:region/clusters/:clusterName'
                        component={this.routeRenderer(Cluster, routeProps)}
                      />
                      <ApmRoute
                        path='/:region/decommissioned'
                        exact
                        component={this.routeRenderer(
                          Decommissioned,
                          routeProps
                        )}
                      />
                      <ApmRoute
                        path='/logout'
                        exact
                        component={this.routeRenderer(Logout, routeProps)}
                      />
                      <ApmRoute
                        component={this.routeRenderer(NotFound, routeProps)}
                      />
                    </Switch>
                  </div>

                  <ReleaseNotes
                    open={releaseNotesOpen}
                    onLoad={releaseNotesTs => this.setState({ releaseNotesTs })}
                    onClose={() => this.setState({ releaseNotesOpen: false })}
                  />
                </div>
              </div>
            </Toasts>
          </div>
        </IntegratedPlatform>
      </Router>
    )
  }
}
