import React, { Component } from 'react'
import { createCluster, getClusters } from 'store/clusters.js'
import Form from 'components/Form/Form.js'
import FormError from 'components/FormError/FormError.js'
import Button from 'components/Button/Button.js'
import notifValidation from 'components/Settings/Notifications/Validation/ValidateHelpers.js'
import ButtonLink from 'components/ButtonLink/ButtonLink'
import BasicInfo from './Sections/BasicInfo/BasicInfo'
import ImportSettings from './Sections/ImportSettings/ImportSettings.js'
import Spinner from 'components/Spinner/Spinner.js'
import queryString from 'qs'
import {
  Advanced,
  Integrations,
  UserAccess,
  Notifications,
} from 'components/Settings/Settings.js'
import createHelpers from './Helpers/CreateHelpers.js'
import './Create.scss'
import config from 'envs/config'
import { generate as shortid } from 'shortid'

export default class Create extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // form
      cluster: {
        cluster_name: '',
        tagguid: '',
        iam_role_arn: '',
        image: '',
        airflow_version: '2.2.5-python38',
        ae_compute_integration: false,
        ae_data_access_ad_group: '',
        sandbox: false,
        nsp_sources: false,
        members: [
          {
            tmpId: shortid(),
            role: 'admin',
            type: 'ad_group',
            ad_group: '',
            sub: '',
          },
        ],
        notifications: [
          {
            tmpId: shortid(),
            service: 'email',
            contact: this.props.user.email,
            event_type: 'cluster.restart.failure',
            meta_data: '',
          },
        ],
        labels: [],
        configs: {},
      },
      emptyCluster: {
        cluster_name: '',
        tagguid: '',
        iam_role_arn: '',
        image: '',
        airflow_version: '2.2.5-python38',
        ae_compute_integration: false,
        ae_data_access_ad_group: '',
        sandbox: false,
        nsp_sources: false,
        members: [
          {
            tmpId: shortid(),
            role: 'admin',
            type: 'ad_group',
            ad_group: '',
            sub: '',
          },
        ],
        notifications: [
          {
            tmpId: shortid(),
            service: 'email',
            contact: this.props.user.email,
            event_type: 'cluster.restart.failure',
            meta_data: '',
          },
        ],
        labels: [
          {
            key: 'env',
            value: '',
          },
        ],
        configs: {},
      },

      // api
      pending: false,
      error: false,
      ae_groups: {
        role_id: 1,
        role: 'admin',
        ad_group: 'Application.Test.Users',
        sub: '',
      },
      // client side validation
      validation: {
        labels: 'All labels must have both a key and a value',
      },

      // importSettings stuff
      importClusters: [],
      basicLoading: false,
      advancedLoading: false,
      integrationsLoading: false,
      userAccessLoading: false,
      selectRows: [],
      importSelected: {
        label: '-',
        value: 'none',
        object: {
          cluster_name: '',
          tagguid: '',
          image: '',
          airflow_version: '2.2.5-python38',
          ae_compute_integration: false,
          ae_data_access_ad_group: '',
          sandbox: false,
          nsp_sources: false,
          members: [
            {
              tmpId: shortid(),
              role: 'admin',
              type: 'ad_group',
              ad_group: '',
              sub: '',
            },
          ],
          notifications: [
            {
              tmpId: shortid(),
              service: 'email',
              contact: this.props.user.email,
              event_type: 'cluster.restart.failure',
              meta_data: '',
            },
          ],
          labels: [
            {
              key: 'env',
              value: '',
            },
          ],
          configs: {},
        },
      },
    }
  }

  componentDidMount() {
    this.fetchClusters()
  }

  // Remove empty (optional) values before sending to the API
  withoutEmpty = cluster =>
    Object.keys(cluster)
      .filter(i => cluster[i] !== '')
      .reduce(
        (obj, i) => ({
          ...obj,
          [i]: this.state.cluster[i],
        }),
        {}
      )

  createCluster = () =>
    this.setState(
      { pending: true, error: false },
      async () =>
        await createCluster(
          this.props.region.api,
          this.withoutEmpty(this.state.cluster)
        )
          .then(data =>
            this.props.history.push(
              `${config.siteRoot}${this.props.region.value}/clusters/${data.cluster_name}`
            )
          )
          .catch(err =>
            this.setState({
              error: err.map(item => item.message),
              pending: false,
            })
          )
    )

  invalid = () => {
    const { cluster, validation } = this.state

    const clusterNameLongEnough = cluster.cluster_name.length === 0
    const validationClusterNameExists = validation.cluster_name !== undefined
    const tagguidLongEnough = cluster.tagguid.length === 0
    const validationTagguidExists = validation.tagguid !== undefined
    const validationLabelsExist =
      validation.labels !== undefined && validation.labels !== false
    const validationIamRoleExists = !!validation.iam_role_arn
    const validationImageExists = validation.image !== undefined
    const validationMembersLongEnough =
      (validation.members || []).filter(Boolean).length > 0
    const validationNotificationsLongEnough =
      validation.notifications !== undefined &&
      (validation.notifications.map(({ contact }) => contact).filter(Boolean)
        .length > 0 ||
        validation.notifications
          .map(({ meta_data }) => meta_data)
          .filter(Boolean).length > 0)
    const membersHasNoAdmin = !cluster.members.find(m => m.role === 'admin')
    const notificationsHasValidWorkerNotification =
      !notifValidation.workerNotificationExists(
        this.state.cluster.notifications
      )
    const aeGroupHasAdGroup = !!(
      cluster.ae_compute_integration &&
      cluster.ae_data_access_ad_group.length === 0
    )
    const aeGroupHasEnvVar = !!(
      cluster.ae_compute_integration &&
      (!cluster.configs.ae_compute ||
        !cluster.configs.ae_compute.find(c => c.key === 'env' && c.value))
    )

    // Since we dont want to show validation errors until the user...
    // has modified the field we need to also check that the field isnt empty.
    return (
      clusterNameLongEnough ||
      validationClusterNameExists ||
      tagguidLongEnough ||
      validationTagguidExists ||
      validationLabelsExist || // optional field; no min length
      validationIamRoleExists || // optional field; no min length
      validationImageExists || // optional field; no min length
      validationMembersLongEnough ||
      validationNotificationsLongEnough ||
      // cluster must have an admin AD role
      membersHasNoAdmin ||
      // Cluster must have a worker node notification
      notificationsHasValidWorkerNotification ||
      // Config must be populated if AE integration enabled
      aeGroupHasAdGroup ||
      aeGroupHasEnvVar
    )
  }

  isLoading = () => {
    return (
      this.state.basicLoading ||
      this.state.advancedLoading ||
      this.state.integrationsLoading ||
      this.state.userAccessLoading
    )
  }

  fetchClusters = () =>
    getClusters(this.props.region.api)
      .then(data => {
        const importClusters = createHelpers.sanitizeClustersData(data)
        const parsed = queryString.parse(window.location.search).import
        const generatedRows = createHelpers.generateSelectRows(
          data,
          this.state.emptyCluster
        )
        let filteredIndex = null
        let selected = this.state.importSelected

        if (parsed) {
          filteredIndex = generatedRows.findIndex(item => item.value === parsed)
          selected = generatedRows[filteredIndex]
        }

        this.setState({
          importClusters: importClusters,
          selectRows: generatedRows,
          cluster: selected ? selected.object : this.state.emptyCluster,
          importSelected: selected,
        })
      })
      .catch(err => console.log(err))

  render() {
    let { cluster, validation, pending, error, ae_groups } = this.state
    let { user, region } = this.props

    return (
      <div className='alt-page-wrapper create-wrapper'>
        <div className='create-sidebar'>
          <h3 className='create-sidebar-title'>PROVISION AIRFLOW CLUSTER</h3>
          <p className='create-sidebar-subtitle'>
            Create a new cluster by selecting your Airflow version with your
            desired configuration.
            <br />
            <br />
            New clusters are generally ready for use within 10 minutes, but can
            sometimes take up to 30 minutes.
            <br />
            <br />
          </p>

          {error && <FormError error={error} />}
          {this.isLoading() && (
            <div className='loading-spinner'>
              <h4>Populating...</h4>
              <span>
                Pulling select dropdown values from database. Please wait a
                moment.
              </span>
              <br />
              <br />
              <Spinner large center />
            </div>
          )}
        </div>

        <Form className='alt-scroll create-main'>
          {this.state.importClusters.length > 0 && (
            <ImportSettings
              clusters={this.state.importClusters}
              disabled={this.isLoading()}
              region={region}
              emptyCluster={this.state.emptyCluster}
              importSelected={this.state.importSelected}
              selectRows={this.state.selectRows}
              updateImportSelected={opt => {
                opt.object.iam_role_arn = ''
                this.setState({
                  importSelected: opt,
                  cluster: opt.object,
                })
              }}
            />
          )}

          <BasicInfo
            cluster_name={cluster.cluster_name}
            tagguid={cluster.tagguid}
            labels={cluster.labels}
            sandbox={cluster.sandbox}
            nsp_sources={cluster.nsp_sources}
            onChange={(key, value, valueValidation) =>
              this.setState({
                cluster: { ...cluster, [key]: value },
                validation: { ...validation, [key]: valueValidation },
              })
            }
            validation={validation}
            region={region}
            disabled={this.isLoading()}
            setBasicLoading={loading => {
              this.setState({
                basicLoading: loading,
              })
            }}
          />

          <Advanced
            ae_compute_integration={cluster.ae_compute_integration}
            image={cluster.image}
            airflow_version={cluster.airflow_version}
            onChange={(key, value, valueValidation) => {
              this.setState({
                cluster: { ...cluster, [key]: value },
                validation: { ...validation, [key]: valueValidation },
              })
              return value
            }}
            validation={validation}
            region={region}
            // clusterToImport={this.state.clusterToImport}
            disabled={this.isLoading()}
            setAdvancedLoading={loading => {
              this.setState({
                advancedLoading: loading,
              })
            }}
          />

          <Integrations
            ae_data_access_ad_group={cluster.ae_data_access_ad_group}
            cluster={cluster}
            onChange={updatedCluster =>
              this.setState({
                cluster: { ...cluster, ...updatedCluster },
              })
            }
            region={region}
            user={user}
            disabled={this.isLoading()}
            setIntegrationsLoading={loading => {
              this.setState({
                integrationsLoading: loading,
              })
            }}
          />

          <Notifications
            notifications={cluster.notifications}
            ae_data_access_ad_group={cluster.ae_data_access_ad_group}
            onChange={(value, valueValidation) =>
              this.setState({
                cluster: { ...cluster, notifications: value },
                validation: { ...validation, notifications: valueValidation },
              })
            }
            validation={validation.notifications}
            disabled={this.isLoading()}
            region={region}
            cluster={cluster}
            isCreate={true}
          />

          <UserAccess
            ae_groups={ae_groups}
            ae_compute_integration={cluster.ae_compute_integration}
            ae_data_access_ad_group={cluster.ae_data_access_ad_group}
            members={cluster.members}
            onChange={(value, valueValidation) =>
              this.setState({
                cluster: { ...cluster, members: value },
                validation: { ...validation, members: valueValidation },
              })
            }
            validation={validation.members}
            region={region}
            user={user}
            disabled={this.isLoading()}
            setUserAccessLoading={loading => {
              this.setState({
                userAccessLoading: loading,
              })
            }}
          />

          <div className='create-form-section'>
            <div className='create-form-section-inner'>
              <div className='create-form-section-buttons'>
                <Button
                  primary
                  borderless
                  onClick={this.createCluster}
                  disabled={pending || this.invalid()}
                  pending={pending}
                >
                  CREATE CLUSTER
                </Button>
                <ButtonLink borderless href={'/'} disabled={pending}>
                  CANCEL
                </ButtonLink>
              </div>
            </div>
          </div>
        </Form>
      </div>
    )
  }
}