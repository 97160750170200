/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import './DeprecationBanner.scss'

export default ({ cluster }) => {
  const unsupported = require('unsupported-versions.json')

  let isSupported = true

  unsupported['unsupported-airflow-versions'].forEach(version => {
    if (cluster?.airflow_version?.includes(version)) {
      isSupported = false
    }
  })

  if (isSupported) return null

  return (
    <div className="deprecation-banner">
      <div className="deprecation-banner-inner">
        <h3 className="deprecation-banner-text">
          <i className="fa fa-exclamation-triangle"></i> Your cluster is
          non-compliant with MAP's supported Airflow version list.{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.platforms.nike.com/managed-airflow-platform/versioning/supported-version-list"
          >
            Please update immediately.
          </a>
        </h3>
      </div>
    </div>
  )
}
