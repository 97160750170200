import React, { useState } from 'react'
import './RebootBanner.scss'

export default ({ cluster }) => {
  const [open, setOpen] = useState(true)
  if (!cluster.reboot_required) return null
  if (!open) return null

  return (
    <div className="reboot-banner">
      <div className="reboot-banner-inner">
        <h3 className="reboot-banner-text">
          <i className="fa fa-exclamation-triangle"></i> Changes have been made
          to this cluster which require a restart.
        </h3>
        <i className="fa fa-times-circle" onClick={() => setOpen(false)}></i>
      </div>
    </div>
  )
}
