import { generate as shortid } from 'shortid'

const generateSelectRows = function generateSelectRows(data, emptyCluster) {
  let rows = []

  const sanitizaed = this.sanitizeClustersData(data)

  rows.push({
    label: '-',
    value: 'none',
    object: emptyCluster,
  })

  sanitizaed.forEach(item => {
    rows.push({
      label: item.cluster_name,
      value: item.cluster_name,
      object: {
        ...item,
        cluster_name: this.adjustClusterName(item.cluster_name),
      },
    })
  })

  return rows
}

const adjustClusterName = function adjustClusterName(name) {
  let clusterName = name

  if (clusterName.length > 15) {
    clusterName = clusterName.replace(/-/g, '')
    clusterName = clusterName.slice(0, 5)
  }

  clusterName += '-copy'
  return clusterName
}

const sanitizeClustersData = function sanitizeClustersData(data) {
  let sanitizedData = []

  data.forEach(item => {
    let rowItem = {}

    rowItem = {
      cluster_id: item.cluster_id ? item.cluster_id : '',
      cluster_name: item.cluster_name ? item.cluster_name : '',
      tagguid: item.tagguid ? item.tagguid : '',
      iam_role_arn: '',
      image: item.image ? item.image : '',
      airflow_version: item.airflow_version ? item.airflow_version : '',
      ae_compute_integration: item.ae_compute_integration
        ? item.ae_compute_integration
        : false,
      ae_data_access_ad_group: item.ae_data_access_ad_group
        ? item.ae_data_access_ad_group
        : '',
      sandbox: item.sandbox ? item.sandbox : false,
      nsp_sources: item.nsp_sources ? item.nsp_sources : false,
      members:
        item.members.length > 0
          ? item.members.map(member => {
              return {
                tmpId: shortid(),
                role: member.role ? member.role : 'admin',
                sub: member.sub ? member.sub : '',
                ad_group: member.ad_group ? member.ad_group : '',
                type: member.sub ? 'sub' : 'ad_group',
              }
            })
          : [
              {
                tmpId: shortid(),
                role: 'admin',
                type: 'ad_group',
                ad_group: '',
                sub: '',
              },
            ],
      notifications:
        item.notifications.length > 0
          ? item.notifications.map(notif => {
              return {
                contact: notif.contact ? notif.contact : '',
                event_type: notif.event_type ? notif.event_type : '',
                meta_data: notif.meta_data ? notif.meta_data : '',
                service: notif.service ? notif.service : 'email',
                tmpId: shortid(),
              }
            })
          : [
              {
                tmpId: shortid(),
                service: 'email',
                contact: 'user@nike.com',
                event_type: 'cluster.restart.failure',
                meta_data: '',
              },
            ],
      labels: item.labels.length > 0 ? item.labels : [],
      configs: item.configs ? item.configs : {},
    }

    sanitizedData.push(rowItem)
  })

  return sanitizedData
}

const exportFunctions = {
  generateSelectRows,
  sanitizeClustersData,
  adjustClusterName,
}

export default exportFunctions
