exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".epic-font-base___2xbd4,\n.label___2GO5U .labelWrapper___3fLoi .labelText___2rmrO,\n.label___2GO5U .labelWrapper___3fLoi .errorText___n1ilM {\n  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-base-md___2ysrb {\n  font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif;\n}\n.epic-font-marketing___3X967 {\n  font-family: 'Futura', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-brand___21x8b {\n  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.label___2GO5U {\n  width: 100%;\n  height: 100%;\n  display: inline-block;\n}\n.label___2GO5U .labelWrapper___3fLoi {\n  display: flex;\n  justify-content: space-between;\n}\n.label___2GO5U .labelWrapper___3fLoi .labelText___2rmrO {\n  display: block;\n  font-size: 12px;\n  color: #6d6d6d;\n  margin-left: 12px;\n  margin-bottom: 2px;\n}\n.label___2GO5U .labelWrapper___3fLoi .errorText___n1ilM {\n  font-size: 12px;\n  text-align: right;\n  color: #fe0000;\n  min-width: 100px;\n}\n.required___1Rx1r {\n  color: #fe0000;\n}\n", ""]);

// Exports
exports.locals = {
	"epic-font-base": "epic-font-base___2xbd4",
	"label": "label___2GO5U",
	"labelWrapper": "labelWrapper___3fLoi",
	"labelText": "labelText___2rmrO",
	"errorText": "errorText___n1ilM",
	"epic-font-base-md": "epic-font-base-md___2ysrb",
	"epic-font-marketing": "epic-font-marketing___3X967",
	"epic-font-brand": "epic-font-brand___21x8b",
	"required": "required___1Rx1r"
};