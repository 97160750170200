import { http, digResponse, getRequestError } from 'util/index.js'

const encode = encodeURIComponent

export const getOperators = (api, clusterId, pageSize, page, search_critera) =>
  http
    .get(
      `${api}/clusters/${encode(
        clusterId
      )}/operators?limit=${pageSize}&cursor=${page}&search=${search_critera}`
    )
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'getOperators')
    })

export const getOperator = (api, clusterId, opId) =>
  http
    .get(`${api}/clusters/${encode(clusterId)}/operators/${encode(opId)}`)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'getOperator')
    })

export const uploadOperator = (api, clusterId, fileName, body) =>
  http
    .put(
      `${api}/clusters/${encode(clusterId)}/operators/${encode(fileName)}`,
      body
    )
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'uploadOperator')
    })

export const deleteOperator = (api, clusterId, fileName) =>
  http
    .delete(
      `${api}/clusters/${encode(clusterId)}/operators/${encode(fileName)}`
    )
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'deleteOperator')
    })
