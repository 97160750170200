import React, { Component } from 'react'
import './ChartLegend.scss'

export default class ChartLegend extends Component {
  render() {
    let { name, items, selected, onSelect, colorFunc } = this.props

    return (
      <div className="chart-legend">
        <div className="chart-legend-header">
          <p className="chart-legend-title">{name}</p>
        </div>

        <div className="chart-legend-body">
          {items &&
            items.sort().map(item => (
              <p
                key={item}
                className={`chart-legend-item${
                  item === selected ? ' item-selected' : ''
                }`}
                onClick={() => onSelect(item === selected ? false : item)}
              >
                <span
                  className="chart-legend-item-dot"
                  style={{ backgroundColor: colorFunc(item).area }}
                ></span>
                {item}
              </p>
            ))}
        </div>
      </div>
    )
  }
}
