import React, { Component } from 'react'
import ChartSingle from './Charts/ChartSingle.js'
import Table from 'components/Table/Table.js'
import './Metrics.scss'

const dagCols = [
  {
    name: 'DAG ID',
    key: 'dag_id',
    maxWidth: 180,
  },
  {
    name: 'LOAD DURATION',
    key: 'loadDuration',
  },
  {
    name: 'SCHEDULE DELAY',
    key: 'scheduleDelay',
  },
  {
    name: 'DP CHECK DURATION',
    key: 'dependencyCheckDuration',
  },
  {
    name: 'SUCCESS DURATION',
    key: 'successDuration',
  },
  {
    name: 'FAILURE DURATION',
    key: 'failureDuration',
  },
]

const taskCols = [
  {
    name: 'TASK ID',
    key: 'task_id',
    maxWidth: 180,
  },
  {
    name: 'TASK DURATION',
    key: 'taskRunDuration',
  },
]

const handleMatch = (obj, group, key, ts, val) => {
  // obj pass by ref like all js objects
  obj[group] = obj[group] || {}
  obj[group][key] = obj[group][key] || []
  obj[group][key].push({ ts, val })
}

const processPodMetrics = data => {
  let metrics = {}

  data.forEach(m => {
    let match = false
    let ts = new Date(m['@timestamp']).getTime()

    switch (true) {
      case !!(match = m.name.match(/^airflow\.dag\.loading-duration\.(.+)/)):
        handleMatch(metrics, match[1], 'loadDuration', ts, m.val)
        break
      case !!(match = m.name.match(/^airflow\.dagrun\.dependency-check\.(.+)/)):
        handleMatch(metrics, match[1], 'dependencyCheckDuration', ts, m.val)
        break
      case !!(match = m.name.match(
        /^airflow\.dagrun\.duration\.success\.(.+)/
      )):
        handleMatch(metrics, match[1], 'successDuration', ts, m.val)
        break
      case !!(match = m.name.match(/^airflow\.dagrun\.duration\.failed\.(.+)/)):
        handleMatch(metrics, match[1], 'failedDuration', ts, m.val)
        break
      case !!(match = m.name.match(/^airflow\.dagrun\.schedule_delay\.(.+)/)):
        handleMatch(metrics, match[1], 'scheduleDelay', ts, m.val)
        break
      case !!(match = m.name.match(/^airflow\.dag\.(.+)\.(.+).duration/)):
        metrics[match[1]] = metrics[match[1]] || {}
        metrics[match[1]].tasks = metrics[match[1]].tasks || {}
        metrics[match[1]].tasks[match[2]] =
          metrics[match[1]].tasks[match[2]] || {}
        metrics[match[1]].tasks[match[2]].taskRun =
          metrics[match[1]].tasks[match[2]].taskRun || []
        metrics[match[1]].tasks[match[2]].taskRun.push({ ts, val: m.val })
        break
      default:
        break
    }
  })

  Object.keys(metrics).forEach(groupItemKey => {
    Object.keys(metrics[groupItemKey]).forEach(metricKey => {
      if (metricKey === 'tasks') {
        Object.keys(metrics[groupItemKey][metricKey]).forEach(taskKey => {
          Object.keys(metrics[groupItemKey][metricKey][taskKey]).forEach(
            taskMetricKey => {
              metrics[groupItemKey][metricKey][taskKey][
                taskMetricKey
              ] = metrics[groupItemKey][metricKey][taskKey][taskMetricKey].sort(
                (a, b) => a.ts - b.ts
              )
            }
          )
        })
      } else {
        metrics[groupItemKey][metricKey] = metrics[groupItemKey][
          metricKey
        ].sort((a, b) => a.ts - b.ts)
      }
    })
  })

  // LEAVING THIS IN FOR DEBUG
  console.log(metrics)
  return metrics
}

export default class DagMetrics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      processedMetrics: false,
    }
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      processedMetrics:
        nextProps.metrics && processPodMetrics(nextProps.metrics),
    }
  }

  render() {
    let { processedMetrics } = this.state
    let { metricsLoading } = this.props

    let dags = Object.keys((processedMetrics && processedMetrics) || []).map(
      dagKey => ({
        dag_id: dagKey,
        loadDuration: (
          <div className="table-chart-wrapper">
            <ChartSingle
              xKey="ts"
              yKey="val"
              data={processedMetrics[dagKey]['loadDuration']}
              margin={{ top: 10, bottom: 0, left: 0, right: 0 }}
              {...this.props}
            />
          </div>
        ),
        scheduleDelay: (
          <div className="table-chart-wrapper">
            <ChartSingle
              xKey="ts"
              yKey="val"
              data={processedMetrics[dagKey]['scheduleDelay']}
              margin={{ top: 10, bottom: 0, left: 0, right: 0 }}
              {...this.props}
            />
          </div>
        ),
        dependencyCheckDuration: (
          <div className="table-chart-wrapper">
            <ChartSingle
              xKey="ts"
              yKey="val"
              data={processedMetrics[dagKey]['dependencyCheckDuration']}
              margin={{ top: 10, bottom: 0, left: 0, right: 0 }}
              {...this.props}
            />
          </div>
        ),
        successDuration: (
          <div className="table-chart-wrapper">
            <ChartSingle
              xKey="ts"
              yKey="val"
              data={processedMetrics[dagKey]['successDuration']}
              margin={{ top: 10, bottom: 0, left: 0, right: 0 }}
              {...this.props}
            />
          </div>
        ),
        failureDuration: (
          <div className="table-chart-wrapper">
            <ChartSingle
              xKey="ts"
              yKey="val"
              data={processedMetrics[dagKey]['failureDuration']}
              margin={{ top: 10, bottom: 0, left: 0, right: 0 }}
              {...this.props}
            />
          </div>
        ),
        tasks: processedMetrics[dagKey].tasks,
      })
    )

    return (
      <div className="metrics-main metrics-table">
        <Table
          rows={dags}
          cols={dagCols}
          loading={metricsLoading}
          getTrProps={(_, rowInfo) => {
            if (
              rowInfo &&
              rowInfo.original &&
              rowInfo.original.tasks &&
              Object.keys(rowInfo.original.tasks).length > 0
            )
              return {}

            return {
              className: {
                '-expander-disabled': true,
              },
            }
          }}
          SubComponent={({ original }) => {
            let tasks = Object.keys(original.tasks || {}).map(taskKey => ({
              task_id: taskKey,
              taskRunDuration: (
                <div className="table-chart-wrapper">
                  <ChartSingle
                    xKey="ts"
                    yKey="val"
                    data={original.tasks[taskKey].taskRun}
                    margin={{ top: 10, bottom: 0, left: 0, right: 0 }}
                    {...this.props}
                  />
                </div>
              ),
            }))

            console.log('tasks: ', tasks)
            console.log('taskCols: ', taskCols)

            return (
              <div
                className="metrics-sub-table"
                // Header height * (row count * row height)
                style={{ height: 25 + tasks.length * 33 }}
              >
                <Table rows={tasks} cols={taskCols} />
              </div>
            )
          }}
        />
      </div>
    )
  }
}
