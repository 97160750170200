import React from 'react'
import './ExcludeDags.scss'
import CreatableSelect from 'components/CreatableSelect/CreatableSelect.js'

export const ExcludeDags = ({
  index,
  notification,
  validation,
  updateCallback,
  disabled,
  dags,
}) => {
  return (
    <div id='notification-exclude-dags'>
      <div className='notification-config-row' key={index}>
        <div className='notification-config-exclude-dags'>
          <CreatableSelect
            isMulti
            id='excludeDagsInput'
            label='EXCLUDE DAGS'
            options={dags}
            isOptionDisabled={option =>
              notification?.included_dags.some(row =>
                row.dag_ids.includes(option.value)
              )
            }
            value={
              typeof notification.excluded_dags === 'string'
                ? notification.excluded_dags
                    ?.split(',')
                    ?.map(dag => ({ value: dag?.trim(), label: dag?.trim() }))
                : notification.excluded_dags?.map(dag => ({
                    value: dag,
                    label: dag,
                  }))
            }
            onChange={opt => updateCallback('excluded_dags', opt)}
            disabled={disabled}
            validation={validation}
          />
        </div>
      </div>
    </div>
  )
}

export default ExcludeDags
