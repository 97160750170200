import React from 'react'
import TextInput from 'components/TextInput/TextInput.js'
import helper from '../../Config/NotificationsHelper.js'
import './PagerDutyNotification.scss'

export default ({ notification, updateCallback, disabled }) => {
  helper.managePagerDutyAttribute(notification)
  if (notification.service && notification.service !== 'pagerduty') {
    return null
  }

  return (
    <div id="notification-pager-duty">
      <div className="notification-config-row">
        <div className="notification-config-pd-service-key">
          <TextInput
            id="pagerdutyServiceKeyInput"
            required
            label="PAGER DUTY SERVICE KEY"
            disabled
            value={notification.contact}
          />
        </div>

        <div className="notification-config-pd-client-name">
          <TextInput
            id="pagerDutyClientName"
            required
            label="PAGER DUTY CLIENT NAME"
            value={notification.pagerduty.client_name}
            onChange={opt => updateCallback('pagerduty_client_name', opt)}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  )
}
