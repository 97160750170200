import React, { useState } from 'react'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import 'moment-timezone'
import SelectInput from 'components/SelectInput/SelectInput.js'
import './DateRange.scss'

const tzOpts = moment.tz.names().map(tz => ({
  label: tz,
  value: tz,
}))

export default ({ className, startDate, endDate, onDatesChange, disabled }) => {
  const [focusedInput, setFocusedInput] = useState(null)
  const [time, setTime] = useState({
    startDate: {
      hours: 0,
      minutes: 0,
    },
    endDate: {
      hours: 0,
      minutes: 0,
    },
  })
  const [tz, setTz] = useState(moment.tz.guess())

  let classes = ['alt-date-range']
  if (disabled) {
    classes.push('alt-date-range-disabled')
  }
  if (className) {
    classes.push(className)
  }

  const handleTzChange = tz => {
    setTz(tz)
    moment.tz.setDefault(tz)

    if (startDate) {
      startDate = startDate
        .clone() // avoid mutability issues
        .tz(tz)
    }
    if (endDate) {
      endDate = endDate
        .clone() // avoid mutability issues
        .tz(tz)
    }
    onDatesChange({ startDate, endDate })
  }

  const handleTimeChange = (focusedInput, hours, minutes) => {
    setTime({
      ...time,
      [focusedInput]: {
        hours,
        minutes,
      },
    })

    if (focusedInput === 'startDate' && startDate) {
      startDate = startDate
        .clone() // avoid mutability issues
        .set('hour', hours)
        .set('minute', minutes)
        .set('second', 0)
        .tz(tz)
      onDatesChange({ startDate, endDate })
    }

    if (focusedInput === 'endDate' && endDate) {
      endDate = endDate
        .clone() // avoid mutability issues
        .set('hour', hours)
        .set('minute', minutes)
        .set('second', 0)
        .tz(tz)
      onDatesChange({ startDate, endDate })
    }
  }

  const timeSelector = () => (
    <div className="time-sidebar">
      <SelectInput
        className="tz-selector"
        options={tzOpts}
        value={{ label: tz, value: tz }}
        onChange={opt => handleTzChange(opt.value)}
        disabled={disabled}
      />
      <div className="time-selector-wrapper">
        <div className="time-selector-abs">
          <div className="time-selector alt-scroll">
            {[...Array(24)].map((_, i) => [
              <p
                key={i}
                className={`time-selector-time${time[focusedInput].hours === i &&
                  time[focusedInput].minutes === 0
                  ? ' time-active'
                  : ''
                  }`}
                onClick={() => handleTimeChange(focusedInput, i, 0)}
              >
                {i.toString().padStart(2, '0')}:00
              </p>,
              <p
                key={`${i}-half`}
                className={`time-selector-time${time[focusedInput].hours === i &&
                  time[focusedInput].minutes === 30
                  ? ' time-active'
                  : ''
                  }`}
                onClick={() => handleTimeChange(focusedInput, i, 30)}
              >
                {i.toString().padStart(2, '0')}:30
              </p>,
            ])}
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className={classes.join(' ')}>
      <DateRangePicker
        startDate={startDate}
        startDateId="alt-start-date"
        startDatePlaceholderText="03/15/44BCE 12:00" // ides of march
        endDate={endDate}
        endDateId="alt-end-date"
        endDatePlaceholderText="NOW"
        displayFormat="DD/MM/YYYY HH:mm"
        onDatesChange={({ startDate, endDate }) => {
          if (startDate) {
            startDate = startDate
              .clone() // avoid mutability issues
              .set('hour', time.startDate.hours)
              .set('minute', time.startDate.minutes)
              .set('second', 0)
              .tz(tz)
          }
          if (endDate) {
            endDate = endDate
              .clone() // avoid mutability issues
              .set('hour', time.endDate.hours)
              .set('minute', time.endDate.minutes)
              .set('second', 0)
              .tz(tz)
          }
          onDatesChange({ startDate, endDate })
        }}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        numberOfMonths={1}
        minimumNights={0}
        horizontalMonthPadding={0}
        verticalSpacing={0}
        maxDate={moment()}
        isOutsideRange={day => moment().diff(day) < 0}
        calendarInfoPosition="after"
        renderCalendarInfo={timeSelector}
        orientation="horizontal"
        anchorDirection="right"
        openDirection="down"
        hideKeyboardShortcutsPanel
        noBorder
        disabled={disabled}
      />
    </div>
  )
}
