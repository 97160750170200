import React, { Component } from 'react'
import { getClusterConfig } from 'store/clusters.js'
import Spinner from 'components/AltSpinner/AltSpinner.js'
import './Dashboard.scss'
import Button from 'components/Button/Button.js'
import { Tooltip } from 'react-tooltip'

const tsOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  timeZoneName: 'short',
}

export default class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cfg: false,
      cfgLoading: true,
      cfgError: false,
      openSections: [],
      copiedToClipboard: false,
    }
  }

  componentDidMount() {
    this.fetchCfg()
  }

  fetchCfg = () =>
    getClusterConfig(this.props.region.api, this.props.cluster.cluster_id)
      .then(data =>
        this.setState({ cfg: data, cfgLoading: false, cfgError: false })
      )
      .catch(err =>
        this.setState({
          cfgLoading: false,
          cfgError: err.map(item => item.message),
        })
      )

  toggleOpenSection = section =>
    this.setState({
      openSections: this.state.openSections.includes(section)
        ? this.state.openSections.filter(k => k !== section)
        : [section, ...this.state.openSections],
    })

  copyToClipboard = text => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        this.setState({ copiedToClipboard: true })
        setTimeout(() => {
          this.setState({ copiedToClipboard: false })
        }, 2000);
      })
      .catch(err => console.error('Could not copy to clipboard: ', err))
  };

  render() {
    let { cfg, cfgLoading, cfgError, openSections, copiedToClipboard } = this.state
    let { cluster, region } = this.props
    let configLink = `${cluster.cluster_name}/config`
    let configContent = false

    if (cfgLoading) {
      configContent = <Spinner />
    }

    if (cfgError && !configContent) {
      const listErrors = cfgError.map((item, index) => {
        return (
          <p key={index}>
            <i className="fa fa-exclamation-triangle"></i> {item}
          </p>
        )
      })

      configContent = <div className="dash-error">{listErrors}</div>
    }

    // Render States
    let provisionContent = [
      <div className="col-line" key="life_cycle">
        <p className="col-line-title">Life Cycle</p>
        <p className="col-line-detail">{cluster.life_cycle}</p>
      </div>,
      <div className="col-line" key="cluster_id">
        <p className="col-line-title">Cluster ID</p>
        <div className="cluster-id-container">
          <p className="col-line-detail">{cluster.cluster_id}</p>
          <Button
            className="copy-to-clipboard-button"
            borderless={false}
            dataTooltipId="copy-to-clipboard"
            dataTooltipContent={copiedToClipboard ? 'Copied!' : 'Copy To Clipboard'}
            onClick={() => this.copyToClipboard(cluster.cluster_id)}
          >
            <Tooltip id="copy-to-clipboard" />
            <i className="fa fa-copy" />
          </Button>
        </div>
      </div>,
      <div className="col-line" key="tagguid">
        <p className="col-line-title">Cloud Red Tag GUID</p>
        <p className="col-line-detail">{cluster.tagguid}</p>
      </div>,
      <div className="col-line" key="created_at">
        <p className="col-line-title">Created</p>
        <p className="col-line-detail">
          {new Date(cluster.created_date).toLocaleString('sv', tsOptions)}
        </p>
      </div>,
      <div className="col-line" key="updated_at">
        <p className="col-line-title">Updated</p>
        <p className="col-line-detail">
          {new Date(cluster.updated_date).toLocaleString('sv', tsOptions)}
        </p>
      </div>,
      <div className="col-line" key="region">
        <p className="col-line-title">Region</p>
        <p className="col-line-detail">{region.value}</p>
      </div>,
      <div className="col-line" key="airflow_version">
        <p className="col-line-title">Airflow Version</p>
        <p className="col-line-detail">{cluster.airflow_version}</p>
      </div>,
    ]
    cluster.labels.forEach((label, i) =>
      provisionContent.push(
        <div className="col-line" key={`label-${i}`}>
          <p className="col-line-title">label::{label.key}</p>
          <p className="col-line-detail">{label.value}</p>
        </div>
      )
    )
    if (!configContent) {
      configContent = Object.keys(cfg).map(
        section =>
          Array.isArray(cfg[section]) && (
            <div
              className="col-line col-line-expandable"
              key={section}
              onClick={() => this.toggleOpenSection(section)}
            >
              <p className="col-line-title">
                <i
                  className={`fa fa-chevron-${openSections.includes(section) ? 'down' : 'right'
                    }`}
                ></i>{' '}
                {section.replace(/_/g, ' ')}
              </p>

              {openSections.includes(section) && (
                <table onClick={e => e.stopPropagation()}>
                  <tbody>
                    {cfg[section].map(item => (
                      <tr key={item.key}>
                        <td>{item.key}</td>
                        <td>{item.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )
      )
    }

    return (
      <div className="dash-main overview alt-scroll">
        <div className="dash-info">
          <div className="dash-col dash-provision-col">
            <div className="col-title">PROVISION DETAILS</div>
            {provisionContent}
          </div>

          <div className="dash-col dash-config-col">
            <p className="col-title">CONFIG DETAILS</p>
            <a className="col-subtitle" href={configLink}>
              Edit <i className="fa fa-long-arrow-right"></i>
            </a>
            {configContent}
          </div>
        </div>
      </div>
    )
  }
}
