import React, { useState, useEffect } from 'react'
import { getConfigVersions } from 'store/config.js'
import UpgradeVersion from '../UpgradeVersion/UpgradeVersion.js'
import './UpgradeBanner.scss'

export default ({ region, cluster, updateCluster, version }) => {
  const [open, setOpen] = useState(true)
  const [newestVersion, setNewestVersion] = useState(false)
  const [upgradeOpen, setUpgradeOpen] = useState(false)

  useEffect(() => {
    getConfigVersions(region.api)
      .then(data =>
        setNewestVersion(
          data
            .filter(v => cluster.airflow_version === v.airflow_python_version)
            .sort((a, b) => b.config_version_id - a.config_version_id)[0]
        )
      )
      .catch(console.log)
  }, [])

  if (
    !open ||
    !version ||
    !newestVersion ||
    newestVersion.config_version_id <= version
  )
    return null

  return (
    <div className="upgrade-banner">
      <div className="upgrade-banner-inner">
        <h3 className="upgrade-banner-text">
          <i className="fa fa-exclamation-triangle"></i> This clusters base
          version is outdated. Upgrade to {newestVersion.description} by
          clicking <u onClick={() => setUpgradeOpen(true)}>here</u>.
        </h3>
        <i className="fa fa-times-circle" onClick={() => setOpen(false)}></i>
      </div>

      {upgradeOpen && (
        <UpgradeVersion
          region={region}
          cluster={cluster}
          version={newestVersion}
          onClose={() => {
            setOpen(false)
            setUpgradeOpen(false)
            updateCluster()
          }}
        />
      )}
    </div>
  )
}
