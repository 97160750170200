import React, { Component } from 'react'
import { logout } from 'Auth.js'
import config from 'envs/config'
import { Spinner } from 'components/index.js'

export default class Logout extends Component {
  componentDidMount() {
    logout()
    window.location.href = config.siteRoot
  }

  render() {
    return <Spinner large center />
  }
}
