import base from './base.env.js'

const prod =
{
  ...base,
  identifier: 'prod',
  entitlementsUrl: 'https://api.entitlements.nikecloud.com',
  ecr: '104636225068.dkr.ecr.us-west-2.amazonaws.com',
  awsAccountId: '104636225068',
  awsAccountIdChina: '478893428223',

  // OKTA
  oAuth: {
    client_id: "nike.niketech.map-ui",
    redirect_uri: window.location.origin,
    response_type: 'code',
    state: 'OR',
    scope: 'openid profile email offline_access',
    code_challenge_method: 'S256',
    authorization: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1',
  },

  // AIRFLOW URLS
  airflowAdmin: (region, cluster) =>
    `https://gateway.${region}.map.nike.com/${encodeURI(cluster)}/`,
  airflowProxy: (region, cluster) =>
    `https://proxy.${region}.map.nike.com/${encodeURI(cluster)}`,

  // EP CONSOLE
  consoleOrigin: 'https://console.platforms.nike.com',
  consoleSubroute: '/map/',

  // MONITORING
  apmEndpoint: 'https://berlioux.us-west-2.eap-mon-prod.nikecloud.com/map-us-west-2/apm',
  apmLabel: 'map-ui-prod',

  // MULTI REGION
  regions: [
    {
      value: 'us-west-2',
      api: 'https://api.us-west-2.map.nike.com/v1',
      label: 'US West (Oregon)',
    },
    {
      value: 'us-east-1',
      api: 'https://api.us-east-1.map.nike.com/v1',
      label: 'US East (N. Virginia)',
    },
    {
      value: 'eu-west-1',
      api: 'https://api.eu-west-1.map.nike.com/v1',
      label: 'EU (Ireland)',
    },
    {
      value: 'cn-northwest-1',
      api: 'https://api.cn-northwest-1.map.nike.com/v1',
      label: 'CN Northwest (Ningxia)',
    },
    {
      value: 'cn-north-1',
      api: 'https://api.cn-north-1.map.nike.com/v1',
      label: 'CN North (Beijing)',
    },
  ],
}

export default prod