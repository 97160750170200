import React, { Component } from 'react'
import SelectInput from 'components/SelectInput/SelectInput.js'
import Table from 'components/Table/Table.js'
import { getConfig } from 'store/config.js'
import './ImportSettings.scss'

export default class Create extends Component {
  constructor(props) {
    super(props)
    this.state = {
      extraAttributes: [],
      selectTouched: props.importSelected.value !== 'none' ? false : true,
      selectedConfig: {},
      configTableRows: [],
    }
  }

  async componentDidMount() {
    if (this.props.importSelected.value !== 'none') {
      await this.fetchCfg(this.props.importSelected.object.cluster_id)
    }
  }

  fetchCfg = clusterId =>
    getConfig(this.props.region.api, clusterId)
      .then(data => {
        const tableRows = this.generateConfigTableRows(data)
        this.setState({
          selectedConfig: data,
          configTableRows: tableRows,
        })
      })
      .catch(err => {
        console.log('error: ', err)
      })

  generateConfigTableRows = configData => {
    let nonDefaultAttributes = []

    Object.keys(configData).forEach(attribute => {
      if (configData[attribute].length > 0) {
        for (var item of configData[attribute]) {
          if (item.value !== item.default_value) {
            nonDefaultAttributes.push({
              setting: item.key,
              default: item.default_value !== null ? item.default_value : '-',
              value: item.value,
            })
          }
        }
      }
    })

    return nonDefaultAttributes
  }

  render() {
    return (
      <div className="create-form-section">
        <h3 className="create-form-section-title">IMPORT SETTINGS</h3>
        <div className="import-settings-section">
          <SelectInput
            label="CLUSTER TO IMPORT"
            detail="Interested in importing settings from a cluster you have already created? Pick a cluster to import settings from."
            options={this.props.selectRows}
            tooltipText="If you select a cluster from this list, the rest of the create page will populate with it's settings."
            value={this.props.importSelected}
            onChange={async opt => {
              this.props.updateImportSelected(opt)
              this.fetchCfg(opt.object.cluster_id)
            }}
            disabled={this.props.disabled}
          />
          <div className="extra-attribute-table">
            {this.state.configTableRows.length > 0 ? (
              <>
                <span className="default-message">
                  Non-Default Configurations on the chosen Cluster to Import:
                </span>
                <Table
                  wrapText="true"
                  minRowsOverride
                  rows={this.state.configTableRows}
                  cols={[
                    {
                      name: 'Setting',
                      key: 'setting',
                      maxWidth: 150,
                    },
                    {
                      name: 'MAP Default',
                      key: 'default',
                      maxWidth: 150,
                    },
                    {
                      name: 'Value',
                      key: 'value',
                    },
                  ]}
                />
              </>
            ) : (
              <>
                {this.props.importSelected.value === 'none' ? (
                  ''
                ) : (
                  <span className="default-message">
                    All Configurations are Default MAP Configurations.
                  </span>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}
