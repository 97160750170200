import React, { useState, useEffect, useRef } from 'react'
import './Button.scss'

const Button = ({
  className,
  danger,
  primary,
  borderless,
  disabled,
  pending,
  locked,
  onClick,
  children,
  dataTooltipId,
  dataTooltipContent,
  isDropdown = false,
  dropDownTitle = '',
}) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const clickRef = useRef(null)
  let classes = ['alt-button']
  if (primary) {
    classes.push('alt-button-primary')
  }
  if (borderless) {
    classes.push('alt-button-borderless')
  }
  if (disabled) {
    classes.push('alt-button-disabled')
  }
  if (locked) {
    classes.push('alt-button-locked')
  }
  if (pending) {
    classes.push('alt-button-pending')
  }
  if (danger) {
    classes.push('alt-button-danger')
  }
  if (isDropdown) {
    classes.push('alt-button-dropdown')
  }
  if (className) {
    classes.push(className)
  }

  const handleClickOutside = e => {
    if (clickRef.current && !clickRef.current.contains(e.target)) {
      setShowDropdown(false)
    }
  }

  useEffect(() => {
    if (isDropdown && showDropdown) {
      document.addEventListener('mouseup', handleClickOutside)
      return () => document.removeEventListener('mouseup', handleClickOutside)
    }
  }, [showDropdown])

  return (
    <button
      ref={clickRef}
      className={classes.join(' ')}
      onClick={e => {
        if (e) {
          e.preventDefault()
        }
        if (!disabled && !locked && !pending && isDropdown) {
          return setShowDropdown(!showDropdown)
        }
        !disabled && !locked && !pending && onClick && onClick(e)
      }}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
    >
      {isDropdown && (
        <div
          className={`dropdown-title ${
            showDropdown ? 'dropdown-open' : 'dropdown-closed'
          }`}
        >
          {dropDownTitle} <i className='fa fa-caret-down' />
        </div>
      )}
      {isDropdown && showDropdown && children}
      {!isDropdown && children}
      {locked && <i className='fa fa-lock alt-button-lock-icon' />}
      {pending && <div className='alt-button-pending-icon' />}
    </button>
  )
}

const DropdownContent = ({ children }) => (
  <div className='dropdown-content'>
    <div className='dropdown-content-body'>{children}</div>
  </div>
)

const DropdownItem = ({ children }) => (
  <div className='dropdown-item'>{children}</div>
)

Button.DropdownContent = DropdownContent
Button.DropdownItem = DropdownItem

export default Button
