import React, { Component } from 'react'
import { updateCluster } from 'store/clusters.js'
import Dialogue from 'components/Dialogue/Dialogue.js'
import './ApplyImage.scss'

export default class ApplyImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      started: false,
      completed: false,
      error: false,
    }
  }

  updateCluster = () =>
    this.setState(
      {
        started: true,
      },
      () => {
        updateCluster(this.props.region.api, this.props.cluster.cluster_id, {
          image: this.props.imageToBuild.value,
        })
          .then(() => this.setState({ completed: true, error: false }))
          .catch(err =>
            this.setState({
              completed: true,
              error: err.map(item => item.message),
            })
          )
      }
    )

  render() {
    let { onClose } = this.props
    let { started, completed, error } = this.state
    let confirmProps = {
      subtitle:
        'Successfully applied image to cluster, you can now restart your cluster to deploy the image.',
    }
    let title = 'IMAGE APPLIED.'

    // dont show the confirm action/text after completion
    if (!completed) {
      title = 'Apply Image?'
      confirmProps = {
        subtitle: (
          <div>
            <span>
              Are you sure you want to apply this image to your cluster? This
              action may break things and you will need to restart your cluster.
            </span>
            <div className="build-subtitle-info">
              <p>
                <b>Source: </b>
                {this.props.imageToBuild.label}
              </p>
              <p>
                <b>URL: </b>
                {this.props.imageToBuild.value}
              </p>
            </div>
          </div>
        ),
        onConfirm: this.updateCluster,
        confirmText: 'APPLY IMAGE',
        confirmDisabled: started,
      }
    }

    if (completed && error) {
      const listErrors = error.map((item, index) => {
        return <li key={index}>{item}</li>
      })

      title = 'Error Applying Image!'
      confirmProps = {
        subtitle: (
          <>
            <p>
              The following errors were encountered when applying this build:
            </p>
            <ul>{listErrors}</ul>
          </>
        ),
      }
    }

    return (
      <Dialogue
        title={title}
        onClose={onClose}
        closeText={started ? 'CLOSE' : 'CANCEL'}
        {...confirmProps}
      />
    )
  }
}
