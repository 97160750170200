export default function digResponse(obj, ...args) {
  let current = obj

  args.forEach(arg => {
    if (!current || !current.hasOwnProperty(arg)) {
      throw new Error(`Unknown API response ${obj}`)
    }
    current = current[arg]
  })

  return current
}
