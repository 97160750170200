import React from 'react'
import './Search.scss'

export default ({ value, onChange, placeholder }) => {
  return (
    <div className="alt-search">
      <i className="fa fa-search"></i>
      <input
        placeholder={placeholder || 'Search...'}
        onChange={onChange}
        value={value}
      />
    </div>
  )
}
