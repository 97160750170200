import React from 'react'
import ReactSelect from 'react-select'
import config from 'envs/config'
import './RegionSelect.scss'

export default ({ region }) => (
  <ReactSelect
    className="alt-region-select"
    classNamePrefix="region-select"
    options={config.regions}
    onChange={val =>
      region.value !== val.value &&
      window.location.assign(`${config.siteRoot}${val.value}/clusters`)
    }
    value={region}
  />
)
