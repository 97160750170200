import React from 'react'
import './ButtonLink.scss'

export default ({
  href,
  className,
  primary,
  borderless,
  disabled,
  target,
  download,
  children,
}) => {
  let classes = ['alt-button-link']
  if (primary) {
    classes.push('alt-button-link-primary')
  }
  if (borderless) {
    classes.push('alt-button-link-borderless')
  }
  if (disabled) {
    classes.push('alt-button-link-disabled')
  }
  if (className) {
    classes.push(className)
  }

  return (
    <a
      href={href}
      className={classes.join(' ')}
      onClick={e => e && disabled && e.preventDefault()}
      target={target}
      download={download}
    >
      {children}
    </a>
  )
}
