import axios from 'axios'

const http = axios.create({
  // baseURL: 'https://api.example.com'
})

http.interceptors.response.use(function (response) {
  if (response.status >= 200 && response.status <= 299) {
    return response
  }
  throw new Error(response.data.message || response.data.detail)
})

http.defaults.headers = { common: {} }

export function setGlobalHeader(name, value) {
  http.defaults.headers.common[name] = value
}

export function removeGlobalHeader(name) {
  delete http.defaults.headers.common[name]
  return
}

export default http
