import React, { Component } from 'react'
import { generate as shortid } from 'shortid'
import Button from 'components/Button/Button.js'
import './ConfigSection.scss'

export default class ConfigSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      passwordType: 'password',
    }
  }

  onFieldChange(key, value) {
    this.props.onChange({
      ...this.props.modified,
      fields: {
        ...this.props.modified.fields,
        [key]: value,
      },
    })
  }

  duplicateKey(key) {
    return (
      Object.keys(this.props.modified.fields).filter(
        li =>
          this.props.modified.fields[li].key ===
          this.props.modified.fields[key].key
      ).length > 1
    )
  }

  withoutKey(key) {
    delete this.props.modified.fields[key]
    return this.props.modified.fields
  }

  sanitizeKey(text) {
    return text
      .replace(/[ ]/gi, '_')
      .replace(/[^a-zA-Z0-9-_.]/gi, '')
      .substring(0, 34)
  }

  sanitizeValue(text) {
    return text
      .replace(/[ ]/gi, '_')
      .replace(/[^a-zA-Z0-9-_=.:/#@]/gi, '')
      .substring(0, 200)
  }

  sanitizeSectionKey(text) {
    return text
      .replace(/[ ]/gi, '_')
      .replace(/[^a-zA-Z0-9-_]/gi, '')
      .substring(0, 50)
  }

  sectionInputWidth() {
    return {
      width:
        this.props.modified.key.length === 0
          ? 110
          : this.props.modified.key.length * 9.4 + 10,
    }
  }

  render() {
    let filteredFieldKeys = Object.keys(this.props.modified.fields)

    if (this.props.filter === 'editable') {
      filteredFieldKeys = filteredFieldKeys.filter(
        key => this.props.modified.fields[key].editable === true
      )
    }
    if (this.props.filter === 'custom') {
      filteredFieldKeys = filteredFieldKeys.filter(
        key => this.props.modified.fields[key].type === 'custom'
      )
    }
    if (filteredFieldKeys.length === 0) {
      return null
    }

    return (
      <div className="config-section">
        {this.props.modified.new ? (
          <div
            className="config-section-title-editable"
            style={this.sectionInputWidth()}
          >
            <input
              placeholder=" ADD SECTION"
              value={this.props.modified.key}
              onChange={e =>
                this.props.onChange({
                  ...this.props.modified,
                  key: this.sanitizeSectionKey(e.target.value),
                })
              }
            />
          </div>
        ) : (
          <h3 className="config-section-title">{this.props.modified.key}</h3>
        )}
        <hr className="config-section-divider" />

        {filteredFieldKeys.map(key => (
          <div className="config-field" key={key}>
            {this.props.modified.fields[key].type === 'custom' ? (
              <div className="config-field-label-editable">
                <input
                  value={this.props.modified.fields[key].key}
                  onChange={e =>
                    this.onFieldChange(key, {
                      ...this.props.modified.fields[key],
                      key: this.sanitizeKey(e.target.value),
                    })
                  }
                  placeholder="add key"
                />
                {!this.props.modified.fields[key].key && (
                  <p className="config-field-key-error">required</p>
                )}
                {this.props.modified.fields[key].key &&
                  this.duplicateKey(key) && (
                    <p className="config-field-key-error">duplicate</p>
                  )}
              </div>
            ) : (
              <div className="config-field-label">
                <label>{this.props.modified.fields[key].key}</label>
              </div>
            )}
            <div
              className={`config-field-input${this.props.original.fields[key] &&
                this.props.modified.fields[key].value ===
                this.props.original.fields[key].value
                ? ''
                : ' changed'
                }`}
            >
              {this.props.modified.fields[key].key === 'smtp_password' ? (
                <input
                  value={this.props.modified.fields[key].value}
                  type={this.state.passwordType}
                  onFocus={() => {
                    this.setState({
                      passwordType: 'text',
                    })
                  }}
                  onBlur={() => {
                    this.setState({
                      passwordType: 'password',
                    })
                  }}
                  onChange={e =>
                    this.onFieldChange(key, {
                      ...this.props.modified.fields[key],
                      value: this.sanitizeValue(e.target.value) || '',
                    })
                  }
                  disabled={!this.props.modified.fields[key].editable}
                />
              ) : (
                <input
                  value={this.props.modified.fields[key].value}
                  onChange={e =>
                    this.onFieldChange(key, {
                      ...this.props.modified.fields[key],
                      value: this.sanitizeValue(e.target.value) || '',
                    })
                  }
                  disabled={!this.props.modified.fields[key].editable}
                  placeholder="enter value"
                />
              )}
            </div>

            {this.props.modified.fields[key].editable &&
              this.props.modified.fields[key].default_value && (
                <Button
                  className="config-field-revert"
                  disabled={
                    this.props.modified.fields[key].default_value ===
                    this.props.modified.fields[key].value
                  }
                  onClick={() =>
                    this.onFieldChange(key, {
                      ...this.props.modified.fields[key],
                      value: this.props.modified.fields[key].default_value,
                    })
                  }
                >
                  <i className="fa fa-undo"></i>
                </Button>
              )}

            {this.props.modified.fields[key].type === 'custom' && (
              <Button
                className="config-field-remove"
                onClick={() =>
                  this.props.onChange({
                    ...this.props.modified,
                    fields: this.withoutKey(key),
                  })
                }
              >
                <i className="fa fa-trash-o"></i>
              </Button>
            )}
          </div>
        ))}

        <div className="add-config-section-row">
          <Button
            className="config-field-add"
            onClick={() =>
              this.onFieldChange(shortid(), {
                key: '',
                value: '',
                editable: true,
                type: 'custom',
              })
            }
          >
            ADD FIELD
          </Button>
        </div>
      </div>
    )
  }
}
