import { http, digResponse, getRequestError } from 'util/index.js'

const encode = encodeURIComponent

export const getDags = (api, clusterId, pageSize, page, search_critera) =>
  http
    .get(
      `${api}/clusters/${encode(
        clusterId
      )}/dags?limit=${pageSize}&cursor=${page}&search=${search_critera}`
    )
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'getDags')
    })

export const getDag = (api, clusterId, dagId) =>
  http
    .get(`${api}/clusters/${encode(clusterId)}/dags/${encode(dagId)}`)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'getDag')
    })

export const uploadDag = (api, clusterId, subfolder, body) =>
  http
    .put(
      `${api}/clusters/${encode(clusterId)}/dags/${encode(
        body.file_name
      )}?subfolder=${encode(subfolder)}`,
      body
    )
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'uploadDag')
    })

export const deleteDag = (api, clusterId, dagId) =>
  http
    .delete(`${api}/clusters/${encode(clusterId)}/dags/${encode(dagId)}`)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'deleteDag')
    })
