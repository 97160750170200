import validate from 'validate.js'

const globalOpts = {
  fullMessages: false,
}

const validateSubject = value =>
  validate.single(
    value,
    {
      presence: {
        allowEmpty: false,
        message: 'Have to have a descriptive subject.',
      },
    },
    globalOpts
  )

const validateDAGTitle = value =>
  validate.single(
    value,
    {
      presence: {
        allowEmpty: false,
        message: 'Must have at least one affected DAG listed.',
      },
    },
    globalOpts
  )

const validateAffectedClusters = value =>
  validate.single(
    value,
    {
      presence: {
        allowEmpty: false,
        message: 'Must have at least one affected cluster',
      },
    },
    globalOpts
  )

const validateDescription = value =>
  validate.single(
    value,
    {
      presence: {
        allowEmpty: false,
        message: 'Must have a detailed description.',
      },
    },
    globalOpts
  )

const validateEmail = value =>
  validate.single(
    value,
    {
      presence: {
        allowEmpty: false,
        message: "Contact can't be blank",
      },
      email: {
        message: 'Email must be a correctly formatted',
      },
      format: {
        pattern: /^.*(@nike.com|@converse.com)/,
        message: 'Email must be @nike.com or @converse.com',
      },
      length: {
        maximum: 50,
        message: 'Email is too long (max 50)',
      },
    },
    globalOpts
  )

export default (key, value) =>
  ({
    subject: validateSubject,
    description: validateDescription,
    affectedClusters: validateAffectedClusters,
    email: validateEmail,
    dag: validateDAGTitle,
  }[key](value))
