import React from "react"
import CodeEdit from "components/CodeEdit/CodeEdit.js"
import Button from "components/Button/Button.js"
import "./FileEdit.scss"

export default ({
  errors,
  headerTitle,
  baseFolder,
  fileName,
  fileNameError,
  fileNameOnChange,
  originalContent,
  modifiedContent,
  onContentChange,
  getPending,
  onSubmit,
  submitText,
  submitPending,
  submitDisabled,
  onCancel,
  readOnly,
  aside,
  secondaryButton,
}) => (
  <div className='dash-main file-edit'>
    <div className='file-edit-header'>
      <h3 className='file-edit-header-title'>{headerTitle}</h3>

      {onCancel && (
        <Button onClick={onCancel} borderless>
          CANCEL
        </Button>
      )}

      {secondaryButton && secondaryButton}

      <Button
        borderless
        primary
        pending={submitPending}
        onClick={onSubmit}
        disabled={submitDisabled}
        locked={readOnly}
      >
        {submitText}
      </Button>
    </div>

    <div className='file-name-row'>
      <div className='base-folder'>
        <div className='base-folder-name'>{baseFolder} /</div>
      </div>

      <div className='file-name'>
        {fileNameOnChange ? (
          <input
            placeholder='file name'
            value={fileName}
            onChange={fileNameOnChange}
          />
        ) : (
          <input defaultValue={fileName} disabled />
        )}

        {getPending && <div className='file-name-loading'></div>}
        {fileNameError && <p className='file-name-error'>{fileNameError}</p>}
      </div>
    </div>

    {originalContent === modifiedContent && (
      <div className='file-error'>
        <i className='fa fa-exclamation-triangle'></i> File content same as
        upload content.
      </div>
    )}

    {errors &&
      errors.map((error, i) => (
        <div className='file-error' key={i}>
          <i className='fa fa-exclamation-triangle'></i> {error}
        </div>
      ))}

    <div className='edit-container'>
      {modifiedContent !== false && (
        <CodeEdit
          readOnly={readOnly}
          original={originalContent}
          modified={modifiedContent}
          onChange={onContentChange}
        />
      )}
    </div>

    {aside}
  </div>
)
