export const releaseNotes = () =>
  fetch('https://api-console.platforms.nike.com/graphql', {
    method: 'POST',
    headers: {
      // This endpoint does NOT want `Bearer` prepended to the auth header
      Authorization: JSON.parse(localStorage.getItem('map_okta_token'))
        .access_token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      operationName: 'techSolution',
      query: `
      query techSolution($id: ID!) {
        techSolution(id: $id) {
          name
          releaseNotes {
            link {
              text
              url
            }
            title
            markdown
            dateReleased
          }
        }
      }
	    `,
      variables: {
        id: 'c89f9802-ab47-453f-9547-7a3a4473c1a1',
      },
    }),
  })
    .then(response => response.json())
    .then(body => {
      try {
        return body.data.techSolution.releaseNotes
      } catch (_) {
        console.log(
          'MAP Error on releaseNotes store (unexpected response returned) -> ',
          body
        )
        throw new Error(['Unexpected response returned.'])
      }
    })
