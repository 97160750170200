import React, { Component } from 'react'
import { updateCluster } from 'store/clusters.js'
import { generate as shortid } from 'shortid'
import { UserAccess } from 'components/Settings/Settings.js'

// Each item must have a unique key (existing and new)
// to avoid react edge cases post insert/delete
const withTmpIds = members =>
  members.map(obj => ({
    ...obj,
    type: obj.ad_group ? 'ad_group' : 'sub',
    tmpId: shortid(),
  }))

// The API throws up if we include an unknown key
const withoutTmpIds = members =>
  members.map(member =>
    Object.keys(member)
      .filter(key => key !== 'tmpId' && key !== 'type')
      .reduce((obj, key) => ({ ...obj, [key]: member[key] }), {})
  )

export default class UserAccessSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      members: withTmpIds(props.cluster.members),
      changes: false,
      validation: [],
      submitPending: false,
      submitError: false,
    }
  }

  updateCluster = () =>
    updateCluster(this.props.region.api, this.props.cluster.cluster_id, {
      members: withoutTmpIds(this.state.changes),
    })
      .then(data =>
        this.setState(
          {
            members: withTmpIds(data.members),
            changes: false,
            validation: [],
            submitPending: false,
            submitError: false,
          },
          () => this.props.updateCluster(data)
        )
      )
      .catch(error =>
        this.setState({
          submitError: error.map(item => item.message),
          submitPending: false,
        })
      )

  handleChange = (value, valueValidation) =>
    this.setState({
      changes:
        JSON.stringify(this.state.members) !== JSON.stringify(value) && value,
      validation: valueValidation,
    })

  render() {
    let { user, region, permissions } = this.props
    let { ae_compute_integration } = this.props.cluster
    let {
      members,
      changes,
      validation,
      submitPending,
      submitError,
    } = this.state
    let currentMembers = changes || members
    let editDisabled = !permissions['CLUSTER.UPDATE']

    return (
      <UserAccess
        region={region}
        ae_compute_integration={ae_compute_integration}
        user={user}
        disabled={editDisabled}
        error={submitError}
        members={currentMembers}
        onChange={this.handleChange}
        validation={validation}
        onSave={() =>
          this.setState(
            {
              submitPending: true,
              submitError: false,
            },
            this.updateCluster
          )
        }
        savePending={submitPending}
        saveDisabled={!changes}
      />
    )
  }
}
