import React from 'react'
import './Form.scss'

export default ({ className, onSubmit, children }) => (
  <form
    className={`alt-form${className ? ' ' + className : ''}`}
    onSubmit={onSubmit}
  >
    {children}
  </form>
)
