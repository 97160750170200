import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './DateInput.styl'
import TextInput from '../TextInput/TextInput.js'
import Label from '../Label/Label.js'

// Formats to YYYY-MM-DD
function formatDate(date) {
  let d = new Date(date)

  let month = '' + (d.getMonth() + 1)

  let day = '' + d.getDate()

  let year = d.getFullYear()
  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day
  return [year, month, day].join('-')
}

class DateInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      month: props.month ? props.month : '',
      day: props.day ? props.day : '',
      year: props.year ? props.year : ''
    }
    this.updateParent = this.updateParent.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  updateParent() {
    let { month, day, year } = { ...this.state }

    // Check if Valid Date
    if (month.length < 2 || year.length < 4 || day.length < 2) {
      if (this.props.onChange) {
        return this.props.onChange(null)
      }
    }
    let date = new Date(`${year}-${month}-${day}`)
    date.setDate(date.getDate() + 1)
    if (!(date instanceof Date) || isNaN(date)) {
      if (this.props.onChange) {
        return this.props.onChange(null)
      }
    }

    date = formatDate(date)
    if (this.props.onChange) {
      this.props.onChange(date)
    }
  }

  onChange(val, key) {
    this.setState({ [key]: val }, () => this.updateParent())
  }

  render() {
    let { month, day, year } = { ...this.state }
    let { className, hasErrors, required, errorMessage, label } = this.props
    let dateClassName = className
      ? classnames(className, styles.dateInput)
      : styles.dateInput
    return (
      <Label
        label={label}
        className={className}
        required={required}
        hasErrors={hasErrors}
        errorMessage={errorMessage}
      >
        <span className={dateClassName}>
          <TextInput
            placeholder="mm"
            onChange={e => this.onChange(e.target.value, 'month')}
            type="text"
            value={month}
            maxLength="2"
            className={`${styles.field} ${styles.month}`}
            hasErrors={hasErrors}
            border={this.props.border || false}
          />
          <i className={styles.slash}>/</i>
          <TextInput
            placeholder="dd"
            onChange={e => this.onChange(e.target.value, 'day')}
            type="text"
            value={day}
            maxLength="2"
            className={`${styles.field} ${styles.day}`}
            hasErrors={hasErrors}
            border={this.props.border || false}
          />
          <i className={styles.slash}>/</i>
          <TextInput
            placeholder="yyyy"
            onChange={e => this.onChange(e.target.value, 'year')}
            type="text"
            value={year}
            maxLength="4"
            max={new Date().getFullYear()}
            className={`${styles.field} ${styles.year}`}
            hasErrors={hasErrors}
            border={this.props.border || false}
          />
        </span>
      </Label>
    )
  }
}

DateInput.propTypes = {
  /** Custom className for the date input component */
  className: PropTypes.string,
  /** Day (dd) of the month of date */
  day: PropTypes.string,
  /** Number of month (MM) of date */
  month: PropTypes.string,
  /** year (YYYY) of date */
  year: PropTypes.string,
  /** Specifies if border is present */
  border: PropTypes.bool,
  /** Sets input as required and also adds red star to label */
  required: PropTypes.bool,
  /** onChange handler for input, will return null if invalid date */
  onChange: PropTypes.func,
  /** Sets whether input is in error state */
  hasErrors: PropTypes.bool,
  /** Sets label for date input */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object
  ]),
  /** Any html attributes to apply to component */
  attributes: PropTypes.object
}

export default DateInput
