import React, { Component } from 'react'
import ReactTable from 'react-table'
import Highlight from 'react-highlighter'
import 'react-table/react-table.css'
import './Table.scss'

export default class Dash extends Component {
  constructor(props) {
    super(props)
    this.state = {
      computedCols: this.computeCols(),
      containerHeight: 0,
    }
    this.tableContainer = React.createRef()
    this.defaultSorted = [
      {
        id: props.cols[0].key,
        desc: false,
      },
    ]
  }

  componentDidMount() {
    this.getContainerHeight()
    window.addEventListener('resize', this.getContainerHeight)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getContainerHeight)
  }

  componentDidUpdate(prevProps) {
    // re-compute cols only if pointer specific variable(s) has changed
    // MUCH cheaper than trying to compare deep value(s)
    if (
      !Object.is(prevProps.cols, this.props.cols) ||
      !Object.is(prevProps.selectable, this.props.selectable) ||
      !Object.is(prevProps.multiSelect, this.props.multiSelect) ||
      !Object.is(prevProps.onSelectAll, this.props.onSelectAll)
    ) {
      this.setState({
        computedCols: this.computeCols(),
      })
    }
  }

  computeCols = () => {
    let computedCols = this.props.cols.map((col, i) => ({
      Header: col.name,
      accessor: col.key,
      style: this.props.wrapText ? { whiteSpace: 'unset' } : {},
      Cell:
        col.Cell ||
        (row =>
          col.highlight ? (
            <Highlight search={this.props.filter}>{row.value}</Highlight>
          ) : (
            row.value || ''
          )),
      maxWidth: col.maxWidth || 'auto',
      resizable: i < this.props.cols.length - 1,
      sortMethod: col.sortMethod,
      sortable: true,
    }))
    if (this.props.selectable) {
      computedCols.unshift({
        className: 'selector-col',
        headerClassName: 'selector-header',
        Header: this.props.multiSelect ? (
          <div className='checkbox' onClick={this.props.onSelectAll}>
            <i className='fa fa-check'></i>
          </div>
        ) : (
          ''
        ),
        Cell: row => (
          <div className='checkbox' onClick={e => this.onClick(e, row)}>
            <i className='fa fa-check'></i>
          </div>
        ),
        width: 36,
        resizable: false,
        sortable: false,
      })
    }
    return computedCols
  }

  onClick = (event, row) => {
    event.stopPropagation()
    this.props.onSelect(row.original)
  }

  getContainerHeight = () => {
    if (this.tableContainer && this.tableContainer.current) {
      this.setState({
        containerHeight: this.tableContainer.current.clientHeight,
      })
    }
  }

  trProps = (_, rowInfo) => ({
    className: {
      'row-selected':
        rowInfo &&
        this.props.selectedMethod &&
        this.props.selectedMethod(rowInfo.original),
    },
  })

  render() {
    let {
      rows,
      loading,
      defaultSorted,
      selectedCount,
      selectable,
      className,
      error,
      getTrProps,
      SubComponent,

      paginate,
      page,
      pages,
      onPageChange,
      pageSize,
      pageSizeOptions,
      onPageSizeChange,
      showPageJump,
      sorted,
      onSortedChange,
      // onFetchData,
    } = this.props
    let { computedCols, containerHeight } = this.state
    let paginateProps = paginate
      ? {
          manual: true,
          showPagination: true,
          showPaginationBottom: true,
          showPageSizeOptions: true,
          nextText: <i className='fa fa-long-arrow-right' />,
          previousText: <i className='fa fa-long-arrow-left' />,
          page,
          pages,
          onPageChange,
          pageSize,
          pageSizeOptions,
          onPageSizeChange,
          showPageJump,
          sorted,
          onSortedChange,
        }
      : {
          showPagination: false,
          pageSize: rows.length,
        }

    // Fill available space. 52=padding, 42=pagination, 32=rowHeight
    let padding = 52 + (paginate ? 42 : 0)
    let minRows = Math.max(
      0,
      Math.floor(((containerHeight || 0) - padding) / 32)
    )
    let tableClass = 'alt-table'
    if (loading) {
      tableClass += ' alt-rows-loading'
    }
    if (selectable) {
      tableClass += ' alt-selectable'
    }
    if (selectedCount > 0 && selectedCount === rows.length) {
      tableClass += ' alt-all-selected'
    }

    // catch real error(s)
    let renderableError = false

    // expects an array of strings
    if (error && (error.length > 0 || React.isValidElement(error))) {
      let errorList = error.join(', ')

      let errorMessage = 'ERROR: '
      renderableError = errorMessage.concat(errorList)
    }

    return (
      <div
        className={`alt-table-wrapper${className ? ' ' + className : ''}`}
        ref={this.tableContainer}
      >
        <ReactTable
          className={tableClass}
          data={rows}
          columns={computedCols}
          minRows={this.props.minRowsOverride ? rows.length : minRows}
          defaultSorted={defaultSorted || this.defaultSorted}
          noDataText={renderableError || 'NO ROWS FOUND'}
          getTrProps={getTrProps || this.trProps}
          SubComponent={SubComponent}
          {...paginateProps}
        />
      </div>
    )
  }
}
