exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".epic-font-base___1fA_Y {\n  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-base-md___6Xgsb {\n  font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif;\n}\n.epic-font-marketing___1rrTp {\n  font-family: 'Futura', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-brand___2Y-g4 {\n  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.radioGroup___3zxJI {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: center;\n  padding: 15px 8px 3px 8px;\n  background-color: #fff;\n  border-radius: 1em;\n  width: 150px;\n}\n", ""]);

// Exports
exports.locals = {
	"epic-font-base": "epic-font-base___1fA_Y",
	"epic-font-base-md": "epic-font-base-md___6Xgsb",
	"epic-font-marketing": "epic-font-marketing___1rrTp",
	"epic-font-brand": "epic-font-brand___2Y-g4",
	"radioGroup": "radioGroup___3zxJI"
};