import { http, digResponse, getRequestError } from 'util/index.js'

const encode = encodeURIComponent
const unsupported = require('unsupported-versions.json')

export const getPackages = (api, source, search) =>
  http
    .get(`${api}/python_packages/${source}?q_name=${encode(search)}`)
    .then(response => digResponse(response, 'data', 'packages'))
    .catch(response => {
      console.log('MAP Error - getPackages store: ', response)
      throw getRequestError(response)
    })

export const getPackage = (api, source, name) =>
  http
    .get(`${api}/python_packages/${source}/${encode(name)}`)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'getPackage')
    })

export const getBaseContainers = api =>
  http
    .get(`${api}/base_containers`)
    .then(response => {
      // filter out unsupported versions
      const filteredResponse = {
        ...response,
      }

      filteredResponse.data.data = filteredResponse.data.data.filter(item => {
        let isSupported = true

        unsupported['unsupported-airflow-versions'].forEach(version => {
          if (item.name.includes(version)) {
            isSupported = false
          }
        })

        return isSupported ? item : null
      })

      return digResponse(filteredResponse, 'data', 'data')
    })
    .catch(response => {
      throw getRequestError(response, 'getBaseContainers')
    })
