import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { getBuilds } from 'store/builds.js'
import Highlight from 'react-highlighter'
import CreateBuild from './CreateBuild.js'
import Table from 'components/Table/Table.js'
import Search from 'components/Search/Search.js'
import Button from 'components/Button/Button.js'
import ButtonLink from 'components/ButtonLink/ButtonLink.js'
import config from 'envs/config'
import './Builds.scss'
import Tooltip from 'components/Tooltip/Tooltip.js'

const tsOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
}

export default class Builds extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: '',
      builds: [],
      buildsLoading: true,
      buildsError: false,
      createOpen: false,
    }
  }

  componentDidMount() {
    this.fetchBuilds()
  }

  fetchBuilds = () =>
    getBuilds(this.props.region.api, this.props.cluster.cluster_id)
      .then(builds =>
        this.setState({
          builds: builds.map(build => ({
            ...build,
            status_link: (
              <Link
                statusref={build.status}
                className='alt-build-link'
                to={`${config.siteRoot}${this.props.region.value}/clusters/${this.props.cluster.cluster_name}/builds/${build.build_id}`}
              >
                <Highlight search={this.state.filter}>{build.status}</Highlight>
              </Link>
            ),
            // local ts. 'sv' locale uses ISO 8601 format while still keeping browsers local tz.
            created: new Date(build.created_date).toLocaleString(
              'sv',
              tsOptions
            ),
          })),
          buildsLoading: false,
          buildsError: false,
        })
      )
      .catch(err =>
        this.setState({
          buildsLoading: false,
          buildsError: err.map(item => item.message),
        })
      )

  render() {
    let { cluster, region, permissions } = this.props
    let { filter, builds, buildsLoading, buildsError, createOpen } = this.state
    let buildspecLink = `${config.siteRoot}${region.value}/clusters/${cluster.cluster_name}/image`
    let filtered = builds.filter(
      build =>
        build.tag.toLowerCase().includes(filter.toLowerCase()) ||
        build.airflow_version.toLowerCase().includes(filter.toLowerCase()) ||
        (build.image &&
          build.image.toLowerCase().includes(filter.toLowerCase()))
    )

    const buildCols = [
      {
        name: 'CREATED',
        key: 'created',
        maxWidth: 150,
      },
      {
        name: 'STATUS',
        key: 'status_link',
        maxWidth: 90,
        sortMethod: (a, b) => (a.props.statusref > b.props.statusref ? 1 : -1),
      },
      {
        name: 'IMAGE',
        highlight: true,
        key: region.value.includes('cn-north') ? 'ecr_image' : 'image',
      },
      {
        name: 'AIRFLOW VERSION',
        key: 'airflow_version',
        highlight: true,
        maxWidth: 130,
      },
      {
        name: 'TAG',
        key: 'tag',
        highlight: true,
        maxWidth: 70,
      },
    ]

    return (
      <div className='dash-main builds'>
        <div className='list-header'>
          <div className='list-header-bar'>
            <h3 className='list-title'>CLUSTER BUILDS</h3>
            <Tooltip tooltipText='It gives all details of a cluster image along with the build specifications.'></Tooltip>
          </div>
          <div className='list-header-bar'>
            <Search
              value={filter}
              onChange={e => this.setState({ filter: e.target.value })}
            />
            <ButtonLink borderless href={buildspecLink}>
              MANAGE BUILDSPEC
            </ButtonLink>
            <Button
              primary
              borderless
              onClick={() => this.setState({ createOpen: true })}
              locked={!permissions['CLUSTER.UPDATE']}
            >
              TRIGGER BUILD
            </Button>
          </div>
        </div>

        <div className='dash-main-list'>
          <Table
            className='alt-builds-list-table'
            rows={filtered}
            cols={buildCols}
            loading={buildsLoading}
            error={buildsError}
            filter={filter}
            defaultSorted={[
              {
                id: 'created',
                desc: true,
              },
            ]}
          />
        </div>

        {createOpen && (
          <CreateBuild
            region={region}
            cluster={cluster}
            onClose={() => this.setState({ createOpen: false })}
          />
        )}
      </div>
    )
  }
}
