import React from 'react'
import { render } from 'react-dom'

// APPLICATION BASE
import App from './App.js'
import { IframeBuster } from 'components/index.js'

render(
  <IframeBuster>
    <App />
  </IframeBuster>,
  document.getElementById('app-host')
)
