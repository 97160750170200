import React from 'react'
import ErrorView from 'components/Error/Error.js'
import './IframeBuster.scss'

const InIframe = () => {
  try {
    if (window.self !== window.top) return true
    return false
  } catch (e) {
    return true
  }
}

export default ({ children }) =>
  InIframe() ? (
    <ErrorView title="OOPS" error="Unsupported Render Context" />
  ) : (
    children
  )
