import { http, digResponse, getRequestError } from 'util/index.js'

const encode = encodeURIComponent

export const createCluster = (api, body) =>
  http
    .post(`${api}/clusters`, body)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'createCluster')
    })

export const getClusters = api =>
  http
    .get(`${api}/clusters`)
    .then(response => digResponse(response, 'data', 'data'))
    .catch(response => {
      throw getRequestError(response, 'getClusters')
    })

export const getCluster = (api, clusterId) =>
  http
    .get(`${api}/clusters/${encode(clusterId)}`)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'getCluster')
    })

export const updateCluster = (api, clusterId, body) =>
  http
    .patch(`${api}/clusters/${encode(clusterId)}`, body)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'updateCluster')
    })

export const restartCluster = (api, clusterId) =>
  http
    .put(`${api}/clusters/${encode(clusterId)}/restart`)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'restartCluster')
    })

export const stopCluster = (api, clusterId) =>
  http
    .put(`${api}/clusters/${encode(clusterId)}/stop`)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'stopCluster')
    })

export const restartScheduler = (api, clusterId) =>
  http
    .post(`${api}/clusters/${encode(clusterId)}/restart/scheduler`)
    .then(response => digResponse(response, 'data', 'message'))
    .catch(response => {
      throw getRequestError(response, 'restartScheduler')
    })

export const getSchedulerStatus = (api, clusterId) =>
  http
    .get(`${api}/clusters/${encode(clusterId)}/scheduler_status`)
    .then(response => digResponse(response, 'data', 'pods'))
    .catch(response => {
      throw getRequestError(response, 'getSchedulerStatus')
    })

export const getClusterConfig = (api, clusterId) =>
  http
    .get(`${api}/clusters/${encode(clusterId)}/config`)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'getClusterConfig')
    })

export const enableObservability = (api, clusterId) =>
  http
    .post(`${api}/clusters/${encode(clusterId)}/observability`)
    .then(response => digResponse(response, 'data', 'message'))
    .catch(response => {
      throw getRequestError(response, 'observability')
    })

export const decommissionCluster = (api, clusterId) =>
  http
    .delete(`${api}/clusters/${encode(clusterId)}`)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'decommissionCluster')
    })

export const getCosts = (api, clusterId, queryString) =>
  http
    .get(`${api}/clusters/${encode(clusterId)}/cost?${queryString}`)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'getCosts')
    })
