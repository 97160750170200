import React, { useState, useEffect } from 'react'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import 'moment-timezone'
import SelectInput from 'components/SelectInput/SelectInput.js'
import './DateSingle.scss'

const tzOpts = moment.tz.names().map(tz => ({
  label: tz,
  value: tz,
}))

export default ({
  className,
  date,
  minDate,
  maxDate,
  onDateChange,
  disabled,
}) => {
  const [focused, setFocused] = useState(false)
  const [tz, setTz] = useState(moment.tz.guess())
  const [time, setTime] = useState({
    hours: (date && date.hours()) || 0,
    minutes: (date && date.minutes()) || 0,
  })

  useEffect(() => {
    window.moment = moment
    // External changes to date should still update time state
    // So that future date changes dont reset the time
    date &&
      setTime({
        hours: date.hours(),
        minutes: date.minutes(),
      })
  }, [date])

  let classes = ['alt-date-single']
  if (disabled) {
    classes.push('alt-date-single-disabled')
  }
  if (className) {
    classes.push(className)
  }

  const handleTzChange = tz => {
    setTz(tz, true)

    if (date) {
      date = date
        .clone() // avoid mutability issues
        .tz(tz, true)
    }
    onDateChange(date)
  }

  const handleTimeChange = (focused, hours, minutes) => {
    setTime({ hours, minutes })

    if (focused && date) {
      date = date
        .clone() // avoid mutability issues
        .set('hour', hours)
        .set('minute', minutes)
        .set('second', 0)
        .tz(tz, true)
      onDateChange(date)
    }
  }

  const timeSelector = () => (
    <div className="time-sidebar">
      <SelectInput
        className="tz-selector"
        options={tzOpts}
        value={{ label: tz, value: tz }}
        onChange={opt => handleTzChange(opt.value)}
        disabled={disabled}
      />
      <div className="time-selector-wrapper">
        <div className="time-selector-abs">
          <div className="time-selector alt-scroll">
            {[...Array(48)].map((_, i) => {
              let hour = Math.floor(i / 2)
              let minute = (i % 2) * 30

              return (
                <p
                  key={i}
                  className={`time-selector-time${time.hours === hour && time.minutes === minute
                    ? ' time-active'
                    : ''
                    }`}
                  onClick={() => handleTimeChange(focused, hour, minute)}
                >
                  {hour.toString().padStart(2, '0')}:
                  {minute.toString().padStart(2, '0')}
                </p>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className={classes.join(' ')}>
      <SingleDatePicker
        id="alt-date-input"
        date={date}
        placeholder="NOW"
        displayFormat="DD/MM/YYYY HH:mm"
        onDateChange={date => {
          if (date) {
            let creationData = date.creationData()
            let hours = time.hours
            let minutes = time.minutes

            // Date was created manually via `input` text
            if (creationData && creationData.input && date['_a']) {
              // react-dates overwrites the hours to 12 (noon)
              // we can extract the original input value via `_a`
              hours = date['_a'][3]
              minutes = date.minutes()
              setTime({ hours, minutes })
            }
            date = date
              .clone() // avoid mutability issues
              .set('hour', hours)
              .set('minute', minutes)
              .set('second', 0)
              .tz(tz, true)
          }
          onDateChange(date)
        }}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        numberOfMonths={1}
        horizontalMonthPadding={0}
        verticalSpacing={0}
        isOutsideRange={day =>
          !day.isBetween(
            minDate.clone().startOf('day'),
            maxDate.clone().endOf('day')
          )
        }
        calendarInfoPosition="after"
        renderCalendarInfo={timeSelector}
        orientation="horizontal"
        anchorDirection="right"
        openDirection="down"
        keepOpenOnDateSelect
        hideKeyboardShortcutsPanel
        noBorder
        disabled={disabled}
      />
    </div>
  )
}
