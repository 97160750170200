import React, { useState, useRef } from 'react'
import Editor, { DiffEditor } from '@monaco-editor/react'

export default ({ original, modified, readOnly, onChange, language }) => {
  const editorRef = useRef()
  const [content, setContent] = useState(modified)
  const opts = { readOnly, automaticLayout: true }

  function handleEditorDidMount(_, editor) {
    if (editor && editor.onDidChangeModelContent) {
      editorRef.current = editor
      editorRef.current.onDidChangeModelContent(() => {
        setContent(editorRef.current.getValue())
        onChange(editorRef.current.getValue())
      })
    }
  }

  function handleDiffEditorDidMount(a, b, editor) {
    if (
      editor &&
      editor.modifiedEditor &&
      editor.modifiedEditor.onDidChangeModelContent
    ) {
      editorRef.current = editor.modifiedEditor
      editorRef.current.onDidChangeModelContent(() => {
        setContent(editorRef.current.getValue())
        onChange(editorRef.current.getValue())
      })

      // Normally `automaticLayout` handles this but there _appears_ to be
      // an issue initializing layout too early a manual (and specifically
      // asynchronous) call to layout seems to do the trick consistently.
      setTimeout(() => editor.layout(), 0)
    }
  }

  if (!original)
    return (
      <Editor
        theme="light"
        language={language || 'python'}
        value={content}
        editorDidMount={handleEditorDidMount}
        options={opts}
        loading={'Loading...'}
      />
    )

  return (
    <DiffEditor
      theme="light"
      language={language || 'python'}
      original={original}
      modified={content}
      editorDidMount={handleDiffEditorDidMount}
      options={opts}
      loading={'Loading...'}
    />
  )
}
