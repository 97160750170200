import React, { Component } from 'react'
import { Base64 } from 'js-base64'
import { getDag, uploadDag } from 'store/dags.js'
import { ToastsContext } from 'components/Toasts/Toasts.js'
import FileEdit from 'components/FileEdit/FileEdit.js'
import DagValidate from '../DagValidate/DagValidate.js'

export default class DagEdit extends Component {
  static contextType = ToastsContext
  constructor(props) {
    super(props)
    this.state = {
      dag: false,
      dagLoading: true,
      dagError: false,
      dagContent: false,
      uploadPending: false,
      uploadError: false,
      validateOpen: false,
      cerberusClient: false,
    }
  }

  componentDidMount() {
    this.fetchDag()
  }

  onClose = () => this.props.history.replace(`/${encodeURI(this.props.region.value)}/clusters/${encodeURI(
    this.props.cluster.cluster_name)}/dags`)

  fetchDag = () =>
    getDag(
      this.props.region.api,
      this.props.cluster.cluster_id,
      decodeURIComponent(this.props.match.params.dagId)
    )
      .then(data => {
        this.setState({
          dag: data,
          dagLoading: false,
          dagError: false,
          dagContent: Base64.decode(data.encoded_content),
          cerberus: Base64.decode(data.encoded_content).includes('cerberus'),
        })
      })
      .catch(err =>
        this.setState({
          dagLoading: false,
          dagError: err.map(item => item.message),
        })
      )

  onSubmit = () =>
    this.setState(
      {
        uploadPending: true,
        uploadError: false,
      },
      () => {
        let folder = '/'
        let sections = this.state.dag.file_name.split('/').filter(Boolean)

        if (sections.length > 1) {
          folder = sections.slice(0, -1).join('/')
        }

        let body = {
          file_name: sections[sections.length - 1],
          encoded_content: Base64.encode(this.state.dagContent),
        }

        return uploadDag(
          this.props.region.api,
          this.props.cluster.cluster_id,
          folder,
          body
        )
          .then(() => {
            this.context.addToast(
              `Successfully Updated ${this.state.dag.file_name}`,
              'ok'
            )
            return this.onClose()
          })
          .catch(error =>
            this.setState({
              uploadPending: false,
              uploadError: error.map(item => item.message),
            })
          )
      }
    )

  render() {
    let { cluster, region, permissions } = this.props
    let onClose = this.onClose
    let file_name = decodeURIComponent(this.props.match.params.dagId)
    let {
      dagContent,
      dagLoading,
      dagError,
      uploadPending,
      uploadError,
      validateOpen,
    } = this.state

    let errors = []

    if (!permissions['DAGS.UPDATE']) {
      errors.push(
        'Read Only Mode; `DAGS.UPDATE` permission required to modify.'
      )
    }
    if (uploadError) {
      const displayError = 'Upload Error: '
      uploadError.forEach(item => {
        errors.push(displayError.concat(item))
      })
    }
    if (dagError) {
      const displayError = 'Dag Error: '
      dagError.forEach(item => {
        errors.push(displayError.concat(item))
      })
    }

    return (
      <FileEdit
        errors={errors}
        headerTitle="EDIT DAG"
        baseFolder="dags"
        fileName={file_name}
        modifiedContent={dagContent}
        onContentChange={value => this.setState({ dagContent: value })}
        getPending={dagLoading}
        onSubmit={() => this.setState({ validateOpen: true })}
        submitText="VALIDATE & SAVE"
        submitPending={uploadPending}
        submitDisabled={dagLoading || dagError}
        onCancel={onClose}
        readOnly={!permissions['DAGS.UPDATE']}
        aside={
          validateOpen && (
            <DagValidate
              region={region}
              cluster={cluster}
              file_name={this.state.dag.file_name}
              encoded_content={Base64.encode(this.state.dagContent)}
              onSubmit={this.onSubmit}
              submitText="SAVE"
              cerberus={this.state.cerberus}
              onClose={() => this.setState({ validateOpen: false })}
            />
          )
        }
      />
    )
  }
}
