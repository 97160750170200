const validateUserAccess = function validateUserAccess(members) {
  try {
    return members.map(
      member =>
        (member.type === 'sub' &&
          typeof member.sub === 'string' &&
          member.sub.length === 0 &&
          "Sub can't be blank") ||
        (member.type === 'ad_group' &&
          typeof member.ad_group === 'string' &&
          member.ad_group.length === 0 &&
          "AD Group can't be blank")
    )
  } catch (err) {
    return false
  }
}

const exportFunctions = { validateUserAccess }

export default exportFunctions
