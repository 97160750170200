import React, { Component } from 'react'
import { generate as shortid } from 'shortid'
import { updateCluster } from 'store/clusters.js'
import { Notifications } from 'components/Settings/Settings.js'

// Each item must have a unique key (existing and new)
// to avoid react edge cases post insert/delete
const withTmpIds = notifications =>
  notifications.map(obj => ({
    ...obj,
    tmpId: shortid(),
  }))

// The API throws up if we include an unknown key
const withoutTmpIds = notifications =>
  notifications.map(notification =>
    Object.keys(notification)
      .filter(key => key !== 'tmpId')
      .reduce((obj, key) => ({ ...obj, [key]: notification[key] }), {})
  )

export default class NotificationsSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      notifications: withTmpIds(props.cluster.notifications),
      modified: false,
      validation: [],
      submitPending: false,
      submitError: false,
    }
  }

  updateCluster = () =>
    updateCluster(this.props.region.api, this.props.cluster.cluster_id, {
      notifications: withoutTmpIds(this.state.notifications),
    })
      .then(data =>
        this.setState(
          {
            notifications: withTmpIds(data.notifications),
            modified: false,
            validation: [],
            submitPending: false,
            submitError: false,
          },
          () => this.props.updateCluster(data)
        )
      )
      .catch(error =>
        this.setState({
          submitError: error.map(item => item.message),
          submitPending: false,
        })
      )

  hasChanged = modified => {
    let hasChanged = false
    let original = this.props.cluster.notifications

    if (modified.length !== original.length) {
      return true
    }

    for (let i in modified) {
      if (
        modified[i].service !== original[i].service ||
        modified[i].contact !== original[i].contact ||
        modified[i].event_type !== original[i].event_type ||
        modified[i].extras_json !== original[i].extras_json ||
        modified[i].excluded_dags !== original[i].excluded_dags ||
        modified[i].included_dags !== original[i].included_dags ||
        modified[i].pagerduty !== original[i].pagerduty ||
        modified[i].service_now !== original[i].service_now
      ) {
        hasChanged = true
        break
      }
    }

    return hasChanged
  }

  handleChange = (value, valueValidation) => {
    this.setState({
      notifications: value,
      modified: this.hasChanged(value),
      validation: valueValidation,
    })
  }

  render() {
    let { permissions } = this.props
    let { notifications, modified, validation, submitPending, submitError } =
      this.state
    let editDisabled = !permissions['CLUSTER.UPDATE']

    return (
      <Notifications
        notifications={notifications}
        onChange={this.handleChange}
        validation={validation}
        onSave={() =>
          this.setState(
            {
              submitPending: true,
              submitError: false,
            },
            this.updateCluster
          )
        }
        savePending={submitPending}
        saveDisabled={!modified}
        disabled={editDisabled}
        error={submitError}
        {...this.props}
      />
    )
  }
}
