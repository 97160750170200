import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Search from './deps/Search.js'
import styles from './Navigation.styl'

const SearchButton = ({
  searchOpen,
  searchValue,
  onClick,
  onChange,
  onSubmit,
  onClose,
  showButtonOnOpen,
  previewItems,
  placeholder
}) => {
  let iconClass = 'epic-icon-search'

  if (searchOpen) {
    if (searchValue && searchValue.length > 0) {
      iconClass = classnames('epic-icon-search', styles.active)
    } else {
      iconClass = classnames('epic-icon-search', styles.disabled)
    }

    if (!showButtonOnOpen) {
      iconClass = classnames('hide')
    }
  }

  return (
    <button className={styles.searchBtn} onClick={onClick}>
      <i id="epic-icon epic-icon-search" className={`epic-icon ${iconClass}`} />
      {searchOpen ? (
        <Search
          value={searchValue}
          onChange={onChange}
          onSubmit={onSubmit}
          onClose={onClose}
          previewItems={previewItems}
          placeholder={placeholder}
        />
      ) : null}
    </button>
  )
}

const ActiveNavIndicator = () => (
  <div className={styles.activeIndicator}>
    <div className={styles.activeIndicatorBar} />
  </div>
)

const DocsLink = ({ url }) => (
  <a href={url}>
    Docs
    <ActiveNavIndicator />
  </a>
)

const SlackLink = ({ url }) => (
  <a href={url} className={styles.appSlack}>
    <i className="epic-icon epic-icon-slack-with-name" />
    <ActiveNavIndicator />
  </a>
)

const NavItems = ({ history, routes }) =>
  routes.map((route, i) => (
    <Link
      key={`nav-item-${i}`}
      activeClassName={styles.active}
      to={route.path}
      pathname={history.location.pathname}
      exact={route.exact}
      onClick={() => history.push(route.path + (route.search || ''))}
    >
      {route.text}
    </Link>
  ))

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}

const Link = ({
  pathname,
  to,
  exact,
  onClick,
  children,
  activeClassName = '',
  className = '',
  ...props
}) => {
  if (exact) {
    if (pathname === to) {
      className += ` ${activeClassName}`
    }
  } else if (pathname.indexOf(to) !== -1) {
    className += ` ${activeClassName}`
  }

  return (
    <a
      className={className}
      href={to}
      onClick={event => {
        let { target } = props
        if (
          event.button === 0 && // ignore everything but left clicks
          (!target || target === '_self') && // let browser handle "target=_blank" etc.
          !isModifiedEvent(event) // ignore clicks with modifier keys
        ) {
          event.preventDefault()
          onClick()
        }
      }}
    >
      {children}
      <ActiveNavIndicator />
    </a>
  )
}

class Navigation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      searchOpen: false,
      searchValue: '',
      routeKey: ''
    }

    props.history.listen(this.onHistory.bind(this))
  }

  onHistory(location) {
    if (this.state.routeKey !== location.key) {
      this.setState({ routeKey: location.key })
    }
  }

  onClick(target) {
    if (this.state.searchOpen && target.id === 'er-nav-search-submit') {
      this.onSubmit()
    } else if (target.dataset.value === undefined) {
      this.setState({ searchOpen: true })
    }
  }

  onChange(value) {
    this.setState(
      {
        searchValue: value || ''
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.searchValue)
        }

        // Clearing the input, if only onSubmit is registered
        if (!value && this.props.onSubmit) {
          this.props.onSubmit(this.state.searchValue)
        }
      }
    )
  }

  onSubmit(selectValue) {
    if (selectValue) {
      this.setState({ searchValue: selectValue })
    }
    const value = selectValue || this.state.searchValue
    value.length > 0 && this.props.onSubmit && this.props.onSubmit(value)
  }

  render() {
    const { searchOpen, searchValue } = this.state
    const {
      history,
      name,
      logo,
      docsUrl,
      slackURL,
      routes,
      onChange,
      onSubmit,
      showSearchBtnOnOpen,
      searchText,
      searchPlaceholder,
      previewItems
    } = this.props

    const rootPath = this.props.rootPath || '/'
    return (
      <Fragment>
        <nav className={`${this.props.className || ''} ${styles.navigation}`}>
          <Link
            className={styles.logo}
            key={`${name}-root-path`}
            to={rootPath}
            exact={true}
            onClick={() => history.push(rootPath)}
          >
            <img src={logo} alt={`${name} logo`} />
            <span>{name}</span>
          </Link>

          <div className={styles.innerNav}>
            <NavItems routes={routes} history={history} />
            {docsUrl && <DocsLink url={docsUrl} />}
            {slackURL && <SlackLink url={slackURL} />}

            {onChange || onSubmit ? (
              <SearchButton
                previewItems={previewItems}
                searchOpen={searchOpen}
                searchValue={
                  searchText !== undefined ? searchText : searchValue
                }
                onClick={e => this.onClick(e.target)}
                onChange={e => this.onChange(e.target.value)}
                onSubmit={selectValue => this.onSubmit(selectValue)}
                onClose={() => this.setState({ searchOpen: false })}
                showButtonOnOpen={showSearchBtnOnOpen}
                placeholder={searchPlaceholder}
              />
            ) : null}
          </div>
        </nav>
      </Fragment>
    )
  }
}

Navigation.propTypes = {
  /** Application Name */
  name: PropTypes.string.isRequired,
  /** http url to logo */
  logo: PropTypes.string.isRequired,
  /** Route objects that describe a react-router route */
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      /** Route path */
      path: PropTypes.string.isRequired,
      /** Text to display for the route */
      text: PropTypes.string.isRequired,
      /** Search value for nav route */
      search: PropTypes.string,
      /** Match the route exact: true [default] OR false */
      exact: PropTypes.bool
    })
  ).isRequired,
  /** http url to client or web application */
  slackURL: PropTypes.string,
  /** http url to external documentation */
  docsUrl: PropTypes.string,
  /** To control the text value of the search, just provide a value */
  searchText: PropTypes.string,
  /** Placeholder text for search bar */
  searchPlaceholder: PropTypes.string,
  /** Search onChange value */
  onChange: PropTypes.func,
  /** Search onSubmit value; will execute if search input is cleared */
  onSubmit: PropTypes.func,
  /** The root path to the application (eg / or /somevalue) */
  rootPath: PropTypes.string,
  /** Show search button when search input is open {default is true} */
  showSearchBtnOnOpen: PropTypes.bool,
  /** Escape hatch for styling */
  className: PropTypes.string,
  /** React Router history instance */
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  /** Search preview items */
  previewItems: PropTypes.arrayOf(PropTypes.string)
}

Navigation.defaultProps = {
  showSearchBtnOnOpen: true,
  searchPlaceholder: 'Search...'
}

export default Navigation
