exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".epic-font-base___nBhS- {\n  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-base-md___39fOc {\n  font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif;\n}\n.epic-font-marketing___34DXj {\n  font-family: 'Futura', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-brand___3ctkM,\n.avatar___xK-ki {\n  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.avatar___xK-ki {\n  background-color: #d8d8d8;\n  border-radius: 100px;\n  width: 100px;\n  height: 100px;\n  margin: 0 auto;\n}\n.avatar___xK-ki img {\n  display: block;\n  border-radius: 100px;\n  width: 100px;\n  height: 100px;\n}\n.avatar___xK-ki .badge___1M-JW::after {\n  top: -4.5em;\n  right: -0.7em;\n}\n.small___2bPyn {\n  width: 40px;\n  height: 40px;\n  font-size: 12px;\n  line-height: 40px;\n  text-align: center;\n}\n.small___2bPyn img {\n  display: block;\n  width: 40px;\n  height: 40px;\n}\n.default___GW1iD {\n  font-size: 30px;\n  line-height: 100px;\n  width: 100px;\n  height: 100px;\n  text-align: center;\n}\n.active___YrGn2 img {\n  border: solid 1px #ffa500;\n}\n", ""]);

// Exports
exports.locals = {
	"epic-font-base": "epic-font-base___nBhS-",
	"epic-font-base-md": "epic-font-base-md___39fOc",
	"epic-font-marketing": "epic-font-marketing___34DXj",
	"epic-font-brand": "epic-font-brand___3ctkM",
	"avatar": "avatar___xK-ki",
	"badge": "badge___1M-JW",
	"small": "small___2bPyn",
	"default": "default___GW1iD",
	"active": "active___YrGn2"
};