import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button/Button.js'
import Icon from '../Icon/Icon.js'
import styles from './styles.styl'

function IconButton({
  positionFirst,
  type,
  bare = false,
  children,
  iconFontSize,
  ...props
}) {
  const iconSize = iconFontSize || (props.large ? '30px' : '')
  let buttonClass = `${styles.button} ${bare ? styles.bare : ''}`
  if (positionFirst) buttonClass += ` ${styles.positionFirst}`
  let icon = <Icon type={type} fontSize={iconSize} />
  let content = <div className={styles.content}>{children}</div>

  return positionFirst ? (
    <Button {...props} className={buttonClass}>
      {icon}
      {content}
    </Button>
  ) : (
    <Button {...props} className={buttonClass}>
      {content}
      {icon}
    </Button>
  )
}

IconButton.propTypes = {
  /** Icon type */
  type: PropTypes.string.isRequired,
  /** Allow to custom webfont size (px values) */
  iconFontSize: PropTypes.string,
  /** Set to true if the icon should appear before the text */
  positionFirst: PropTypes.bool,
  /** Remove the background */
  bare: PropTypes.bool
}

IconButton.defaultProps = {
  positionFirst: false,
  iconFontSize: '14x',
  bare: false
}

export default IconButton
