import React from 'react'
import { ErrorBoundary } from 'components/index.js'
import BasicInfo from './Sections/BasicInfo/BasicInfo.js'
import Advanced from './Sections/Advanced/Advanced.js'
import Integrations from './Sections/Integrations/Integrations.js'
import Notifications from './Sections/Notifications/Notifications.js'
import UserAccess from './Sections/UserAccess/UserAccess.js'
import SupportAccess from './Sections/SupportAccess/SupportAccess.js'
import Decommission from './Sections/Decommission/Decommission.js'
import './Settings.scss'

const sectionError = sectionName => (
  <div className="settings-form-section section-error">
    <h3 className="settings-form-section-title">{sectionName}</h3>

    <div className="settings-form-section-inner">
      <p className="settings-error">
        <i className="fa fa-exclamation-triangle"></i> An unexpected error
        occured
      </p>
    </div>
  </div>
)

export default props => (
  <div className="dash-main settings">
    <div className="settings-inner alt-scroll">
      <h1 className="settings-title">CLUSTER SETTINGS</h1>
      <p className="settings-description">
        These settings are used for the administration of your Airflow Cluster.
        Any changes performed here take immediate effect and do not impact
        cluster operations.
      </p>

      <div className="settings-form">
        <ErrorBoundary errorComponent={sectionError('BASIC INFO')}>
          <BasicInfo {...props} />
        </ErrorBoundary>

        <ErrorBoundary errorComponent={sectionError('ADVANCED')}>
          <Advanced {...props} />
        </ErrorBoundary>

        <ErrorBoundary errorComponent={sectionError('INTEGRATIONS')}>
          <Integrations {...props} />
        </ErrorBoundary>

        <ErrorBoundary errorComponent={sectionError('NOTIFICATIONS')}>
          <Notifications {...props} />
        </ErrorBoundary>

        <ErrorBoundary errorComponent={sectionError('USER ACCESS')}>
          <UserAccess {...props} />
        </ErrorBoundary>

        <ErrorBoundary errorComponent={sectionError('SUPPORT ACCESS')}>
          <SupportAccess {...props} />
        </ErrorBoundary>

        <ErrorBoundary errorComponent={sectionError('DECOMMISSION')}>
          <Decommission {...props} />
        </ErrorBoundary>
      </div>
    </div>
  </div>
)
