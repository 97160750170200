import React from 'react'
import Modal from '../Modal/Modal.js'
import Button from '../Button/Button.js'
import './Dialogue.scss'

export default ({
  className,
  title,
  subtitle,
  children,
  confirmText,
  onConfirm,
  confirmDisabled,
  confirmPending = false,
  closeText,
  onClose,
}) => (
  <Modal
    onClose={onClose}
    className={`alt-dialogue${className ? ' ' + className : ''}`}
  >
    <div className="dialogue-header">
      <h2 className="dialogue-title">{title}</h2>
      {subtitle && <div className="dialogue-subtitle">{subtitle}</div>}
    </div>

    {children && (
      <div className="dialogue-body alt-scroll" children={children} />
    )}

    <div className="dialogue-actions">
      <Button onClick={onClose}>{closeText}</Button>
      {onConfirm && (
        <Button onClick={onConfirm} disabled={confirmDisabled} pending={confirmPending}>
          {confirmText || 'CONFIRM'}
        </Button>
      )}
    </div>
  </Modal>
)
