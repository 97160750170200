import { http, digResponse, getRequestError } from 'util/index.js'

const encode = encodeURIComponent

export const getFiles = (api, clusterId, pageSize, page, search_critera) =>
  http
    .get(
      `${api}/clusters/${encode(
        clusterId
      )}/files?limit=${pageSize}&cursor=${page}&search=${search_critera}`
    )
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'getFiles')
    })

export const getFile = (api, clusterId, fileId) =>
  http
    .get(`${api}/clusters/${encode(clusterId)}/files/${encode(fileId)}`)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'getFile')
    })

export const uploadFile = (api, clusterId, subfolder, body) =>
  http
    .put(
      `${api}/clusters/${encode(clusterId)}/files/${encode(
        body.file_name
      )}?subfolder=${encode(subfolder)}`,
      body
    )
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'uploadFile')
    })

export const deleteFile = (api, clusterId, fileId) =>
  http
    .delete(`${api}/clusters/${encode(clusterId)}/files/${encode(fileId)}`)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'deleteFile')
    })
