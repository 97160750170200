import React, { useState, useEffect } from 'react'
import { getCosts } from 'store/clusters.js'
import Button from 'components/Button/Button.js'
import moment from 'moment'
import './Costs.scss'
import { SimpleMetric } from 'components/SimpleMetric/SimpleMetric'
import { clusterCostColumns } from './consts'

// 6m and 12m can be added back to the periodOpt array when more data is available
// , '6m', '12m'
const periodOpt = ['1d', '7d', '1m', '3m']

export const Costs = ({ cluster, region, ...props }) => {
  const [period, setPeriod] = useState('1m')
  const [state, setState] = useState({
    costs: {},
    costsLoading: true,
    costsError: false,
    showResults: false,
  })

  const getClusterCosts = () => {
    setState(prev => ({ ...prev, costsLoading: true, costsError: false }))
    let filterAmount = parseInt(period)
    let filterUnit = period.includes('d') ? 'days' : 'months'
    // Offset by 1 day
    return getCosts(
      region.api,
      cluster.cluster_id,
      new URLSearchParams({
        range_from:
          moment()
            .subtract(1, 'days')
            .subtract(filterAmount, filterUnit)
            .format('YYYY-MM-DD') || '',
        range_to: moment().subtract(1, 'days').format('YYYY-MM-DD') || '',
      }).toString()
    )
      .then(costs =>
        setState(prev => ({
          ...prev,
          costs,
          costsLoading: false,
          costsError: false,
        }))
      )
      .catch(err =>
        setState(prev => ({
          ...prev,
          costs: [],
          costsLoading: false,
          costsError: err.map(item => item.message),
        }))
      )
  }

  useEffect(() => {
    getClusterCosts()
  }, [period])

  let { costs, costsLoading, costsError } = state

  let displayError = null
  if (costsError) {
    let errorList = costsError.map((item, index) => {
      return (
        <p key={index}>
          <i className='fa fa-exclamation-triangle'></i>
          {item}
        </p>
      )
    })

    displayError = <div className='costs-error'>{errorList}</div>
  }

  return (
    <div className='dash-main costs'>
      <div className='costs-header'>
        <h3 className='list-title tooltip-ad'>
          CLUSTER COST SHOWBACK
          <div className='list-subtitle'>
            View estimated cluster costs for select periods of time.
          </div>
        </h3>
        {periodOpt.map(per => (
          <Button
            primary={per === period}
            key={per}
            onClick={() => setPeriod(per)}
            disabled={state.costsLoading}
          >
            {per}
          </Button>
        ))}

        <Button
          className='alt-refresh-button'
          onClick={getClusterCosts}
          disabled={costsLoading}
        >
          <i className={`fa fa-refresh`} />
        </Button>
      </div>

      <div className='costs-main alt-scroll'>
        {displayError}
        <div className='cost-row'>
          <CostColumn
            name={'TOTAL COSTS'}
            desc='Costs are based on available Observability data.'
            value={costs.total_costs || '-'}
            colClassname='cost-col-12'
          />
        </div>
        <div className='cost-row'>
          {Object.keys(clusterCostColumns).map((key, i) => (
            <CostColumn
              key={i}
              name={clusterCostColumns[key].fields[0].name}
              desc={clusterCostColumns[key].fields[0].description}
              value={
                (costs?.detail_costs &&
                  costs?.detail_costs[0][
                    clusterCostColumns[key].fields[0].key
                  ]) ||
                '-'
              }
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const CostColumn = ({ name, value, desc, colClassname = 'cost-col-6' }) => (
  <div className={colClassname}>
    <div className='cost-item-title'>
      {name}
      <div className='cost-item-subtitle'>{desc}</div>
    </div>
    <div className='cost-item-value'>
      <SimpleMetric name={name} value={value} type='currency' />
    </div>
  </div>
)
export default Costs
