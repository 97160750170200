import { http, getRequestError, digResponse } from 'util/index.js'

export const aeGroups = (region, email) =>
  http
    .get(
      `https://platform-internal.w2.ngap2.nike.com/auth/adgroups/v1?email=${encodeURIComponent(
        email
      )}&onboardedTo=ngap2&region=${encodeURIComponent(region)}`
    )
    .then(response => digResponse(response, 'data', 'groups'))
    .catch(response => {
      throw getRequestError(response, 'aeGroups')
    })
