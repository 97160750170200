import React, { Component } from 'react'
import Highlight from 'react-highlighter'
import { getClusters } from 'store/clusters.js'
import { Link } from 'react-router-dom'
import config from 'envs/config'
import ButtonLink from 'components/ButtonLink/ButtonLink.js'
import Button from 'components/Button/Button.js'
import Search from 'components/Search/Search.js'
import Table from 'components/Table/Table.js'
import './Clusters.scss'

const clusterCols = [
  {
    name: 'CREATED',
    key: 'created',
    maxWidth: 100,
  },
  {
    name: 'CLUSTER NAME',
    key: 'cluster_link',
    maxWidth: 180,
    highlight: false,
    sortMethod: (a, b) => (a.props.to > b.props.to ? 1 : -1),
  },
  {
    name: 'LABELS',
    key: 'label_els',
    highlight: false,
    maxWidth: 200,
  },
  {
    name: 'MEMBERS',
    key: 'member_els',
    highlight: false,
  },
  {
    name: 'STATUS',
    key: 'life_cycle',
    maxWidth: 130,
  },
  {
    name: 'AE COMPUTE',
    key: 'ae_compute_integration',
    maxWidth: 80,
    Cell: cell => cell.value?.toString()
  },
  {
    name: 'SANDBOX',
    key: 'sandbox',
    maxWidth: 70,
    Cell: cell => cell.value?.toString()
  },
  {
    name: 'AIRFLOW VERSION',
    key: 'airflow_version_compliant',
    maxWidth: 150,
  },
  {
    name: 'MANAGE',
    key: 'links',
    maxWidth: 150,
  },
]

const tsOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
}

export default class Clusters extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clusters: [],
      clustersLoading: true,
      clustersError: false,
      refreshing: false,
      filter: '',
    }
  }

  componentDidMount() {
    this.fetchClusters().then(() => {
      this.refreshInterval = setInterval(this.refreshClusters, 30000)
    })
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval)
  }

  fetchClusters = () =>
    getClusters(this.props.region.api)
      .then(data => {
        this.setState({
          clusters: this.formatClusters(data),
          clustersLoading: false,
          clustersError: false,
        })
      })
      .catch(err =>
        this.setState({
          clustersLoading: false,
          clustersError: err.map(item => item.message),
        })
      )

  refreshClusters = () => document.visibilityState === 'visible' &&
    this.setState({ refreshing: true }, () =>
      this.fetchClusters().finally(() => this.setState({ refreshing: false }))
    )

  formatClusters = clusters =>
    clusters.map(cluster => {
      cluster.links = [
        ['provisioned', 'running'].includes(cluster.life_cycle) ? (
          <a
            key="airflow-link"
            className="alt-airflow-link"
            href={config.airflowAdmin(
              this.props.region.value,
              cluster.cluster_name
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            Airflow
          </a>
        ) : (
          <span
            key="airflow-link"
            className="alt-airflow-link alt-link-disabled"
          >
            Airflow
          </span>
        ),
        <Link
          key="edit-link"
          className="alt-airflow-link"
          to={`/${encodeURI(this.props.region.value)}/clusters/${encodeURI(
            cluster.cluster_name
          )}`}
        >
          Edit
        </Link>,
        <Link
          key="copy-link"
          className="alt-airflow-link"
          to={`/${encodeURI(this.props.region.value)}/create?import=${encodeURI(
            cluster.cluster_name
          )}`}
        >
          Copy
        </Link>,
      ]

      // on-load formatting for search
      cluster.labels_str = cluster.labels
        .map(l => `${l.key}:${l.value}`)
        .join(', ')
        .toLowerCase()
      cluster.members_str = cluster.members
        .map(m => m.ad_group || m.sub)
        .join(', ')
        .toLowerCase()

      // local ts. 'sv' locale uses ISO 8601 format while still keeping browsers local tz.
      cluster.created = new Date(cluster.created_date).toLocaleString(
        'sv',
        tsOptions
      )
      return cluster
    })

  render() {
    let {
      clusters,
      clustersLoading,
      clustersError,
      refreshing,
      filter,
    } = this.state
    const unsupported = require('unsupported-versions.json')

    let filtered = clusters
      .filter(
        cluster =>
          cluster.cluster_name.toLowerCase().includes(filter.toLowerCase()) ||
          cluster.members_str.includes(filter.toLowerCase()) ||
          cluster.labels_str.includes(filter.toLowerCase())
      )
      .map(cluster => {
        let isSupported = true
        unsupported['unsupported-airflow-versions'].forEach(version => {
          if (cluster.airflow_version.includes(version)) {
            isSupported = false
          }
        })

        return {
          ...cluster,
          cluster_link: (
            <Link
              key="edit-link"
              className="alt-airflow-link"
              to={`/${encodeURI(this.props.region.value)}/clusters/${encodeURI(
                cluster.cluster_name
              )}`}
            >
              <Highlight search={filter}>{cluster.cluster_name}</Highlight>
            </Link>
          ),
          label_els: cluster.labels.map((label, i) => (
            <p className="alt-cluster-label" key={i}>
              <em className="alt-cluster-label-key">
                <Highlight search={filter}>{label.key}</Highlight>
              </em>
              <Highlight search={filter}>{label.value}</Highlight>
            </p>
          )),
          member_els: cluster.members.map((member, i) => (
            <p className="alt-cluster-member" key={i}>
              {member.role === 'admin' ? (
                <i className="fa fa-star"></i>
              ) : (
                <i className="fa fa-user"></i>
              )}
              <Highlight search={filter}>
                {member.ad_group || member.sub}
              </Highlight>
            </p>
          )),
          airflow_version_compliant: !isSupported ? (
            <div>
              {cluster.airflow_version}{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://docs.platforms.nike.com/managed-airflow-platform/versioning/supported-version-list"
              >
                <i
                  className="fa fa-exclamation-triangle vc-icon"
                  title="Your cluster is non-compliant with MAP's supported Airflow version list."
                ></i>
              </a>
            </div>
          ) : (
            <div>{cluster.airflow_version} </div>
          ),
        }
      })

    return (
      <div className="alt-page-wrapper">
        <div className="dash-main clusters">
          <div className="list-header">
            <h3 className="list-title">AIRFLOW CLUSTERS</h3>

            <div className="list-header-bar">
              <Search
                value={filter}
                onChange={e => this.setState({ filter: e.target.value })}
              />
              <Button
                className="alt-refresh-button"
                onClick={this.refreshClusters}
                disabled={refreshing}
              >
                <i className="fa fa-refresh"></i> REFRESH
              </Button>
              <ButtonLink
                primary
                borderless
                href={`${config.siteRoot}${encodeURI(
                  this.props.region.value
                )}/create`}
              >
                CREATE CLUSTER
              </ButtonLink>
            </div>
          </div>

          <div className="dash-main-list">
            <Table
              className="alt-clusters-list-table"
              rows={filtered}
              cols={clusterCols}
              loading={clustersLoading}
              error={clustersError}
              filter={filter}
            />
          </div>
        </div>
      </div>
    )
  }
}
