import React, { Component } from 'react'
import { ErrorBoundary } from 'components/index.js'
import MetricsError from './MetricsError.js'
import ChartSingle from './Charts/ChartSingle.js'
import Spinner from 'components/AltSpinner/AltSpinner.js'
import './Metrics.scss'

const ChartLoader = ({ loading, children }) =>
  loading ? <Spinner /> : children

const processPodMetrics = data => {
  let metrics = {}

  data.forEach(m => {
    metrics[m.name] = metrics[m.name] || []
    metrics[m.name].push({
      val: m.val,
      ts: new Date(m['@timestamp']).getTime(),
    })
  })

  Object.keys(metrics).forEach(key => {
    metrics[key].sort((a, b) => a.ts - b.ts)
  })

  // LEAVING THIS IN FOR DEBUG
  console.log(metrics)
  return metrics
}

export default class AirflowMetrics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      processedMetrics: false,
    }
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      processedMetrics:
        nextProps.metrics && processPodMetrics(nextProps.metrics),
    }
  }

  render() {
    let { processedMetrics } = this.state
    let { metricsLoading, tsFrom, tsTo } = this.props

    return (
      <div className="metrics-main alt-scroll">
        <div className="metric-chart-row">
          <ErrorBoundary
            errorComponent={<MetricsError error="Error Rendering Metric." />}
          >
            <ChartLoader loading={metricsLoading}>
              <div className="metric-chart">
                <ChartSingle
                  title="SCHEDULER HEARTBEAT"
                  xKey="ts"
                  yKey="val"
                  minX={tsFrom}
                  maxX={tsTo}
                  data={
                    processedMetrics &&
                    processedMetrics['airflow.scheduler_heartbeat']
                  }
                  {...this.props}
                />
              </div>

              <div className="metric-chart">
                <ChartSingle
                  title="TASK SUCCESSES"
                  xKey="ts"
                  yKey="val"
                  minX={tsFrom}
                  maxX={tsTo}
                  data={
                    processedMetrics && processedMetrics['airflow.ti_successes']
                  }
                  {...this.props}
                />
              </div>

              <div className="metric-chart">
                <ChartSingle
                  title="TASK FAILURES"
                  xKey="ts"
                  yKey="val"
                  minX={tsFrom}
                  maxX={tsTo}
                  data={
                    processedMetrics && processedMetrics['airflow.ti_failures']
                  }
                  {...this.props}
                />
              </div>
            </ChartLoader>
          </ErrorBoundary>
        </div>

        <div className="metric-chart-row">
          <ErrorBoundary
            errorComponent={<MetricsError error="Error Rendering Metric." />}
          >
            <ChartLoader loading={metricsLoading}>
              <div className="metric-chart">
                <ChartSingle
                  title="EXECUTOR OPEN SLOTS"
                  xKey="ts"
                  yKey="val"
                  minX={tsFrom}
                  maxX={tsTo}
                  data={
                    processedMetrics &&
                    processedMetrics['airflow.executor.open_slots']
                  }
                  {...this.props}
                />
              </div>

              <div className="metric-chart">
                <ChartSingle
                  title="EXECUTOR QUEUED TASKS"
                  xKey="ts"
                  yKey="val"
                  minX={tsFrom}
                  maxX={tsTo}
                  data={
                    processedMetrics &&
                    processedMetrics['airflow.executor.queued_tasks']
                  }
                  {...this.props}
                />
              </div>

              <div className="metric-chart">
                <ChartSingle
                  title="EXECUTOR RUNNING TASKS"
                  xKey="ts"
                  yKey="val"
                  minX={tsFrom}
                  maxX={tsTo}
                  data={
                    processedMetrics &&
                    processedMetrics['airflow.executor.running_tasks']
                  }
                  {...this.props}
                />
              </div>
            </ChartLoader>
          </ErrorBoundary>
        </div>

        <div className="metric-chart-row">
          <ErrorBoundary
            errorComponent={<MetricsError error="Error Rendering Metric." />}
          >
            <ChartLoader loading={metricsLoading}>
              <div className="metric-chart">
                <ChartSingle
                  title="DAG PARSE PROCESSES"
                  xKey="ts"
                  yKey="val"
                  minX={tsFrom}
                  maxX={tsTo}
                  data={
                    processedMetrics &&
                    processedMetrics['dag_processing.processes']
                  }
                  {...this.props}
                />
              </div>

              <div className="metric-chart">
                <ChartSingle
                  title="TOTAL PARSE TIME"
                  xKey="ts"
                  yKey="val"
                  minX={tsFrom}
                  maxX={tsTo}
                  data={
                    processedMetrics &&
                    processedMetrics['airflow.dag_processing.total_parse_time']
                  }
                  {...this.props}
                />
              </div>

              <div className="metric-chart">
                <ChartSingle
                  title="IMPORT ERRORS"
                  xKey="ts"
                  yKey="val"
                  minX={tsFrom}
                  maxX={tsTo}
                  data={
                    processedMetrics &&
                    processedMetrics['airflow.dagbag_import_errors']
                  }
                  {...this.props}
                />
              </div>
            </ChartLoader>
          </ErrorBoundary>
        </div>

        <div className="metric-chart-row">
          <ErrorBoundary
            errorComponent={<MetricsError error="Error Rendering Metric." />}
          >
            <ChartLoader loading={metricsLoading}>
              <div className="metric-chart">
                <ChartSingle
                  title="DAGBAG SIZE"
                  xKey="ts"
                  yKey="val"
                  minX={tsFrom}
                  maxX={tsTo}
                  data={
                    processedMetrics && processedMetrics['airflow.dagbag_size']
                  }
                  {...this.props}
                />
              </div>

              <div className="metric-chart">
                <ChartSingle
                  title="PROCESSOR TIMEOUTS"
                  xKey="ts"
                  yKey="val"
                  minX={tsFrom}
                  maxX={tsTo}
                  data={
                    processedMetrics &&
                    processedMetrics['dag_processing.processor_timeouts']
                  }
                  {...this.props}
                />
              </div>

              <div className="metric-chart">
                <ChartSingle
                  title="ZOMBIES KILLED"
                  xKey="ts"
                  yKey="val"
                  minX={tsFrom}
                  maxX={tsTo}
                  data={
                    processedMetrics &&
                    processedMetrics['airflow.zombies_killed']
                  }
                  {...this.props}
                />
              </div>
            </ChartLoader>
          </ErrorBoundary>
        </div>
      </div>
    )
  }
}
