import React, { Component } from 'react'
import Dialogue from 'components/Dialogue/Dialogue.js'
import { updateCluster } from 'store/clusters.js'
import './UpgradeVersion.scss'

export default class DagsDelete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      started: false,
      completed: false,
      error: false,
    }
  }

  updateVersion = () =>
    this.setState(
      {
        started: true,
      },
      () =>
        updateCluster(this.props.region.api, this.props.cluster.cluster_id, {
          config_version_id: this.props.version.config_version_id,
        })
          .then(() =>
            this.setState({
              completed: true,
              error: false,
            })
          )
          .catch(err =>
            this.setState({
              completed: true,
              error: err.map(item => item.message),
            })
          )
    )

  render() {
    let { version, onClose } = this.props
    let { started, completed, error } = this.state
    let confirmProps = {}
    let title = 'Upgraded Config Version.'
    let subtitle = `your config version is now ${version.description}`

    // dont show the confirm action/text after completion
    if (!completed) {
      title = 'Upgrade Config Version?'
      subtitle = (
        <span>
          Are you sure you want to upgrade to <i>{version.description}</i>?
          <br />
          This action is not reversible and may break things!
        </span>
      )
      confirmProps = {
        onConfirm: this.updateVersion,
        confirmText: 'UPGRADE CONFIG',
        confirmDisabled: started,
      }
    }

    if (completed && error) {
      const listErrors = error.map((item, index) => {
        return <li key={index}>{item}</li>
      })

      title = `Error Upgrading Config!`
      subtitle = (
        <>
          <p>
            The following errors were encountered when upgrading this config:
          </p>
          <ul>{listErrors}</ul>
        </>
      )
    }

    return (
      <Dialogue
        className="upgrade-version-dialogue"
        title={title}
        subtitle={subtitle}
        onClose={onClose}
        closeText={started ? 'CLOSE' : 'CANCEL'}
        {...confirmProps}
      ></Dialogue>
    )
  }
}
