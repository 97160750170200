import React, { Component } from "react"
import { getBuildspec } from "store/builds.js"
import { getPackages } from "store/packages.js"
import { debounce } from "util/index.js"
import ImportPackage from "./ImportPackage.js"
import DeletePackages from "./DeletePackages.js"
import SelectInput from "components/SelectInput/SelectInput.js"
import Search from "components/Search/Search.js"
import Button from "components/Button/Button.js"
import Table from "components/Table/Table.js"
import CreateBuild from "../Builds/CreateBuild.js"
import "./Packages.scss"

const packageCols = [
  {
    name: "PACKAGE NAME",
    key: "name",
    maxWidth: 180,
    highlight: true,
  },
  {
    name: "VERSION",
    key: "version",
    highlight: true,
  },
]

const importCols = {
  nike: [
    {
      name: "PACKAGE NAME",
      key: "name",
      maxWidth: 140,
      highlight: true,
    },
    {
      name: "VERSION",
      key: "version",
      maxWidth: 110,
    },
    {
      name: "MODIFIED",
      key: "modified",
      maxWidth: 150,
    },
    {
      name: "PATH",
      key: "path",
    },
  ],
}

const packageSourceOpts = [{ label: "artifactory.nike.com", value: "nike" }]

const tsOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
}

const comparePackages = (a, b) =>
  a.name === b.name && a.version === b.version && a.path === b.path

export default class Package extends Component {
  constructor(props) {
    super(props)
    this.state = {
      buildspec: false,
      buildspecLoading: true,
      buildspecError: false,

      packages: [],
      packagesLoading: true,
      packagesError: true,

      filter: "",
      search: "",

      packageSource: "nike",
      importSelected: false,
      importOpen: false,

      createOpen: false,

      deleteSelected: [],
      deleteOpen: false,
    }
    this.refetchPackages = debounce(this.fetchPackages, 500)
  }

  componentDidMount() {
    this.fetchBuildSpec()
    this.fetchPackages()
  }

  fetchBuildSpec = () =>
    getBuildspec(this.props.region.api, this.props.cluster.cluster_id)
      .then((data) =>
        this.setState({
          buildspec: data,
          buildspecLoading: false,
          buildspecError: false,
        })
      )
      .catch((err) =>
        this.setState({
          buildspecLoading: false,
          buildspecError: err.map((item) => item.message),
        })
      )

  fetchPackages = () =>
    getPackages(
      this.props.region.api,
      this.state.packageSource,
      this.state.search
    )
      .then((packages) =>
        this.setState({
          packages: packages.map((p) => ({
            ...p,
            // format modified key if package obj has key
            ...(p.modified && {
              // local ts. 'sv' locale uses ISO 8601 format while still keeping browsers local tz.
              modified: new Date(p.modified).toLocaleString("sv", tsOptions),
            }),
          })),
          packagesLoading: false,
          packagesError: false,
        })
      )
      .catch((err) =>
        this.setState({
          packagesLoading: false,
          packagesError: err.map((item) => item.message),
        })
      )

  render() {
    let { cluster, region, permissions } = this.props
    let {
      buildspec,
      buildspecLoading,
      buildspecError,
      packages,
      packagesLoading,
      packagesError,
      packageSource,
      importSelected,
      importOpen,
      createOpen,
      deleteSelected,
      deleteOpen,
      filter,
      search,
    } = this.state
    let editDisabled = !permissions["CLUSTER.UPDATE"]
    let filteredBuildspecPackages = (buildspec.packages || []).filter(
      (p) =>
        p.name.toLowerCase().includes(filter.toLowerCase()) ||
        p.version.toLowerCase().includes(filter.toLowerCase())
    )

    return (
      <div className='dash-main packages'>
        <div className='list-header'>
          <h3 className='list-title'>BUILDSPEC PACKAGES</h3>

          <div className='list-header-bar'>
            <Search
              value={filter}
              onChange={(e) => this.setState({ filter: e.target.value })}
            />
            <Button
              primary
              borderless
              onClick={() => this.setState({ createOpen: true })}
              locked={!permissions["CLUSTER.UPDATE"]}
            >
              TRIGGER BUILD
            </Button>
            <Button
              borderless
              primary
              locked={editDisabled}
              disabled={deleteSelected.length === 0}
              onClick={() => this.setState({ deleteOpen: true })}
            >
              REMOVE {deleteSelected.length > 0 ? deleteSelected.length : ""}{" "}
              PACKAGE
              {deleteSelected.length > 1 ? "S" : ""}
            </Button>
          </div>
        </div>

        <div className='dash-main-list'>
          <Table
            rows={filteredBuildspecPackages}
            cols={packageCols}
            loading={buildspecLoading}
            error={buildspecError}
            filter={filter}
            selectable
            onSelect={(p) =>
              this.setState({
                deleteSelected: deleteSelected.some((d) =>
                  comparePackages(d, p)
                )
                  ? deleteSelected.filter((d) => !comparePackages(d, p))
                  : [p, ...deleteSelected],
              })
            }
            onSelectAll={() =>
              this.setState({
                deleteSelected:
                  deleteSelected.length === buildspec.packages.length
                    ? []
                    : buildspec.packages,
              })
            }
            selectedMethod={(p) =>
              deleteSelected.some((d) => comparePackages(d, p))
            }
            selectedCount={deleteSelected.length}
            multiSelect
          />
        </div>

        <div className='list-split'>
          <div className='list-split-bar'>
            <h3 className='list-title'>
              PACKAGE REGISTRY
              <a
                className='list-subtitle'
                href='https://docs.platforms.nike.com/managed-airflow-platform'
                target='_blank'
                rel='noopener noreferrer'
              >
                DOCUMENTATION →
              </a>
            </h3>

            <SelectInput
              options={packageSourceOpts}
              value={packageSourceOpts.find(
                (opt) => opt.value === packageSource
              )}
              onChange={(opt) =>
                this.setState(
                  {
                    search: "",
                    packageSource: opt.value,
                    importSelected: false,
                    packages: [],
                    packagesLoading: true,
                    packagesError: true,
                  },
                  this.refetchPackages
                )
              }
              disabled={packagesLoading}
            />

            <Search
              value={search}
              onChange={(e) =>
                this.setState(
                  {
                    search: e.target.value,
                    importSelected: false,
                    packages: [],
                    packagesLoading: true,
                    packagesError: true,
                  },
                  this.refetchPackages
                )
              }
            />
            <Button
              locked={editDisabled}
              disabled={!importSelected}
              onClick={() => this.setState({ importOpen: true })}
            >
              IMPORT PACKAGE
            </Button>
          </div>
        </div>

        <div className='dash-main-list'>
          <Table
            rows={packages}
            cols={importCols[packageSource]}
            loading={packagesLoading}
            error={packagesError}
            filter={search}
            selectable
            onSelect={(p) =>
              this.setState({
                importSelected: comparePackages(importSelected, p) ? false : p,
              })
            }
            selectedMethod={(p) => comparePackages(importSelected, p)}
          />
        </div>

        {importOpen && (
          <ImportPackage
            region={region}
            cluster={cluster}
            buildspec={buildspec}
            source={packageSource}
            name={importSelected.path}
            onClose={() =>
              this.setState(
                {
                  buildspec: false,
                  buildspecLoading: true,
                  buildspecError: false,
                  importOpen: false,
                  importSelected: false,
                },
                this.fetchBuildSpec
              )
            }
          />
        )}

        {createOpen && (
          <CreateBuild
            region={region}
            cluster={cluster}
            onClose={() => this.setState({ createOpen: false })}
          />
        )}

        {deleteOpen && (
          <DeletePackages
            region={region}
            cluster={cluster}
            buildspec={buildspec}
            packages={deleteSelected}
            onClose={() =>
              this.setState(
                {
                  buildspec: false,
                  buildspecLoading: true,
                  buildspecError: false,
                  deleteOpen: false,
                  deleteSelected: [],
                },
                this.fetchBuildSpec
              )
            }
          />
        )}
      </div>
    )
  }
}
