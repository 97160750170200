import React, { Component } from 'react'
import DecommissionDialogue from './DecommissionDialogue.js'
import Button from 'components/Button/Button.js'

export default class Decommission extends Component {
  constructor(props) {
    super(props)
    this.state = {
      decommissionOpen: false,
    }
  }

  render() {
    let { decommissionOpen } = this.state
    let { cluster, region, permissions } = this.props

    return (
      <div className="settings-form-section">
        <h3 className="settings-form-section-title">DECOMMISSION</h3>
        <p className="settings-form-section-description">
          Decommissioning a cluster will immediately terminate the cluster and
          all data associated to it. Any automated backups are also immediately
          deleted. This action is irreversible.
        </p>

        <div className="settings-form-section-inner">
          <div className="button-row">
            <Button
              danger
              locked={!permissions['CLUSTER.DELETE']}
              onClick={() => this.setState({ decommissionOpen: true })}
            >
              DECOMMISSION CLUSTER
            </Button>
          </div>
        </div>

        {decommissionOpen && (
          <DecommissionDialogue
            region={region}
            cluster={cluster}
            onClose={() => this.setState({ decommissionOpen: false })}
          />
        )}
      </div>
    )
  }
}
