import React, { useState, useEffect } from 'react'
import CreatableSelect from 'react-select/creatable'
import FormField from '../FormField/FormField.js'
import Tooltip from 'components/Tooltip/Tooltip.js'
import './CreatableSelect.scss'
import Button from 'components/Button/Button.js'
import { Tooltip as ReactTooltip } from 'react-tooltip'

export default ({
  className,
  label,
  required,
  detail,
  error,
  options,
  value,
  onChange,
  onInputChange,
  tooltipText,
  disabled,
  placeholder,
  displayCopyButton,
  isMulti,
  isOptionDisabled,
}) => {
  const [valueCopied, setValueCopied] = useState(false)

  const handleCopyToClipboard = valueToCopy => {
    navigator.clipboard.writeText(valueToCopy)
    setValueCopied(true)
  }

  useEffect(() => {
    if (valueCopied) {
      setTimeout(() => setValueCopied(false), 2000)
    }
  }, [valueCopied])

  return (
    <FormField
      className={className}
      label={label}
      required={required}
      detail={detail}
      error={error}
    >
      <div
        className={`alt-creatable-select-input ${
          displayCopyButton ? 'copy-button' : ''
        }`}
      >
        <CreatableSelect
          className='creatable-select-container'
          classNamePrefix='creatable-select'
          isClearable
          onChange={onChange}
          onInputChange={onInputChange}
          options={options}
          value={value}
          isDisabled={disabled}
          placeholder={placeholder}
          isMulti={isMulti}
          isOptionDisabled={isOptionDisabled}
        />
        <Tooltip tooltipText={tooltipText} />
        {displayCopyButton && (
          <Button
            className='copy-to-clipboard-button'
            dataTooltipId='copy-to-clipboard'
            dataTooltipContent={valueCopied ? 'Copied!' : 'Copy To Clipboard'}
            onClick={() => handleCopyToClipboard(value?.label)}
            disabled={!value}
          >
            <ReactTooltip id='copy-to-clipboard' />
            <i className='fa fa-copy' />
          </Button>
        )}
      </div>
    </FormField>
  )
}
