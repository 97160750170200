import React, { Component } from 'react'
import { updateCluster } from 'store/clusters.js'
import { Advanced } from 'components/Settings/Settings.js'

export default class AdvancedSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cluster: props.cluster, // reference to unmodified values
      iam_role_arn: props.cluster.iam_role_arn,
      image: props.cluster.image,
      airflow_version: props.cluster.airflow_version,
      validation: {},
      submitPending: false,
      submitError: false,
    }
  }

  onSubmit = () =>
    this.setState(
      {
        submitPending: true,
        submitError: false,
      },
      () =>
        updateCluster(this.props.region.api, this.props.cluster.cluster_id, {
          iam_role_arn: this.state.iam_role_arn,
          image: this.state.image,
          airflow_version: this.state.airflow_version,
        })
          .then(data =>
            this.setState(
              {
                cluster: data,
                iam_role_arn: data.iam_role_arn,
                image: data.image,
                airflow_version: data.airflow_version,
                submitPending: false,
                submitError: false,
              },
              () => this.props.updateCluster(data)
            )
          )
          .catch(error =>
            this.setState({
              submitError: error.map(item => item.message),
              submitPending: false,
            })
          )
    )

  render() {
    let { region, permissions } = this.props
    let { ae_compute_integration } = this.props.cluster
    let {
      cluster,
      iam_role_arn,
      image,
      airflow_version,
      validation,
      submitPending,
      submitError,
    } = this.state
    let editDisabled = !permissions['CLUSTER.UPDATE']

    return (
      <Advanced
        ae_compute_integration={ae_compute_integration}
        iam_role_arn={iam_role_arn}
        image={image}
        airflow_version={airflow_version}
        onChange={(key, value, valueValidation) =>
          this.setState({
            [key]: value,
            validation: { ...validation, [key]: valueValidation },
          })
        }
        validation={validation}
        region={region}
        error={submitError}
        locked={editDisabled}
        onSave={this.onSubmit}
        savePending={submitPending}
        saveDisabled={
          Object.values(validation).filter(Boolean).length > 0 ||
          (iam_role_arn === cluster.iam_role_arn &&
            airflow_version === cluster.airflow_version &&
            image === cluster.image)
        }
      />
    )
  }
}
