import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './Search.styl'

const SearchResults = ({
  items,
  open,
  onClick,
  activeIndex,
  maxSearchItems = 7
}) => {
  if (items) {
    if (items.length < 1) {
      return (
        <ul
          className={`${styles.searchResults} ${open ? styles.open : ''}`}
          tabIndex="0"
        >
          <li className={styles.searchResult}>
            <span>No results.</span>
          </li>
        </ul>
      )
    }
    return (
      <ul
        className={`${styles.searchResults} ${open ? styles.open : ''}`}
        tabIndex="0"
      >
        {items.slice(0, maxSearchItems).map((item, i) => (
          <li
            key={`${item}-${i}`}
            className={`${styles.searchResult} ${
              activeIndex === i ? styles.active : ''
            }`}
          >
            <span data-value={item} onClick={onClick}>
              {item}
            </span>
          </li>
        ))}
      </ul>
    )
  }
}

SearchResults.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.string),
  maxSearchItems: PropTypes.number
}

class Search extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.previewItems &&
      nextProps.previewItems.length !== prevState.previewItemsCount
    ) {
      return {
        cursor: -1,
        previewItemsCount: nextProps.previewItems
          ? nextProps.previewItems.length
          : 0
      }
    }

    return null
  }

  constructor(props) {
    super(props)
    this.state = {
      cursor: -1,
      previewItemsCount: props.previewItems ? props.previewItems.length : 0
    } // No item selected
    this.handleKeypress = this.handleKeypress.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleMouseOver = this.handleMouseOver.bind(this)
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    document.addEventListener('keydown', this.handleKeypress)
    if (this.xRef) this.xRef.addEventListener('mouseover', this.handleMouseOver)
    this.search && this.search.focus()
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    document.removeEventListener('keydown', this.handleKeypress)
    if (this.xRef)
      this.xRef.removeEventListener('mouseover', this.handleMouseOver)
  }

  // Reset selected value from keypress
  handleMouseOver() {
    this.setState({ cursor: -1 })
  }

  handleClickOutside(event) {
    if (
      this.xRef &&
      !this.xRef.contains(event.target) &&
      event.target.id !== 'er-nav-search-submit'
    ) {
      this.setState({ cursor: -1 }, () => {
        this.props.onClose()
      })
    }
  }

  handleKeypress(e) {
    let { cursor } = this.state
    if (e.keyCode === 27) {
      return this.props.onClose()
    }
    if (e.keyCode === 13 && this.props.value.length > 0) {
      if (cursor === -1) {
        return this.props.onSubmit()
      }
      return this.props.onSubmit(this.props.previewItems[cursor])
    }
    if (e.keyCode === 38) this.navUp.bind(this)()
    if (e.keyCode === 40) this.navDown.bind(this)()
  }

  navUp() {
    let { cursor } = this.state
    if (cursor === -1 || cursor === 0) {
      this.setState({ cursor: this.props.previewItems.length - 1 })
    } else {
      this.setState({ cursor: --cursor })
    }
  }

  navDown() {
    let { cursor } = this.state
    if (cursor === -1 || cursor >= this.props.previewItems.length - 1) {
      this.setState({ cursor: 0 })
    } else this.setState({ cursor: ++cursor })
  }

  onResultClick(value) {
    this.setState({ cursor: -1 }, () => {
      this.props.onSubmit(value)
      this.props.onClose()
    })
  }

  render() {
    const { placeholder, onChange, previewItems, value } = this.props

    return (
      <div
        className={styles.search}
        ref={n => {
          this.xRef = n
        }}
      >
        <input
          className={styles.searchInput}
          value={this.props.value}
          ref={n => {
            this.search = n
          }}
          placeholder={placeholder}
          onChange={onChange}
          aria-label="Search through provided list object"
        />
        {previewItems && (
          <SearchResults
            items={previewItems}
            activeIndex={this.state.cursor}
            open={value.length > 0}
            onClick={e =>
              this.onResultClick.bind(this)(e.currentTarget.dataset.value)
            }
          />
        )}
      </div>
    )
  }
}

Search.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  placeholder: PropTypes.string,
  previewItems: PropTypes.arrayOf(PropTypes.string)
}

export default Search
