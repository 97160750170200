exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".epic-font-base___Gcr-I {\n  font-family: 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-base-md___1AH4q {\n  font-family: 'Helvetica Neue Medium', Helvetica, Arial, sans-serif;\n}\n.epic-font-marketing___3ElGO {\n  font-family: 'Futura', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.epic-font-brand___2FVxr {\n  font-family: 'Trade Gothic', 'Helvetica Neue Regular', Helvetica, Arial, sans-serif;\n}\n.input___29BO0 {\n  padding: 0 12px;\n  height: 39px;\n  border-radius: 1.5em;\n  border: 1px solid #e5e5e5;\n  font-size: 14px;\n  outline: none;\n  margin-bottom: 10px;\n  width: 100%;\n  text-indent: 4px;\n}\n.input___29BO0::placeholder {\n  color: #c7c7c7;\n}\n.input___29BO0.noBorder___6IjeM {\n  border: none;\n}\n.input___29BO0.hasErrors___3aaOK {\n  border: 1px solid #fe0000;\n  background-color: #fceeed;\n}\n.input___29BO0.hasErrors___3aaOK::placeholder {\n  color: #fe0000;\n}\n", ""]);

// Exports
exports.locals = {
	"epic-font-base": "epic-font-base___Gcr-I",
	"epic-font-base-md": "epic-font-base-md___1AH4q",
	"epic-font-marketing": "epic-font-marketing___3ElGO",
	"epic-font-brand": "epic-font-brand___2FVxr",
	"input": "input___29BO0",
	"noBorder": "noBorder___6IjeM",
	"hasErrors": "hasErrors___3aaOK"
};