import React, { Component } from 'react'
import FormField from 'components/FormField/FormField.js'
import TextInput from 'components/TextInput/TextInput.js'
import SelectInput from 'components/SelectInput/SelectInput.js'
import ButtonLink from 'components/ButtonLink/ButtonLink.js'
import config from 'envs/config'
import './Image.scss'

const versionOptions = [
  {
    label: '2.2.5-python38',
    value: '2.2.5-python38',
  },
  {
    label: '1.10.6-python36',
    value: '1.10.6-python36',
  },
  {
    label: '1.10.4-python36',
    value: '1.10.4-python36',
  },
  {
    label: '1.10.4-python27',
    value: '1.10.4-python27',
  },
]

export default class Image extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cluster: props.cluster, // reference to unmodified values
      image: props.cluster.image,
      airflow_version: props.cluster.airflow_version,
      validation: {},
      submitPending: false,
      submitError: false,
    }
  }

  render() {
    let { region } = this.props
    let { cluster, image, airflow_version } = this.state
    let urlBase = `${config.siteRoot}${region.value}/clusters/${cluster.cluster_name}`

    return (
      <div className='dash-main image'>
        <div className='image-inner alt-scroll'>
          <h1 className='image-base-title'>IMAGE MANAGEMENT</h1>
          <p className='image-base-description'>
            Clusters can be configured with several versions of Airflow out of
            the box.
          </p>

          <div className='image-base-form'>
            <div className='image-base-form-section'>
              <h3 className='image-base-form-section-title'>CLUSTER IMAGE</h3>
              <p className='image-base-form-section-subtitle'>
                Fields dictating the docker image used by this cluster. Updating
                these will require a cluster restart before changes will be
                applied.
              </p>

              <div className='image-base-form-section-body'>
                <SelectInput
                  label='AIRFLOW VERSION'
                  detail='Version number of Airflow to be used for this cluster.'
                  options={versionOptions}
                  value={versionOptions.find(
                    opt => opt.value === airflow_version
                  )}
                  disabled={true}
                />

                <TextInput
                  label='BASE DOCKER IMAGE'
                  detail='Base image used for this cluster.'
                  value={`artifactory.nike.com:9001/map/airflowcontainer/${airflow_version}:latest`}
                  disabled
                />

                <TextInput
                  label='CUSTOM DOCKER IMAGE'
                  detail='Optionally extend your base image with a docker image created from your clusters base image.'
                  value={image}
                  disabled={true}
                />
              </div>
            </div>

            <div className='image-base-form-section'>
              <h3 className='image-base-form-section-title'>BUILD SPEC</h3>
              <p className='image-base-form-section-subtitle'>
                Specify which custom images will be built allowing you to extend
                your cluster base image with additional python packages or build
                time commands. After making changes, visit the{' '}
                <strong>Builds</strong> tab after making any changes here to
                trigger a new build before restarting the cluster.
              </p>

              <div className='image-base-form-section-body'>
                <TextInput
                  label='RELEASE TAG'
                  detail='Base Image release tag to use.'
                  value='latest'
                  disabled
                />

                <FormField
                  label='PACKAGES'
                  detail='Python packages to be installed during image creation.'
                >
                  <ButtonLink
                    className='image-base-form-image-button'
                    href={`${urlBase}/packages`}
                  >
                    MANAGE PACKAGES
                  </ButtonLink>
                </FormField>

                <FormField
                  label='BUILD SCRIPT'
                  detail='Bash Script that will be run in during image creation.'
                >
                  <ButtonLink
                    className='image-base-form-image-button'
                    href={`${urlBase}/buildscript`}
                  >
                    MANAGE BUILD SCRIPT
                  </ButtonLink>
                </FormField>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
