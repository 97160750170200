import React from 'react'
import Modal from 'react-modal'
import './Modal.scss'

export default ({ className, onClose, children }) => (
  <Modal
    isOpen
    onRequestClose={onClose}
    closeTimeoutMS={150}
    className={`alt-modal ${className}`}
    overlayClassName="alt-modal-overlay"
    appElement={document.body}
  >
    <i
      className="fa fa-times alt-modal-exit"
      aria-hidden="true"
      onClick={onClose}
    ></i>
    <div className="alt-modal-content" children={children} />
  </Modal>
)
