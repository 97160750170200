import React, { Component } from 'react'
import { getCluster } from 'store/clusters.js'
import config from 'envs/config'
import '../Provisioning/Provisioning.scss'

export default props => {
  if (['updating', 'retrying'].includes(props.cluster.life_cycle))
    return <Updating {...props} />
  return props.children
}

class Updating extends Component {
  componentDidMount() {
    this.refreshInterval = setInterval(this.refreshCluster, 5000)
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval)
  }

  refreshCluster = () =>
    getCluster(this.props.region.api, this.props.cluster.cluster_id)
      .then(this.props.updateCluster)
      .catch(console.log)

  render() {
    return (
      <div className="dash-main provisioning">
        <h1 className="provisioning-title">
          Hang Tight while we update your cluster
        </h1>
        <p className="provisioning-subtitle">
          Clusters generally become available within 3 minutes, but can
          sometimes take up to 10 minutes. Our <a href={config.slack}>Slack</a>.
        </p>

        <div className="steps">
          <div className="step green">
            <div className="step-inner">
              <div className="step-spinner">
                <i className="fa fa-check"></i>
              </div>
              <h2 className="step-title">Update Pending</h2>
            </div>
          </div>

          <div className="step active">
            <div className="step-inner">
              <div className="step-spinner">
                <i className="fa fa-check"></i>
                <div className="step-spinning"></div>
              </div>
              <h2 className="step-title">Update Applying</h2>

              <div className="sub-steps">
                <div className="sub-step">
                  <h3 className="sub-step-title">
                    Applying Infrastructure Changes
                  </h3>
                </div>
                <div className="sub-step">
                  <h3 className="sub-step-title">Rebooting Apache Airflow</h3>
                </div>
                <div className="sub-step">
                  <h3 className="sub-step-title">
                    Verifying Apache Airflow is online and operating normally
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="step">
            <div className="step-inner">
              <div className="step-spinner">
                <div className="step-spinning"></div>
              </div>
              <h2 className="step-title">Update Complete</h2>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
