import React, { Component } from 'react'
import { generate as shortid } from 'shortid'
import { getHealth } from 'store/airflow.js'
import { getSchedulerStatus } from 'store/clusters'
import Button from 'components/Button/Button.js'
import './Health.scss'
import { Tooltip } from 'react-tooltip'

export default class ClusterHealth extends Component {
  constructor(...props) {
    super(...props)
    this.state = {
      webserver: false,
      scheduler: false,
      schedulerk8s: [],
    }
  }

  componentDidMount() {
    this.fetchHealth().then(() => {
      this.fetchInterval = setInterval(this.fetchHealth, 10000)
    })
  }

  componentWillUnmount() {
    clearInterval(this.fetchInterval)
  }

  fetchHealth = async () => {
    getHealth(this.props.region.value, this.props.cluster.cluster_name)
      .then(data =>
        this.setState({
          webserver: data.metadatabase.status,
          scheduler: data.scheduler.status,
        })
      )
      .catch(() =>
        this.setState({
          webserver: false,
          scheduler: false,
        })
      )
    !this.props.isChina &&
      getSchedulerStatus(this.props.region.api, this.props.cluster.cluster_name)
        .then(data =>
          this.setState({
            schedulerk8s: data.sort((a, b) => (a > b ? 1 : -1)),
          })
        )
        .catch(() =>
          this.setState({
            schedulerk8s: [{ pod_status: 'error' }],
          })
        )
  }

  healthStatusEl = (status, countString = '') =>
    ({
      healthy: (
        <p key={shortid()} className='alt-health-item-status alt-health-green'>
          Healthy
        </p>
      ),
      Running: (
        <p key={shortid()} className='alt-health-item-status alt-health-green'>
          Healthy{countString}
        </p>
      ),
      unhealthy: (
        <p key={shortid()} className='alt-health-item-status alt-health-yellow'>
          Unhealthy
        </p>
      ),
      Pending: (
        <p key={shortid()} className='alt-health-item-status alt-health-yellow'>
          Pending{countString}
        </p>
      ),
    }[status] || (
      <p key={shortid()} className='alt-health-item-status'>
        Unavailable
      </p>
    ))

  render() {
    let { webserver, scheduler, schedulerk8s } = this.state
    let {
      restartScheduler,
      restartAllowed,
      restartLocked,
      refreshHealth,
      resetRefreshHealth,
      isChina,
    } = this.props
    if (refreshHealth) this.fetchHealth().then(() => resetRefreshHealth())
    if (!schedulerk8s.length) schedulerk8s = [{ pod_status: 'error' }]

    return (
      <div className='alt-health'>
        <div className='alt-health-item'>
          <p className='alt-health-item-title'>WEBSERVER</p>
          {this.healthStatusEl(webserver)}
        </div>

        <div className='alt-health-item'>
          <p className='alt-health-item-title'>SCHEDULER</p>
          <Tooltip id='restart-scheduler-tooltip' />
          {isChina
            ? this.healthStatusEl(scheduler)
            : schedulerk8s?.map((x, i) =>
                this.healthStatusEl(
                  x.pod_status,
                  schedulerk8s.length > 1 &&
                    ` (${i + 1}/${schedulerk8s.length})`
                )
              )}
          {restartAllowed && (
            <Button
              className='btn-restart scheduler'
              onClick={() => restartScheduler()}
              locked={restartLocked}
              dataTooltipId='restart-scheduler-tooltip'
              dataTooltipContent='Restart Scheduler'
            >
              <i className='fa fa-refresh' />
            </Button>
          )}
        </div>
      </div>
    )
  }
}
