export function getHttpErrorMessage(error, location) {
  console.log(`MAP Error on ${location} -> `, error)
  if (!error) return ''
  if (error.response && error.response.data) error.data = error.response.data
  if (error.data) error = error.data
  if (error.message) return formatErrorMessage(error.message)
  if (error.detail) return formatErrorMessage(error.detail)
  return JSON.stringify(error)
}

function formatErrorMessage(message) {
  if (message.indexOf('Error: ') === 0) message = message.replace('Error: ', '')
  return message
}

export function getRequestError(request, location) {
  console.log(`MAP Error on ${location} -> `, request)
  // attempt to return formatted error returned from API

  try {
    console.log(request.response)
    return request.response.data?.errors || request.response.data?.message
  } catch (_) { }

  // ingress / kong error message
  try {
    console.log(request.response.data.message)
    if (request instanceof Error) {
      return request.response.data.message
    }
  } catch (_) { }

  // top level error thrown by gateway or browser
  try {
    console.log(request.message)
    if (request instanceof Error) {
      return request.message
    }
  } catch (_) { }

  // No error info to be found
  return 'An unexpected error occured.'
}
