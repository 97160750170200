import React from 'react'
import PropTypes from 'prop-types'
import styles from './RadioGroup.styl'
import RadioButton from '../RadioButton/RadioButton.js'
import Label from '../Label/Label.js'

function RadioGroup({ list, name, value, onChange, ...rest }) {
  return (
    <Label {...rest} className={styles.label}>
      <span className={styles.radioGroup}>
        {list.map(item => {
          return (
            <RadioButton
              name={name}
              value={item.value}
              label={item.label}
              key={item.label}
              checked={value === item.value}
              onChange={() => onChange(item.value)}
            />
          )
        })}
      </span>
    </Label>
  )
}

RadioGroup.propTypes = {
  /** Shape of the data needed to be passed into the component */
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired
    })
  ).isRequired,
  /** name attribute to be passed to radioButton elements */
  name: PropTypes.string,
  /** value of RadioGroup */
  value: PropTypes.any,
  /** label for the RadioGroup */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object
  ]),
  /** required flag for the RadioGroup */
  required: PropTypes.bool,
  /** onChange handler for the RadioGroup */
  onChange: PropTypes.func.isRequired,
  /** Any html attributes to apply to component */
  attributes: PropTypes.object
}

RadioGroup.defaultProps = {
  list: []
}

export default RadioGroup
