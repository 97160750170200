import React, { Component } from 'react'
import { Base64 } from 'js-base64'
import { debounce } from 'util/index.js'
import { getOperator, uploadOperator } from 'store/operators.js'
import { ToastsContext } from 'components/Toasts/Toasts.js'
import FileEdit from 'components/FileEdit/FileEdit.js'

export default class OperatorUpload extends Component {
  static contextType = ToastsContext
  constructor(props) {
    super(props)
    this.state = {
      name: props.files[0].name,
      content: props.files[0].content,
      uploadPending: false,
      uploadError: false,
      operator: {},
      operatorLoading: true,
    }
    this.refetchOperator = debounce(
      () => this.fetchOperator(this.state.name),
      500
    )
  }

  componentDidMount() {
    this.fetchOperator(this.props.files[0].name)
  }

  fetchOperator = operatorId =>
    getOperator(
      this.props.region.api,
      this.props.cluster.cluster_id,
      operatorId
    )
      .then(data => this.setState({ operator: data, operatorLoading: false }))
      .catch(() => this.setState({ operatorLoading: false }))

  handleNameChange = e =>
    this.setState(
      {
        name: e.target.value,
        operator: {},
        operatorLoading: true,
      },
      this.refetchOperator()
    )

  onSubmit = e =>
    this.setState(
      {
        uploadPending: true,
        uploadError: false,
      },
      () => {
        if (e) {
          e.preventDefault()
        }
        if (!this.validName()) {
          return
        }

        let body = {
          file_name: this.state.name,
          encoded_content: Base64.encode(this.state.content),
        }

        return uploadOperator(
          this.props.region.api,
          this.props.cluster.cluster_id,
          this.state.name,
          body
        )
          .then(() => {
            this.context.addToast(
              `Successfully Uploaded ${this.state.name}`,
              'ok'
            )
            return this.props.onClose()
          })
          .catch(error =>
            this.setState({
              uploadPending: false,
              uploadError: error.map(item => item.message),
            })
          )
      }
    )

  validName = () => /^[a-zA-Z0-9-_.]{1,}$/.test(this.state.name)

  render() {
    let { onClose } = this.props
    let {
      name,
      content,
      uploadPending,
      uploadError,
      operator,
      operatorLoading,
    } = this.state
    let validName = this.validName()
    let existingOpContent = false

    if (operator.encoded_content) {
      existingOpContent = Base64.decode(operator.encoded_content)
    }

    let errors = []

    if (uploadError) {
      const displayError = 'Upload Error: '
      uploadError.forEach(item => {
        errors.push(displayError.concat(item))
      })
    }

    return (
      <FileEdit
        errors={errors}
        headerTitle="UPLOAD OPERATOR"
        baseFolder="plugins"
        fileName={name}
        fileNameError={
          !validName && 'Alphanumeric, -, ., and _ characters only.'
        }
        fileNameOnChange={this.handleNameChange}
        originalContent={existingOpContent}
        modifiedContent={content}
        onContentChange={value => this.setState({ content: value })}
        getPending={operatorLoading}
        onSubmit={this.onSubmit}
        submitText="UPLOAD"
        submitPending={uploadPending}
        submitDisabled={!validName}
        onCancel={onClose}
      />
    )
  }
}
