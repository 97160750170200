import React, { Component } from 'react'
import { getPermissions } from 'store/permissions.js'
import RequestSupportDialogue from './RequestSupportDialogue.js'
import RevokeSupportDialogue from './RevokeSupportDialogue.js'
import Button from 'components/Button/Button.js'

export default class SupportAccess extends Component {
  constructor(props) {
    super(props)
    this.state = {
      supportGrantOpen: false,
      supportRevokeOpen: false,

      // support access state
      now: Date.now(),
      supportAccessUntil: false,
      supportAccessError: false,
    }
  }

  componentDidMount() {
    this.fetchSupportState()
    this.nowInterval = setInterval(
      () => this.setState({ now: Date.now() }),
      1000
    )
  }

  componentWillUnmount() {
    clearInterval(this.nowInterval)
  }

  fetchSupportState = () =>
    getPermissions(this.props.region.api, this.props.cluster.cluster_name)
      .then(data => {
        let supportStatement = data.statements.find(
          s =>
            s.principal.oauth2.claims.groups ===
            'App.ManagedAirflowPlatform.SupportAccess.Admin' &&
            s.time &&
            s.time.end
        )
        if (!supportStatement) {
          // Valid condition; no support access currently granted
          this.setState({
            supportAccessUntil: false,
            supportAccessError: false,
          })
          return
        }

        let supportAccessEnds = Date.parse(supportStatement.time.end)
        if (!supportAccessEnds) {
          // Invalid condition; support group but end date not found/parsed
          throw new Error('Error parsing support group end date.')
        }

        this.setState({ supportAccessUntil: supportAccessEnds })
      })
      .catch(error =>
        this.setState({
          supportAccessUntil: false,
          supportAccessError: error.map(item => item.message),
        })
      )

  supportAccessTimer = () => {
    let seconds = Math.floor(
      Math.max(0, this.state.supportAccessUntil - this.state.now) / 1000
    )
    let hours = Math.floor(seconds / 60 / 60)
    seconds -= hours * 60 * 60
    let minutes = Math.floor(seconds / 60)
    seconds -= minutes * 60

    return (
      <p>
        <mark>{hours}</mark>:<mark>{minutes}</mark>:<mark>{seconds}</mark>
      </p>
    )
  }

  render() {
    let {
      supportAccessUntil,
      supportAccessError,
      supportGrantOpen,
      supportRevokeOpen,
    } = this.state
    let { cluster, region, permissions } = this.props
    let editDisabled = !permissions['CLUSTER.UPDATE']

    let listErrors = null
    if (supportAccessError) {
      listErrors = supportAccessError.map((item, index) => {
        return (
          <p className="settings-error" key={index}>
            <i className="fa fa-exclamation-triangle"></i>
            {item}
          </p>
        )
      })
    }

    return (
      <div className="settings-form-section">
        <h3 className="settings-form-section-title">SUPPORT ACCESS</h3>
        <p className="settings-form-section-description">
          Authorize temporary (48 hour) admin access for MAP
          Platform Engineers to troubleshoot issues with your cluster.
        </p>

        <div className="settings-form-section-inner">
          {supportAccessError ? (
            <div>{listErrors}</div>
          ) : supportAccessUntil ? (
            <div className="button-row countdown-row">
              <Button className="countdown">
                SUPPORT ACCESS EXPIRES IN {this.supportAccessTimer()}
              </Button>
              <Button
                danger
                disabled={editDisabled}
                onClick={() => this.setState({ supportRevokeOpen: true })}
              >
                REVOKE
              </Button>
            </div>
          ) : (
            <div className="button-row">
              <Button
                disabled={editDisabled}
                onClick={() => this.setState({ supportGrantOpen: true })}
              >
                REQUEST ADMIN SUPPORT ACCESS
              </Button>
            </div>
          )}

          {supportGrantOpen && (
            <RequestSupportDialogue
              region={region}
              cluster={cluster}
              onClose={() =>
                this.setState(
                  { supportGrantOpen: false },
                  this.fetchSupportState
                )
              }
            />
          )}

          {supportRevokeOpen && (
            <RevokeSupportDialogue
              region={region}
              cluster={cluster}
              onClose={() =>
                this.setState(
                  { supportRevokeOpen: false },
                  this.fetchSupportState
                )
              }
            />
          )}
        </div>
      </div>
    )
  }
}
