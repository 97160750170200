import React, { Component } from 'react'
import { getConfig } from 'store/config.js'
import { updateCluster } from 'store/clusters.js'
import { Integrations } from 'components/Settings/Settings.js'

export default class IntegrationsSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cluster: this.props.cluster,
      configs: {},
      modified: false,
      submitPending: false,
      submitError: false,
    }
  }

  componentDidMount() {
    this.fetchConfigs()
  }

  fetchConfigs = () =>
    getConfig(this.props.region.api, this.props.cluster.cluster_id).then(
      configs => this.setState({ configs })
    )

  onSubmit = () =>
    this.setState(
      {
        submitPending: true,
        submitError: false,
      },
      () =>
        updateCluster(this.props.region.api, this.props.cluster.cluster_id, {
          members: this.state.cluster.members,
        })
          .then(data =>
            this.setState(
              {
                cluster: data,
                modified: false,
                submitPending: false,
                submitError: false,
              },
              () => this.props.updateCluster(data)
            )
          )
          .catch(error =>
            this.setState({
              submitError: error.map(item => item.message),
              submitPending: false,
            })
          )
    )

  render() {
    let { user, region, permissions } = this.props
    let { modified, submitPending, submitError } = this.state
    // Integrations component expects configs to be a key of cluster
    // because in cluster creation thats how the API accepts it
    let cluster = { ...this.state.cluster, configs: this.state.configs }
    let editDisabled = !permissions['CLUSTER.UPDATE']

    return (
      <Integrations
        user={user}
        region={region}
        cluster={cluster}
        onChange={updatedCluster =>
          this.setState({
            modified: true,
            cluster: { ...cluster, ...updatedCluster },
          })
        }
        error={submitError}
        locked={editDisabled}
        onSave={this.onSubmit}
        savePending={submitPending}
        saveDisabled={!modified}
        existingCluster
      />
    )
  }
}
