import React, { Component } from 'react'
import { Base64 } from 'js-base64'
import { getFile, uploadFile } from 'store/files.js'
import { ToastsContext } from 'components/Toasts/Toasts.js'
import Edit from 'components/FileEdit/FileEdit.js'

export default class FileEdit extends Component {
  static contextType = ToastsContext
  constructor(props) {
    super(props)
    this.state = {
      file: false,
      fileLoading: true,
      fileError: false,
      fileContent: false,
      uploadPending: false,
      uploadError: false,
    }
  }

  componentDidMount() {
    this.fetchFile()
  }

  onClose = () => this.props.history.replace(`/${encodeURI(this.props.region.value)}/clusters/${encodeURI(
    this.props.cluster.cluster_name)}/files`)

  fetchFile = () =>
    getFile(
      this.props.region.api,
      this.props.cluster.cluster_id,
      decodeURIComponent(this.props.match.params.fileId)
    )
      .then(data =>
        this.setState({
          file: data,
          fileLoading: false,
          fileError: false,
          fileContent: Base64.decode(data.encoded_content),
        })
      )
      .catch(err =>
        this.setState({
          fileLoading: false,
          fileError: err.map(item => item.message),
        })
      )

  onSubmit = e =>
    this.setState(
      {
        uploadPending: true,
        uploadError: false,
      },
      () => {

        let folder = '/'
        let sections = this.state.file.file_name.split('/').filter(Boolean)

        if (sections.length > 1) {
          folder = sections.slice(0, -1).join('/')
        }

        let body = {
          file_name: sections[sections.length - 1],
          encoded_content: Base64.encode(this.state.fileContent),
        }

        return uploadFile(
          this.props.region.api,
          this.props.cluster.cluster_id,
          folder,
          body
        )
          .then(() => {
            this.context.addToast(
              `Successfully Updated ${this.state.file.file_name}`,
              'ok'
            )
            return this.onClose()
          })
          .catch(error =>
            this.setState({
              uploadPending: false,
              uploadError: error.map(item => item.message),
            })
          )
      }
    )

  render() {
    let { permissions } = this.props
    let onClose = this.onClose
    let file_name = decodeURIComponent(this.props.match.params.fileId)
    let {
      fileContent,
      fileLoading,
      fileError,
      uploadPending,
      uploadError,
    } = this.state

    let errors = []

    if (!permissions['FILES.UPDATE']) {
      errors.push(
        'Read Only Mode; `FILES.UPDATE` permission required to modify.'
      )
    }
    if (uploadError) {
      const displayError = 'Upload Error: '
      uploadError.forEach(item => {
        errors.push(displayError.concat(item))
      })
    }
    if (fileError) {
      const displayError = 'File Error: '
      fileError.forEach(item => {
        errors.push(displayError.concat(item))
      })
    }

    return (
      <Edit
        errors={errors}
        headerTitle="EDIT FILE"
        baseFolder="code"
        fileName={file_name}
        modifiedContent={fileContent}
        onContentChange={value => this.setState({ fileContent: value })}
        getPending={fileLoading}
        onSubmit={this.onSubmit}
        submitText="SAVE"
        submitPending={uploadPending}
        submitDisabled={fileError}
        onCancel={onClose}
        readOnly={!permissions['FILES.UPDATE']}
      />
    )
  }
}
