import React, { Component } from 'react'
import SelectInput from 'components/SelectInput/SelectInput'
import { getPackage } from 'store/packages.js'
import { updateBuildspec } from 'store/builds.js'
import Dialogue from 'components/Dialogue/Dialogue.js'
import Spinner from 'components/AltSpinner/AltSpinner.js'
import './ImportPackage.scss'

export default class ImportPackage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pkg: false,
      pkgLoading: true,
      pkgError: false,

      started: false,
      completed: false,
      error: false,
      version: false,
      versionOptions: [],
    }
  }

  componentDidMount() {
    this.getPackage()
  }

  getPackage = () =>
    getPackage(this.props.region.api, this.props.source, this.props.name)
      .then(pkg =>
        this.setState({
          pkg,
          pkgLoading: false,
          pkgError: false,
          version: pkg.version,
          versionOptions:
            (pkg.releases &&
              pkg.releases.map(r => ({
                label: r,
                value: r,
              }))) ||
            [],
        })
      )
      .catch(err =>
        this.setState({
          pkg: false,
          pkgLoading: false,
          pkgError: err.map(item => item.message),
        })
      )

  importPackage = () =>
    this.setState(
      {
        started: true,
      },
      () => {
        let updated_body
        let index = this.props.buildspec.packages.findIndex(
          obj => obj.name === this.state.pkg.name
        )
        if (index !== -1) {
          this.props.buildspec.packages[index].version = this.state.version
          updated_body = this.props.buildspec
        } else {
          updated_body = {
            ...this.props.buildspec,
            packages: [
              {
                name: this.state.pkg.name,
                version: this.state.version,
              },
              ...this.props.buildspec.packages,
            ],
          }
        }
        updateBuildspec(
          this.props.region.api,
          this.props.cluster.cluster_id,
          updated_body
        )
          .then(() => this.setState({ completed: true, error: false }))
          .catch(err =>
            this.setState({
              completed: true,
              error: err.map(item => item.message),
            })
          )
      }
    )

  render() {
    let { onClose, buildspec } = this.props
    let {
      pkg,
      pkgLoading,
      pkgError,
      started,
      completed,
      error,
      version,
      versionOptions,
    } = this.state
    let body = false
    let confirmProps = {
      subtitle: 'Successfully imported package.',
    }
    let title = 'Package Imported.'

    // dont show the confirm action/text after completion
    if (!completed) {
      let index = buildspec.packages.findIndex(obj => obj.name === pkg.name)

      title = 'Import Package?'

      confirmProps = {
        onConfirm: this.importPackage,
        confirmText: 'IMPORT PACKAGE',
        confirmDisabled: started || pkgLoading || pkgError,
        subtitle:
          index !== -1 &&
          'Existing Package version will be replaced with selected version?',
      }
    }

    if (completed && error) {
      const listErrors = error.map((item, index) => {
        return <li key={index}>{item}</li>
      })

      title = 'Error Importing Package!'
      confirmProps = {
        subtitle: (
          <>
            <p>
              The following errors were encountered when importing this package:
            </p>
            <ul>{listErrors}</ul>
          </>
        ),
      }
    }

    if (pkgLoading) {
      body = <Spinner />
    }

    if (!body && pkgError) {
      const listErrors = pkgError.map((item, index) => {
        return (
          <p className="package-error" key={index}>
            <i className="fa fa-exclamation-triangle"></i>
            {item}
          </p>
        )
      })

      body = <div className="package-details">{listErrors}</div>
    }

    if (!body) {
      body = (
        <div className="package-details">
          <p className="package-detail">
            <b>Name</b>: {pkg.name}
          </p>
          <p className="package-detail">
            <b>Summary</b>: {pkg.summary}
          </p>
          <div className="package-detail">
            <SelectInput
              label="Version:"
              options={versionOptions}
              className="package-version-dp"
              onChange={opt =>
                this.setState({
                  version: opt.value,
                })
              }
              value={{ label: version, value: version }}
            />
          </div>
          <p className="package-detail">
            <b>Author</b>: {pkg.author}
          </p>
          <p className="package-detail">
            <b>Author Email</b>: {pkg.author_email}
          </p>
          <p className="package-detail">
            <b>Homepage</b>: {pkg.home_page}
          </p>
          <p className="package-detail">
            <b>License</b>: {pkg.license}
          </p>
        </div>
      )
    }

    return (
      <Dialogue
        title={title}
        onClose={onClose}
        closeText={started ? 'CLOSE' : 'CANCEL'}
        {...confirmProps}
      >
        {body}
      </Dialogue>
    )
  }
}
