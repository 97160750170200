import { http, digResponse, getRequestError } from 'util/index.js'
import config from 'envs/config'

export const getHealth = (region, clusterId) =>
  http
    .get(`${config.airflowProxy(region, clusterId)}/health`)
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'getHealth')
    })

export const validateDag = (region, clusterId, body) =>
  http
    .post(
      `${config.airflowProxy(
        region,
        clusterId
      )}/admin/rest_api/api?api=validate_dag`,
      body
    )
    .then(response => digResponse(response, 'data'))
    .catch(response => {
      throw getRequestError(response, 'validateDag')
    })

export const getDAGNames = (region, clusterId) =>
  http
    .get(`${config.airflowProxy(region, clusterId)}/api/v1/dags?limit=10000&only_active=false`)
    .then(response => digResponse(response, 'data', 'dags'))
    .catch(response => {
      throw getRequestError(response, 'getDAGNames')
    })
