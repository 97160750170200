import React from 'react'
import FormField from '../FormField/FormField.js'
import Tooltip from 'components/Tooltip/Tooltip.js'
import './TextInput.scss'

export default ({
  className,
  label,
  required,
  detail,
  error,
  value,
  disabled,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  aside,
  tooltipText,
}) => (
  <FormField
    className={className}
    label={label}
    required={required}
    detail={detail}
    error={error}
  >
    <div className="alt-text-input">
      <input
        value={value || ''}
        onChange={e => !disabled && onChange(e)}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        placeholder={placeholder}
      />
      <div
        id="aside"
        className={
          typeof tooltipText !== 'undefined'
            ? 'cluster-name-aside-tooltip'
            : 'cluster-name-aside'
        }
      >
        {aside}
      </div>
      <Tooltip tooltipText={tooltipText} />
    </div>
  </FormField>
)
